import React, { useState, useEffect } from 'react';
import {
  Button, Divider, notification, Tabs
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import * as Service from '../../core/Service';

import NavAndSideFrame from '../../components/NavAndSideFrame';
import OrderInfoTable from './OrderInfoTable';
import UserAccountForm from './UserAccountForm';

const { TabPane } = Tabs;

const UserAccountContainer = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { user_id } = queryString.parse(location.search);
  const { t } = useTranslation();
  const staff_id = useSelector((state) => state.app.company_user.company_user_id)
  const system_currency = useSelector((state) => state.app.meta.system_currency)
  const myPermission = useSelector((state) => state.app.company_user.myPermission);

  const [orderDataList, setOrderDataList] = useState([]);
  const [userObj, setUser] = useState({create_staff_id: staff_id, currency: system_currency.meta_value});
  const [title, setTitle] = useState('add')
  const [isAllowOrderInfoTap, setIsAllowOrderInfoTap] = useState(false);

  useEffect(() => {
    if (!_.isUndefined(user_id)) {
      setTitle('client_modify')
      fetchUser()
      fetchOrderDataByUser();
    }
    setOrderInfoTapVisible();
  }, [user_id]);

  const setOrderInfoTapVisible = () => {
    let isAllowPermission = _.intersection(myPermission, ['p_order_agreement_view', 'p_any'])
    if (!_.isEmpty(isAllowPermission)) {
      setIsAllowOrderInfoTap(true);
    }
  }

  const fetchUser = async () => {
    try {
      let result = await Service.call('get', `/api/user/${user_id}`);
      if (result.status !== 1) {
        return notification.warning({ message: '獲取資料失敗' });
      }
      // console.log(result)
      // Handle status
      if (result.data.status !== 1) {
        result.data.statusText = t('inactive')
      } else {
        result.data.statusText = t('active')
      }
      let { data } = result;
      if (data.create_staff_id === 0) data.create_staff_id = userObj.create_staff_id;
      if (!data.currency) {
        data.currency = system_currency.meta_value
      }
      setUser(data);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  const fetchOrderDataByUser = async () => {
    try {
      let result = await Service.call('get', `/api/order_info/search?user_id=${user_id}`);
      // console.log('result', result);

      if (result.status !== 1) {
        return notification.warning({ message: '獲取資料失敗' });
      }

      const orderList = result.data.map((item) => {
        let entity = item;
        entity.order_no_with_prefix = item.order_no_prefix + item.order_no;
        return entity;
      })

      setOrderDataList(orderList);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  return (
    <NavAndSideFrame {...props} title={`${t(title)} ${t('user')}`} selectedKey="user_account">
      <Tabs type="card" defaultActiveKey="user_form" onChange={(key) => null}>
        <TabPane tab={t('user')} key="user_form">
          <UserAccountForm
            initialValues={userObj}
          />
        </TabPane>

        {isAllowOrderInfoTap && (
          <TabPane tab={t('order_information')} key="order_table">
            <OrderInfoTable
              dataObj={orderDataList}
              fetchData={fetchOrderDataByUser}
            />
          </TabPane>
        )}
      </Tabs>
    </NavAndSideFrame>
  )
}

export default UserAccountContainer;