import React, { useState, useEffect } from "react";
import {
  notification,
  Tabs,
  Table,
  Badge,
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Tag,
  Empty,
  Select,
  InputNumber,
  Space,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import _ from "lodash";
import * as Service from "../../core/Service";
import * as Main from "../../core/Main";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;
const { confirm } = Modal;

const PetServiceCharge = (props) => {
  const { t, i18n } = useTranslation();

  const [dataList, setDataList] = useState([]);
  const [hydrationService, setHydrationService] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [recordId, setRecordId] = useState(0);
  const [selectedFormData, setSelectedFormData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    fetchData();
    fetchHydrationService();
  }, []);


  useEffect(() => {
    fetchData();
    fetchHydrationService();
  }, [i18n.language])

  const fetchData = async () => {
    // get formId-Category Look Up
    let options = await Main.fetchFormOption("pet_category", true);
    setCategoryOptions(options)
    let formIdCategoryLookUp = _.keyBy(options, 'value');
    let petServiceChargeResult = await Service.call(
      "get",
      `/api/pet_service_charge_lookup/all`
    );
    if (petServiceChargeResult.status !== 1) {
      return notification.warning({
        message: t("failed_to_obtain_information"),
      });
    }
    let data = petServiceChargeResult.data
    _.forEach(data, (item) => {
      item.pet_category = formIdCategoryLookUp[item.pet_category_id]
        ? formIdCategoryLookUp[item.pet_category_id].label
        : ''
    })
    setDataList(data);
  };

  const fetchHydrationService = async () => {
    let options = await Main.fetchFormOption("hydration_service", true);
    setHydrationService(options);
  };

  const columns = [
    {
      title: t("operation"),
      className: "p_pet_relation_edit",
      dataIndex: "pet_service_charge_lookup_id",
      render: (value, record, index) => {
        return (
          <Space>
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                console.log(value, record)
                setRecordId(value);
                setSelectedFormData(record);
                setModalVisible(true);
              }}
            />
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => {
                confirm({
                  title: `確認刪除${record.hydration_service_name} (${record.min_weight} - ${record.max_weight})？`,
                  okText: "是",
                  okType: "danger",
                  cancelText: "否",
                  onOk: async () => {
                    let result = await Service.call(
                      "delete",
                      `/api/pet_service_charge_lookup/${record.pet_service_charge_lookup_id}`
                    );
                    // console.log('result', result);

                    if (result.status !== 1) {
                      return notification.error({
                        message: "刪除失敗",
                        description: t(result.errorMessage),
                      });
                    }
                    // remove the item when result.status === 1
                    setDataList(_.filter(dataList, ({pet_service_charge_lookup_id}) => pet_service_charge_lookup_id !== record.pet_service_charge_lookup_id))
                    notification.success({ message: "刪除成功" });
                  },
                  onCancel: () => {
                    // console.log('Cancel');
                  },
                });
              }}
            />
          </Space>
        );
      },
    },
    {
      title: `${t("pet_category")}`,
      dataIndex: "pet_category",
      filters: _.map(categoryOptions, ({label, value}) => {
        return { text: label, value }
      }),
      onFilter: (value, record) => record.pet_category_id === value
    },
    {
      title: `${t("service_plan")}`,
      dataIndex: "hydration_service_name",
      filters: _.map(hydrationService, ({label, value}) => {
        return { text: label, value }
      }),
      onFilter: (value, record) => record.hydration_service_id === value
    },
    {
      title: `${t("min_weight")}`,
      dataIndex: "min_weight",
      sorter: (a, b) => a.min_weight - b.min_weight,
    },
    {
      title: `${t("max_weight")}`,
      dataIndex: "max_weight",
    },
    {
      title: `${t("price")}`,
      dataIndex: "price",
    },
    {
      title: `${t("active")}`,
      dataIndex: "is_active",
      filters: [
        {text: t('active'), value: 1},
        {text: t('inactive'), value: 0},
      ],
      onFilter: (value, record) => record.is_active === value,
      render: (text, record, index) => {
        // console.log("record", record.is_active)
        let { is_active } = record;
        return (
          <>
            {is_active === 1 ? (
              <Tag color="green">{t("active")}</Tag>
            ) : (
              <Tag color="red">{t("inactive")}</Tag>
            )}
          </>
        );
      },
    },
  ];

  return (
    <NavAndSideFrame
      {...props}
      title={t("pet_service_charge")}
      selectedKey="pet_service_charge"
    >
      {_.isEmpty(categoryOptions) && <Empty />}
      <Button
        type="primary"
        className="p_pet_relation_edit"
        onClick={() => {
          setRecordId(0);
          setSelectedFormData({});
          setModalVisible(true);
        }}
      >
        {t("add")}
      </Button>

      <Modal
        title={recordId > 0 ? t("edit") : t("add")}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <PetServiceChargeForm
          recordId={recordId}
          categoryOptions={categoryOptions}
          selectedFormData={selectedFormData}
          hydrationService={hydrationService}
          closeMethod={async () => {
            setModalVisible(false);
            fetchData();
          }}
        />
      </Modal>

      <Table
        columns={columns}
        dataSource={dataList}
        rowKey={({pet_service_charge_lookup_id}) => pet_service_charge_lookup_id}
      />
    </NavAndSideFrame>
  );
};

const TabComponent = (props) => {
  const { petCategoryObject, hydrationService } = props;
  const { t } = useTranslation();

  const [dataList, setDataList] = useState([]);
  const [recordId, setRecordId] = useState(0);
  const [selectedFormData, setSelectedFormData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const columns = [
    {
      title: t("operation"),
      className: "p_pet_relation_edit",
      dataIndex: "pet_service_charge_lookup_id",
      render: (value, record, index) => {
        return (
          <Space>
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                setRecordId(value);
                setSelectedFormData(record);
                setModalVisible(true);
              }}
            />
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => {
                confirm({
                  title: `確認刪除${record.hydration_service_name} (${record.min_weight} - ${record.max_weight})？`,
                  okText: "是",
                  okType: "danger",
                  cancelText: "否",
                  onOk: async () => {
                    let result = await Service.call(
                      "delete",
                      `/api/pet_service_charge_lookup/${record.pet_service_charge_lookup_id}`
                    );
                    // console.log('result', result);

                    if (result.status !== 1) {
                      return notification.error({
                        message: "刪除失敗",
                        description: t(result.errorMessage),
                      });
                    }

                    notification.success({ message: "刪除成功" });
                    getAllData();
                  },
                  onCancel: () => {
                    // console.log('Cancel');
                  },
                });
              }}
            />
          </Space>
        );
      },
    },
    {
      title: `${t("hydration_service_name")}`,
      dataIndex: "hydration_service_name",
      sorter: (a, b) => a.hydration_service_name.localeCompare(b.hydration_service_name)
    },
    {
      title: `${t("min_weight")}`,
      dataIndex: "min_weight",
      sorter: (a, b) => a.min_weight - b.min_weight,
    },
    {
      title: `${t("max_weight")}`,
      dataIndex: "max_weight",
    },
    {
      title: `${t("price")}`,
      dataIndex: "price",
    },
    {
      title: `${t("active")}`,
      dataIndex: "is_active",
      filters: [
        {text: t('active'), value: 1},
        {text: t('inactive'), value: 0},
      ],
      onFilter: (value, record) => record.is_active === value,
      render: (text, record, index) => {
        // console.log("record", record.is_active)
        let { is_active } = record;
        return (
          <>
            {is_active === 1 ? (
              <Tag color="green">{t("active")}</Tag>
            ) : (
              <Tag color="red">{t("inactive")}</Tag>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      let result = await Service.call(
        "get",
        `/api/pet_service_charge_lookup/all?pet_category_id=${petCategoryObject.form_option_id}`
      );
      // console.log('result', result);

      if (result.status !== 1) {
        return notification.warning({
          message: t("failed_to_obtain_information"),
        });
      }

      setDataList(result.data);
    } catch (error) {
      console.error("error >>> ", error);
    }
  };

  return (
    <>
      <Button
        type="primary"
        className="p_pet_relation_edit"
        onClick={() => {
          setRecordId(0);
          setSelectedFormData({});
          setModalVisible(true);
        }}
      >
        {t("create")}
      </Button>

      <Modal
        title={recordId > 0 ? t("edit") : t("create")}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <PetServiceChargeForm
          recordId={recordId}
          selectedFormData={selectedFormData}
          petCategoryObject={petCategoryObject}
          hydrationService={hydrationService}
          closeMethod={async () => {
            getAllData();
            setModalVisible(false);
          }}
        />
      </Modal>

      <Table
        columns={columns}
        dataSource={dataList}
        rowKey={() => "pet_service_charge_lookup"}
      />
    </>
  );
};

const PetServiceChargeForm = (props) => {
  const { t } = useTranslation();

  const {
    recordId,
    selectedFormData,
    categoryOptions,
    hydrationService,
    closeMethod,
  } = props;
  let initialValues = { is_active: 1 };
  let combinedInitialValues = { ...initialValues, ...selectedFormData };

  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      initialValues={combinedInitialValues}
      onFinish={async (values) => {
        let method = "post";
        if (recordId !== 0) {
          //  Edit
          method = "patch";
          values.pet_service_charge_lookup_id = recordId;
        }

        let result = await Service.call(
          method,
          "/api/pet_service_charge_lookup",
          values
        );
        // console.log('result', result)

        if (result.status !== 1) {
          return notification.error({
            message: t("submission_failed"),
            description: result.errorMessage,
          });
        }

        notification.success({ message: t("submitted_successfully") });
        closeMethod();
      }}
    >
      <Form.Item
        label={t("pet_category")}
        name="pet_category_id"
        rules={[{ required: true, message: t('please_enter_this_field') }]}
      >
        <Select
          showSearch
          placeholder="搜尋"
          options={categoryOptions}
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </Form.Item>

      <Form.Item
        label={t("hydration_service")}
        name="hydration_service_id"
        rules={[{ required: true, message: t('please_enter_this_field') }]}
      >
        <Select
          showSearch
          placeholder="搜尋"
          options={hydrationService}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </Form.Item>

      <Form.Item
        label={t("min_weight")}
        name="min_weight"
        rules={[
          {
            required: true,
            message: t('please_enter_this_field'),
          },
          {
            min: '0',
            message: t('must_be_a_positive_numerical_value'),
          }
        ]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label={t("max_weight")}
        name="max_weight"
        rules={[
          {
            required: true,
            message: t('please_enter_this_field'),
          },
          {
            min: '0',
            message: t('must_be_a_positive_numerical_value'),
          }
        ]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label={t("price")}
        name="price"
        rules={[
          {
            required: true,
            message: t('please_enter_this_field'),
          },
          {
            min: '0',
            message: t('must_be_a_positive_numerical_value'),
          }
        ]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label={t("active")}
        name="is_active"
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Radio value={1}>{t("active")}</Radio>
          <Radio value={0}>{t("inactive")}</Radio>
        </Radio.Group>
      </Form.Item>

      <Button type="primary" htmlType="submit">
        {t("submit")}
      </Button>
    </Form>
  );
};

export default PetServiceCharge;
