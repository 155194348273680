import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Row, Col, AutoComplete, Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Space, Modal, Card
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import OptionForm from './OptionForm'
import finishList from '../../data/finishList.json'

const debug = require('debug')('app:admin:pages:OrderInfoAshesServiceForm');

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;


const OrderInfoAshesServiceForm = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  let location = useLocation();

  let { order_id } = queryString.parse(location.search);
  let { initialValues, order_id: orderIdProp = 0, isReadOnly } = props
  if (orderIdProp > 0) {
    order_id = orderIdProp // Override order_id if prop existing
  }

  const isNeededOptions = [{ label: t('needed'), value: t('needed') }, { label: t('no_needed'), value: t('no_needed') }]

  const [ashesServiceInitialValues, setAshesServiceInitialValues] = useState(initialValues);
  const [serviceDetailForm] = Form.useForm();
  const [hydrationServiceOptions, setHydrationServiceOptions] = useState([]);
  const [ashesServiceOptions, setAshesServiceOptions] = useState([]);
  const [plantOptions, setPlantOptions] = useState([]);
  const [planterOptions, setPlanterOptions] = useState([]);
  const [grindTypeOptions, setGrindTypeOptions] = useState([]);
  const [photoLaceOptions, setPhotoLaceOptions] = useState([]);
  const [fontOptions, setfontOptions] = useState([]);
  const [modalVisible, setModalVisible] = useState(false)
  const [optionType, setOptionType] = useState(false)
  const [currentKey, setCurrentKey] = useState(0)
  const [itemOptions, setItemOptions] = useState([])
  const [viewCeremonyInfo, setViewCeremonyInfo] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    checkPermission();
  }, []);

  useEffect(() => {
    fetchFormOption()
    fetchAshesService();
  }, []);

  useEffect(() => {
    let newDefaultValues = { ...ashesServiceInitialValues, ...initialValues };
    setAshesServiceInitialValues(newDefaultValues);
  }, [initialValues]);

  useEffect(() => {
    serviceDetailForm.resetFields();
  }, [ashesServiceInitialValues]);

  const checkPermission = async () => {
    // call new api check p_order_ceremony_view
    let result = await Service.call("get", `/api/check_permission/p_order_ceremony_view?checkOnly=true`);
    // console.log(`result cp777 >> `, result)
    const {
      status = -1,
    } = result;

    if (status !== -1) {
      setViewCeremonyInfo(true);
    }
  }

  const fetchFormOption = async () => {
    fetchHydrationService();
    fetchAshesServiceOption();
    fetchPlantOption();
    fetchPlanterOption();
    fetchGrindType();
    fetchPhotoLace();
    fetchfont();
    fetchProductItem()
  }
  const fetchData = async (type, id, value) => {
    switch (type) {
      case "hydration_service":
        fetchHydrationService(id, value);
        serviceDetailForm.setFieldsValue({ hydration_service: value, hydration_service_id: id})
        break;
      case "ashes_service":
        fetchAshesServiceOption(id, value);
        fitNewCreateOption(id, value, type, "ashes_setup")
        break;
      case "plant":
        fetchPlantOption(id, value);
        fitNewCreateOption(id, value, type)
        break;
      case "planter":
        fetchPlanterOption(id, value);
        fitNewCreateOption(id, value, type, "plant_staff")
        break;
      case "grinding_type":
        fetchGrindType(id, value);
        fitNewCreateOption(id, value, type, "ashes_grind_type")
        break;
      case "photo_lace":
        fetchPhotoLace(id, value);
        fitNewCreateOption(id, value, type)
        break;
      case "font":
        fetchfont(id, value);
        fitNewCreateOption(id, value, type)
        break;
      default:
        break;
    }
  }

  const fetchHydrationService = async (id, value) => {
    let options = await Main.fetchFormOption('hydration_service', true)
    options.unshift({ value: 0, label: t('pre_chosen_option') })
    if (id) options.push({value: id, label: value})
    setHydrationServiceOptions(options);
  }

  const fetchAshesServiceOption = async (id, value) => {
    let options = await Main.fetchFormOption('ashes_service', true)
    if (id) options.push({value: id, label: value})
    setAshesServiceOptions(options);
  }

  const fetchPlantOption = async (id, value) => {
    let options = await Main.fetchFormOption('plant', true)
    if (id) options.push({value: id, label: value})
    setPlantOptions(options);
  }

  const fetchPlanterOption = async (id, value) => {
    let options = await Main.fetchFormOption('planter', true)
    if (id) options.push({value: id, label: value})
    setPlanterOptions(options);
  }
  // fetchGrindType
  const fetchGrindType = async (id, value) => {
    let options = await Main.fetchFormOption('grinding_type', true)
    if (id) options.push({value: id, label: value})
    setGrindTypeOptions(options);
  }
  // fetch font
  const fetchfont = async (id, value) => {
    let options = await Main.fetchFormOption('font', true)
    if (id) options.push({value: id, label: value})
    setfontOptions(options);
  }

  const fetchPhotoLace = async (id, value) => {
    let options = await Main.fetchFormOption('photo_lace', true)
    if (id) options.push({value: id, label: value})
    setPhotoLaceOptions(options)
  }

  const fetchProductItem = async () => {
    let results = await Service.call("get", "/api/product?is_active=1");
    // console.log('results', results);
    if (results.status !== 1) {
      return;
    }

    let options = results.data.map(({sku, product_id, product_name}) => {
      return {
        sku,
        product_id,
        product_name,
      };
    });
    setItemOptions(options)
  };

  const fetchAshesService = async () => {
    let results = await Service.call('get', `/api/order_info/ashes_service/${order_id}`);
    // console.log('resultsss', results);
    if (results.status !== 1) {
      return;
    }
    // console.log('ashesServiceInitialValues', ashesServiceInitialValues);
    // let ashesServiceObject = {ashes_service: results.data}

    let ashesServiceObject = {
      hydration_service_id: serviceDetailForm.getFieldValue("hydration_service_id"),
      hydration_service_total: serviceDetailForm.getFieldValue("hydration_service_total"),
      ashes_pick_up_time: serviceDetailForm.getFieldValue("ashes_pick_up_time"),
      confirmed_ashes_pick_up_time: serviceDetailForm.getFieldValue("confirmed_ashes_pick_up_time"),
      ashes_remark: serviceDetailForm.getFieldValue("ashes_remark"),
      ashes_pick_up_time_status: serviceDetailForm.getFieldValue("ashes_pick_up_time_status"),
    }

    ashesServiceObject.ashes_service = []; // Default empty array
    if (!_.isEmpty(results.data)) {
      let ashesServices = _.orderBy(results.data, ['sku'], 'asc')
      ashesServiceObject.ashes_service = ashesServices.map((item) => {
        let perAshesServiceObject = { ashes_setup: item.ashes_setup }
        perAshesServiceObject.ashes_setup_id = item.ashes_setup_id;
        perAshesServiceObject.plant_id = item.plant_id ? item.plant_id : undefined;
        perAshesServiceObject.plant_staff_id = item.plant_staff_id ? item.plant_staff_id : undefined;
        perAshesServiceObject.ashes_grind_type_id = item.ashes_grind_type_id ? item.ashes_grind_type_id : undefined;
        perAshesServiceObject.photo_lace_id = item.photo_lace_id ? item.photo_lace_id : undefined;
        perAshesServiceObject.font_id = item.font_id ? item.font_id : undefined;
        perAshesServiceObject.plant_time_status = item.plant_time_status;
        perAshesServiceObject.product_name = item.product_name;
        perAshesServiceObject.sku = item.sku;
        perAshesServiceObject.product_id = item.product_id;
        if (!_.isEmpty(item.name)) perAshesServiceObject.name = item.name;
        if (!_.isEmpty(item.photo)) perAshesServiceObject.photo = item.photo;
        if (!_.isEmpty(item.plant)) perAshesServiceObject.plant = item.plant;
        if (!_.isEmpty(item.plant_staff)) perAshesServiceObject.plant_staff = item.plant_staff;
        if (!_.isEmpty(item.shoot_setup)) perAshesServiceObject.shoot_setup = item.shoot_setup;
        if (!_.isEmpty(item.date)) perAshesServiceObject.date = item.date;
        if (_.isInteger(item.plant_time)) perAshesServiceObject.plant_time = moment.unix(item.plant_time);
        if (!_.isEmpty(item.ashes_grind_type)) perAshesServiceObject.ashes_grind_type = item.ashes_grind_type;
        if (!_.isEmpty(item.photo_lace)) perAshesServiceObject.photo_lace = item.photo_lace;
        if (!_.isEmpty(item.remark)) perAshesServiceObject.remark = item.remark;
        if (!_.isEmpty(item.font)) perAshesServiceObject.font = item.font;

        return perAshesServiceObject;
      })
    }

    let nextAshesServiceInitialValues = { ...ashesServiceInitialValues, ...ashesServiceObject }
    // console.log('nextAshesServiceInitialValues', nextAshesServiceInitialValues)
    setAshesServiceInitialValues(nextAshesServiceInitialValues);
  }

  const calculationHydrationService = async (hydration_service_id = 0) => {
    let extraQueryString = ''
    if (hydration_service_id !== 0) {
      extraQueryString = `&hydration_service_id=${hydration_service_id}`
    }
    let result = await Service.call('get', `/api/hydration_service_charge/calculation?order_id=${order_id}${extraQueryString}`);
    serviceDetailForm.setFieldsValue({ hydration_service_total: result.data })
  }

  const fitNewCreateOption = (id, value, type, name) => {
    let ashes_service = serviceDetailForm.getFieldValue("ashes_service");
    if (name) {
      let name_id = name + "_id";
      ashes_service[currentKey][name] = value
      ashes_service[currentKey][name_id] = id
    } else {
      let name_id = type + "_id"
      ashes_service[currentKey][type] = value
      ashes_service[currentKey][name_id] = id
    }

    serviceDetailForm.setFieldsValue({ ashes_service })
  }

  // ger redux site meta value
  const meta = useSelector((state) => state.app.meta);

  const dateFormat = `${
    !_.isEmpty(meta.system_date_format.meta_value) ? meta.system_date_format.meta_value : 'YYYY/MM/DD'
  } ${
    !_.isEmpty(meta.system_time_format.meta_value) ? meta.system_time_format.meta_value : 'HH:mm'
  }`

  if (!viewCeremonyInfo) {
    return (
      <>
        <div style={{marginTop: 75, textAlign: 'center', fontWeight: 'bold'}}>
          Unauthorized Access
        </div>
      </>
    )
  }

  return (
    <>
      <Modal
        title={t('create') + t(optionType)}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <OptionForm
          selectedFormData={{}}
          type={optionType}
          closeMethod={async (form_option_id, value) => {
            fetchData(optionType, form_option_id, value);
            setModalVisible(false);
          }}
        />
      </Modal>
      <Form
        form={serviceDetailForm}
        name="serviceDetailForm"
        layout="vertical"
        initialValues={ashesServiceInitialValues}
        onFinish={async (values) => {
          console.log('values', values);
          // return;

          if (_.isUndefined(order_id)) {
            notification.error({ message: t("submission_failed"), description: '請先提交客戶及寵物資料' });
            return;
          }

          values.order_id = order_id;
          setSubmitLoading(true);
          let data = await Service.call('patch', '/api/order_info', values);
          // console.log('data', data);

          if (data.status !== 1) {
            notification.error({ message: t("submission_failed"), description: data.errorMessage });
            setSubmitLoading(false);
            return;
          }

          let ashesServiceData = await Service.call('post', '/api/order_info/ashes_service', values);
          // console.log('ashesServiceData', ashesServiceData)

          if (ashesServiceData.status !== 1) {
            notification.error({ message: t("submission_failed"), description: ashesServiceData.errorMessage });
            setSubmitLoading(false);
            return;
          }

          notification.success({ message: t("submitted_successfully") });
          setSubmitLoading(false);
          props.onChange()
          fetchAshesService();
        }}
      >

        <Divider>
          ***
          {' '}
          {t("ashes_plant_planting_arrangement")}
          {' '}
          ***
        </Divider>

        <Form.Item
          label={t("hydration_service")}
          name="hydration_service"
          hidden
        >
          <Input disabled />
        </Form.Item>
        <Row align="middle" gutter={24}>
          <Col span={10}>
            <Form.Item
              label={t("hydration_service")}
              name="hydration_service_id"
            >
              <Select
                disabled={isReadOnly}
                showSearch
                options={_.sortBy(hydrationServiceOptions, ['label'])}
                placeholder={t('click_to_view_options')}
                filterOption={(inputValue, option) => {
                  return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                }}
                onChange={async (value, option) => {
                  let hydrationService = ''
                  if (value) {
                    hydrationService = option.label
                  }
                  serviceDetailForm.setFieldsValue({ hydration_service: hydrationService })
                  calculationHydrationService(value)
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Button
              disabled={isReadOnly}
              type="primary"
              shape="circle"
              className="p_form_option_edit"
              onClick={() => {
                setModalVisible(true)
                setOptionType('hydration_service')
              }}
              icon={<PlusOutlined />}
            />
          </Col>
        </Row>
        <Form.Item
          label={t("hydration_service_total")}
          name="hydration_service_total"
        >
          <InputNumber
            disabled={isReadOnly}

            placeholder={t('total_amount')}
          />
        </Form.Item>

        <Form.Item
          label={t("ashes_pick_up_time")}
          name="ashes_pick_up_time"
        >
          <DatePicker
            showTime
            disabled={isReadOnly}
            format={dateFormat}
            placeholder={t('please_select')}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={t("confirmed_ashes_pick_up_time")}
          name="confirmed_ashes_pick_up_time"
        >
          <DatePicker
            showTime
            disabled={isReadOnly}
            format={dateFormat}
            // onChange={(value) => {
            //   if (value) return serviceDetailForm.setFieldsValue({ ashes_pick_up_time_status: 1 })
            //   if (!value) return serviceDetailForm.setFieldsValue({ ashes_pick_up_time_status: 0 })
            // }}
            placeholder={t('please_select')}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={t("ashes_pick_up_time_status")}
          name="ashes_pick_up_time_status"
        >
          <Select>
            {_.map(finishList, (val, key) => {
              return (
                <Option value={val}>{t(`${key}`)}</Option>
              )
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label={t("ashes_remark")}
          name="ashes_remark"
        >
          <Input disabled={isReadOnly} />
        </Form.Item>

        <Form.List name="ashes_service">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, dynamicRowIndex) => (
                  <Card key={field.key} style={{ marginBottom: 30, borderRadius: 15, borderWith: 2 }}>
                    <Row gutter={24} align="middle">
                      <Divider>
                        {t('ashes_setup')}
                        (
                        {dynamicRowIndex + 1}
                        )
                      </Divider>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'plant_time_status']}
                          fieldKey={[field.fieldKey, 'plant_time_status']}
                          label={t("plant_time_status")}
                        >
                          <Select
                            disabled={isReadOnly}
                          >
                            {_.map(finishList, (val, key) => {
                              return (
                                <Option value={val}>{t(`${key}`)}</Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Row align="middle" gutter={[20, 0]}>
                          <Col span={20}>
                            <Form.Item
                              {...field}
                              label={t("ashes_setup")}
                              name={[field.name, 'ashes_setup']}
                              fieldKey={[field.fieldKey, 'ashes_setup']}
                              hidden
                            >
                              <Input disabled />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'ashes_setup_id']}
                              fieldKey={[field.fieldKey, 'ashes_setup_id']}
                              rules={[{ required: true, message: '欠缺骨灰安排' }]}
                              label={t("ashes_setup")}
                            >
                              <Select
                                disabled={isReadOnly}

                                showSearch
                                style={{ width: '100%' }}
                                options={_.sortBy(ashesServiceOptions, ['label'])}
                                placeholder={t('click_to_view_options')}
                                filterOption={(inputValue, option) => {
                                  return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                onChange={async (value, option) => {
                                  let ashesSetup = ''
                                  if (value) {
                                    ashesSetup = option.label
                                  }
                                  let ashes_service = serviceDetailForm.getFieldValue("ashes_service");
                                  ashes_service[dynamicRowIndex].ashes_setup = ashesSetup
                                  serviceDetailForm.setFieldsValue({ ashes_service })
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Button
                              disabled={isReadOnly}
                              type="primary"
                              shape="circle"
                              className="p_form_option_edit"
                              onClick={() => {
                                setModalVisible(true)
                                setOptionType('ashes_service')
                                setCurrentKey(field.key)
                              }}
                              icon={<PlusOutlined />}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'photo']}
                          fieldKey={[field.fieldKey, 'photo']}
                          rules={[{ required: true, message: '欠缺列印相片' }]}
                          label={t("photo")}
                        >
                          <AutoComplete
                            disabled={isReadOnly}
                            style={{ width: '100%' }}
                            options={isNeededOptions}
                            // filterOption={(inputValue, option) => {
                            //   return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            // }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle" gutter={24}>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'name']}
                          fieldKey={[field.fieldKey, 'name']}
                          rules={[{ required: true, message: '欠缺名字' }]}
                          label={t('name')}
                        >
                          <AutoComplete
                            disabled={isReadOnly}
                            style={{ width: '100%' }}
                            options={isNeededOptions}
                            // filterOption={(inputValue, option) => {
                            //   return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            // }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'date']}
                          fieldKey={[field.fieldKey, 'date']}
                          // rules={[{ required: true, message: '欠缺日期' }]}
                          label={t('date')}
                        >
                          <AutoComplete
                            disabled={isReadOnly}
                            style={{ width: '100%' }}
                            options={isNeededOptions}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'remark']}
                          fieldKey={[field.fieldKey, 'remark']}
                          label={t('remark')}
                        >
                          <TextArea disabled={isReadOnly} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle" gutter={24}>
                      <Col span={8}>
                        <Row gutter={[20, 0]} align="middle">
                          <Col span={20}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'plant']}
                              fieldKey={[field.fieldKey, 'plant']}
                              label={t("plant")}
                              hidden
                            >
                              <Input disabled />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'plant_id']}
                              fieldKey={[field.fieldKey, 'plant_id']}
                              label={t('plant')}
                            >
                              <Select
                                disabled={isReadOnly}
                                allowClear
                                showSearch
                                style={{ width: '100%' }}
                                options={plantOptions}
                                placeholder={t('click_to_view_options')}
                                filterOption={(inputValue, option) => {
                                  return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                onChange={async (value, option) => {
                                  let plant = ''
                                  if (value) {
                                    plant = option.label
                                  }
                                  let ashes_service = serviceDetailForm.getFieldValue("ashes_service");
                                  ashes_service[dynamicRowIndex].plant = plant
                                  serviceDetailForm.setFieldsValue({ ashes_service })
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Button
                              disabled={isReadOnly}

                              type="primary"
                              shape="circle"
                              className="p_form_option_edit"
                              onClick={() => {
                                setModalVisible(true)
                                setOptionType('plant')
                                setCurrentKey(field.key)
                              }}
                              icon={<PlusOutlined />}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'plant_time']}
                          fieldKey={[field.fieldKey, 'plant_time']}
                          label={t('plant_time')}
                        >
                          <DatePicker
                            showTime
                            disabled={isReadOnly}
                            style={{ width: '100%' }}
                            placeholder={t('plant_time')}
                            format={dateFormat}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Row align="middle" gutter={[20, 0]}>
                          <Col span={20}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'plant_staff']}
                              fieldKey={[field.fieldKey, 'plant_staff']}
                              label={t("plant_staff")}
                              hidden
                            >
                              <Input disabled />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'plant_staff_id']}
                              fieldKey={[field.fieldKey, 'plant_staff_id']}
                              label={t('plant_staff')}
                            >
                              <Select
                                disabled={isReadOnly}
                                showSearch
                                allowClear
                                style={{ width: '100%' }}
                                options={planterOptions}
                                placeholder={t('click_to_view_options')}
                                filterOption={(inputValue, option) => {
                                  return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                onChange={async (value, option) => {
                                  let plantStaff = ''
                                  if (value) {
                                    plantStaff = option.label
                                  }
                                  let ashes_service = serviceDetailForm.getFieldValue("ashes_service");
                                  ashes_service[dynamicRowIndex].plant_staff = plantStaff
                                  serviceDetailForm.setFieldsValue({ ashes_service })
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Button
                              disabled={isReadOnly}
                              type="primary"
                              shape="circle"
                              className="p_form_option_edit"
                              onClick={() => {
                                setModalVisible(true)
                                setOptionType('planter')
                                setCurrentKey(field.key)
                              }}
                              icon={<PlusOutlined />}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row align="middle" gutter={24}>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'shoot_setup']}
                          fieldKey={[field.fieldKey, 'shoot_setup']}
                          label={t('shoot_setup')}
                        >
                          <AutoComplete
                            disabled={isReadOnly}
                            style={{ width: '100%' }}
                            options={isNeededOptions}
                            // filterOption={(inputValue, option) => {
                            //   return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            // }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Row align="middle" gutter={[20, 0]}>
                          <Col span={20}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'ashes_grind_type']}
                              fieldKey={[field.fieldKey, 'ashes_grind_type']}
                              label={t("ashes_grind_type")}
                              hidden
                            >
                              <Input disabled />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'ashes_grind_type_id']}
                              fieldKey={[field.fieldKey, 'ashes_grind_type_id']}
                              label={t('ashes_grind_type')}
                            >
                              <Select
                                showSearch
                                disabled={isReadOnly}
                                style={{ width: '100%' }}
                                options={grindTypeOptions}
                                placeholder={t('click_to_view_options')}
                                filterOption={(inputValue, option) => {
                                  return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                onChange={async (value, option) => {
                                  let ashesGrindType = ''
                                  if (value) {
                                    ashesGrindType = option.label
                                  }
                                  let ashes_service = serviceDetailForm.getFieldValue("ashes_service");
                                  ashes_service[dynamicRowIndex].ashes_grind_type = ashesGrindType
                                  serviceDetailForm.setFieldsValue({ ashes_service })
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Button
                              disabled={isReadOnly}
                              type="primary"
                              shape="circle"
                              className="p_form_option_edit"
                              onClick={() => {
                                setModalVisible(true)
                                setOptionType('grinding_type')
                                setCurrentKey(field.key)
                              }}
                              icon={<PlusOutlined />}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={24} align="middle">
                      <Col span={8}>
                        <Row align="middle" gutter={[20, 0]}>
                          <Col span={20}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'photo_lace']}
                              fieldKey={[field.fieldKey, 'photo_lace']}
                              label={t("photo_lace")}
                              hidden
                            >
                              <Input disabled />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'photo_lace_id']}
                              fieldKey={[field.fieldKey, 'photo_lace_id']}
                              label={t('photo_lace')}
                            >
                              <Select
                                disabled={isReadOnly}
                                showSearch
                                allowClear
                                style={{ width: '100%' }}
                                options={photoLaceOptions}
                                placeholder={t('click_to_view_options')}
                                filterOption={(inputValue, option) => {
                                  return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                onChange={async (value, option) => {
                                  let photoLace = ''
                                  if (value) {
                                    photoLace = option.label
                                  }
                                  let ashes_service = serviceDetailForm.getFieldValue("ashes_service");
                                  ashes_service[dynamicRowIndex].photo_lace = photoLace
                                  serviceDetailForm.setFieldsValue({ ashes_service })
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Button
                              disabled={isReadOnly}
                              type="primary"
                              shape="circle"
                              className="p_form_option_edit"
                              onClick={() => {
                                setModalVisible(true)
                                setOptionType('photo_lace')
                                setCurrentKey(field.key)
                              }}
                              icon={<PlusOutlined />}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row align="middle" gutter={[20, 0]}>
                          <Col span={20}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'font']}
                              fieldKey={[field.fieldKey, 'font']}
                              label={t("font")}
                              hidden
                            >
                              <Input disabled />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'font_id']}
                              fieldKey={[field.fieldKey, 'font_id']}
                              label={t('font')}
                            >
                              <Select
                                disabled={isReadOnly}
                                showSearch
                                allowClear
                                style={{ width: '100%' }}
                                options={fontOptions}
                                placeholder={t('click_to_view_options')}
                                filterOption={(inputValue, option) => {
                                  return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                onChange={async (value, option) => {
                                  let font = ''
                                  if (value) {
                                    font = option.label
                                  }
                                  let ashes_service = serviceDetailForm.getFieldValue("ashes_service");
                                  ashes_service[dynamicRowIndex].font = font
                                  serviceDetailForm.setFieldsValue({ ashes_service })
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Button
                              disabled={isReadOnly}
                              type="primary"
                              shape="circle"
                              className="p_form_option_edit"
                              onClick={() => {
                                setModalVisible(true)
                                setOptionType('font')
                                setCurrentKey(field.key)
                              }}
                              icon={<PlusOutlined />}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                     <Row align="middle" gutter={24}>
                        <Col span={8}>
                          <Form.Item
                            {...field}
                            name={[field.name, "product_id"]}
                            fieldKey={[field.fieldKey, "product_id"]}
                            label={t("item_product_id")}
                            hidden
                          >
                            <Input disabled />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "sku"]}
                            fieldKey={[field.fieldKey, "sku"]}
                            label={t('sku')}
                            >
                            <Select
                              disabled={isReadOnly}
                              showSearch
                              allowClear
                              placeholder={t('search_sku')}
                              options={_.sortBy(itemOptions.map(({sku, product_name, product_id}) => {
                                return {
                                  label: sku, value: sku, product_id, product_name
                                }
                              }), ['label'])}
                              optionFilterProp="children"
                              onChange={(value, option) => {
                                if (!option) {
                                  let ashes_service = serviceDetailForm.getFieldValue().ashes_service
                                  ashes_service[dynamicRowIndex].product_name = undefined;
                                  ashes_service[dynamicRowIndex].product_id = undefined;
                                  serviceDetailForm.setFieldsValue({ashes_service})
                                  return
                                }
                                let ashes_service = serviceDetailForm.getFieldValue().ashes_service
                                ashes_service[dynamicRowIndex].product_name = option.product_name;
                                ashes_service[dynamicRowIndex].product_id = option.product_id;
                                serviceDetailForm.setFieldsValue({ashes_service})
                              }}
                              filterOption={(input, option) => {
                                return (
                                  option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            {...field}
                            name={[field.name, "product_name"]}
                            fieldKey={[field.fieldKey, "product_name"]}
                            label={t('item_name')}
                            >
                            <Select
                              disabled
                              showSearch
                              placeholder={t('search_item_name')}
                              options={itemOptions.map(({sku, product_name, product_id}) => {
                                return {
                                  label: product_name, value: product_name, product_id, sku
                                }
                              })}
                              optionFilterProp="children"
                              onChange={(value, option) => {
                                let ashes_service = serviceDetailForm.getFieldValue().ashes_service
                                ashes_service[dynamicRowIndex].sku = option.sku
                                ashes_service[dynamicRowIndex].product_id = option.product_id;
                                serviceDetailForm.setFieldsValue({ashes_service})
                              }}
                              filterOption={(input, option) => {
                                return (
                                  option.label
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                    );
                                  }}
                                  />
                          </Form.Item>
                        </Col>
                      </Row>
                    <Row align="middle" gutter={24}>
                      <Col span={24}>
                        <Button
                          disabled={isReadOnly}
                          type="dashed"
                          danger
                          block
                          onClick={() => {
                            remove(field.name);
                          }}
                        >
                          {t('delete')}
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                ))}

                <Form.Item>
                  <Button
                    disabled={isReadOnly}
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    block
                  >
                    <PlusOutlined />
                    {' '}
                    {t("add_ashes_arrangement")}
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>

        <Form.Item>
          <Button
            disabled={isReadOnly || submitLoading}
            loading={submitLoading}
            className="p_order_ceremony_edit"
            type="primary"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default OrderInfoAshesServiceForm;
