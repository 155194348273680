import React, {
  useState, useEffect, useRef, useContext
} from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Radio, Popconfirm, Spin, Table, Space, Modal, Row, Col
} from 'antd';
import {
  PlusOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';

import ProductInventoryHistoryTable from './ProductInventoryHistoryTable'
import OptionForm from './OptionForm';

const debug = require('debug')('app:src:admin:client:src:pages:company:ProductInventory')

const { Search, TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const ProductInventory = (props) => {
  const { t } = useTranslation()
  let location = useLocation();
  const { product_id } = queryString.parse(location.search);

  const { fetchData, productObject} = props
  const { cost, stock_number } = productObject;

  const [dataList, setDataList] = useState([])

  useEffect(() => {
    fetchProductInventoryHistoryData()
  }, [])

  const fetchProductInventoryHistoryData = async () => {
    let result = await Service.call('get', `/api/product_inventory_stock_history?product_id=${product_id}`)
    if (result.status !== 1) {
      return notification.error({ message: '錯誤', description: result.errorMessage });
    }
    result.data = _.orderBy(result.data, ['ctime'], ['desc']);
    setDataList(result.data)
  }

  return (
    <>
      <InventoryControlPanel
        cost={cost}
        stock_number={stock_number}
        closeCallback={() => {
          fetchProductInventoryHistoryData()
          fetchData()
        }}
      />

      <ProductInventoryHistoryTable
        dataObj={dataList}
        fetchData={fetchProductInventoryHistoryData}
      />

      {/* <Modal
        title={stockAction}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <InventoryForm
          stockAction={stockAction}
          productCostValue={cost}
          closeMethod={async () => {
            fetchProductInventoryHistoryData()
            fetchData()
            setModalVisible(false);
          }}
        />
      </Modal> */}
    </>
  )
}

export const InventoryControlPanel = ({
  productList = [],
  cost = 0,
  stock_number = 0,
  closeCallback = () => {}
}) => {
  const { t } = useTranslation()

  const [stockAction, setStockAction] = useState('')
  const [modalVisible, setModalVisible] = useState(false);
  const [amount, setAmount] = useState(cost);

  // useEffect(() => {

  // }, [])

  return (
    <>
      <Row gutter={[20, 10]}>
        <Col span={24}>{t('p_product_inventory_edit')}</Col>
        <Col xs={12} md={4} lg={3}>
          <Button
            block
            type="primary"
            // className="p_product_inventory_edit"
            onClick={() => {
              setStockAction('in')
              setModalVisible(true)
            }}
          >
            {t('input')}
          </Button>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <Button
            block
            type="primary"
            // className="p_product_inventory_edit"
            onClick={() => {
              setStockAction('out')
              setModalVisible(true)
            }}
          >
            {t('output')}
          </Button>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <Button
            block
            type="primary"
            // className="p_product_inventory_edit"
            onClick={() => {
              setStockAction('stock_take')
              setModalVisible(true)
            }}
          >
            {t('stock_take')}
          </Button>
        </Col>
      </Row>

      <Modal
        title={
          (stockAction === 'in' && t('input'))
          || (stockAction === 'out' && t('output'))
          || (stockAction === 'stock_take' && t('stock_take'))
        }
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <InventoryForm
          productList={productList}
          stockAction={stockAction}
          productCostValue={amount}
          productStockNumber={stock_number}
          closeMethod={async () => {
            closeCallback()
            setModalVisible(false);
          }}
        />
      </Modal>
    </>
  )
}


const InventoryForm = (props) => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [optionType, setOptionType] = useState(false)
  const [remarkOptions, setRemarKOptions] = useState([])

  const { t } = useTranslation();
  let location = useLocation();
  const { product_id } = queryString.parse(location.search);
  const {
    stockAction,
    closeMethod,
    productCostValue = 0,
    productStockNumber,
    productList = []
  } = props

  useEffect(() => {
    form.setFieldsValue({ cost: productCostValue })
  }, [productCostValue])

  useEffect(() => {
    form.setFieldsValue({ stock_number: productStockNumber })
  }, [productStockNumber])

  useEffect(() => {
    fetchRemarkOptions()
  }, [])

  const fetchRemarkOptions = async (id, value) => {
    let options = await Main.fetchFormOption('inventory_in_out_reason', true)
    // console.log(options)
    if (id) options.push({value: id, label: value})
    setRemarKOptions(options);
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
      // initialValues={initialValues}
        onFinish={async (values) => {
        // console.log('values', values)
          values.stock_in_out = stockAction
          values.stock_action = stockAction;
          if (stockAction === 'stock_take') {
            const {stock_number, update_stock_number} = values;
            switch (Math.sign(stock_number - update_stock_number)) {
              case 1:
                values.stock_in_out = 'out'
                values.stock_change = stock_number - update_stock_number
                values.remark_2 = '盤點調整'
                break;
              case -1:
                values.stock_in_out = 'in'
                values.stock_change = update_stock_number - stock_number
                values.remark_2 = '盤點調整'
                break;
              default:
                notification.success({ message: '沒有更改' });
                closeMethod()
                return;
            }
          }
          if (_.toInteger(product_id)) values.product_id = product_id

          let result = await Service.call('post', '/api/product_inventory_stock_history', values)

          if (result.status !== 1) {
            return notification.error({ message: '提交失敗', description: result.errorMessage });
          }

          notification.success({ message: '提交成功' });
          closeMethod()
        }}
      >
        {
        !_.isEmpty(productList) && (
        <Form.Item
          label={`${t('product_name')} (${t('sku')})`}
          name="product_id"
          rules={[{ required: true }]}
        >
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            style={{ width: '100%' }}
            options={_.map(productList, (item) => {
              return ({
                value: item.product_id,
                label: `${item.product_name} (${item.sku})`,
                cost: item.cost,
                stock_number: item.stock_number
              })
            })}
            onChange={(value, options) => {
              if (!options) {
                return form.setFieldsValue({
                  product_id: undefined,
                  cost: undefined,
                  stock_number: undefined
                })
              }
              form.setFieldsValue({
                product_id: value,
                cost: options.cost,
                stock_number: options.stock_number
              })
            }}
          />

        </Form.Item>
        )
      }
        { stockAction !== 'stock_take'
          ? (
            <>
              <Form.Item
                label={t('stock_change')}
                name="stock_change"
                rules={[{ required: true }]}
              >
                <InputNumber min={1} />
              </Form.Item>

              {stockAction === 'in' && (
              <Form.Item
                label={t('cost')}
                name="cost"
                className="p_product_cost_edit"
                // rules={[{ required: true }]}
              >
                <InputNumber />
              </Form.Item>
              )}
              <Row gutter={24} align="middle">
                <Col span={20}>
                  <Form.Item
                    name="remark"
                    hidden
                  >
                    <InputNumber disabled />
                  </Form.Item>

                  <Form.Item
                    label={t("reason")}
                    name="remark_id"
                    rules={[
                      {
                        required: true,
                        message: t("please_enter_this_field")
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      options={remarkOptions}
                      placeholder={t("click_to_view_options")}
                      filterOption={(inputValue, option) => {
                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                      }}
                      onChange={(value, option) => {
                        if (option) {
                          form.setFieldsValue({
                            remark: option.label,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Button
                    className="p_form_option_edit"
                    type="primary"
                    shape="circle"
                    onClick={() => {
                      setModalVisible(true)
                      setOptionType('inventory_in_out_reason')
                    }}
                    icon={<PlusOutlined />}
                  />
                </Col>
              </Row>
              <Form.Item
                label={t("remark")}
                name="remark_2"
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </>
          )
          : (
            <>
              <Form.Item
                label={t("stock_number")}
                name="stock_number"
              >
                <InputNumber disabled />
              </Form.Item>
              <Form.Item
                label={t("edit_stock_number")}
                name="update_stock_number"
              >
                <InputNumber />
              </Form.Item>
            </>
          )}

        <Button type="primary" htmlType="submit">
          {t('submit')}
        </Button>
      </Form>
      <Modal
        title={t('create') + t(optionType)}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <OptionForm
          selectedFormData={{}}
          type={optionType}
          isActive={1}
          closeMethod={async (form_option_id, value) => {
            await fetchRemarkOptions(form_option_id, value);
            form.setFieldsValue({ remark: value, remark_id: form_option_id })
            setModalVisible(false);
          }}
        />
      </Modal>
    </>
  )
}

export default ProductInventory;
