import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  AutoComplete,
  Button,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  notification,
  Divider,
  InputNumber,
  Space,
  Row,
  Col,
  Card,
  List,
  Table,
  Modal
} from "antd";
import { MinusCircleOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { useSelector, useDispatch } from 'react-redux'
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import finishList from '../../data/finishList.json'
import { setSideEffect } from '../../redux/actions/common'

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const OrderItemForm = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // ger redux site meta value
  const meta = useSelector((state) => state.app.meta);
  const OrderItemCheckForm = useSelector((state) => state.app.sideEffect.OrderItemCheckForm);
  const {
    action = '',
  } = OrderItemCheckForm;

  let { order_id } = queryString.parse(location.search);
  // let { initialValues, order_id: orderIdProp = 0, isReadOnly, itemFlag, setItemFlag } = props
  let { initialValues, order_id: orderIdProp = 0, isReadOnly } = props
  if (orderIdProp > 0) {
    order_id = orderIdProp // Override order_id if prop existing
  }
  const [orderItemProduct, setOrderItemProdcut] = useState([]);
  const [orderInfo, setOrderInfo] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [user, setUser] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [form] = Form.useForm();

  // useEffect(() => {
  //   if (itemFlag) {
  //     fetchData()
  //   }
  // }, [itemFlag]);

  // const fetchData = async () => {
  //   await fetchOrderItem();
  //   await fetchOrderInfo();
  //   await fetchUserAll();
  //   setItemFlag(false)
  // }

  useEffect(() => {
    fetchOrderItem();
    fetchOrderInfo();
    fetchUserAll();
  }, []);

  useEffect(() => {
    if (action === 'fetchOrderItem') {
      fetchOrderItem();
      dispatch(setSideEffect({
        OrderItemCheckForm: {
          action: '',
        }
      }));
    }
  }, [action])

  const fetchUserAll = async () => {
    let results = await Service.call(
      "get",
      `/api/company/user`
    );
    if (results.status !== 1 || _.isEmpty(results.data)) {
      return;
    }
    setUser(results.data)
  };

  const fetchOrderItem = async () => {
    let results = await Service.call(
      "get",
      `/api/order_info/product/order_item/${order_id}`
    );
    if (results.status !== 1 || _.isEmpty(results.data)) {
      return;
    }
    const formattedData = _.map(results.data, (item) => {
      return {
        ...item,
        item_checking_1_completed_date: item.item_checking_1_completed_date > 0 ? moment.unix(item.item_checking_1_completed_date).format("YYYY/MM/DD hh:mm") : "",
        item_checking_2_completed_date: item.item_checking_2_completed_date > 0 ? moment.unix(item.item_checking_2_completed_date).format("YYYY/MM/DD hh:mm") : "",
        item_checking_3_completed_date: item.item_checking_3_completed_date > 0 ? moment.unix(item.item_checking_3_completed_date).format("YYYY/MM/DD hh:mm") : "",
      }
    })
    // console.log("values", formattedData)
    setOrderItemProdcut(formattedData)
  };

  const fetchOrderInfo = async () => {
    let data = await Service.call(
      "get",
      `/api/order_info/${order_id}`
    );
    if (data.status !== 1 || _.isEmpty(data)) {
      return;
    }
    let processArr = ["ceremony_procedure_photo", "ceremony_hydration_certificate", "ceremony_grouping_photo"]
    let orderInfoArr = []
    let processObject = {}
    _.forEach(processArr, function(value, key) {
      if (value === 'ceremony_procedure_photo') {
        processObject = {
          ...data.results,
          process: value,
          process_status: data.results.ceremony_procedure_photo,
          ceremony_checking_2: data.results.ceremony_procedure_photo_checking_2,
          ceremony_checking_3: data.results.ceremony_procedure_photo_checking_3,
          ceremony_checking_2_staff: data.results.ceremony_procedure_photo_checking_2_staff,
          ceremony_checking_3_staff: data.results.ceremony_procedure_photo_checking_3_staff,
          ceremony_checking_2_complete_date: data.results.ceremony_procedure_photo_checking_2_completed_date > 0 ? moment.unix(data.results.ceremony_procedure_photo_checking_2_completed_date).format("YYYY/MM/DD hh:mm") : "",
          ceremony_checking_3_complete_date: data.results.ceremony_procedure_photo_checking_3_completed_date > 0 ? moment.unix(data.results.ceremony_procedure_photo_checking_3_completed_date).format("YYYY/MM/DD hh:mm") : ""
        }
      } else if (value === 'ceremony_hydration_certificate') {
        processObject = {
          ...data.results,
          process: value,
          process_status: data.results.ceremony_hydration_certificate,
          ceremony_checking_2: data.results.ceremony_hydration_certificate_checking_2,
          ceremony_checking_3: data.results.ceremony_hydration_certificate_checking_3,
          ceremony_checking_2_staff: data.results.ceremony_hydration_certificate_checking_2_staff,
          ceremony_checking_3_staff: data.results.ceremony_hydration_certificate_checking_3_staff,
          ceremony_checking_2_complete_date: data.results.ceremony_hydration_certificate_checking_2_completed_date > 0 ? moment.unix(data.results.ceremony_hydration_certificate_checking_2_completed_date).format("YYYY/MM/DD hh:mm") : "",
          ceremony_checking_3_complete_date: data.results.ceremony_hydration_certificate_checking_3_completed_date > 0 ? moment.unix(data.results.ceremony_hydration_certificate_checking_3_completed_date).format("YYYY/MM/DD hh:mm") : ""
        }
      } else {
        processObject = {
          ...data.results,
          process: value,
          process_status: data.results.ceremony_grouping_photo,
          ceremony_checking_2: data.results.ceremony_grouping_photo_checking_2,
          ceremony_checking_3: data.results.ceremony_grouping_photo_checking_3,
          ceremony_checking_2_staff: data.results.ceremony_grouping_photo_checking_2_staff,
          ceremony_checking_3_staff: data.results.ceremony_grouping_photo_checking_3_staff,
          ceremony_checking_2_complete_date: data.results.ceremony_grouping_photo_checking_2_completed_date > 0 ? moment.unix(data.results.ceremony_grouping_photo_checking_2_completed_date).format("YYYY/MM/DD hh:mm") : "",
          ceremony_checking_3_complete_date: data.results.ceremony_grouping_photo_checking_3_completed_date > 0 ? moment.unix(data.results.ceremony_grouping_photo_checking_3_completed_date).format("YYYY/MM/DD hh:mm") : ""
        }
      }
      orderInfoArr.push(processObject)
    })
    setOrderInfo(orderInfoArr)
  };


  async function handleChangeOrderItem(value, record, type) {
    let order_item_id = record.order_item_id

    let postData = {
      order_item_id,
      type,
      value
    }

    let data = await Service.call(
      "patch",
      "/api/order_item/checked/status",
      postData
    );

    if (data.status !== 1) {
      notification.error({
        message: "提交失敗",
        description: data.errorMessage,
      });
      return;
    }
    notification.success({ message: "提交成功" });
    fetchOrderItem();
  }

  const onFinish = async (value, record) => {
    let {status, company_user_id, date } = value
    let id = 0
    if (_.isEmpty(selectedType)) {
      id = record.order_item_id
    } else {
      id = record.order_id
    }

    let postData = {
      id,
      type: selectedType,
      status,
      company_user_id,
      date: moment(date).unix(),
      index: selectedIndex
    }
    let data = await Service.call(
      "patch",
      "/api/order_item/checked/status/byUser",
      postData
    );

    if (data.status !== 1) {
      notification.error({
        message: "提交失敗",
        description: data.errorMessage,
      });
      return;
    }
    notification.success({ message: "提交成功" });
    fetchOrderItem();
    fetchOrderInfo();
    // setItemFlag(true);
    setIsModalVisible(false);
    form.resetFields();
  }

  async function handleChangeOrderInfo(value, record, type, index) {
    let order_item_id = record.order_id

    let postData = {
      order_item_id,
      type,
      value,
      index
    }

    let data = await Service.call(
      "patch",
      "/api/order_info/checked/status",
      postData
    );

    if (data.status !== 1) {
      notification.error({
        message: "提交失敗",
        description: data.errorMessage,
      });
      return;
    }
    notification.success({ message: "提交成功" });
    fetchOrderInfo();
  }
  const showModal = (record, type, index) => {
    setIsModalVisible(true);
    setSelectedRecord(record)
    setSelectedIndex(index)
    setSelectedType(type)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  }

  const setTableHeaderOrderItem = () => {
    const columns = [
      {
        title: t('order_item_title'),
        dataIndex: 'item_name',
        key: 'item_name',
        width: '30%',
        render: (value, record, key) => {
          return (
            `${value} - ${record.item_sku}`
          )
        }
      },
      {
        title: `${t('item_checking')} 1`,
        dataIndex: 'item_checking_1',
        key: 'item_checking_1',
        render: (value, record, key) => {
          return (
            <div>
              <Select
                showSearch
                optionFilterProp="children"
                onChange={(val) => handleChangeOrderItem(val, record, 1)}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={value}
                disabled={record.hydration_preparation === 'no' ? 1 : 0}
              >
                <Option value={1}>{t('checked')}</Option>
                <Option value={0}>{t('no_checked')}</Option>
              </Select>
              <Button type="primary" icon={<EditOutlined />} onClick={() => showModal(record, "", 1)} />
              <p>{`${record.item_checking_1_staff} - ${record.item_checking_1_completed_date}`}</p>
            </div>
          )
        }
      },
      {
        title: `${t('item_checking')} 2`,
        dataIndex: 'item_checking_2',
        key: 'item_checking_2',
        render: (value, record, key) => {
          return (
            <div>
              <Select
                showSearch
                optionFilterProp="children"
                onChange={(val) => handleChangeOrderItem(val, record, 2)}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={value}
              >
                <Option value={1}>{t('checked')}</Option>
                <Option value={0}>{t('no_checked')}</Option>
              </Select>
              <Button type="primary" icon={<EditOutlined />} onClick={() => showModal(record, "", 2)} />
              <p>{`${record.item_checking_2_staff} - ${record.item_checking_2_completed_date}`}</p>
            </div>
          )
        }
      },
      {
        title: `${t('item_checking')} 3`,
        dataIndex: 'item_checking_3',
        key: 'item_checking_3',
        render: (value, record, key) => {
          return (
            <div>
              <Select
                showSearch
                optionFilterProp="children"
                onChange={(val) => handleChangeOrderItem(val, record, 3)}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={value}
              >
                <Option value={1}>{t('checked')}</Option>
                <Option value={0}>{t('no_checked')}</Option>
              </Select>
              <Button type="primary" icon={<EditOutlined />} onClick={() => showModal(record, "", 3)} />
              <p>{`${record.item_checking_3_staff} - ${record.item_checking_3_completed_date}`}</p>
            </div>
          )
        }
      },
    ]
    return columns
  }

  const setTableHeaderOrderInfo = () => {
    const columns = [
      {
        title: t('order_item_title'),
        dataIndex: 'process',
        key: 'process',
        width: '53%',
        render: (value, record, key) => {
          return (
            `${t(value)} (${t(record.process_status)})`
          )
        }
      },
      {
        title: `${t('item_checking')} 2`,
        dataIndex: 'ceremony_checking_2',
        key: 'ceremony_checking_2',
        width: '23%',
        render: (value, record, key) => {
          return (
            <div>
              <Select
                showSearch
                placeholder="Select "
                optionFilterProp="children"
                onChange={(val) => handleChangeOrderInfo(val, record, record.process, 2)}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={value}
                disabled={record.hydration_preparation === 'no' ? 1 : 0}
              >
                <Option value={1}>{t('checked')}</Option>
                <Option value={0}>{t('no_checked')}</Option>
              </Select>
              <Button type="primary" icon={<EditOutlined />} onClick={() => showModal(record, record.process, 2)} />
              <p>{`${record.ceremony_checking_2_staff} - ${record.ceremony_checking_2_complete_date}`}</p>
            </div>
          )
        }
      },
      {
        title: `${t('item_checking')} 3`,
        dataIndex: 'ceremony_checking_3',
        key: 'ceremony_checking_3',
        render: (value, record, key) => {
          return (
            <div>
              <Select
                showSearch
                optionFilterProp="children"
                onChange={(val) => handleChangeOrderInfo(val, record, record.process, 3)}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={value}
              >
                <Option value={1}>{t('checked')}</Option>
                <Option value={0}>{t('no_checked')}</Option>
              </Select>
              <Button type="primary" icon={<EditOutlined />} onClick={() => showModal(record, record.process, 3)} />
              <p>{`${record.ceremony_checking_3_staff} - ${record.ceremony_checking_3_complete_date}`}</p>
            </div>
          )
        }
      }
    ]
    return columns
  }

  return (
    <div>
      <Table dataSource={orderInfo} columns={setTableHeaderOrderInfo()} />
      <Table dataSource={orderItemProduct} columns={setTableHeaderOrderItem()} />
      <Modal
        visible={isModalVisible}
        bodyStyle={{ paddingBottom: "1px" }}
        forceRender
        footer={null}
        closable={false}
      >
        <div>
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={(val) => onFinish(val, selectedRecord)}
            autoComplete="off"
          >
            <Form.Item
              label={t('staff')}
              name="company_user_id"
              rules={[{ required: true, message: 'Please select staff!' }]}
            >
              <Select
                showSearch
                placeholder={t('select_a_staff_option')}
                optionFilterProp="children"
                // onChange={onChange}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {_.map(user, (userRc) => (
                  <Option key={userRc.company_user_id}>{`${userRc.first_name}  ${userRc.last_name}`}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={t('date')}
              name="date"
              rules={[{ required: true, message: 'Please select date!' }]}
            >
              <DatePicker 
                placeholder={t('select_date_option')}
                format={"YYYY/MM/DD"}
              />
            </Form.Item>

            <Form.Item
              label={t('status')}
              name="status"
              rules={[{ required: true, message: 'Please select status!' }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                placeholder={t('select_status_option')}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value={1}>{t('checked')}</Option>
                <Option value={0}>{t('no_checked')}</Option>
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Space>
                <Button type="primary" htmlType="submit">
                  {t('submit')}
                </Button>
                <Button htmlType="button" onClick={() => handleCancel()}>
                  {t('cancel')}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default OrderItemForm;
