import React, { useState, useEffect, useRef, useContext } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Radio, Popconfirm, Spin, Table, Space, Modal
} from 'antd';
import {
  DeleteOutlined, EditOutlined
} from '@ant-design/icons';
import moment from 'moment';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import HydrationBatchDetailTable from './HydrationBatchDetailTable'

const debug = require('debug')('app:src:admin:client:src:pages:company:HydrationBatchDetail')

const { Search, TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const HydrationBatchDetail = (props) => {
  const { t } = useTranslation()
  let location = useLocation();

  const { fetchData, hydrationBatchObject} = props
  const { hydration_batch_id } = hydrationBatchObject;

  const [dataList, setDataList] = useState([])

  useEffect(() => {
    fetchHydrationBatchDetailData()
  }, [])

  const fetchHydrationBatchDetailData = async () => {
    console.log("Batch ID", hydration_batch_id)
    let result = await Service.call('get', `/api/hydration_batch_detail?hydration_batch_id=${hydration_batch_id}`)
    if (result.status !== 1) {
      return notification.error({ message: '錯誤', description: result.errorMessage });
    }
    result.data = _.orderBy(result.data, ['ctime'], ['desc']);
    setDataList(result.data)
  }

  return (
    <>
      <HydrationBatchDetailTable
        dataObj={dataList}
        hydration_batch_id={hydration_batch_id}
        fetchData={fetchHydrationBatchDetailData}
      />
    </>
  )
}

export default HydrationBatchDetail;
