import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Col, Row, Space, Typography, Tag
} from 'antd';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import * as Service from '../../core/Service';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { Title } = Typography;

const OrderInfoProgressForm = (props) => {
  const {GOOGLE_MAPS_EMBED_API} = useSelector((state) => state.app.config)
  const history = useHistory();
  let location = useLocation();
  const { t } = useTranslation();
  let { order_id } = queryString.parse(location.search);
  let { initialValues, order_id: orderIdProp = 0, isReadOnly } = props
  if (orderIdProp > 0) {
    order_id = orderIdProp // Override order_id if prop existing
  }

  const [formValues, setFormValues] = useState({})
  const [companyUser, setCompanyUser] = useState([])
  const [carOptions, setCarOptions] = useState([]);
  const [ashesSpaceForm] = Form.useForm();
  const [viewStatusInfo, setViewStatusInfo] = useState(false);

  useEffect(() => {
    checkPermission();
  }, []);

  useEffect(() => {
    fetchCompanyUser()
    fetchCar();
  }, []);

  useEffect(() => {
    ashesSpaceForm.resetFields();
  }, [formValues]);

  useEffect(() => {
    updateFormValues();
  }, [initialValues])

  const checkPermission = async () => {
    // call new api check p_order_ceremony_view
    let result = await Service.call("get", `/api/check_permission/p_order_status_view?checkOnly=true`);
    // console.log(`result cp777 >> `, result)
    const {
      status = -1,
    } = result;

    if (status !== -1) {
      setViewStatusInfo(true);
    }
  }

  const fetchCompanyUser = async () => {
    let result = await Service.call('get', '/api/company/user');
    // console.log('result', result)
    if (result.status !== 1) {
      return notification.error({ message: '失敗', description: result.errorMessage });
    }
    setCompanyUser(result.data)
  }

  const fetchCar = async (id, value) => {
    let options = await Main.fetchFormOption('car', true)
    if (id) options.push({ value: id, label: value })
    setCarOptions(options);
  }

  const updateFormValues = async () => {
    let result = await Service.call('get', `/api/hydration_batch_detail/orderID/${order_id}`);
    if (result.status !== 1) {
      return notification.error({ message: '失敗', description: result.errorMessage });
    }
    let { data } = result;
    setFormValues({ ...initialValues, ...data });
  }

  // ger redux site meta value
  const meta = useSelector((state) => state.app.meta);

  const dateFormat = `${!_.isEmpty(meta.system_date_format.meta_value) ? meta.system_date_format.meta_value : 'YYYY/MM/DD'
    } `
  // ${!_.isEmpty(meta.system_time_format.meta_value) ? meta.system_time_format.meta_value : 'HH:mm'}`

  if (!viewStatusInfo) {
    return (
      <>
        <div style={{ marginTop: 75, textAlign: 'center', fontWeight: 'bold' }}>
          Unauthorized Access
        </div>
      </>
    )
  }

  return (
    <Form
      form={ashesSpaceForm}
      name="ashesSpaceForm"
      // layout="vertical"
      initialValues={formValues}
      onFinish={async (values) => {
        // return console.log('values', values)
        if (_.isUndefined(order_id)) {
          notification.error({ message: '提交失敗', description: '請先提交客戶及寵物資料' });
          return;
        }

        values.order_id = order_id;
        let result = await Service.call('patch', '/api/order_info', values);
        // console.log('data', data);

        if (result.status !== 1) {
          notification.error({ message: '提交失敗', description: result.errorMessage });
          return;
        }

        notification.success({ message: '提交成功' });
        props.onChange()
      }}
    >
      <Divider />
      <Title level={4}>{t('receive_order')}</Title>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t('staff')}
            name="progress_in_call_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_in_call_completed_date"
          >
            <DatePicker
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Title level={4}>{t('pending_car')}</Title>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t("staff")}
            name="progress_receive_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_receive_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Title level={4}>{t('pending_driver')}</Title>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t('staff')}
            name="progress_driver_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_driver_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider />
      <Title level={4}>{t('driver_driving')}</Title>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t('staff')}
            name="progress_driver_driving_staff_id"
          >
            <Select
              // disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_driver_driving_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("car")}`}
            name="driver_license_id"
          >
            <Select
              // disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_driver_license")}
              options={carOptions}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("remark")}`}
            name="progress_driving_remark"
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("location")}`}
          >
            <Tag color="blue">
              Lat:
              {formValues.progress_driver_driving_lat}
            </Tag>
            <Tag color="blue">
              Lng:
              {formValues.progress_driver_driving_lng}
            </Tag>
          </Form.Item>
          {(formValues.progress_driver_driving_lat && GOOGLE_MAPS_EMBED_API) && (
            <iframe
              title="driver-pick-up"
              width="100%"
              height="200"
              style={{ border: 0 }}
              loading="lazy"
              src={`https://www.google.com/maps/embed/v1/place?q=${formValues.progress_driver_driving_lat},${formValues.progress_driver_driving_lng}&center=${formValues.progress_driver_driving_lat},${formValues.progress_driver_driving_lng}&zoom=18&key=${GOOGLE_MAPS_EMBED_API}`}
            />
          )}
        </Col>
      </Row>
      <Divider />
      <Title level={4}>{t('pet_received')}</Title>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t("staff")} // 細節/儀式
            name="progress_pet_received_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_pet_received_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("remark")}`}
            name="progress_pet_received_remark"
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("location")}`}
          >
            <Tag color="blue">
              Lat:
              {formValues.progress_pet_received_lat}
            </Tag>
            <Tag color="blue">
              Lng:
              {formValues.progress_pet_received_lng}
            </Tag>
          </Form.Item>
        </Col>
        <Divider />
      </Row>
      <Title level={4}>{t('before_hydration')}</Title>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t("staff")}
            name="progress_before_hydration_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_before_hydration_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider>{t('pet_weighed')}</Divider>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t("staff")}
            name="progress_pet_weighed_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_pet_weighed_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("pet_weight")}
            name="pet_weight"
          >
            <Input
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("remark")}`}
            name="progress_pet_weighed_remark"
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("location")}`}
          >
            <Tag color="blue">
              Lat:
              {formValues.progress_pet_weighed_lat}
            </Tag>
            <Tag color="blue">
              Lng:
              {formValues.progress_pet_weighed_lng}
            </Tag>
          </Form.Item>
        </Col>
      </Row>
      <Divider>{t('pet_refrigerated')}</Divider>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t("staff")}
            name="progress_pet_refrigerated_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_pet_refrigerated_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("location")}
            name="pet_refrigerated_location"
          >
            <Input
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("remark")}`}
            name="progress_pet_refrigerated_remark"
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("location")}`}
          >
            <Tag color="blue">
              Lat:
              {formValues.progress_pet_refrigerated_lat}
            </Tag>
            <Tag color="blue">
              Lng:
              {formValues.progress_pet_refrigerated_lng}
            </Tag>
          </Form.Item>
        </Col>
      </Row>
      <Divider>{t('cleaned')}</Divider>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t("staff")} // 清潔程序
            name="progress_clean_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_clean_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("remark")}`}
            name="progress_clean_remark"
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("location")}`}
          >
            <Tag color="blue">
              Lat:
              {formValues.progress_clean_lat}
            </Tag>
            <Tag color="blue">
              Lng:
              {formValues.progress_clean_lng}
            </Tag>
          </Form.Item>
        </Col>
      </Row>
      <Divider>{t('send_to_funeral')}</Divider>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t('staff')}
            name="progress_send_ceremony_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_send_ceremony_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("remark")}`}
            name="progress_send_ceremony_remark"
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("location")}`}
          >
            <Tag color="blue">
              Lat:
              {formValues.progress_send_ceremony_lat}
            </Tag>
            <Tag color="blue">
              Lng:
              {formValues.progress_send_ceremony_lng}
            </Tag>
          </Form.Item>
        </Col>
      </Row>
      <Divider>{t('waiting_for_funeral')}</Divider>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t('staff')}
            name="progress_pending_ceremony_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_pending_ceremony_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("remark")}`}
            name="progress_pending_ceremony_remark"
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("location")}`}
          >
            <Tag color="blue">
              Lat:
              {formValues.progress_pending_ceremony_lat}
            </Tag>
            <Tag color="blue">
              Lng:
              {formValues.progress_pending_ceremony_lng}
            </Tag>
          </Form.Item>
        </Col>
      </Row>
      <Divider>{t('starting_funeral')}</Divider>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t('staff')}
            name="progress_ceremony_starting_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_ceremony_starting_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("location")}
            name="hydration_started_location"
          >
            <Input
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("remark")}`}
            name="progress_ceremony_starting_remark"
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("location")}`}
          >
            <Tag color="blue">
              Lat:
              {formValues.progress_ceremony_starting_lat}
            </Tag>
            <Tag color="blue">
              Lng:
              {formValues.progress_ceremony_starting_lng}
            </Tag>
          </Form.Item>
        </Col>
      </Row>
      <Divider>{t('ceremony_completed')}</Divider>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t("staff")}
            name="progress_ceremony_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_ceremony_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("remark")}`}
            name="progress_ceremony_completed_remark"
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("location")}`}
          >
            <Tag color="blue">
              Lat:
              {formValues.progress_ceremony_lat}
            </Tag>
            <Tag color="blue">
              Lng:
              {formValues.progress_ceremony_lng}
            </Tag>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Title level={4}>{t('enter_hydration')}</Title>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t("staff")}
            name="progress_enter_hydration_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={16}>
          <Form.Item
            label={t("completion_date")}
            name="progress_enter_hydration_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("hydration_machine_no")}
            name="machine_no"
          >
            <Input
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("hydraiton_batch_no")}
            name="batch_no"
          >
            <Input
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("cradle")}
            name="cradle"
          >
            <Input
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("remark")}`}
            name="progress_enter_hydration_remark"
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("location")}`}
          >
            <Tag color="blue">
              Lat:
              {formValues.progress_enter_hydration_lat}
            </Tag>
            <Tag color="blue">
              Lng:
              {formValues.progress_enter_hydration_lng}
            </Tag>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Title level={4}>{t('hydration_started')}</Title>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t("staff")} // 水化處理
            name="progress_hydration_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_hydration_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Title level={4}>{t('hydration_completed')}</Title>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t("staff")}
            name="progress_hydration_completed_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_hydration_completed_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Title level={4}>{t('air_dried')}</Title>

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t("staff")} // 風乾
            name="progress_air_dried_staff_id"
          >
            <Select
              disabled
              showSearch
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_air_dried_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("location")}
            name="air_dried_location"
          >
            <Input
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("remark")}`}
            name="progress_air_dried_remark"
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("location")}`}
          >
            <Tag color="blue">
              Lat:
              {formValues.progress_air_dried_lat}
            </Tag>
            <Tag color="blue">
              Lng:
              {formValues.progress_air_dried_lng}
            </Tag>
          </Form.Item>
        </Col>
        <Divider />
      </Row>
      <Title level={4}>{t('bones_broken')}</Title>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t("staff")} // 骨灰處理
            name="progress_bones_broken_staff_id"
          >
            <Select
              showSearch
              disabled
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_bones_broken_completed_date"
          >
            <DatePicker
              // disabled
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("remark")}`}
            name="progress_bones_broken_remark"
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("location")}`}
          >
            <Tag color="blue">
              Lat:
              {formValues.progress_bones_broken_lat}
            </Tag>
            <Tag color="blue">
              Lng:
              {formValues.progress_bones_broken_lng}
            </Tag>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Title level={4}>{t('final_checking')}</Title>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t("staff")}
            name="progress_review_staff_id"
          >
            <Select
              showSearch
              disabled
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_review_completed_date"
          >
            <DatePicker
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            // disabled
            />
          </Form.Item>
        </Col>
        <Divider />
      </Row>
      <Title level={4}>{t('order_completed')}</Title>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t("staff")}
            name="progress_order_completed_staff_id"
          >
            <Select
              showSearch
              disabled
              // style={{ width: 400 }}
              placeholder={t("search_for_staff")}
              options={companyUser.map((item) => { return { value: item.company_user_id, label: `${item.first_name} ${item.last_name}` } })}
              optionFilterProp="children"
              onChange={(value) => {
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("completion_date")}
            name="progress_order_completed_completed_date"
          >
            <DatePicker
              format="YYYY/MM/DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            // format={dateFormat}
            // disabled
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={`${t("remark")}`}
            name="progress_order_completed_remark"
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>
        </Col>
        <Divider />
      </Row>

      <Form.Item>
        <Button
          type="primary"
          className="p_order_status_edit"
          htmlType="submit"
        >
          {t("submit")}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default OrderInfoProgressForm;
