import React, { useState, useEffect } from 'react';
import {
  Button, Layout, Table, Tooltip, Modal, notification, Badge, Space, Tag
} from 'antd';
import {
  DeleteOutlined, EditOutlined, CloseOutlined, CheckOutlined, FilePdfOutlined, FileAddOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import _ from 'lodash';
import * as Service from '../../core/Service';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import * as Main from "../../core/Main";

const debug = require('debug')('app:admin:pages:InvoiceHistoryTable');

const { confirm } = Modal;

const InvoiceHistoryTable = (props) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);

  const { invoice_info_id } = props

  useEffect(() => {
    fetchInvoiceHistory()
  }, []);

  const fetchInvoiceHistory = async () => {
    let result = await Service.call('get', `/api/invoice_history?invoice_info_id=${invoice_info_id}`);
    // debug('result>>>', result);

    if (result.status !== 1) {
      return notification.error({ message: "失敗", description: result.errorMessage });
    }

    result.data = _.orderBy(result.data, ['ctime'], ['desc']);
    setDataList(result.data)
  }

  const setTableHeader = () => {
    const columns = [
      {
        title: t('ctime'),
        dataIndex: `ctime`,
        render: (value) => {
          return Main.displayMoment(value);
        }
      },
      {
        title: t('type'),
        dataIndex: `type`,
        render: (value) => {
          if (value === 'receipt') return t('receipt_info')
          if (value === 'invoice') return t('invoice_info')
        }
      },
      {
        title: t('pdf'),
        dataIndex: `pdf`,
        render: (value) => {
          return (
            <Tooltip title="查看PDF">
              <Button
                shape="circle"
                icon={<FilePdfOutlined />}
                onClick={async () => {
                  let result = await Service.call('get', `/api/invoice_info/invoice_pdf/${value}`, {}, { responseType: 'blob' })
                  if (!result) return
                  const pdfBlob = new Blob([result], { type: 'application/pdf' });
                  saveAs(pdfBlob, value)
                }}
              />
            </Tooltip>
          )
        }
      },
    ];
    return columns;
  }

  const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm') => {
    if (unixTime !== 0) {
      return moment.unix(unixTime).format(outputFormat);
    }
    return '-';
  }

  return (
    <Layout>
      <Table
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        rowKey={(record) => record.invoice_history_id}
      />
    </Layout>
  )
}

export default InvoiceHistoryTable;
