import React, { useState, useEffect } from 'react';
import {
  notification, Tabs, Table, Badge, Button, Modal, Form, Input, Radio, Spin, Select, Space
} from 'antd';
import {
  DeleteOutlined, EditOutlined, LoadingOutlined, BorderOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/zh-tw';
import moment from 'moment';
import OrderInfoForm from './OrderInfoForm';

const debug = require('debug')('app:admin:pages:CalendarView');

const { TabPane } = Tabs;

const localizer = momentLocalizer(moment)

const CalendarView = (props) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [phone, setPhone] = useState();
  const [timeOption, setTimeOption] = useState("all");
  const [eventList, setEventList] = useState([])
  const [phoneList, setPhoneList] = useState([])
  const [timeOptionsList, setTimeOptionsList] = useState([
    {label: t('all'), value: "all"},
    {label: (<>{t('ceremony_time')} <BorderOutlined style={{backgroundColor: '#FF0066', color: '#FF0066'}}/></>), value: "ceremony_time"},
    // {label: t('pick_up_time'), value: "pick_up_time"},
    {label: (<>{t('meet_up_time')} <BorderOutlined style={{backgroundColor: '#006118', color: '#006118'}}/></>), value: "meet_up_time"},
    // {label: t('ashes_pick_up_time'), value: "ashes_pick_up_time"},
    {label: (<>{t('confirmed_ashes_pick_up_time')} <BorderOutlined style={{backgroundColor: '#FFA500', color: '#FFA500'}}/></>), value: "confirmed_ashes_pick_up_time"},
    {label: (<>{t('ashes_space_meet_up_time')} <BorderOutlined style={{backgroundColor: '#A87743', color: '#A87743'}}/></>), value: "ashes_space_meet_up_time"},
    {label: (<>{t('plant_space_meet_up_time')} <BorderOutlined style={{backgroundColor: '#4042B8', color: '#4042B8'}}/></>), value: "plant_space_meet_up_time"},
    {label: (<>{t('estimate_pick_up_time')} <BorderOutlined style={{backgroundColor: '#964DA8', color: '#964DA8'}}/></>), value: "estimate_pick_up_time"},
    {label: (<>{t('diy_time')} <BorderOutlined style={{backgroundColor: '#55A39F', color: '#55A39F'}}/></>), value: "diy_time"},
    {label: (<>{t('plant_time')} <BorderOutlined style={{backgroundColor: '#47718A', color: '#47718A'}}/></>), value: "plant_time"}
  ])
  const [orderId, setOrderId] = useState(0)
  const [chosenType, setChosenType] = useState("")
  // debug(`eventList>>>`, eventList)

  useEffect(() => {
    fetchData()
  }, [phone, timeOption])
  useEffect(() => {
    fetchUserPhone()
  }, [])
  useEffect(() => {
    console.log(phoneList)
  }, [phoneList])

  const fetchData = async () => {
    try {
      console.log("Phone ", phone)
      console.log("timeOption ", timeOption)
      let result = {};
      if (_.isUndefined(phone)) result = await Service.call('get', '/api/order_info/calendar_list');
      else result = await Service.call('get', `/api/order_info/calendar_list/mobile?mobile=${phone}`)
      debug(`result>>>`, result)
      if (result.status !== 1) {
        notification.warning({ message: '獲取資料失敗' });
        return;
      }

      let { data } = result;

      //  Convert record into event
      let eventObjects = []
      _.reduce(data, (result, value, key) => {
        const { owner_first_name, owner_last_name, pet_name, ceremony_time, ceremony_end_time, ceremony_room, pick_up_time, meet_up_time, ashes_pick_up_time, ashes_space_meet_up_time, plant_space_meet_up_time, estimate_pick_up_time, estimate_pick_up_end_time, diy_time, order_item, order_ashes_service, confirmed_ashes_pick_up_time } = value

        if (ceremony_time > 0 && (timeOption === 'all' || timeOption === 'ceremony_time')) {
          eventObjects.push(
            {
              //  Calendar usage
              title: `[${t('ceremony')} - ${ceremony_room}] ${owner_first_name} ${owner_last_name} / ${pet_name}`,
              start: moment.unix(ceremony_time).toDate(),
              end: moment.unix(ceremony_end_time).toDate(),

              //  Customize
              type: "ceremony_time",
              rawObject: value
            }
          )
        }

        // if (pick_up_time > 0 && (timeOption === 'all' || timeOption === 'pick_up_time')) {
        //   eventObjects.push(
        //     {
        //       //  Calendar usage
        //       title: `[${t('pick_up_time')}] ${owner_first_name} ${owner_last_name} / ${pet_name}`,
        //       start: moment.unix(pick_up_time).toDate(),
        //       end: moment.unix(pick_up_time).add(1, 'hours').toDate(),

        //       //  Customize
        //       type: "pick_up_time",
        //       rawObject: value
        //     }
        //   )
        // }

        if (meet_up_time > 0 && (timeOption === 'all' || timeOption === 'meet_up_time')) {
          eventObjects.push(
            {
              //  Calendar usage
              title: `[${t('meet_up_time')}] ${owner_first_name} ${owner_last_name} / ${pet_name}`,
              start: moment.unix(meet_up_time).toDate(),
              end: moment.unix(meet_up_time).add(1, 'hours').toDate(),

              //  Customize
              type: "meet_up_time",
              rawObject: value
            }
          )
        }

        // if (ashes_pick_up_time > 0 && (timeOption === 'all' || timeOption === 'ashes_pick_up_time')) {
        //   eventObjects.push(
        //     {
        //       //  Calendar usage
        //       title: `[${t('ashes_pick_up_time')}] ${owner_first_name} ${owner_last_name} / ${pet_name}`,
        //       start: moment.unix(ashes_pick_up_time).toDate(),
        //       end: moment.unix(ashes_pick_up_time).add(1, 'hours').toDate(),

        //       //  Customize
        //       type: "ashes_pick_up_time",
        //       rawObject: value
        //     }
        //   )
        // }

        if (confirmed_ashes_pick_up_time > 0 && (timeOption === 'all' || timeOption === 'confirmed_ashes_pick_up_time')) {
          eventObjects.push(
            {
              //  Calendar usage
              title: `[${t('confirmed_ashes_pick_up_time')}] ${owner_first_name} ${owner_last_name} / ${pet_name}`,
              start: moment.unix(confirmed_ashes_pick_up_time).toDate(),
              end: moment.unix(confirmed_ashes_pick_up_time).add(1, 'hours').toDate(),

              //  Customize
              type: "confirmed_ashes_pick_up_time",
              rawObject: value
            }
          )
        }

        if (ashes_space_meet_up_time > 0 && (timeOption === 'all' || timeOption === 'ashes_space_meet_up_time')) {
          eventObjects.push(
            {
              //  Calendar usage
              title: `[${t('ashes_space_meet_up_time')}] ${owner_first_name} ${owner_last_name} / ${pet_name}`,
              start: moment.unix(ashes_space_meet_up_time).toDate(),
              end: moment.unix(ashes_space_meet_up_time).add(1, 'hours').toDate(),

              //  Customize
              type: "ashes_space_meet_up_time",
              rawObject: value
            }
          )
        }
        if (plant_space_meet_up_time > 0 && (timeOption === 'all' || timeOption === 'plant_space_meet_up_time')) {
          eventObjects.push(
            {
              //  Calendar usage
              title: `[${t('plant_space_meet_up_time')}] ${owner_first_name} ${owner_last_name} / ${pet_name}`,
              start: moment.unix(plant_space_meet_up_time).toDate(),
              end: moment.unix(plant_space_meet_up_time).add(1, 'hours').toDate(),

              //  Customize
              type: "plant_space_meet_up_time",
              rawObject: value
            }
          )
        }
        if (estimate_pick_up_time > 0 && (timeOption === 'all' || timeOption === 'estimate_pick_up_time')) {
          eventObjects.push(
            {
              //  Calendar usage
              title: `[${t('estimate_pick_up_time')}] ${owner_first_name} ${owner_last_name} / ${pet_name}`,
              start: moment.unix(estimate_pick_up_time).toDate(),
              end: moment.unix(estimate_pick_up_end_time).toDate(),

              //  Customize
              type: "estimate_pick_up_time",
              rawObject: value
            }
          )
        }
        if (order_item.length !== 0) {
          order_item.forEach((element, index) => {
            if (element.diy_time > 0 && (timeOption === 'all' || timeOption === 'diy_time')) {
              eventObjects.push(
                {
                  title: `[${t('diy_time')}] ${owner_first_name} ${owner_last_name} / ${pet_name}`,
                  start: moment.unix(element.diy_time).toDate(),
                  end: moment.unix(element.diy_time).add(1, 'hours').toDate(),
                  type: "diy_time",
                  rawObject: value,
                  diy_id: value.order_item[index].order_item_id
                }
              )
            }
          })
        }
        if (order_ashes_service.length !== 0) {
          order_ashes_service.forEach((element, index) => {
            if (element.plant_time > 0 && (timeOption === 'all' || timeOption === 'plant_time')) {
              eventObjects.push(
                {
                  title: `[${t('plant_time')}] ${owner_first_name} ${owner_last_name} / ${pet_name}`,
                  start: moment.unix(element.plant_time).toDate(),
                  end: moment.unix(element.plant_time).add(1, 'hours').toDate(),
                  type: "plant_time",
                  rawObject: value,
                  plant_id: value.order_ashes_service[index].order_ashes_service_id
                }
              )
            }
          })
        }
      }, []);

      debug(`eventObjects>>>`, eventObjects)

      setEventList(eventObjects);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  const fetchUserPhone = async () => {
    try {
      let result = await Service.call("get", "/api/user/list");
      if (result.status !== 1) {
        notification.warning({ message: '獲取資料失敗' });
        return;
      }

      let { data } = result;
      let fetchPhoneList = data.dataList.map((item) => {
        return {label: item.mobile, value: item.mobile, key: item.user_id}
      })
      setPhoneList(fetchPhoneList)
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  const lookDetail = async (event) => {
    // debug(`event>>>`, event)
    const { rawObject, type } = event
    const { order_id } = rawObject
    console.log(type)
    setOrderId(order_id)
    setChosenType(type)
    setModalVisible(true)
  }

  const renderBackgroundColor = (object) => {
    if (_.isUndefined(object.rawObject)) return "#FFFFFF"
    let currentTimeStamp = moment().unix();
    if (object.type === 'ceremony_time') {
      if (object.rawObject.ceremony_time_status === 1) return "#808080";
      return "#FF0066"
    }
    if (object.type === 'pick_up_time') {
      if (object.rawObject.pick_up_time_status === 1) return "#808080";
      return "#094099"
    }
    if (object.type === 'meet_up_time') {
      console.log(object)
      if (object.rawObject.meet_up_time_status === 1) return "#808080";
      return "#006118"
    }
    // if (object.type === 'ashes_pick_up_time') {
    //   if (object.rawObject.ashes_pick_up_time_status === 1) return "#808080";
    //   return "#6B0202"
    // }

    if (object.type === 'confirmed_ashes_pick_up_time') {
      if (object.rawObject.ashes_pick_up_time_status === 1) return "#808080";
      return "#FFA500"
    }

    if (object.type === 'ashes_space_meet_up_time') {
      if (object.rawObject.ashes_space_meet_up_time_status === 1) return "#808080";
      return "#A87743"
    }
    if (object.type === 'plant_space_meet_up_time') {
      if (object.rawObject.plant_space_meet_up_time_status === 1) return "#808080";
      return "#4042B8"
    }
    if (object.type === 'estimate_pick_up_time') {
      if (object.rawObject.estimate_pick_up_time_status === 1) return "#808080";
      return "#964DA8"
    }
    if (object.type === 'diy_time') {
      console.log(object)
      if (_.find(object.rawObject.order_item, (subItem) => { return subItem.order_item_id === object.diy_id }).diy_time_status === 1) return "#808080";
      return "#55A39F"
    }
    if (object.type === 'plant_time') {
      if (_.find(object.rawObject.order_ashes_service, (subItem) => { return subItem.order_ashes_service_id === object.plant_id }).plant_time_status === 1) return "#808080";
      return "#47718A"
    }
  }

  const calendarStyle = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: renderBackgroundColor(event),
        borderColor: 'rgba(255, 255, 255, 0.3)'
      }
    }
  }


  return (
    <NavAndSideFrame {...props} title={t('calendar')} selectedKey="calendar">
      <>
        <Spin
          indicator={<LoadingOutlined />}
          spinning={loading}
        >
          <Space size={15}>
            <Select
              showSearch
              allowClear
              style={{ width: 400 }}
              placeholder={t('telephone')}
              options={phoneList}
              optionFilterProp="children"
              onChange={(value) => {
                setPhone(value)
                // fetchData()
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
            <Select
              showSearch
              style={{ width: 400 }}
              placeholder={t('timeOptions')}
              options={timeOptionsList}
              optionFilterProp="children"
              defaultValue="all"
              onChange={(value) => {
                setTimeOption(value)
                // fetchData()
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Space>
          <Calendar
            culture={i18n.language === "en" ? "en-UK" : "zh_hk"}
            scrollToTime={new Date(new Date().setHours(8, 1))}
            localizer={localizer}
            events={eventList}
            startAccessor="start"
            endAccessor="end"
            timeslots={4}
            step={15}
            style={{
              height: "1600px",
              background: "white"
            }}
            defaultView={Views.MONTH}
            views={['month', 'week', 'day']}
            messages={{
              month: t('month'),
              day: t('day'),
              week: t('week'),
              today: t('today'),
              next: t('next'),
              previous: t('previous')
            }}
            dayLayoutAlgorithm="no-overlap"
            // onNavigate={(focusWeek, flipUnit, prevOrNext) => selectDate(prevOrNext,focusWeek)}
            // onNavigate={(focusWeek, flipUnit, prevOrNext) => selectDateCallBack(prevOrNext, focusWeek)}
            // components={(value)=>{console.log('check', value)}}

            // view="week"
            // views={{ 'month': true}}
            onSelectEvent={(event) => lookDetail(event)}
          // onSelectSlot={(slotInfo) => addNewEvent(slotInfo)}
            eventPropGetter={(event) => calendarStyle(event)}
          // for one day change color
            dayPropGetter={calendarStyle}
          // defaultDate={new Date(2015, 3, 1)}
          />

          <Modal
            // title={''}
            visible={modalVisible}
            footer={null}
            style={{ maxWidth: 800 }}
            width="90%"
            destroyOnClose
            onCancel={() => {
              setModalVisible(false);
              fetchData();
            }}
          >
            <OrderInfoForm
              order_id={orderId}
              type={chosenType}
              setModalVisible={setModalVisible}
            />
          </Modal>
        </Spin>
      </>
    </NavAndSideFrame>
  )
}

export default CalendarView;
