import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import {
  AutoComplete,
  Button,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  notification,
  Divider,
  InputNumber,
  Space,
  Checkbox,
  Row,
  Col,
  Modal,
  Spin,
} from "antd";
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import OptionForm from './OptionForm'
import finishList from '../../data/finishList.json'
import OrderInfoCalendar from "./OrderInfoCalendar";

const debug = require('debug')('app:admin:pages:OrderInfoServiceDetailForm');

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const OrderInfoServiceDetailForm = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  let location = useLocation();
  const companyUser = useSelector((state) => state.app.company_user);

  let { order_id } = queryString.parse(location.search);
  let { initialValues, order_id: orderIdProp = 0, isReadOnly } = props
  if (orderIdProp > 0) {
    order_id = orderIdProp // Override order_id if prop existing
  }
  const isNeededOptions = [{ label: t('needed'), value: t('needed') }, { label: t('no_needed'), value: t('no_needed') }]

  const [serviceDetailForm] = Form.useForm();
  const [ceremonyRoomOptions, setCeremonyRoomOptions] = useState([]);
  const [ceremonyItemOptions, setCeremonyItemOptions] = useState([]);
  const [itemStorageOptions, setItemStorageOptions] = useState([]);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [hairTreatmentOptions, setHairTreatmentOptions] = useState([]);
  const [staffOptions, setStaffOption] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [optionModalVisible, setOptionModalVisible] = useState(false)
  const [optionType, setOptionType] = useState(false)
  const [viewCeremonyInfo, setViewCeremonyInfo] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    checkPermission();
  }, []);

  useEffect(() => {
    if (!companyUser.company_user_id || !staffOptions) { return }
    serviceDetailForm.setFieldsValue({service_consultant_staff_id: companyUser.company_user_id})
  }, [companyUser, serviceDetailForm, staffOptions])

  useEffect(() => {
    fetchFormOption();
    serviceDetailForm.resetFields();
  }, [initialValues]);

  const checkPermission = async () => {
    // call new api check p_order_ceremony_view
    let result = await Service.call("get", `/api/check_permission/p_order_ceremony_view?checkOnly=true`);
    const {
      status = -1,
    } = result;

    if (status !== -1) {
      setViewCeremonyInfo(true);
    }
  }

  const fetchFormOption = async () => {
    setDataLoading(true);
    await fetchCeremonyRoom();
    await fetchCeremonyItem();
    await fetchItemStorage();
    await fetchHairTreatment();
    await fetchSourceOptions();
    await fetchStaff();
    setDataLoading(false);
  };

  const fetchData = async (type, id, value) => {
    switch (type) {
      case "ceremony_room":
        fetchCeremonyRoom(id, value);
        serviceDetailForm.setFieldsValue({ ceremony_room: value, ceremony_room_id: id})
        break;
      case "ceremony_item":
        fetchCeremonyItem(id, value);
        serviceDetailForm.setFieldsValue({
          ceremony_bed_id: id,
          ceremony_bed: value,
          ceremony_quilt_id: id,
          ceremony_quilt: value,
          ceremony_flower_id: id,
          ceremony_flower: value,
          ceremony_photo_frame_id: id,
          ceremony_photo_frame: value,
          ceremony_food_plate_id: id,
          ceremony_food_plate: value,
        })
        break;
      case "item_storage":
        fetchItemStorage(id, value);
        serviceDetailForm.setFieldsValue({ ceremony_item_storage: value, ceremony_item_storage_id: id})
        break;
      case "source":
        fetchSourceOptions(id, value);
        serviceDetailForm.setFieldsValue({ ceremony_source: value, ceremony_source_id: id})
        break;
      default:
        break;
    }
  }

  const fetchStaff = async () => {
    let result = await Service.call("get", `/api/company/user`);
    if (result.status !== 1) return;
    let userInfoList = result.data;
    let staffOption = userInfoList.map((entity) => {
      return {label: entity.company_user_id + '-' + entity.first_name + " " + entity.last_name, value: entity.company_user_id}
    })
    setStaffOption(staffOption);
  };

  const fetchCeremonyRoom = async (id, value) => {
    let options = await Main.fetchFormOption("ceremony_room", true);
    if (id) options.push({value: id, label: value})
    setCeremonyRoomOptions(options);
  };

  const fetchCeremonyItem = async (id, value) => {
    let options = await Main.fetchFormOption("ceremony_item", true);
    if (id) options.push({value: id, label: value})
    setCeremonyItemOptions(options);

    //  Find default option
    let ceremonyItemDefaultObject = _.find(options, ['is_default', 1])
    if (!id) updateCeremonyItemDefault(ceremonyItemDefaultObject)
  };

  const fetchItemStorage = async (id, value) => {
    let options = await Main.fetchFormOption("item_storage", true);
    if (id) options.push({value: id, label: value})
    setItemStorageOptions(options);
    //  Find default option
    let itemStorageDefaultObject = _.find(options, ['is_default', 1])
    if (!id) updateItemStorageDefault(itemStorageDefaultObject)
  };

  const fetchSourceOptions = async (id, value) => {
    let options = await Main.fetchFormOption("source", true);
    if (id) options.push({value: id, label: value})
    setSourceOptions(options);
  };

  const fetchHairTreatment = async (id, value) => {
    let options = await Main.fetchFormOption("hair_treatment", true);
    // console.log("hair>>", options)
    if (id) options.push({value: id, label: value});
    setHairTreatmentOptions(options);
  };

  const updateCeremonyEndTime = () => {
    let ceremony_time = serviceDetailForm.getFieldValue().ceremony_time;
    let ceremony_duration = serviceDetailForm.getFieldValue().ceremony_duration;

    // console.log('ceremony_time', ceremony_time, ceremony_duration)

    if (!ceremony_time || !ceremony_duration) {
      serviceDetailForm.setFieldsValue({ ceremony_end_time: null });
      return;
    }

    let ceremony_end_time = moment(ceremony_time).add(
      ceremony_duration,
      "minutes"
    );
    // console.log('ceremony_end_time', ceremony_end_time)

    serviceDetailForm.setFieldsValue({ ceremony_end_time });
  };

  const updateCeremonyItemDefault = (ceremonyItemDefaultObject) => {
    if (_.isEmpty(ceremonyItemDefaultObject)) {
      return
    }

    const { value } = ceremonyItemDefaultObject

    let ceremony_bed_id = serviceDetailForm.getFieldValue().ceremony_bed_id;
    let ceremony_quilt_id = serviceDetailForm.getFieldValue().ceremony_quilt_id;
    let ceremony_flower_id = serviceDetailForm.getFieldValue().ceremony_flower_id;
    let ceremony_photo_frame_id = serviceDetailForm.getFieldValue().ceremony_photo_frame_id;
    let ceremony_food_plate_id = serviceDetailForm.getFieldValue().ceremony_food_plate_id;

    if (ceremony_bed_id === 0) serviceDetailForm.setFieldsValue({ ceremony_bed_id: value });
    if (ceremony_quilt_id === 0) serviceDetailForm.setFieldsValue({ ceremony_quilt_id: value });
    if (ceremony_flower_id === 0) serviceDetailForm.setFieldsValue({ ceremony_flower_id: value });
    if (ceremony_photo_frame_id === 0) serviceDetailForm.setFieldsValue({ ceremony_photo_frame_id: value });
    if (ceremony_food_plate_id === 0) serviceDetailForm.setFieldsValue({ ceremony_food_plate_id: value });
  }

  const updateItemStorageDefault = (itemStorageDefaultObject) => {
    if (_.isEmpty(itemStorageDefaultObject)) {
      return
    }
    const { value } = itemStorageDefaultObject;
    let ceremony_item_storage_id = serviceDetailForm.getFieldValue().ceremony_item_storage_id || value;
    serviceDetailForm.setFieldsValue({ ceremony_item_storage_id});
  }
  const findHairTreatment = (valueArr) => {
    console.log(valueArr)
    if (!valueArr) return;
    let nameArr = valueArr.map((item) => {
      let name = "";
      hairTreatmentOptions.every((entity) => {
        console.log(item)
        if (item === entity.value) { name = entity.label; return false }
        return true;
      })
      return name;
    })
    serviceDetailForm.setFieldsValue({ ceremony_hair_treatment: nameArr})
  }

  const updateOrderInfo = async (formValues) => {
    setSubmitLoading(true);
    // console.log(formValues)
    let result = await Service.call("patch", "/api/order_info", formValues);
    // console.log('data', data);

    if (result.status !== 1) {
      notification.error({
        message: t("submission_failed"),
        description: result.errorMessage,
      });
      setSubmitLoading(false);
      return;
    }

    notification.success({ message: t("submitted_successfully") });
    props.onChange();
    setSubmitLoading(false);
  };

  // ger redux site meta value
  const meta = useSelector((state) => state.app.meta);

  const dateFormat = `${
    !_.isEmpty(meta.system_date_format.meta_value) ? meta.system_date_format.meta_value : 'YYYY/MM/DD'
  } ${
    !_.isEmpty(meta.system_time_format.meta_value) ? meta.system_time_format.meta_value : 'HH:mm'
  }`

  if (!viewCeremonyInfo) {
    return (
      <>
        <div style={{marginTop: 75, textAlign: 'center', fontWeight: 'bold'}}>
          Unauthorized Access
        </div>
      </>
    )
  }

  return (
    <>
      <Modal
        title={t('create') + t(optionType)}
        visible={optionModalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setOptionModalVisible(false);
        }}
      >
        <OptionForm
          selectedFormData={{}}
          type={optionType}
          closeMethod={async (form_option_id, value) => {
            fetchData(optionType, form_option_id, value);
            setOptionModalVisible(false);
          }}
        />
      </Modal>
      <Spin
        indicator={<LoadingOutlined spin />}
        size="large"
        spinning={dataLoading}
      >
        <Form
          form={serviceDetailForm}
          name="serviceDetailForm"
          layout="vertical"
          initialValues={{
            ...initialValues,
            ceremony_source_id: initialValues.ceremony_source_id === 0 ? null : initialValues.ceremony_source_id,
            ceremony_room_id: initialValues.ceremony_room_id ? initialValues.ceremony_room_id : undefined
          }}
          onFinish={async (values) => {
            if (_.isUndefined(order_id)) {
              notification.error({
                message: t("submission_failed"),
                description: t("please_submit_customer_and_pet_information_first"),
              });
              return;
            }
            values.order_id = order_id;
            values.ceremony_room_id = _.toInteger(values.ceremony_room_id);
            // if (!values.ceremony_time) {
            //   delete values.ceremony_time
            //   delete values.ceremony_end_time
            // }
            // console.log(values)
            if (
              values.ceremony_room_id === 0
              || !values.ceremony_time
              || !values.ceremony_end_time
            ) {
              await updateOrderInfo(values);
              return;
            }
            let { data } = await Service.call("post", "/api/order_info/ceremonyAvailability", values);
            if (data) {
              await updateOrderInfo(values);
              return;
            }
            await Modal.confirm({
              title: t("confirm"),
              icon: <PlusOutlined />,
              content: t("the_room_is_occupied_are_you_going_to_book_this_room"),
              okText: t("ok"),
              cancelText: t("cancel"),
              onOk: async() => {
                await updateOrderInfo(values);
              }
            })
          }}
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="meet_up_time_status"
                label={t("meet_up_time_status")}
              >
                <Select
                  disabled={isReadOnly}
                >
                  {_.map(finishList, (val, key) => {
                    return (
                      <Option value={val}>{t(`${key}`)}</Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="ceremony_time_status"
                label={t("ceremony_time_status")}
              >
                <Select
                  disabled={isReadOnly}
                >
                  {_.map(finishList, (val, key) => {
                    return (
                      <Option value={val}>{t(`${key}`)}</Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider>
            ***
            {' '}
            {t("ceremony_info_detail")}
            {' '}
            ***
          </Divider>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label={t("meet_up_time")}
                name="meet_up_time"
                // rules={[{ required: true }]}
              >
                <DatePicker
                  disabled={isReadOnly}
                  placeholder={t('please_select')}
                  format={dateFormat}
                  showTime
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t("ceremony_time")}
                name="ceremony_time"
                // rules={[{ required: true }]}
              >
                <DatePicker
                  showTime
                  disabled={isReadOnly}
                  showNow={false}
                  minuteStep={15}
                  secondStep={60}
                  format={dateFormat}
                  style={{ width: "100%" }}
                  onChange={() => {
                    updateCeremonyEndTime();
                  }}
                  placeholder={t('please_select')}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t("ceremony_end_time")}
                name="ceremony_end_time"
                // rules={[{ required: true }]}
              >
                <DatePicker
                  disabled
                  showTime
                  showNow={false}
                  minuteStep={15}
                  secondStep={60}
                  format={dateFormat}
                  style={{ width: "100%" }}
                  placeholder={t('please_select')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                name="ceremony_duration"
                label={t("ceremony_duration")}
                rules={[{ required: true }]}
              >
                <Select
                  disabled={isReadOnly}
                  options={
                    _.times(21, (item) => {
                      return (
                        { label: `${item / 2 + 0.5}${t("hours")}`, value: (item / 2 + 0.5) * 60 }
                      )
                    })
                }
                  placeholder={t("click_to_view_options")}
                  onChange={() => {
                    updateCeremonyEndTime();
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={t("ceremony_guest")} name="ceremony_guest">
                <InputNumber
                  disabled={isReadOnly}
                  style={{ width: "100%" }}
                  min={1}
                  max={999}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={t("ceremony_room")}>
                <Row gutter={8}>
                  <Col span={13}>
                    <Form.Item label={t("ceremony_room")} name="ceremony_room" hidden>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item name="ceremony_room_id">
                      <Select
                        disabled={isReadOnly}
                        showSearch
                        options={_.sortBy(ceremonyRoomOptions, ['label'])}
                        placeholder={t("click_to_view_options")}
                        filterOption={(inputValue, option) => {
                          return (
                            option.label
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          );
                        }}
                        onChange={(value, option) => {
                          if (!option) {
                            serviceDetailForm.setFieldsValue({
                              ceremony_room: null,
                            });
                            return;
                          }
                          serviceDetailForm.setFieldsValue({
                            ceremony_room: option.label,
                          });
                        }}
                        allowClear
                      />
                    </Form.Item>

                    <Form.Item name="ceremony_room" hidden>
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Button
                      disabled={isReadOnly}
                      className="p_form_option_edit"
                      type="primary"
                      shape="circle"
                      onClick={() => {
                        setOptionModalVisible(true)
                        setOptionType('ceremony_room')
                      }}
                      icon={<PlusOutlined />}
                    />
                  </Col>
                  <Col span={8}>
                    <Button
                      disabled={isReadOnly}
                      style={{ width: "100%" }}
                      onClick={() => {
                        setModalVisible(true);
                      }}
                    >
                      {t("check_for_room")}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} align="middle">
            <Col span={8}>
              <Form.Item label={t("ceremony_bed")} name="ceremony_bed" hidden>
                <Input disabled />
              </Form.Item>
              <Form.Item label={t("ceremony_bed")} name="ceremony_bed_id" hidden>
                <Select
                  disabled={isReadOnly}
                  showSearch
                  options={ceremonyItemOptions}
                  placeholder={t("click_to_view_options")}
                  filterOption={(inputValue, option) => {
                    return (
                      option.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    );
                  }}
                  onChange={(value, option) => {
                    serviceDetailForm.setFieldsValue({
                      ceremony_bed: option.label,
                    });
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Button
                disabled={isReadOnly}
                className="p_form_option_edit"
                type="primary"
                shape="circle"
                onClick={() => {
                  setOptionModalVisible(true)
                  setOptionType('ceremony_item')
                }}
                icon={<PlusOutlined />}
                hidden
              />
            </Col>

            <Col span={12}>
              <Form.Item label={t("ceremony_quilt")} name="ceremony_quilt" hidden>
                <Input disabled />
              </Form.Item>
              <Form.Item label={t("ceremony_quilt")} name="ceremony_quilt_id" hidden>
                <Select
                  disabled={isReadOnly}
                  showSearch
                  options={ceremonyItemOptions}
                  placeholder={t("click_to_view_options")}
                  filterOption={(inputValue, option) => {
                    return (
                      option.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    );
                  }}
                  onChange={(value, option) => {
                    serviceDetailForm.setFieldsValue({
                      ceremony_quilt: option.label,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={t("ceremony_flower")} name="ceremony_flower" hidden>
                <Input disabled />
              </Form.Item>
              <Form.Item label={t("ceremony_flower")} name="ceremony_flower_id" hidden>
                <Select
                  disabled={isReadOnly}
                  showSearch
                  options={ceremonyItemOptions}
                  placeholder={t("click_to_view_options")}
                  filterOption={(inputValue, option) => {
                    return (
                      option.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    );
                  }}
                  onChange={(value, option) => {
                    serviceDetailForm.setFieldsValue({
                      ceremony_flower: option.label,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t("ceremony_photo_frame")} name="ceremony_photo_frame" hidden>
                <Input disabled />
              </Form.Item>
              <Form.Item label={t("ceremony_photo_frame")} name="ceremony_photo_frame_id" hidden>
                <Select
                  disabled={isReadOnly}
                  showSearch
                  options={ceremonyItemOptions}
                  placeholder={t("click_to_view_options")}
                  filterOption={(inputValue, option) => {
                    return (
                      option.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    );
                  }}
                  onChange={(value, option) => {
                    serviceDetailForm.setFieldsValue({
                      ceremony_photo_frame: option.label,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} align="middle">
            <Col span={8}>
              <Form.Item label={t("ceremony_item_storage")} name="ceremony_item_storage" hidden>
                <Input disabled />
              </Form.Item>
              <Form.Item label={t("ceremony_item_storage")} name="ceremony_item_storage_id">
                <Select
                  disabled={isReadOnly}
                  showSearch
                  options={itemStorageOptions}
                  placeholder={t("click_to_view_options")}
                  filterOption={(inputValue, option) => {
                    return (
                      option.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    );
                  }}
                  onChange={(value, option) => {
                    serviceDetailForm.setFieldsValue({
                      ceremony_item_storage: option.label,
                    });
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Button
                disabled={isReadOnly}
                className="p_form_option_edit"
                type="primary"
                shape="circle"
                onClick={() => {
                  setOptionModalVisible(true)
                  setOptionType('item_storage')
                }}
                icon={<PlusOutlined />}
              />
            </Col>
            <Col span={12}>
              <Form.Item label={t("ceremony_food_plate")} name="ceremony_food_plate" hidden>
                <Input disabled />
              </Form.Item>
              <Form.Item label={t("ceremony_food_plate")} name="ceremony_food_plate_id" hidden>
                <Select
                  disabled={isReadOnly}
                  showSearch
                  options={ceremonyItemOptions}
                  placeholder={t("click_to_view_options")}
                  filterOption={(inputValue, option) => {
                    return (
                      option.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    );
                  }}
                  onChange={(value, option) => {
                    serviceDetailForm.setFieldsValue({
                      ceremony_food_plate: option.label,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label={t("ceremony_grouping_photo")} name="ceremony_grouping_photo">
                <AutoComplete
                  disabled={isReadOnly}
                  options={isNeededOptions}
                  placeholder={t("click_to_view_options")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} align="middle">
            <Col span={8}>
              <Form.Item label={t("ceremony_source")} name="ceremony_source" hidden>
                <Input disabled />
              </Form.Item>
              <Form.Item label={t("ceremony_source")} name="ceremony_source_id">
                <Select
                  showSearch
                  disabled={isReadOnly}
                  options={sourceOptions}
                  placeholder={t("click_to_view_options")}
                  filterOption={(inputValue, option) => {
                    return (
                      option.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    );
                  }}
                  onChange={(value, option) => {
                    serviceDetailForm.setFieldsValue({
                      ceremony_source: option.label,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Button
                disabled={isReadOnly}
                className="p_form_option_edit"
                type="primary"
                shape="circle"
                onClick={() => {
                  setOptionModalVisible(true)
                  setOptionType('source')
                }}
                icon={<PlusOutlined />}
              />
            </Col>
            <Col span={12}>
              <Form.Item label={t("ceremony_procedure_photo")} name="ceremony_procedure_photo">
                <AutoComplete
                  disabled={isReadOnly}
                  options={isNeededOptions}
                  placeholder={t("click_to_view_options")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={t("ceremony_hair_treatment")} name="ceremony_hair_treatment" hidden>
                <Input disabled />
              </Form.Item>
              <Form.Item label={t("ceremony_hair_treatment")} name="ceremony_hair_treatment_id">
                <Checkbox.Group
                  disabled={isReadOnly}
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    findHairTreatment(value)
                  }}
                >
                  <Row>
                    {hairTreatmentOptions.map((item) => {
                      return (
                        <Col span={6} key={item.value}>
                          <Checkbox value={item.value}>{item.label}</Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label={t("ceremony_hydration_certificate")} name="ceremony_hydration_certificate">
                <AutoComplete
                  disabled={isReadOnly}
                  options={isNeededOptions}
                  placeholder={t("click_to_view_options")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label={t("ceremony_remark")} name="ceremony_remark">
            <Input disabled={isReadOnly} />
          </Form.Item>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                label={t('consultant_staff')}// "地址" / "診所"
                name="service_consultant_staff_id"
              >
                <Select
                  disabled={isReadOnly}
                  showSearch
                  options={staffOptions}
                  placeholder={t("click_to_view_options")}
                  filterOption={(inputValue, option) => {
                    return (
                      option.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              disabled={isReadOnly || submitLoading || dataLoading}
              loading={submitLoading}
              type="primary"
              className="p_order_ceremony_edit"
              htmlType="submit"
            >
              {t("submit")}
            </Button>
          </Form.Item>
        </Form>
      </Spin>

      <Modal
        visible={modalVisible}
        footer={null}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <OrderInfoCalendar />
      </Modal>
    </>
  );
};

export default OrderInfoServiceDetailForm;
