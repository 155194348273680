import React, { useState, useEffect } from 'react';
import {
  // eslint-disable-next-line max-len
  notification, Tabs, Table, Badge, Button, Modal, Form, Input, Radio, DatePicker, Row, Col, Select, Space
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import debugLib from 'debug';
import NavAndSideFrame from '../../components/NavAndSideFrame';

import * as Service from '../../core/Service';
import * as Main from "../../core/Main";

const { Option } = Select;

const StockBalanceReportWithPrice = ({ dataList }) => {
  const app = useSelector((state) => state.app);
  const myPermission = useSelector((state) => state.app.company_user.myPermission);
  const [reportwithprice] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [productDataList, setProductDataList] = useState([])
  const [toProductDataList, setToProductDataList] = useState([])
  const [cutDate, setCutDate] = useState(0)
  const [isAllowBalanceReport, setIsAllowBalanceReport] = useState(false);

  useEffect(() => {
    getAllData();
    setAllowReportGeneration();
  }, [])

  const setAllowReportGeneration = () => {
    let isAllowPermission = _.intersection(myPermission, ['p_report_price_view', 'p_any'])
    if (!_.isEmpty(isAllowPermission)) {
      setIsAllowBalanceReport(true);
    }
  }

  const getAllData = async () => {
    try {
      let result = await Service.call('get', `/api/product`);

      if (result.status !== 1) {
        notification.warning({ message: '獲取資料失敗' });
        return;
      }

      let { data } = result;
      let sortData = _.sortBy(data, ['sku']);
      setProductDataList(sortData);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  function onChange(date, dateString) {
    let cutDateUnix = moment(dateString).unix()
    setCutDate(cutDateUnix)
  }

  function handleChangeFromProduct(value) {
    let skuFindIndex = _.findIndex(productDataList, ['sku', value]);
    let skuSlice = productDataList.slice(skuFindIndex);
    setToProductDataList(skuSlice)
  }
  

  return (
    <Form
      form={reportwithprice}
      onFinish={async (values) => {
        if (!isAllowBalanceReport) {
          return notification.warning({ message: `${t('permission_denied')} [${t('p_report_price_view')}]` });
        }
        let { from_product_code, to_product_code, cut_date } = values;
        window.location = `${app.config.API_SERVER_URL}/api/report/stock_balance_with_price_report?cutDate=${cutDate}&fromSku=${from_product_code}&toSku=${to_product_code}&language=${i18n.language}`;
      }}
    >
      <Col span={8}>
        <Form.Item
          label={t("from_product_code")}
          name="from_product_code"
        >
          <Select
            showSearch
            placeholder={t("from_product_code")}
            optionFilterProp="children"
            onChange={handleChangeFromProduct}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {_.map(productDataList, (productRc) => (
              <Option key={productRc.sku}>{productRc.sku}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          label={t("to_product_code")}
          name="to_product_code"
        >
          <Select
            showSearch
            placeholder={t("to_product_code")}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {_.map(toProductDataList, (productRc) => (
              <Option key={productRc.sku}>{productRc.sku}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          label={t("cut_date")}
          name="cut_date"
        >
          <DatePicker onChange={onChange} format={"YYYY/MM/DD"} />

        </Form.Item>
      </Col>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
        >
          {t('export')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default StockBalanceReportWithPrice;
