import React, { useState, useEffect } from 'react';
import {
  Button, Divider, notification, Modal, Select, Input, Space, Tooltip, Layout, Table, Form,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import LanguageList from '../../data/LanguageList.json';

const { Option } = Select;
const { TextArea } = Input

let fuse = [];

const TranslationList = (props) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const history = useHistory();

  const tableIDName = "translation_id";

  const [modalVisible, setModalVisible] = useState(false)
  const [selectedFormData, setSelectedFormData] = useState({});

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      let result = await Service.call('get', '/api/translation/list');
      // console.log('result', result);

      if (result.status !== 1) {
        return notification.warning({ message: '獲取資料失敗' });
      }

      let { data } = result;
      let groupedDataList = _.groupBy(data, 'translation_key');
      let groupedMultiLangDataList = [];
      _.map(groupedDataList, (translationArray, key) => {
        let perTranslationObject = {}
        _.map(translationArray, (val, index) => {
          perTranslationObject.translation_key = val.translation_key
          perTranslationObject[val.language] = val.translation_text
        })
        groupedMultiLangDataList.push(perTranslationObject)
      })
      // console.log('groupedMultiLangDataList', groupedMultiLangDataList)

      let langKeys = _.keys(LanguageList);
      const fuseOptions = {
        findAllMatches: true,
        includeScore: true,
        threshold: 0.15,
        keys: langKeys
      };
      fuse = new Fuse(groupedMultiLangDataList, fuseOptions);

      setDataList(groupedMultiLangDataList);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  const filterData = async (filterObj = {}) => {
    if (_.isEmpty(filterObj.searchWord)) { //  Return original data without search
      return setDataList(fuse._docs)
    }

    const fuzzyResults = fuse.search(filterObj.searchWord).map((result) => result.item)
    setDataList(fuzzyResults)
  }

  const setTableHeader = () => {
    const columns = [];
    _.map(LanguageList, (val, key) => {
      columns.push({
        title: val,
        dataIndex: key,
        render: (value, record) => {
          return (
            <Space>
              {value}

              { key === 'en' && (
              <Tooltip title={t('modify')}>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    let editObject = {
                      language: key,
                      translation_key: record.translation_key,
                      translation_text: value
                    }

                    setSelectedFormData(editObject)
                    setModalVisible(true)
                  }}
                  style={{ marginRight: 8, color: '#000000' }}
                />
              </Tooltip>
              )}
            </Space>
          )
        }
      })
    })

    return columns;
  }

  return (
    <NavAndSideFrame {...props} title={t('translation')} selectedKey="translation">

      <Space direction="horizontal">
        <>
          {t('search')}
        </>
        <Input
          placeholder={t('please_input_keyword')}
          onChange={(event) => {
            filterData({ searchWord: event.target.value })
          }}
        />
      </Space>

      <Divider />

      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
      />

      <Modal
        title={!_.isEmpty(selectedFormData) ? t('edit') : t('create')}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <TranslationForm
          selectedFormData={selectedFormData}
          closeMethod={async () => {
            getAllData();
            setModalVisible(false);
          }}
        />
      </Modal>

    </NavAndSideFrame>
  )
}

const TranslationForm = (props) => {
  const { t } = useTranslation();

  const { selectedFormData, closeMethod } = props

  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      initialValues={selectedFormData}
      onFinish={async (values) => {
        let result = await Service.call('post', '/api/translation', values)
        // console.log('result', result)

        if (result.status !== 1) {
          return notification.error({ message: '提交失敗', description: result.errorMessage });
        }

        notification.success({ message: '提交成功' });
        closeMethod()
      }}
    >
      {!_.isEmpty(selectedFormData) && (
        <Form.Item
          label={t('translation_key')}
          name="translation_key"
          hidden
        >
          <Input disabled />
        </Form.Item>
      )}

      <Form.Item
        label={t('language')}
        name="language"
        rules={[{ required: true }]}
      >
        <Select
          disabled
        >
          {_.map(LanguageList, (val, key) => <Option value={key} key={key}>{val}</Option>)}
        </Select>
      </Form.Item>

      <Form.Item
        label={t('translation_to')}
        name="translation_text"
        rules={[{ required: true }]}
      >
        <TextArea rows={4} />
      </Form.Item>

      <Button type="primary" htmlType="submit" className='p_form_option_edit'>
        {t('submit')}
      </Button>
    </Form>
  )
}

export default TranslationList;
