import React, { Component } from 'react'
import { Anchor, Divider, Layout, Menu } from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  Link
} from "react-router-dom";
import {
  DashboardOutlined,
  UserOutlined,
  AppstoreOutlined,
  TeamOutlined,
  BankOutlined,
  ProjectOutlined,
  DollarOutlined,
  MailOutlined,
  HistoryOutlined,
  UnorderedListOutlined,
  ShoppingCartOutlined,
  MoneyCollectOutlined,
  SettingOutlined,
  BarChartOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import * as Service from '../core/Service';
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../redux/actions';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

const { Sider } = Layout;
const { SubMenu } = Menu;

let dataList;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      logout: false,
      isAllowInvoiceTab: false,
      Version: '',
    };
    this.setVersion()
  }

  componentDidMount() {
    this.setTabVisible();
  }

  async toggle() {
    let { collapsed } = this.state
    this.setState({ collapsed: !collapsed });
  }

  async logout() {
    await Service.logout();
    this.props.setAdmin({})
    this.props.setCompanyAdmin({})
    this.props.setAuth(false)
    // this.props.history.push('/login');
    this.setState({ logout: true });
  }

  setTabVisible() {
    let isAllowPermission = _.intersection(this.props.app.company_user.myPermission, ['p_invoice_monthly_view', 'p_invoice_view', 'p_any'])
    if (!_.isEmpty(isAllowPermission)) {
      this.setState({isAllowInvoiceTab: true});
    }
  }

  async setVersion() {
    let resp = await Service.call('get', '/api/config');
    if (resp && resp.version) {
      this.setState({Version: resp.version});
    } else {
      throw new Error('failed to get app config');
    }
  }

  renderMenuItem() {
    let admin = false;
    admin = _.isEmpty(this.props.app.company_admin);
    if (!_.isEmpty(this.props.app.company_user)) {
      admin = false;
    }

    return dataList.map((pathData, i) => {
      if (pathData.submenu && admin) {
        return (
          <SubMenu
            key={pathData.key}
            icon={pathData.icon}
            title={pathData.title}
            className={pathData.className ? pathData.className.join(' ') : ''}
          >
            {pathData.submenu.map((subPathData, i) => {
              return (
                <Menu.Item key={subPathData.key}>
                  <Link to={subPathData.path}>
                    {subPathData.icon}
                    {subPathData.title}
                  </Link>
                </Menu.Item>
              )
            })}
          </SubMenu>
        )
      }

      //  privilege remark: all / admin / company
      if (!admin && pathData.privilege === 'admin') { //  Company user not allow to access admin page
        return;
      }

      if (admin && pathData.privilege === 'company') { //  Admin user not allow to access company user page
        return;
      }

      let redirectPath = pathData.path;
      if (!admin) {
        redirectPath = pathData.companyPath;
      }

      return (
        <Menu.Item
          key={pathData.key}
          onClick={this.handleClick}
          className={
            pathData.className ? pathData.className.join(' ') : ''
          }
        >
          <Link to={redirectPath}>
            {pathData.icon}
            <span>{pathData.title}</span>
          </Link>
        </Menu.Item>
      )
    })
  }

  render() {
    let { defaultSelectedKeys, t } = this.props;

    dataList = [
      { key: 'dashboard', title: t('dashboard'), path: '/admin/home', companyPath: '/company/home', icon: <DashboardOutlined />, display: 'block', privilege: 'company' },
      // { key: 'admin_user', title: t('admin_user'), path: '/admin/list', icon: <TeamOutlined />, display: 'block', privilege: 'admin' },
      { key: 'order_info', title: t('order_information'), path: '/', companyPath: '/company/order_info', icon: <UnorderedListOutlined />, display: 'block', privilege: 'company', className: ['p_order_agreement_view'] },
      { key: 'calendar_view', title: t('calendar'), path: '/', companyPath: '/company/calendar_view', icon: <CalendarOutlined />, display: 'block', privilege: 'company' },
      // { key: 'product_item', title: t('product_information'), path: '/', companyPath: '/company/product_item', icon: <ShoppingCartOutlined />, display: 'block', privilege: 'company', className: ['p_product_name_view'] },
      { key: 'product_item', title: t('product_information'), path: '/', companyPath: '/company/product_item', icon: <ShoppingCartOutlined />, display: 'block', privilege: 'company' },
      { key: 'product_category', title: t('product_category'), path: '/', companyPath: '/company/product_category', icon: <ShoppingCartOutlined />, display: 'block', privilege: 'company', className: ['p_product_category_view'] }
    ]

    //  Check permission included p_invoice or p_monthly
    if (this.state.isAllowInvoiceTab) {
      dataList.push(
        {
          key: 'invoice_info', title: t('invoice_information'), path: '/', companyPath: '/company/invoice_info', icon: <MoneyCollectOutlined />, display: 'block', privilege: 'company'
        }
      );
    }

    dataList = _.concat(dataList, [
      { key: 'receipt_info', title: t('receipt_information'), path: '/', companyPath: '/company/receipt_info', icon: <MoneyCollectOutlined />, display: 'block', privilege: 'company', className: ['p_receipt_view'] },
      { key: 'report', title: t('sales_report'), path: '/', companyPath: '/company/report', icon: <BarChartOutlined />, display: 'block', privilege: 'company', className: ['p_sales_report_view'] },
      { key: 'general_report', title: t('general_report'), path: '/', companyPath: '/company/general_report', icon: <BarChartOutlined />, display: 'block', privilege: 'company', className: ['p_general_report_view'] },
      { key: 'user_account', title: t('user_management_client'), path: '/', companyPath: '/company/user_account', icon: <TeamOutlined />, display: 'block', privilege: 'company', className: ['p_user_view'] },
      { key: 'company_user', title: t('user_management_staff'), path: '/', companyPath: '/company/user', icon: <TeamOutlined />, display: 'block', privilege: 'company', className: ['p_company_user_view'] },
      { key: 'setting', title: t('setting'), path: '/', companyPath: '/company/setting', icon: <SettingOutlined />, display: 'block', privilege: 'company', className: ['p_setting_view'] },
      // { key: 'option_setting', title: t('option_setting'), path: '/', companyPath: '/company/option_setting', icon: <SettingOutlined />, display: 'block', privilege: 'company', className: ['p_form_option_view'] },
      { key: 'option_setting', title: t('option_setting'), path: '/', companyPath: '/company/option_setting', icon: <SettingOutlined />, display: 'block', privilege: 'company' },
      { key: 'hydration_machine_setting', title: t('hydration_machine_setting'), path: '/', companyPath: '/company/hydration_machine_setting', icon: <SettingOutlined />, display: 'block', privilege: 'company', className: ['p_order_hydration_view'] },
      { key: 'hydration_batch_setting', title: t('hydration_batch_setting'), path: '/', companyPath: '/company/hydration_batch_setting', icon: <SettingOutlined />, display: 'block', privilege: 'company', className: ['p_order_hydration_view']},
      // { key: 'action_info_setting', title: t('action_info_setting'), path: '/', companyPath: '/company/action_info_setting', icon: <SettingOutlined />, display: 'block', privilege: 'company' },
      // { key: 'workflow_setting', title: t('workflow_setting'), path: '/', companyPath: '/company/workflow_setting', icon: <SettingOutlined />, display: 'block', privilege: 'company' },
      { key: 'pet_service_charge', title: t('pet_service_charge'), path: '/', companyPath: '/company/pet_service_charge', icon: <SettingOutlined />, display: 'block', privilege: 'company', className: ['p_pet_relation_view'] },
      // { key: 'pet_category_species', title: t('pet_category_species'), path: '/', companyPath: '/company/pet_category_species', icon: <SettingOutlined />, display: 'block', privilege: 'company', className: ['p_form_option_view'] },
      { key: 'pet_category_species', title: t('pet_category_species'), path: '/', companyPath: '/company/pet_category_species', icon: <SettingOutlined />, display: 'block', privilege: 'company' },
      // { key: 'area_district_pickUp', title: t('area_district_pickUp'), path: '/', companyPath: '/company/area_district_pickUp', icon: <SettingOutlined />, display: 'block', privilege: 'company', className: ['p_form_option_view'] },
      { key: 'area_district_pickUp', title: t('area_district_pickUp'), path: '/', companyPath: '/company/area_district_pickUp', icon: <SettingOutlined />, display: 'block', privilege: 'company' },
      // { key: 'translation', title: t('translation'), path: '/', companyPath: '/company/translation', icon: <SettingOutlined />, display: 'block', privilege: 'company', className: ['p_form_option_view'] },
      { key: 'translation', title: t('translation'), path: '/', companyPath: '/company/translation', icon: <SettingOutlined />, display: 'block', privilege: 'company' },
      { key: 'space_rental', title: t('space_rental'), path: '/', companyPath: '/company/space_rental', icon: <SettingOutlined />, display: 'block', privilege: 'company', className: ['p_order_ceremony_view'] },
      { key: 'user_role', title: t('user_role'), path: '/', companyPath: '/company/user_role', icon: <SettingOutlined />, display: 'block', privilege: 'company', className: ['p_permission_view'] },
      { key: 'audit_log', title: t('audit_log'), path: '/', companyPath: '/company/audit_log', icon: <SettingOutlined />, display: 'block', privilege: 'company', className: ['p_audit_log_view'] }
    ])

    return (
      <Sider
        breakpoint="sm"
        collapsedWidth="0"
        width={250}
        style={{
          height: '100%', opacity: 1, position: 'relative', backgroundColor: 'white'
        }}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={[defaultSelectedKeys]}
          defaultOpenKeys={['1']}
          style={{ paddingBottom: '50px', paddingTop: '20px' }}
        >
          {this.renderMenuItem()}
        </Menu>
        <Menu mode="inline" style={{ opacity: 1, position: 'relative' }}>
          {/* <Divider /> */}
          <span style={{marginLeft:25}}>{`Ver${this.state.Version}`}</span>
          <Menu.Item key="99" onClick={() => { this.logout(); }}>
            <span>{t('logout')}</span>
          </Menu.Item>
        </Menu>

        {
          this.state.logout === true
            ? <Redirect to="/admin/login" />
            : null
        }
      </Sider>
    )
  }
}

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Sidebar));
