import React, { useState, useEffect } from 'react';
import {
  Button, Layout, Table, Tooltip, Modal, notification, Badge, Space
} from 'antd';
import {
  DeleteOutlined, EditOutlined, CloseOutlined, CheckOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import _ from 'lodash';
import * as Service from '../../core/Service';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const { confirm } = Modal;

const ProductCategoryTable = (props) => {
  const { t } = useTranslation()

  const [dataList, setDataList] = useState([]);
  const tableIDName = "product_category_id";
  const history = useHistory();

  useEffect(() => {
    setDataList(props.dataObj)
  }, [props.dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record) => {
          let { is_active } = record
          return (
            <Space>
              <Tooltip title= {t("modify")}>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    history.push(`/company/product_category_form?product_category_id=${value}`)
                  }}
                />
              </Tooltip>
              {/* <Button className="p_product_edit" shape="circle" icon={is_active ? <CloseOutlined /> : <CheckOutlined />} style={{ color: (is_active ? '#AA0000' : '#00AA00') }} onClick={() => toggleActive(record)} />
              <Button className="p_product_edit" shape="circle" icon={<DeleteOutlined />} onClick={() => toggleDelete(record)} /> */}
            </Space>
          )
        }
      },
      {
        title: t("activity"),
        dataIndex: 'is_active',
        key: 'is_active',
        render: (text, record, index) => {
          let {is_active} = record;
          return (
            <span>
              {
                is_active === 1
                  ? (<Badge status="processing" />)
                  : <Badge color="#555" />
              }
            </span>
          )
        }
      },
      {
        title: t('category_name'),
        dataIndex: `category_name`,
      },
      {
        title: t('product_category_id'),
        dataIndex: `product_category_id`,
      },
      {
        title: t('parent_category_id'),
        dataIndex: `parent_category_id`,
      },
      {
        title: t('parent_index'),
        dataIndex: `parent_index`,
      },
    ];
    return columns;
  }

  // const toggleActive = (record) => {
  //   confirm({
  //     title: `確認${record.is_active ? '停用' : '允許'}${record.product_name}？`,
  //     okText: '是',
  //     okType: 'danger',
  //     cancelText: '否',
  //     onOk: async () => {
  //       let postObject = {
  //         product_category_id: record.product_category_id,
  //         is_active: (record.is_active ? 0 : 1)
  //       }
  //       let result = await Service.call('patch', '/api/product', postObject);
  //       // console.log('result', result);

  //       if (result.status !== 1) {
  //         notification.error({ message: '修改失敗', description: result.errorMessage });
  //         return;
  //       }

  //       notification.success({ message: '修改成功' });
  //       props.fetchData();
  //     },
  //     onCancel: () => {
  //       // console.log('Cancel');
  //     },
  //   });
  // }

  // const toggleDelete = (record) => {
  //   confirm({
  //     title: `確認刪除${record.product_name}？`,
  //     okText: '是',
  //     okType: 'danger',
  //     cancelText: '否',
  //     onOk: async () => {
  //       let result = await Service.call('delete', `/api/product/${record.product_category_id}`);
  //       // console.log('result', result);

  //       if (result.status !== 1) {
  //         return notification.error({ message: '刪除失敗', description: t(result.errorMessage) });
  //       }

  //       notification.success({ message: '刪除成功' });
  //       props.fetchData();
  //     },
  //     onCancel: () => {
  //       // console.log('Cancel');
  //     },
  //   });
  // }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
      />
    </Layout>
  )
}

export default ProductCategoryTable;
