import React, { useState, useEffect, useRef, useContext } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Radio, Popconfirm, Spin, Table, Space, Modal
} from 'antd';
import {
  DeleteOutlined, EditOutlined
} from '@ant-design/icons';
import moment from 'moment';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import HydrationMachinePlaceTable from './HydrationMachinePlaceTable'
import HydrationMachinePlaceForm from './HydrationMachinePlaceForm'

const debug = require('debug')('app:src:admin:client:src:pages:company:HydrationMachinePlace')

const { Search, TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const HydrationMachinePlace = (props) => {
  const { t } = useTranslation()
  let location = useLocation();

  const { fetchData, hydrationMachineObject} = props
  const { hydration_machine_id } = hydrationMachineObject;

  const [dataList, setDataList] = useState([])
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    fetchHydrationMachinePlaceData()
  }, [])

  const fetchHydrationMachinePlaceData = async () => {
    console.log("Hydration ID", hydration_machine_id)
    let result = await Service.call('get', `/api/hydration_machine_place?hydration_machine_id=${hydration_machine_id}`)
    if (result.status !== 1) {
      return notification.error({ message: '錯誤', description: result.errorMessage });
    }
    result.data = _.orderBy(result.data, ['ctime'], ['desc']);
    setDataList(result.data)
  }

  return (
    <>
      <Space direction="horizontal">
        <Button
          type="primary"
          onClick={() => {
            setModalVisible(true)
          }}
        >
          {t('add_hydration_machine_place')}
        </Button>
      </Space>
      <Divider />

      <HydrationMachinePlaceTable
        dataObj={dataList}
        hydration_machine_id={hydration_machine_id}
        fetchData={fetchHydrationMachinePlaceData}
      />

      <Modal
        title={t('add_hydration_machine_place')}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <HydrationMachinePlaceForm
          hydration_machine_id={hydration_machine_id}
          closeMethod={async () => {
            fetchHydrationMachinePlaceData()
            fetchData()
            setModalVisible(false);
          }}
        />
      </Modal>
    </>
  )
}

export default HydrationMachinePlace;
