import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, AutoComplete, Modal, Row, Col
} from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';

import OrderInfoBasicForm from './OrderInfoBasicForm';
import OrderInfoServiceDetailForm from './OrderInfoServiceDetailForm';
import OrderInfoAshesSpaceForm from './OrderInfoAshesSpaceForm';
import OrderItemForm from './OrderItemForm';
import OrderInfoPlantSpaceForm from './OrderInfoPlantSpaceForm';
import OrderInfoAshesServiceForm from './OrderInfoAshesServiceForm';
import OptionForm from './OptionForm'

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const ReceiptInfoBasicForm = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { t } = useTranslation();
  const { receipt_info_id, invoice_no } = queryString.parse(location.search);

  const { initialValues } = props
  // console.log(initialValues)
  const [receiptInfoForm] = Form.useForm();
  // const [hydrationServiceOptions, setHydrationServiceOptions] = useState([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [optionType, setOptionType] = useState(false)


  useEffect(() => {
    fetchFormOption()
  }, [])

  useEffect(() => {
    if (invoice_no) fetchInvoice()
  }, [invoice_no])

  useEffect(() => {
    receiptInfoForm.resetFields()
  }, [initialValues])

  const fetchFormOption = async () => {
    // fetchHydrationService()
    fetchPaymentMethod();
  }

  const fetchInvoice = async () => {
    const resp = await Service.call('get', `/api/invoice_info?invoice_no=${invoice_no}`)
    const {
      data = [],
    } = resp;
    const [firstInvoiceObj] = data;
    const {
      invoice_no_with_prefix = '',
    } = firstInvoiceObj;

    receiptInfoForm.setFieldsValue({
      invoice_no_with_prefix,
      payment_total: _.first(resp.data).payment_balance // Default is payment balance of invoice
    })
  }

  // const fetchHydrationService = async () => {
  //   let options = await Main.fetchFormOption('hydration_service')
  //   setHydrationServiceOptions(options);
  // }

  const fetchData = async (type, id, value) => {
    switch (type) {
      case "payment_method":
        fetchPaymentMethod(id, value);
        receiptInfoForm.setFieldsValue({ payment_method: value, payment_method_id: id})
        break;
      default:
        break;
    }
  }

  const fetchPaymentMethod = async (id, value) => {
    let options = await Main.fetchFormOption('payment_method', true)
    if (id) options.push({value: id, label: value})
    setPaymentMethodOptions(options);
  }

  // ger redux site meta value
  const meta = useSelector((state) => state.app.meta);

  const dateFormat = `${
    !_.isEmpty(meta.system_date_format.meta_value) ? meta.system_date_format.meta_value : 'YYYY/MM/DD'
  }`

  return (
    <>
      <Modal
        title={t('create') + t(optionType)}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <OptionForm
          selectedFormData={{}}
          type={optionType}
          closeMethod={async (form_option_id, value) => {
            fetchData(optionType, form_option_id, value);
            setModalVisible(false);
          }}
        />
      </Modal>
      <Form
        form={receiptInfoForm}
        name="receiptInfoForm"
        layout="vertical"
        onFinish={async (postObj) => {
          let method = 'post';
          if (!_.isUndefined(receipt_info_id)) {
            method = 'patch';
            postObj.receipt_info_id = receipt_info_id;
          }

          let results = await Service.call(method, '/api/receipt_info', postObj)
          // console.log('results', results)

          if (results.status !== 1) {
            notification.error({ message: '提交失敗', description: results.errorMessage });
            return;
          }

          notification.success({ message: '提交成功' });
          history.push(`/company/receipt_info`);
        }}
        initialValues={{
          ...initialValues,
          payment_date: initialValues.payment_date ? initialValues.payment_date : moment(),
        }}
      >

        <Form.Item
          hidden
          label={t('invoice_no')}
          name="invoice_no"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label={t('invoice_no')}
          name="invoice_no_with_prefix"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          hidden
          label={t('receipt_no')}
          name="receipt_no"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label={t('receipt_no')}
          name="receipt_no_with_prefix"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label={t('receipt_date')}
          name="receipt_date"
          rules={[
            {
              required: true,
              message: t('please_enter_this_field')
            },
          ]}
        >
          <DatePicker
            format={dateFormat}
          />
        </Form.Item>

        {/* <Form.Item
          label="水化服務"
          name="hydration_service"
        >
          <AutoComplete
            options={hydrationServiceOptions}
            placeholder="點擊查看選項"
            filterOption={(inputValue, option) => {
              return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
            }}
          />
        </Form.Item> */}

        {/* <Form.Item
          label="水化服務總額"
          name="hydration_service_total"
        >
          <InputNumber />
        </Form.Item> */}


        {/* <Form.Item
          label="按金"
          name="deposit"
        >
          <InputNumber />
        </Form.Item> */}

        {/* <Form.Item
          label="折扣金額"
          name="discount"
        >
          <InputNumber />
        </Form.Item> */}

        {/* <Form.Item
          label="折扣原因"
          name="discount_reason"
        >
          <Input />
        </Form.Item> */}

        {/* <Form.Item
          label="總金額"
          name="receipt_total"
        >
          <InputNumber disabled />
        </Form.Item> */}

        <Form.Item
          label={t('remark')}
          name="receipt_remark"
        >
          <Input />
        </Form.Item>

        <Divider>
          ***
          {' '}
          {t('payment_information')}
          {' '}
          ***
        </Divider>

        <Row gutter={24} align="middle">
          <Col span={20}>
            <Form.Item
              label={t("payment_method")}
              name="payment_method"
              hidden
            >
              <InputNumber disabled />
            </Form.Item>

            <Form.Item
              label={t("payment_method")}
              name="payment_method_id"
              rules={[
                {
                  required: true,
                  message: t('please_enter_this_field'),
                },
              ]}
            >
              <Select
                showSearch
                options={paymentMethodOptions}
                placeholder={t("click_to_view_options")}
                filterOption={(inputValue, option) => {
                  return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                }}
                onChange={(value, option) => {
                  receiptInfoForm.setFieldsValue({
                    payment_method: option.label,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              shape="circle"
              className="p_form_option_edit"
              onClick={() => {
                setModalVisible(true)
                setOptionType('payment_method')
              }}
              icon={<PlusOutlined />}
            />
          </Col>
        </Row>
        <Form.Item
          label={t('payment_date')}
          name="payment_date"
          rules={[
            {
              required: true,
              message: t('please_enter_this_field'),
            },
          ]}
        >
          <DatePicker
            format={dateFormat}
          />
        </Form.Item>

        <Form.Item
          label={t('paid_total')}
          name="payment_total"
        >
          <InputNumber className="rightAlignNumber" />
        </Form.Item>

        <Form.Item>
          <Button
            className="p_receipt_edit"
            type="primary"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default ReceiptInfoBasicForm;
