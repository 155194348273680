import React, { useState, useEffect, useDebugValue, useRef} from 'react';
import {
  Button, Divider, notification, Space, Input, Modal, Select
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import Fuse from 'fuse.js';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import HydrationMachineTable from './HydrationMachineTable';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

let fuse = [];
const HydrationMachineList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [productCategoryId, setProductCategoryId] = useState();
  const [productCategoryOptions, setProductCategoryOptions] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const inputRef = useRef();

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    console.log(productCategoryId)
  }, [productCategoryId])

  const getAllData = async () => {
    try {
      let result = await Service.call('get', '/api/hydration_machine');
      // console.log('result', result);

      if (result.status !== 1) {
        return notification.warning({message: result.errorMessage});
      }

      let { data } = result;
      let orderedDataList = _.orderBy(data, ['ctime'], ['desc']);
      const fuseOptions = {
        findAllMatches: true,
        includeScore: true,
        threshold: 0.15,
        keys: [
          'name', 'machine_no'
        ]
      };
      fuse = new Fuse(orderedDataList, fuseOptions);
      setDataList(orderedDataList);
      filterData({searchWord: inputRef.current.input.value})
    } catch (error) {
      console.error('error >>> ', error);
    }
  }


  const filterData = async (filterObj = {}) => {
    if (_.isEmpty(filterObj.searchWord)) { //  Return original data without search
      return setDataList(fuse._docs)
    }

    const fuzzyResults = fuse.search(filterObj.searchWord).map((result) => result.item)
    setDataList(fuzzyResults)
  }

  return (
    <NavAndSideFrame {...props} title={t('hydration_machine_list')} selectedKey="hydration_machine_setting">
      <Modal
        title={t("export_Product")}
        visible={modalVisible}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onOk={async (e) => {
          console.log(e)
          if (_.isUndefined(productCategoryId)) return
          window.location = `${app.config.API_SERVER_URL}/api/product/report?product_category_id=${productCategoryId}`;
          setModalVisible(false)
        }}
        onCancel={(e) => {
          setModalVisible(false)
        }}
      >
        <Space>
          <>
            {t('product_category')}
          </>
          <Select
            showSearch
            style={{ width: 400 }}
            placeholder={t('product_category')}
            options={productCategoryOptions}
            optionFilterProp="children"
            onChange={(value) => {
              setProductCategoryId(value)
            }}
            filterOption={(input, option) => {
              return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
          />
        </Space>
      </Modal>
      <Space>
        <Button
          type="primary"
          onClick={() => {
            history.push('/company/hydration_machine_form')
          }}
          className="p_order_hydration_edit"
        >
          {t("add_new_hydration_machine")}
        </Button>
        <>
          {t('search')}
        </>
        <Input
          ref={inputRef}
          placeholder={t('please_input_keyword')}
          onChange={(event) => {
            filterData({ searchWord: event.target.value })
          }}
        />
      </Space>
      <Divider />
      <HydrationMachineTable
        dataObj={dataList}
        fetchData={getAllData}
      />
    </NavAndSideFrame>
  )
}

export default HydrationMachineList;
