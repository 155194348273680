import React, { Component, useState, useEffect } from "react";
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm,
  Table, Tag, Tooltip, message, notification, Space, Input,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  CheckOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from 'query-string';
import { useTranslation } from "react-i18next";
import * as Service from "../core/Service";

const debug = require('debug')('app:admin:client:src:components:PPSignature');
// var __html = require('../lib-client/PPSignSDK_PPSignSDK-DLL-V2.4.2_demo_JavaScript/WebSample/e560.html');
// var template = { __html: __html };

// require('../lib-client/PPSignSDK_PPSignSDK-DLL-V2.4.2_demo_JavaScript/WebSample/scripts/vendor.js');
// require('../lib-client/PPSignSDK_PPSignSDK-DLL-V2.4.2_demo_JavaScript/WebSample/scripts/plugins.js');
// require('../lib-client/PPSignSDK_PPSignSDK-DLL-V2.4.2_demo_JavaScript/WebSample/scripts/main.js');

// require('../lib-client/PPSignSDK_PPSignSDK-DLL-V2.4.2_demo_JavaScript/WebSample/scripts/e560.js');

// const modernizr = require('../lib-client/PPSignSDK_PPSignSDK-DLL-V2.4.2_demo_JavaScript/WebSample/scripts/vendor/modernizr')

// const vendor = require('../lib-client/PPSignSDK_PPSignSDK-DLL-V2.4.2_demo_JavaScript/WebSample/scripts/vendor')
// const plugins = require('../lib-client/PPSignSDK_PPSignSDK-DLL-V2.4.2_demo_JavaScript/WebSample/scripts/plugins')

// const e560 = require('../lib-client/PPSignSDK_PPSignSDK-DLL-V2.4.2_demo_JavaScript/WebSample/scripts/e560')

// var apiUrl = 'http://localhost:8089/PPSignSDK/';
// var initUrl = apiUrl + 'InitialDevice?id=2&width=580&height=380';

// var isPolling = false;

const PPSignature = ({
  isReadOnly
}) => {
  const { t } = useTranslation()

  // initialize device
  // const initDevice = async () => {
  //   //event.preventDefault();

  //   // $.ajax({
  //   //     url: initUrl,
  //   //     type: 'GET',
  //   //     cache: false
  //   // }).done(function (response) {
  //   //     if (response === true) {
  //   //         isPolling = true;

  //   //         getInk();

  //   //         getStatus();
  //   //         $('.init').removeAttr('disabled');
  //   //         $('#initBtn').attr('disabled', 'disabled');
  //   //         document.getElementById('VideoStatusBtn').innerHTML = 'Enable';
  //   //     } else {
  //   //         alert('No device!');
  //   //     }
  //   // }).fail(function (jqXHR, textStatus, errorThrown) {
  //   //     alert('Connection fail(' + jqXHR.status + ')!');
  //   // });

  //   let resp = await Service.call('get', initUrl);
  //   if (response === true) {
  //     isPolling = true;

  //     getInk();

  //     getStatus();
  //     $('.init').removeAttr('disabled');
  //     $('#initBtn').attr('disabled', 'disabled');
  //     document.getElementById('VideoStatusBtn').innerHTML = 'Enable';
  //   } else {
  //     alert('No device!');
  //   }
  // }

  // const getInk = async () => {
  //   // 用polling的方式向self-host發送request取得簽名板畫面(base64格式)
  //   (function poll() {
  //     var timeId = setTimeout(function () {

  //       clearTimeout(timeId);

  //       if (isPolling) {
  //         $.ajax({
  //           url: getInkUrl,
  //           type: 'GET',
  //           cache: false
  //         }).done(function (data) {
  //           //console.log('poll-getInkUrl');
  //           var dataInfos = JSON.parse(data);

  //           dataInfos.forEach(function (value) {
  //             if (value.EventType === 0) {
  //               drawImage(value.Image);
  //               LastSignatureBase64Data = value.Image;
  //             }
  //           });
  //         }).always(function () {
  //           if (isPolling) {
  //             poll();
  //           } else { }
  //         });
  //       }
  //     }, 50);
  //   })();
  // };

  return (
    <>
      {/* <div className="content" dangerouslySetInnerHTML={template}></div> */}

      <div className="row-canvas" style={{ background: '#f0f2f5' }}>
        <canvas id="ppCanvas" width="600" height="375" />
        <div className="shutdown" />
      </div>

      <Space style={{ margin: '20px 0px' }}>
        <Button
          disabled={isReadOnly}
          type="primary"
          id="initBtn"
          className="btn btn-block btn-success btn-sm"
          onClick={() => {
            window.onload()
            window.initDevice()
          }}
        >
          {t('initialize_device')}
        </Button>

        <Button
          disabled={isReadOnly}
          type="primary"
          className="btn btn-block btn-success btn-sm init"
          onClick={() => {
            window.clearInk()
          }}
        >
          {t('clear')}
        </Button>

        <Button
          disabled={isReadOnly}
          type="primary"
          className="btn btn-block btn-success btn-sm init"
          onClick={() => {
            // let result = await window.saveDrawing()   // Save to local computer document
            // console.log(`cp1 result>>>`, result)
            window.saveDrawing().then((data) => {
              console.log(`saveDrawing data>>>`, data)
            }).catch((error) => {
              console.log(`saveDrawing error>>>`, error)
            })

            // Get base64, Type 3 is png
            // let signatureBase64 = await Service.call('get', 'http://localhost:8089/PPSignSDK/Encode?type=3')
            // console.log(`signatureBase64>>>`, signatureBase64)
          }}
        >
          {t('save_drawing_image')}
        </Button>
      </Space>
    </>
  );
};

export default PPSignature;
