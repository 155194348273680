/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import _ from 'lodash';
import {
  Tabs, Spin, Button, Badge, Table, Modal, notification, Form, Input, Select, Tooltip
} from 'antd';
import {
  EditOutlined, DeleteOutlined, PlusOutlined, FilePdfOutlined
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment'
import debugLib from 'debug';
import Fuse from 'fuse.js';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';

const debug = debugLib('app:pages/ActionAudit');

const { confirm } = Modal;
const { TabPane } = Tabs;
const { Option } = Select;

let fuse = [];

const ActionAudit = (props) => {
  const { t } = useTranslation()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    // Service.call('post', '/api/client/data', reqData)
  }

  return (
    <NavAndSideFrame {...props} title={t('audit_log')} selectedKey="general">
      <Tabs type="card">
        <TabPane tab={t('general')} key="general">
          <TabComponent keyName="" />
        </TabPane>
        <TabPane tab={t('product')} key="product_item">
          <TabComponent keyName="product_item" />
        </TabPane>
        <TabPane tab={t('order_info')} key="order_info">
          <TabComponent keyName="order_info" />
        </TabPane>
        <TabPane tab={t('order_item')} key="order_item">
          <TabComponent keyName="order_item" />
        </TabPane>
        <TabPane tab={t('invoice_info')} key="invoice_info">
          <TabComponent keyName="invoice_info" />
        </TabPane>
        <TabPane tab={t('receipt_info')} key="receipt_info">
          <TabComponent keyName="receipt_info" />
        </TabPane>
        <TabPane tab={t('translation')} key="translation">
          <TabComponent keyName="translation" />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  )
}

const TabComponent = (props) => {
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)
  const history = useHistory();
  const { t } = useTranslation();

  const {
    keyName
  } = props;

  const [loading, setLoading] = useState(true)
  const [dataSource, setDataSource] = useState([])
  const meta = useSelector((state) => state.app.meta);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await Service.call('get', `/api/action_audit/${keyName}`)
    let { data } = result;
    let orderedDataList = data;

    let fuseOptions = {
      findAllMatches: true,
      includeScore: true,
      threshold: 0.15,
    };
    switch (keyName) {
      case "product_item":
        fuseOptions.keys = ['sku']
        break;
      case "order_info":
      case "order_item":
        fuseOptions.keys = ['order_no']
        break;
      case "invoice_info":
        fuseOptions.keys = ['invoice_no']
        break;
      case "receipt_info":
        fuseOptions.keys = ['receipt_no']
        break;
      case "translation":
        fuseOptions.keys = ['translation_text', 'original_text']
        break;
    }
    fuse = new Fuse(orderedDataList, fuseOptions);
    setDataSource(orderedDataList);
    setLoading(false)
  }

  const filterData = async (filterObj = {}) => {
    if (_.isEmpty(filterObj.searchWord)) { //  Return original data without search
      return setDataSource(fuse._docs)
    }

    const fuzzyResults = fuse.search(filterObj.searchWord).map((result) => result.item)
    setDataSource(fuzzyResults)
  }

  const getColumns = () => {
    let columns;
    let basicData = [
      {
        title: t('ctime'),
        dataIndex: 'ctime',
        key: 'ctime',
        render: (value) => {
          return Main.displayMoment(value);
        }
      },
      {
        title: t('staff_id'),
        dataIndex: 'staff_id',
        key: 'staff_id',
      },
      {
        title: t('method'),
        dataIndex: 'method',
        key: 'method',
        render: ((value) => {
          return t(value.toLowerCase())
        })
      },
      {
        title: t('endpoint'),
        dataIndex: 'endpoint',
        key: 'endpoint',
      },
    ]
    let general = [
      ...basicData,
      {
        title: t('data'),
        dataIndex: 'data',
        key: 'data',
      },
    ]
    let productItem = [
      ...basicData,
      {
        title: t('sku'),
        dataIndex: 'sku',
        key: 'sku',
      },
      {
        title: t('stock_number'),
        dataIndex: 'stock_number',
        key: 'stock_number',
      },
      {
        title: t('price'),
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: t('wholesale_price'),
        dataIndex: 'wholesale_price',
        key: 'wholesale_price',
      },
      {
        title: t('description'),
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: t('status'),
        dataIndex: 'is_active',
        key: 'is_active',
        render: ((value) => {
          if (value === 1) return t("active")
          return t("inactive")
        })
      },
      {
        title: t('product_status'),
        dataIndex: 'product_status',
        key: 'product_status',
      },
    ]
    let orderInfo = [
      ...basicData,
      {
        title: t('order_no'),
        dataIndex: 'order_no',
        key: 'order_no',
        render: ((value, record) => {
          return record.order_no_prefix + value
        })
      },
      {
        title: t('deposit'),
        dataIndex: 'deposit',
        key: 'deposit',
      },
    ]
    let orderItem = [
      ...basicData,
      {
        title: t('order_no'),
        dataIndex: 'order_no',
        key: 'order_no',
        render: ((value, record) => {
          return record.order_no_prefix + value
        })
      },
      {
        title: t('item_name'),
        dataIndex: 'item_name',
        key: 'item_name',
      },
      {
        title: t('quantity'),
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: t('item_price'),
        dataIndex: 'item_price',
        key: 'item_price',
      },
      {
        title: t('item_total'),
        dataIndex: 'item_total',
        key: 'item_total',
      },
    ]
    let invoiceInfo = [
      ...basicData,
      {
        title: t('invoice_no'),
        dataIndex: 'invoice_no',
        key: 'invoice_no',
        render: ((value, record) => {
          return record.invoice_no_prefix + value;
        })
      },
      {
        title: t('invoice_date'),
        dataIndex: 'invoice_date',
        key: 'invoice_date',
        render: ((value) => {
          return Main.displayMoment(value);
        })
      },
      {
        title: t('hydration_service_total'),
        dataIndex: 'hydration_service_total',
        key: 'hydration_service_total',
      },
      {
        title: t('discount'),
        dataIndex: 'discount',
        key: 'discount',
      },
      {
        title: t('invoice_item_total'),
        dataIndex: 'invoice_item_total',
        key: 'invoice_item_total',
      },
      {
        title: t('invoice_sum'),
        dataIndex: 'invoice_sum',
        key: 'invoice_sum',
      },
      {
        title: t('invoice_total'),
        dataIndex: 'invoice_total',
        key: 'invoice_total',
      },
    ]
    let receiptInfo = [
      ...basicData,
      {
        title: t('receipt_no'),
        dataIndex: 'receipt_no',
        key: 'receipt_no',
        render: ((value, record) => {
          return record.receipt_no_prefix + value
        })
      },
      {
        title: t('receipt_date'),
        dataIndex: 'receipt_date',
        key: 'receipt_date',
        render: ((value) => {
          return Main.displayMoment(value);
        })
      },
      {
        title: t('payment_method'),
        dataIndex: 'payment_method',
        key: 'payment_method',
      },
      {
        title: t('payment_date'),
        dataIndex: 'payment_date',
        key: 'payment_date',
        render: ((value) => {
          return Main.displayMoment(value);
        })
      },
      {
        title: t('payment_total'),
        dataIndex: 'payment_total',
        key: 'payment_total',
      },
    ]
    let translation = [
      ...basicData,
      {
        title: t('language'),
        dataIndex: 'language',
        key: 'language',
        render: ((value, record) => {
          return t(`${value}`)
        })
      },
      {
        title: t('original_text'),
        dataIndex: 'original_text',
        key: 'original_text',
      },
      {
        title: t('translation_text'),
        dataIndex: 'translation_text',
        key: 'translation_text',
      },
    ]

    switch (keyName) {
      case 'product_item':
        columns = productItem
        break;
      case 'order_info':
        columns = orderInfo;
        break;
      case 'order_item':
        columns = orderItem;
        break;
      case 'invoice_info':
        columns = invoiceInfo;
        break;
      case 'receipt_info':
        columns = receiptInfo;
        break;
      case 'translation':
        columns = translation;
        break;
      default:
        columns = general
        break;
    }
    return columns;
  }
  return (
    <Spin spinning={loading}>
      <Input
        placeholder={t('please_input_keyword')}
        onChange={(event) => {
          filterData({ searchWord: event.target.value })
        }}
      />
      <Table
        rowKey={() => 'log_admin_action_audit'}
        scroll={{ x: 'max-content' }}
        dataSource={dataSource}
        columns={getColumns()}
      />
    </Spin>
  )
}

export default ActionAudit;
