import React, { useState, useEffect } from 'react';
import {
  notification, Tabs, Table, Badge, Button, Modal, Form, Input, Radio, Select, Space
} from 'antd';
import {
  DeleteOutlined, EditOutlined, PlusOutlined, FileAddOutlined, DownloadOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import LanguageList from '../../data/LanguageList.json'
import moment from 'moment-timezone';
import { useSelector } from 'react-redux'
import * as Main from '../../core/Main';

const { TabPane } = Tabs;
const { Option } = Select;

const OptionSetting = (props) => {
  const { t } = useTranslation();
  const optionKeys = [
    'hydration_service',
    'pet_category',
    'pet_species',
    'payment_method',
    'ashes_service', // changed display name to Ashes Management in AMS
    'ceremony_room',
    // 'ceremony_item',
    'item_storage',
    'hair_treatment',
    'plant',
    'planter',
    'place',
    'area',
    'district',
    'clinic',
    'grinding_type',
    'source',
    'photo_lace',
    'font',
    'foot_color',
    'organization',
    'pet_hotel',
    'hospice',
    'pet_shop',
    'car',
    'refrigerator',
    'air_dry',
    'discount_reason',
    'inventory_in_out_reason'
  ];

  return (
    <NavAndSideFrame {...props} title={t('option_setting')} selectedKey="option_setting">
      <Tabs type="card" defaultActiveKey="hydration_service" onChange={(key) => null}>
        {optionKeys.map((item) => (
          <TabPane tab={t(item !== 'hydration_service' ? item : 'service_plan')} key={item}>
            <TabComponent type={item} />
          </TabPane>
        ))}
      </Tabs>
    </NavAndSideFrame>
  )
}

const TabComponent = (props) => {
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)
  const { type } = props
  const { t, i18n } = useTranslation()

  const [dataList, setDataList] = useState([]);
  const [workflowList, setWorkflowList] = useState([]);
  const [recordId, setRecordId] = useState(0);
  const [selectedFormData, setSelectedFormData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [translationModalVisible, setTranslationModalVisible] = useState(false);
  // const meta = useSelector((state) => state.app.meta);

  const columns = [
    {
      title: t("operation"),
      className: "p_form_option_edit",
      dataIndex: 'form_option_id',
      render: (value, record, index) => {
        let {qr_code_1, qr_code_2} = record;
        return (
          <>
            <Space>
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => {
                  setRecordId(value)
                  setSelectedFormData(record)
                  setModalVisible(true)
                }}
              />
              {(record.type === 'car' || record.type === 'refrigerator' || record.type === 'ceremony_room' || record.type === 'air_dry') && (
                <>
                  <Button
                    shape="circle"
                    icon={<FileAddOutlined />}
                    onClick={async () => {
                      let result = await Service.call('post', '/api/form_option_generate/qrcode', { form_option_id: value, qrcode_store: 1 })
                      // console.log('result', result)
                      if (result.status !== 1) return

                      notification.success({ message: t("success") });
                      getAllData()
                    }}
                  />

                  {!_.isEmpty(qr_code_1) && (
                    <Button
                      shape="circle"
                      icon={<DownloadOutlined />}
                      onClick={() => {
                        window.open(`${STATIC_SERVER_URL}/${qr_code_1}`)
                      }}
                    />
                  )}
                  {/* {_.isEmpty(qr_code_2) ? (
                    <>
                      {record.type !== 'car' && (
                        <Button
                          shape="circle"
                          icon={<FileAddOutlined />}
                          onClick={async () => {
                            let result = await Service.call('post', '/api/form_option_generate/qrcode', { form_option_id: value, qrcode_store: 0 })
                            // console.log('result', result)
                            if (result.status !== 1) return
                            notification.success({ message: t("success") });
                            getAllData()
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Button
                      shape="circle"
                      icon={<DownloadOutlined />}
                      onClick={() => {
                        window.open(`${STATIC_SERVER_URL}/${qr_code_2}`)
                      }}
                    />
                  )} */}
                </>
              )}
            </Space>
          </>
        )
      }
    },
    {
      title: t("translation"),
      dataIndex: 'form_option_id',
      render: (value, record, index) => {
        return (
          <Button
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => {
              setRecordId(value)
              setSelectedFormData(record)
              setTranslationModalVisible(true)
            }}
          />
        )
      }
    },
    {
      title: `${t('name')}`,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: `${t('ctime')}`,
      dataIndex: 'ctime',
      sorter: (a, b) => a.ctime - b.ctime,
      render: (value) => {
        return Main.displayMoment(value);
      }
    },
    {
      title: `${t('active')}`,
      dataIndex: 'is_active',
      filters: [
        {
          text: `${t('active')}`,
          value: 1,
        },
        {
          text: `${t('inactive')}`,
          value: 0,
        },
      ],
      onFilter: (value, record) => record.is_active === value,
      render: (text, record, index) => {
        let { is_active } = record
        return (
          <>
            {
              is_active === 1
                ? (<Badge status="processing" />)
                : (<Badge status="default" />)
            }
          </>
        )
      }
    },
  ]


  useEffect(() => {
    getAllData();
    getAllWorkflow();
  }, []);

  const getAllData = async () => {
    try {
      let result = await Service.call('get', `/api/form_option/all/${type}`);

      if (result.status !== 1) {
        notification.warning({ message: t("failed_to_obtain_information") });
        return;
      }
      let { data } = result;
      setDataList(data);
      console.log(`data>>`, data)
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  const getAllWorkflow = async () => {
    try {
      let result = await Service.call('get', `/api/workflow/list`);

      if (result.status !== 1) {
        notification.warning({ message: t("failed_to_obtain_information") });
        return;
      }
      let { data } = result;
      let workflowOptions = _.map(data, (entity) => {
        return {label: entity.name, value: entity.workflow_id}
      })
      setWorkflowList(workflowOptions);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  return (
    <>
      { i18n.language === 'zh_hant' && (
      <Button
        className="p_form_option_edit"
        type="primary"
        onClick={() => {
          setRecordId(0)
          setSelectedFormData({})
          setModalVisible(true);
        }}
      >
        {t('create')}
      </Button>
      )}

      <Modal
        title={recordId > 0 ? t('edit') : t('create')}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <OptionForm
          recordId={recordId}
          selectedFormData={selectedFormData}
          type={type}
          dataList={dataList}
          workflowOptions={workflowList}
          closeMethod={async () => {
            getAllData();
            setModalVisible(false);
          }}
        />
      </Modal>

      <Modal
        title={t("translation")}
        visible={translationModalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setTranslationModalVisible(false);
        }}
      >
        <TranslatonForm
          recordId={recordId}
          selectedFormData={selectedFormData}
          type={type}
          dataList={dataList}
          closeMethod={async () => {
            getAllData();
            setTranslationModalVisible(false);
          }}
        />
      </Modal>

      <Table
        columns={columns}
        dataSource={dataList}
        rowKey={() => 'form_option'}
      />
    </>
  )
}

const OptionForm = (props) => {
  const { t, i18n } = useTranslation();
  const { TextArea } = Input
  const {
    recordId, selectedFormData, type, closeMethod, dataList, workflowOptions
  } = props
  let initialValues = { is_active: 1 }
  let combinedInitialValues = { ...initialValues, ...selectedFormData }
  // console.log("selectedFormData", selectedFormData);
  const [form] = Form.useForm();
  // console.log("sadasdasd", type);
  return (
    <Form
      form={form}
      initialValues={combinedInitialValues}
      onFinish={async (values) => {
        if (values.name.length > 50) {
          return notification.error({ message: t("cant_over_50_word") });
        }
        values.type = type
        values.language = i18n.language
        let method = 'post'
        if (recordId !== 0) { //  Edit
          method = 'patch'
        }
        if (type === "ceremony_item") {
          _.map(dataList, async (item) => {
            if (item.form_option_id !== selectedFormData && item.is_default !== 0) {
              let off = { ...item }
              off.is_default = 0
              await Service.call("patch", '/api/form_option', off)
            }
          })
        }

        let result = await Service.call(method, '/api/form_option', values)
        // console.log('result', result)

        if (result.status !== 1) {
          return notification.error({ message: t("submission_failed"), description: result.errorMessage });
        }

        notification.success({ message: t("submitted_successfully") });
        closeMethod()
      }}
    >
      {recordId > 0 && (
        <Form.Item
          label={t('id')}
          name="form_option_id"
        >
          <Input disabled />
        </Form.Item>
      )}

      {i18n.language !== (_.keys(LanguageList))[0] && (
      <Form.Item
        label={t('name')}
        name="name"
        rules={[{ required: true }]}
      >
        <Input disabled />
      </Form.Item>
      )}

      {i18n.language === (_.keys(LanguageList))[0] && (
      <Form.Item
        label={t('name')}
        name="name"
        rules={[{ required: true }]}
      >
        <Input autoFocus />
      </Form.Item>
      )}

      {i18n.language !== (_.keys(LanguageList))[0] && (
      <Form.Item
        label={t('remark')}
        name="remark"
      >
        <TextArea rows={4} disabled />
      </Form.Item>
      )}

      {i18n.language === (_.keys(LanguageList))[0] && (
      <Form.Item
        label={t('remark')}
        name="remark"
        rules={[{ required: (type === 'hydration_service') }]}
      >
        <TextArea rows={4} />
      </Form.Item>
      )}


      <Form.Item
        label={t('active')}
        name="is_active"
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Radio value={1}>{t('active')}</Radio>
          <Radio value={0}>{t('inactive')}</Radio>
        </Radio.Group>
      </Form.Item>

      {(type === "ceremony_item" || type === "item_storage" || type === "hydration_service") && (
        <Form.Item
          label={t('default')}
          name="is_default"
        >
          <Radio.Group>
            <Radio value={1}>{t('default')}</Radio>
            <Radio value={0}>{t('non_default')}</Radio>
          </Radio.Group>
        </Form.Item>
      )}

      {type === 'hydration_service' && (
      <Form.Item
        label={t('workflow_name')}
        name="workflow_id"
      >
        <Select
          showSearch
          options={workflowOptions}
          placeholder={t('click_to_view_options')}
          filterOption={(inputValue, option) => {
            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
          }}
        />
      </Form.Item>
      )}

      <Button type="primary" htmlType="submit">
        {t('submit')}
      </Button>
    </Form>
  )
}
const TranslatonForm = (props) => {
  const { t, i18n } = useTranslation();
  const { TextArea } = Input
  const {
    recordId, selectedFormData, type, closeMethod, dataList
  } = props
  console.log("selectedFormData", selectedFormData);
  const [translationForm] = Form.useForm();
  // console.log("sadasdasd", type);

  useEffect(() => {
    configureTranslationPair();
  }, [recordId])

  const configureTranslationPair = async () => {
    const language = (_.keys(LanguageList))[1];
    let translationResult = await Service.call("get", `/api/translation/translate?name=${selectedFormData.name}&lang=${language}`);
    let translationPair = {translation_key: translationResult.data.translation_code, originalName: selectedFormData.name, language };
    if (translationResult.data.translation_text !== selectedFormData.name) { translationPair.translation_text = translationResult.data.translation_text; }
    translationForm.setFieldsValue({translation_key: translationPair.translation_key, language: translationPair.language, originalName: translationPair.originalName, translation_text: translationPair.translation_text })
    console.log(selectedFormData.remark)
    if (!_.isNull(selectedFormData.remark)) {
      translationPair.original_remark = selectedFormData.remark;
      let strArr = selectedFormData.remark.split(/\r?\n/)
      let newLineStr = strArr.join("%0A");
      let remarkTranslationResult = await Service.call("get", `/api/translation/translate?name=${newLineStr}&lang=${language}`);
      if (remarkTranslationResult.data.translation_text !== selectedFormData.remarks) { translationPair.remark_translation_text = remarkTranslationResult.data.translation_text; translationPair.remark_translation_key = remarkTranslationResult.data.translation_code }
      translationForm.setFieldsValue({remark_translation_text: translationPair.remark_translation_text, originalRemark: translationPair.original_remark, remark_translation_key: translationPair.remark_translation_key})
    }
  }
  return (
    <Form
      form={translationForm}
      onFinish={async (values) => {
        let result = await Service.call('post', '/api/translation', values)
        // console.log('result', result)

        if (result.status !== 1) {
          return notification.error({ message: '提交失敗', description: result.errorMessage });
        }
        if (result.data.result.error) {
          closeMethod()
          return notification.warning({ message: '警告', description: t('title_duplicated') })
        }
        if (result.data.remarkResult.error) {
          closeMethod()
          return notification.warning({ message: '警告', description: t('remark_duplicated') })
        }

        notification.success({ message: '提交成功' });
        closeMethod()
      }}
    >
      <Form.Item
        label={t('translation_key')}
        name="translation_key"
        hidden
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label={t('remark_translation_key')}
        name="remark_translation_key"
        hidden
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label={t('original_name')}
        name="originalName"
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        label={t('language')}
        name="language"
        rules={[{ required: true }]}
      >
        <Select
          disabled
        >
          {_.map(LanguageList, (val, key) => <Option value={key} key={key}>{val}</Option>)}
        </Select>
      </Form.Item>

      <Form.Item
        label={t('translation_to')}
        name="translation_text"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      {type === 'hydration_service' && (
        <>
          <Form.Item
            label={t('original_remark')}
            name="originalRemark"
          >
            <TextArea rows={4} disabled />
          </Form.Item>

          <Form.Item
            label={t('translation_to')}
            name="remark_translation_text"
            rules={[{ required: true }]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </>
      )}

      <Button type="primary" htmlType="submit" className='p_form_option_edit'>
        {t('submit')}
      </Button>
    </Form>
  )
}

export default OptionSetting;
