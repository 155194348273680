import React, { Component, useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import {
  notification, Avatar, Button, Divider, Form, Input, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Space
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  SettingOutlined
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import SpaceRentalForm from '../../components/SpaceRentalForm';

const debug = require('debug')('app:admin:client:src:AdvertisementList');

const { confirm } = Modal;
const title = "靈位租務";
const selectedKey = 'space_rental';
const tableIDName = "space_rental_id";

let fuse = [];

const SpaceRentalList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [initialValues, setInitialValues] = useState({})
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const meta = useSelector((state) => state.app.meta);
  const inputRef = useRef();

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    let orderedDataList = [];
    try {
      let result = await Service.call('get', '/api/space_rental/list');
      // console.log('result', result)
      let { data } = result;
      orderedDataList = _.orderBy(data, ['space_rental_id']);

      const getDeadlineStatus = (end_date) => {
        if (end_date === 0 || moment.unix(end_date).subtract(1, "months") > moment()) {
          return 'notExpired'
        }

        if (moment.unix(end_date).subtract(1, "months") < moment() && moment.unix(end_date) > moment()) {
          return 'almostExpired'
        }

        if (end_date !== 0 && moment.unix(end_date) <= moment()) {
          return 'expired'
        }

        return ''
      }

      orderedDataList = _.map(orderedDataList, (item) => {
        return {
          ...item,
          deadline_status: getDeadlineStatus(item.end_date)

        }
      })
      const fuseOptions = {
        findAllMatches: true,
        includeScore: true,
        threshold: 0,
        keys: [
          'order_no_with_prefix', 'space_no'
        ]
      };
      fuse = new Fuse(orderedDataList, fuseOptions);
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setDataList(orderedDataList);
      filterData({searchWord: inputRef.current.input.value})
    }
  }

  // const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm') => {
  //   if (unixTime !== 0) {
  //     return moment.unix(unixTime).format(outputFormat);
  //   }
  //   return '-';
  // }

  const filterData = async (filterObj = {}) => {
    if (_.isEmpty(filterObj.searchWord)) { //  Return original data without search
      return setDataList(fuse._docs)
    }

    const fuzzyResults = fuse.search(filterObj.searchWord).map((result) => result.item)
    setDataList(fuzzyResults)
  }

  const columns = [
    {
      title: t('operation'),
      dataIndex: tableIDName,
      render: (value, record) => {
        let status = (record.status === 1);
        let color = '#000000';
        let icon = '';
        let wordings = '';
        if (status) {
          color = '#AA0000'
          icon = 'stop';
          wordings = 'Disable';
        } else {
          color = '#00AA00'
          icon = 'check';
          wordings = 'Enable';
        }
        return (
          <>
            <Space>
              {record.status === 'a' && (
              <Tooltip title="Edit">
                <Button
                  shape="circle"
                  style={{ marginRight: 8, color: '#000000' }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record)
                  }}
                />
              </Tooltip>
              )}
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => {
                  confirm({
                    title: `確認清空？`,
                    okText: "是",
                    okType: "danger",
                    cancelText: "否",
                    onOk: async () => {
                      let result = await Service.call(
                        "post",
                        `/api/space_rental/clear`,
                        {space_rental_id: record.space_rental_id}
                      );
                      // console.log('result', result);

                      if (result.status !== 1) {
                        return notification.error({
                          message: "清空失敗",
                          description: t(result.errorMessage),
                        });
                      }

                      notification.success({ message: "清空成功" });
                      getAllData();
                    },
                    onCancel: () => {
                      // console.log('Cancel');
                    },
                  });
                }}
              />
              {record.status === 'a' && (
              <Button
                shape="circle"
                icon={<SettingOutlined />}
                onClick={() => {
                  confirm({
                    title: `轉換狀態至維護？`,
                    okText: "是",
                    okType: "danger",
                    cancelText: "否",
                    onOk: async () => {
                      let result = await Service.call(
                        "patch",
                        `/api/space_rental/`,
                        {space_rental_id: record.space_rental_id, status: "m"}
                      );
                      // console.log('result', result);

                      if (result.status !== 1) {
                        return notification.error({
                          message: "轉換失敗",
                          description: t(result.errorMessage),
                        });
                      }

                      notification.success({ message: "轉換成功" });
                      getAllData();
                    },
                    onCancel: () => {
                      // console.log('Cancel');
                    },
                  });
                }}
              />
              )}
            </Space>
          </>
        )
      }
    },
    {
      title: t('space_rental_id'),
      dataIndex: `space_rental_id`,
      sorter: (a, b) => a.space_rental_id - b.space_rental_id,
    },
    {
      title: t('space_no'),
      dataIndex: 'space_no',
      sorter: (a, b) => a.space_no.localeCompare(b.space_no)
    },
    {
      title: t('space_type'),
      dataIndex: 'type',
      filters: [
        {text: t('ashes_space'), value: "ashes"},
        {text: t('plant_space'), value: "plant"}
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
      render: (value) => {
        return (
          <>
            {value === "ashes" && (t('ashes_space'))}
            {value === "plant" && (t('plant_space'))}
          </>
        )
      }
    },
    {
      title: t('order_no'),
      dataIndex: 'order_no_with_prefix',
      sorter: (a, b) => a.order_no_with_prefix.localeCompare(b.order_no_with_prefix), // sort by order_no_with_prefix)
    },
    {
      title: t('start_date'),
      dataIndex: 'start_date',
      render: (value) => {
        return Main.displayMoment(value);
      }
    },
    {
      title: t('end_date'),
      dataIndex: 'end_date',
      sorter: (a, b) => a.end_date - b.end_date,
      render: (value) => {
        return Main.displayMoment(value);
      }
    },
    {
      title: t('deadline'),
      dataIndex: 'deadline_status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (value, record) => {
        let color = ''
        if (value === 'notExpired') color = 'green'
        if (value === 'almostExpired') color = 'warning'
        if (value === 'expired') color = 'red'

        return (
          <Tag
            style={{margin: 10}}
            color={color}
            value={value}
          >
            {t(value)}
          </Tag>
        )
      }
    },
    {
      title: t('status'),
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (value) => {
        return (
          <>
            {value === "a" && (<Tag style={{margin: "10px 10px 10px"}} color="green" value={value}>{t(`available`)}</Tag>)}
            {value === "o" && (<Tag style={{margin: "10px 10px 10px"}} color="red" value={value}>{t(`occupied`)}</Tag>)}
            {value === "m" && (<Tag style={{margin: "10px 10px 10px"}} color="default" value={value}>{t(`maintainence`)}</Tag>)}
          </>
        )
      }
    },
  ]
  return (
    <NavAndSideFrame {...props} title={t('rental_management')} selectedKey={selectedKey}>
      <Space>
        <Button
          type="primary"
          onClick={() => {
            setSelectedRecord({ company_user_role_id: 0 });
            setModalVisible(true);
          }}
        >
          {t('add')}
        </Button>
        <>
          {t('search')}
        </>
        <Input
          ref={inputRef}
          placeholder={t('please_input_keyword')}
          onChange={(event) => {
            filterData({ searchWord: event.target.value })
          }}
        />
      </Space>
      <Divider />
      {/* <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}

      /> */}
      <Table
        columns={columns}
        dataSource={dataList}
        rowKey={tableIDName}
      />
      <Modal
        title={t('add')}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 1200 }}
        width="90%"
        onCancel={() => { setModalVisible(false) }}
      >
        <SpaceRentalForm
          dataObj={
            selectedRecord
          }
          openModal={
            (value) => {
              getAllData();
              setModalVisible(value)
            }
          }
        />
      </Modal>
    </NavAndSideFrame>
  )
}

export default SpaceRentalList;
