import React, { useState, useEffect } from "react";
import {
  Button,
  Layout,
  Table,
  Tooltip,
  Modal,
  notification,
  Badge,
  Space,
  Tag
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  CloseOutlined,
  CheckOutlined,
  FileAddOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import { useSelector } from 'react-redux'
import "antd/dist/antd.css";
import _ from "lodash";
import * as Service from "../../core/Service";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

const { confirm } = Modal;

const ActionInfoTable = (props) => {
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)
  const {fetchData} = props
  const { t } = useTranslation();
  // console.log("object >> ", props.dataObj);

  const [dataList, setDataList] = useState([]);
  const tableIDName = "action_info_id";
  const history = useHistory();

  useEffect(() => {
    setDataList(props.dataObj);
  }, [props.dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record) => {
          let { is_active } = record;
          return (
            <Space>
              <Tooltip title={t("modify")}>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    history.push(
                      `/company/action_info_form?action_info_id=${value}`
                    );
                  }}
                />
              </Tooltip>
              {_.isEmpty(record.qr_code) ? (
                <Button
                  shape="circle"
                  icon={<FileAddOutlined />}
                  onClick={async () => {
                    let result = await Service.call('post', '/api/action_info/qrcode', { action_info_id: value})
                    // console.log('result', result)
                    if (result.status !== 1) return

                    notification.success({ message: t("success") });
                    fetchData();
                  }}
                />
              ) : (
                <Button
                  shape="circle"
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    window.open(`${STATIC_SERVER_URL}/${record.qr_code}`)
                  }}
                />
              )}
            </Space>
          );
        },
      },
      {
        title: t("action_code"),
        dataIndex: `action_code`,
      },
    ];
    return columns;
  };

  // const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm') => {
  //   if (unixTime !== 0) {
  //     return moment.unix(unixTime).format(outputFormat);
  //   }
  //   return '-';
  // }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList}
        columns={setTableHeader()}
      />
    </Layout>
  );
};

export default ActionInfoTable;
