import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Col, Form, Input, Layout, Modal,
  notification, Radio, Row, Select, Tabs, Upload, message,
  Transfer
} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import { formItemLayout, tailLayout } from "./ModalLayout";

const debug = require('debug')('app:admin:components:SpaceRentalForm');

const { Option } = Select;

const SpaceRentalForm = (props) => {
  const { t } = useTranslation();

  const { dataObj } = props
  const { space_rental_id = 0 } = dataObj

  const [form] = Form.useForm();
  const company_admin = useSelector((state) => state.app.company_admin);

  useEffect(() => {
    form.resetFields();
  }, [dataObj]);

  const onFinish = async (postObj) => {
    let url = `/api/space_rental`;
    console.log("Post Object", postObj)
    // Patch
    if (dataObj.space_rental_id > 0) {
      postObj.space_rental_id = dataObj.space_rental_id;

      let data = await Service.call('patch', url, postObj);
      if (data.errorMessage) {
        message.error(data.errorMessage);
        return props.openModal(true);
      }
      message.success('success');
      return props.openModal(false);
    }

    // POST
    postObj.create_time = moment().unix();
    postObj.status = "a"
    let data = await Service.call('post', url, postObj);
    if (data.errorMessage) {
      message.error(data.errorMessage);
      return props.openModal(true);
    }
    message.success('success');
    return props.openModal(false);
  };

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="time_related_controls"
        onFinish={onFinish}
        initialValues={dataObj}
      >
        {/* <Form.Item
        label="Company"
        name="company_id"
        rules={[{ required: true, message: 'Please select one.' }]}
      >
        <Select
          placeholder="- select one -"
          disabled={dataObj.company_id}
          onChange={(value)=> {
            getCompanyRole(value)
          }}
        >
          {companyList.map(({ company_id, company_name }) =>
            (<Option value={company_id}>{`(${company_id}) `+company_name}</Option>)
          )}
        </Select>
      </Form.Item> */}
        <Form.Item
          label={t("space_no")}
          name="space_no"
          rules={[{ required: true, message: 'Please input space no.' }]}
        >
          <Input autoFocus />
        </Form.Item>
        <Form.Item
          label={t("space_type")}
          name="type"
          rules={[{ required: true, message: 'Please input role key.' }]}
        >
          <Select style={{ width: 150 }}>
            <Option value="ashes">{t('ashes_space')}</Option>
            <Option value="plant">{t('plant_space')}</Option>
          </Select>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default SpaceRentalForm;
