import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, AutoComplete, Modal, Row, Col
} from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import OptionForm from './OptionForm';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const OrderInfoDepositForm = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { t } = useTranslation();

  let { order_id } = queryString.parse(location.search);
  let { initialValues, order_id: orderIdProp = 0, isReadOnly } = props
  if (orderIdProp > 0) {
    order_id = orderIdProp // Override order_id if prop existing
  }

  const [depositForm] = Form.useForm();
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [optionType, setOptionType] = useState(false)
  // const [carOptions, setCarOptions] = useState([]);
  // depositForm State Control
  const [depositFormStatus, setDepositFormStatus] = useState(false)

  // const [companyUser, setCompanyUser] = useState([])

  useEffect(() => {
    fetchFormOption()
    checkUnpaidStatus()
    // fetchCompanyUser()
  }, []);

  useEffect(() => {
    depositForm.resetFields();
  }, [initialValues]);

  const fetchFormOption = async () => {
    fetchPaymentMethod();
    // fetchCar();
  }

  const fetchData = async (type, id, value) => {
    switch (type) {
      case "payment_method":
        fetchPaymentMethod(id, value);
        depositForm.setFieldsValue({ payment_method: value, payment_method_id: id})
        break;
      default:
        break;
    }
  }

  const fetchPaymentMethod = async (id, value) => {
    let options = await Main.fetchFormOption('payment_method', true)
    if (id) options.push({value: id, label: value})
    setPaymentMethodOptions(options);
  }

  // const fetchCar = async (id, value) => {
  //   let options = await Main.fetchFormOption('car', true)
  //   if (id) options.push({value: id, label: value})
  //   setCarOptions(options);
  // }

  const depositFormStatusHandler = (value) => {
    switch (value) {
      case 'unpaid':
        setDepositFormStatus(true)
        depositForm.setFieldsValue({ payment_date: null, payment_method: '', deposit: '0' });
        break;
      case 'monthly_paid':
        setDepositFormStatus(true);
        depositForm.setFieldsValue({ payment_date: null, payment_method: '', deposit: '0' });
        break;
      case 'paid':
        setDepositFormStatus(false)
        depositForm.setFieldsValue({ payment_date: null, payment_method: '', deposit: '0' });
        break;
      case 'full_paid':
        setDepositFormStatus(false)
        depositForm.setFieldsValue({ payment_date: moment(new Date(), 'YYYY-MM-DD') })
        break;
      default:
        break;
    }
  }
  // disable options if deposit_status is unpaid
  const checkUnpaidStatus = async () => {
    if (depositForm.getFieldValue('deposit_status') === 'unpaid') {
      setDepositFormStatus(true)
    }
  }
  // ger redux site meta value
  const meta = useSelector((state) => state.app.meta);

  const dateFormat = `${
    !_.isEmpty(meta.system_date_format.meta_value) ? meta.system_date_format.meta_value : 'YYYY/MM/DD'
  } ${
    !_.isEmpty(meta.system_time_format.meta_value) ? meta.system_time_format.meta_value : 'HH:mm'
  }`

  console.log(initialValues)

  // const fetchCompanyUser = async () => {
  //   let result = await Service.call('get', '/api/company/user');
  //   if (result.status !== 1) {
  //     return notification.error({ message: '失敗', description: result.errorMessage });
  //   }
  //   setCompanyUser(result.data)
  // }


  return (
    <>
      <Modal
        title={t('create') + t(optionType)}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <OptionForm
          selectedFormData={{}}
          type={optionType}
          closeMethod={async (form_option_id, value) => {
            fetchData(optionType, form_option_id, value);
            setModalVisible(false);
          }}
        />
      </Modal>
      <Form
        form={depositForm}
        name="depositForm"
        layout="vertical"
        initialValues={{
          ...initialValues,
          payment_method_id: initialValues.payment_method_id ? initialValues.payment_method_id : undefined,
          payment_date: initialValues.payment_date ? initialValues.payment_date : moment()
        }}
        onFinish={async (values) => {
          if (_.includes(['paid', 'full_paid'], values.deposit_status) && values.deposit <= 0) {
            notification.error({ message: t("submission_failed"), description: '請輸入正確的按金數目' })
            return
          }
          if (_.includes(['unpaid', 'monthly_paid'], values.deposit_status)) {
            delete values.payment_date
          }
          if (_.isUndefined(order_id)) {
            notification.error({ message: t("submission_failed"), description: t("Please_submit_customer_and_pet_information_first") });
            return;
          }

          values.order_id = order_id;
          let data = await Service.call('patch', '/api/order_info', values);
          // console.log('data', data);

          if (data.status !== 1) {
            notification.error({ message: t("submission_failed"), description: data.errorMessage });
            return;
          }

          notification.success({ message: t("submitted_successfully") });
          props.onChange()
        }}
      >

        <Divider>
          ***
          {' '}
          {t("deposit_record")}
          {' '}
          ***
        </Divider>

        <Form.Item label={`${t("status")}`} name="deposit_status" rules={[{ required: true, message: t("please_enter_this_field") }]}>
          <Select
            disabled={isReadOnly}
            placeholder={t('please_select')}
            style={{ width: 200 }}
            onChange={depositFormStatusHandler}
          >
            <Option value="unpaid">
              {t('unpaid')}
              
            </Option>
            <Option value="paid">
              {t('paid')}
              
            </Option>
            <Option value="full_paid">
              {t('full_paid')}
              
            </Option>
            <Option value="monthly_paid">
              {t('monthly_paid')}
              
            </Option>
          </Select>
        </Form.Item>
        
        <Form.Item
          label={t("deposit")}
          name="deposit"
          hidden={depositFormStatus}
          required={!depositFormStatus}
          rules={[
            {
              required: !depositFormStatus,
              message: t("please_enter_this_field")
            },
          ]}
        >
          <InputNumber disabled={isReadOnly} />
        </Form.Item>
        <Row gutter={24} align="middle">
          <Col span={20}>
            <Form.Item
              label={t("payment_method")}
              name="payment_method"
              hidden
            >
              <InputNumber disabled />
            </Form.Item>

            <Form.Item
              label={t("payment_method")}
              name="payment_method_id"
              hidden={depositFormStatus}
              required={!depositFormStatus}
              rules={[
                {
                  required: !depositFormStatus,
                  message: t("please_enter_this_field")
                },
              ]}
            >
              <Select
                disabled={isReadOnly}
                showSearch
                options={paymentMethodOptions}
                placeholder={t("click_to_view_options")}
                filterOption={(inputValue, option) => {
                  return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                }}
                onChange={(value, option) => {
                  if (option) {
                    depositForm.setFieldsValue({
                      payment_method: option.label,
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button
              disabled={isReadOnly}
              className="p_form_option_edit"
              type="primary"
              shape="circle"
              hidden={depositFormStatus}
              onClick={() => {
                setModalVisible(true)
                setOptionType('payment_method')
              }}
              icon={<PlusOutlined />}
            />
          </Col>
        </Row>

        <Form.Item
          label={t("payment_date")}
          name="payment_date"
          hidden={depositFormStatus}
          required={!depositFormStatus}
          rules={[
            {
              required: !depositFormStatus,
              message: t("please_enter_this_field")
            },
          ]}
        >
          <DatePicker
            disabled={isReadOnly}
            placeholder={t('please_select')}
            // format={dateFormat}
            format="YYYY/MM/DD"
          />
        </Form.Item>

        {/* <Divider /> */}

        {/* <Form.Item
          label={`${t("car")}`}
          name="driver_license_id"
        >
          <Select
            // disabled
            showSearch
            // style={{ width: 400 }}
            placeholder={t("search_for_driver_license")}
            options={carOptions}
            optionFilterProp="children"
            onChange={(value) => {
            }}
            filterOption={(input, option) => {
              return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
          />
        </Form.Item> */}

        {/* <Form.Item
          label={t("driver_license")}
          name="driver_license"
        >
          <Input
            disabled
          />
        </Form.Item> */}

        {/* <Form.Item
          label={t("receive_pet_staff")}
          name="progress_receive_staff_id"
        >
          <Select
            // disabled
            showSearch
            // style={{ width: 400 }}
            placeholder={t("search_for_staff")}
            options={companyUser.map((item) => { return {value: item.company_user_id, label: `${item.first_name} ${item.last_name}`} })}
            optionFilterProp="children"
            onChange={(value) => {
            }}
            filterOption={(input, option) => {
              return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
          />
        </Form.Item> */}

        <Form.Item>
          <Button
            disabled={isReadOnly}
            className="p_order_agreement_edit"
            type="primary"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default OrderInfoDepositForm;
