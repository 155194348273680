import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber
} from 'antd';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from "react-i18next";
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';

// import OrderInfoBasicForm from './OrderInfoBasicForm';
// import OrderInfoServiceDetailForm from './OrderInfoServiceDetailForm';
// import OrderInfoAshesSpaceForm from './OrderInfoAshesSpaceForm';
// import OrderItemForm from './OrderItemForm';
// import OrderInfoPlantSpaceForm from './OrderInfoPlantSpaceForm';
// import OrderInfoAshesServiceForm from './OrderInfoAshesServiceForm';
import ReceiptInfoBasicForm from './ReceiptInfoBasicForm';
// import ReceiptItemForm from './ReceiptItemForm';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const ReceiptInfoForm = (props) => {
  const { t } = useTranslation();

  const history = useHistory();
  let location = useLocation();
  const { receipt_info_id, invoice_no } = queryString.parse(location.search);

  const [initialValues, setInitialValues] = useState({invoice_no, receipt_date: moment()});
  // const [order_no, setOrderNo] = useState('')

  useEffect(() => {
    if (!_.isUndefined(receipt_info_id)) {
      fetchData();
    }
  }, []);

  // useEffect(() => {
  //   if (!_.isUndefined(initialValues.invoice_no)) {
  //     fetchInvoiceData()
  //   }
  // }, [initialValues.invoice_no])

  const fetchData = async () => {
    let results = await Service.call('get', `/api/receipt_info/${receipt_info_id}`)
    // console.log('results', results)
    if (_.isInteger(results.receipt_date)) results.receipt_date = moment.unix(results.receipt_date)
    if (_.isInteger(results.payment_date)) results.payment_date = moment.unix(results.payment_date)

    let newInitialValues = {...initialValues, ...results}
    setInitialValues(newInitialValues)
  }

  // const fetchInvoiceData = async () => {
  //   let result = await Service.call('get', `/api/invoice_info?invoice_no=${initialValues.invoice_no}`)
  //   // console.log('result', result)
  //   if (result.status !== 1) return;
  //   let [invoiceInfoObj] = result.data
  //   setOrderNo(invoiceInfoObj.order_no)
  // }

  return (
    <NavAndSideFrame {...props} title={!_.isUndefined(receipt_info_id) ? t('edit_receipt') : t('add_new_receipt')} selectedKey="receipt_info">

      <Tabs type="receipt" defaultActiveKey="receipt_info" onChange={(key) => null}>
        <TabPane tab={t('receipt_information')} key="receipt_info">
          <ReceiptInfoBasicForm
            initialValues={initialValues}
          />
        </TabPane>
        {/* <TabPane tab="客戶及寵物資料" key="order_info_basic">
          <OrderInfoBasicForm
            order_no={order_no}
            isReadOnly
          />
        </TabPane> */}
        {/* <TabPane tab="收據貨品" key="receipt_item" disabled={!receipt_info_id}>
          <ReceiptItemForm
            initialValues={initialValues}
          />
        </TabPane> */}
      </Tabs>

    </NavAndSideFrame>
  )
}

export default ReceiptInfoForm;
