import React, { Component, useEffect, useState } from 'react';
import {
  Upload, message, Button, notification
} from 'antd';
import {
  UploadOutlined, InboxOutlined
} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import debugLib from 'debug';
import FormUploadFile from "./FormUploadFile";

const debug = debugLib('app:components/form/FormUploadFile');

const { Dragger } = Upload;

function FormUploadExcel(props) {
  const {
    onSuccess,
  } = props;

  const onChange = (info) => {
    const { status, response } = info.file;

    const name = info.file.name
    if (!_.includes(['error', 'done'], status)) { // Processing
      return;
    }

    if (status !== 'done') {
      return message.error(`${name} uploaded failed, please contact system administrator`);
    }

    const { status: responseStatus } = response;
    if (!response || responseStatus <= 0) {
      return notification.error({
        message: `${info.file.name} uploaded failed, please contact system administratorss`,
      });
    }

    if (!onSuccess) {
      return;
    }

    message.success(`成功上載${name}`);
    onSuccess(response);
  }

  return (
    <FormUploadFile
      type="button"
      imageURL={null}
      data={{
        scope: 'private',
      }}
      onChange={onChange}
      buttonText="上傳CSV"
    />
  );
}

export default FormUploadExcel;
