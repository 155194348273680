import React, { useState, useEffect, useRef, useContext } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Radio, Popconfirm, Spin, Table, Space, Cascader
} from 'antd';
import {
  DeleteOutlined, EditOutlined
} from '@ant-design/icons';
import moment from 'moment';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const ProductCategoryForm = (props) => {
  const history = useHistory();
  let location = useLocation();
  let { product_category_id } = queryString.parse(location.search);
  if (!_.isUndefined(product_category_id)) {
    product_category_id = _.toInteger(product_category_id)
  }
  const { t } = useTranslation()

  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const [options, setOption] = useState([])

  useEffect(() => {
    if (!_.isUndefined(product_category_id)) {
      fetchData();
    }

    fetchProductCategoryOption()
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const fetchData = async () => {
    let result = await Service.call('get', `/api/product_category/by/${product_category_id}`);

    if (result.status !== 1) {
      return notification.error({ message: '錯誤', description: result.errorMessage });
    }

    let productCategoryObj = result.data

    let catIdArr = []
    if (!_.isEmpty(productCategoryObj.parent_index)) {
      catIdArr = productCategoryObj.parent_index.split('-');
      catIdArr = _.map(catIdArr, _.toInteger);
    }
    productCategoryObj.parent_index = catIdArr

    let newDefaultValues = { ...initialValues, ...productCategoryObj };
    setInitialValues(newDefaultValues);
  }

  const fetchProductCategoryOption = async () => {
    let cascaderOptions = await Main.fetchProductCategoryOption(product_category_id)
    setOption(cascaderOptions);
  }

  return (
    <NavAndSideFrame {...props} title={!_.isUndefined(product_category_id) ? t("modify_item_category") : t("add_new_product_category")} selectedKey="product_category">
      <Form
        form={form}
        name="productCategoryForm"
        layout="vertical"
        initialValues={initialValues}
        onFinish={async (postObj) => {
          let method = 'post';
          if (!_.isUndefined(product_category_id)) {
            method = 'patch';
            postObj.product_category_id = product_category_id;
          }

          let result = await Service.call(method, '/api/product_category', postObj);

          if (result.status !== 1) {
            return notification.error({ message: '提交失敗', description: result.errorMessage });
          }

          notification.success({ message: '提交成功' });
          // props.onChange()
          history.push('product_category')
        }}
      >

        <Form.Item
          label={t('category_name')}
          name="category_name"
          rules={[{required: true}]}
        >
          <Input autoFocus />
        </Form.Item>

        <Form.Item
          label={t('description')}
          name="description"
        >
          <Input />
        </Form.Item>

        {/* <Form.Item
          label={t('sorting_level')}
          name="sorting"
        >
          <InputNumber />
        </Form.Item> */}

        <Form.Item
          label={t('parent_category')}
          name="parent_index"
        >
          <Cascader
            showSearch={{
              filter: (inputValue, path) => path[path.length - 1].label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }}
            options={options}
            onChange={() => null}
            changeOnSelect
          />
        </Form.Item>

        <Form.Item
          label={t("activity")}
          name="is_active"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group>
            <Radio value={1}>{t("active")}</Radio>
            {/* 活動 */}
            <Radio value={0}>{t("inactive")}</Radio>
            {/*  不活動 */}
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button
            className="p_product_category_edit"
            type="primary"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </NavAndSideFrame>
  )
}

export default ProductCategoryForm;
