import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  AutoComplete,
  Button,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  notification,
  Divider,
  InputNumber,
  Space,
  Row,
  Col,
  Card,
  List,
  Spin,
} from "antd";
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { useSelector, useDispatch } from 'react-redux'
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import finishList from '../../data/finishList.json'
import { setSideEffect } from '../../redux/actions/common'

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const OrderItemForm = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let { order_id } = queryString.parse(location.search);
  let { initialValues, order_id: orderIdProp = 0, isReadOnly } = props
  if (orderIdProp > 0) {
    order_id = orderIdProp // Override order_id if prop existing
  }

  const [orderItemInitialValues, setOrderItemInitialValues] = useState(
    initialValues
  );
  const [orderItemForm] = Form.useForm();
  const [placeOptions, setPlaceOptions] = useState([]);
  const [footColorOptions, setFootColorOptions] = useState([]);
  const [productItemSku, setProductItemSku] = useState([]);
  const [productItemName, setProductItemName] = useState([]);
  const [productStock, setProductStock] = useState({});
  const [asheServiceItemList, setAsheServiceItemList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false)
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    fetchFormOption();
    fetchAllInfo();
  }, []);

  const fetchAllInfo = async() => {
    setDataLoading(true);
    await fetchProductItem();
    await fetchOrderItem();
    await fetchAshesServiceItemList();
    setDataLoading(false);
  };

  useEffect(() => {
    orderItemForm.resetFields();
  }, [orderItemInitialValues]);

  const fetchFormOption = async () => {
    fetchPlaceOption();
    fetchFootColorOption()
  };

  const fetchPlaceOption = async () => {
    let options = await Main.fetchFormOption("place");
    setPlaceOptions(options);
  };

  const fetchFootColorOption = async () => {
    let options = await Main.fetchFormOption("foot_color");
    setFootColorOptions(options);
  };

  const fetchProductItem = async () => {
    let results = await Service.call("get", "/api/product?is_active=1");
    // console.log('results', results);
    if (results.status !== 1) {
      return;
    }

    let skuOptions = results.data.map((item) => {
      return {
        value: item.sku,
        product_id: item.product_id,
        item_name: item.product_name,
        item_price: item.price,
        item_wholesale_price: item.wholesale_price,
      };
    });

    let productNameOptions = results.data.map((item) => {
      return {
        value: item.product_name,
        product_id: item.product_id,
        item_sku: item.sku,
        item_price: item.price,
        item_wholesale_price: item.wholesale_price,
      };
    });

    let stocks = results.data.map(({product_id, stock_number}) => {
      return {
        product_id,
        stock: stock_number
      };
    });
    stocks = _.keyBy(stocks, 'product_id')

    // console.log("skuOptions",skuOptions)
    // console.log("skuOptions", _.sortBy(skuOptions, ['value']))
    setProductItemSku(_.sortBy(skuOptions, ['value']));
    setProductItemName(productNameOptions);
    setProductStock(stocks)
  };

  const fetchOrderItem = async () => {
    let results = await Service.call(
      "get",
      `/api/order_info/order_item/${order_id}`
    );
    // console.log('results', results);
    if (results.status !== 1 || _.isEmpty(results.data)) {
      return;
    }
    // console.log('orderItemInitialValues', orderItemInitialValues);

    let orderItemObject = {};
    orderItemObject.order_item = results.data.map((item) => {
      let entity = item;
      if (_.isInteger(item.diy_time)) entity.diy_time = moment.unix(item.diy_time);
      return entity;
    });

    let nextOrderItemInitialValues = {
      ...orderItemInitialValues,
      ...orderItemObject,
    };
    // console.log('nextOrderItemInitialValues', nextOrderItemInitialValues)

    // show total_price
    nextOrderItemInitialValues.order_item.total_price = 0
    _.each(nextOrderItemInitialValues.order_item, (item) => {
      nextOrderItemInitialValues.order_item.total_price += item.quantity * item.item_price;
    })

    setOrderItemInitialValues(nextOrderItemInitialValues);
  };

  const fetchAshesServiceItemList = async () => {
    let results = await Service.call('get', `/api/order_info/ashes_service/${order_id}`);
    if (results.status !== 1) {
      return;
    }
    const itemList = results.data.map(({product_id, product_name, sku}) => {
      return {
        product_id, product_name, sku
      }
    })
    setAsheServiceItemList(itemList);
  }

  const updateItemTable = async ({ index, targetColumn, option }) => {
    let order_item = orderItemForm.getFieldValue().order_item;
    // get user type first
    // change whole sales price

    // const { order_id } = queryString.parse(location.search);
    let orderInfo = await Service.call("get", `/api/order_info/${order_id}`);
    let userId = orderInfo.results.user_id;
    // console.log("id>", userId);

    // let user_id = await Service.call('get', '/api/user/list');
    let user_id = await Service.call("get", `/api/user/${userId}`);
    // console.log("ggog>>", user_id);

    let user_type = user_id.data.type;
    // console.log("type", user_type);

    switch (targetColumn) {
      case "item_sku":
        order_item[index].product_id = option.product_id;
        order_item[index].item_name = option.item_name;
        break;

      case "item_name":
        order_item[index].product_id = option.product_id;
        order_item[index].item_sku = option.item_sku;
        break;

      default:
        break;
    }
    if (user_type === "wholesale") {
      order_item[index].item_price = option.item_wholesale_price;
    } else {
      order_item[index].item_price = option.item_price;
    }
    orderItemForm.setFieldsValue({ order_item });
    calculateTotal();
  };

  const calculateTotal = () => {
    let order_item = orderItemForm.getFieldValue().order_item;
    // console.log("order_item>>", order_item)
    order_item.total_price = 0;

    order_item.map((item) => {
      if (!item.item_price) {
        item.item_total = 0
        return
      }
      item.item_total = item.quantity * item.item_price;
      order_item.total_price += item.item_total;
      return true;
    });
    orderItemForm.setFieldsValue({ order_item });
  };

  const getStock = (index) => {
    let id = orderItemForm.getFieldValue().order_item[index].product_id
    let target = productStock[id]
    // if product id not exist in ProductStock array
    if (!target) {
      return 0;
    }
    return target.stock
  }

  // ger redux site meta value
  const meta = useSelector((state) => state.app.meta);

  const dateFormat = `${
    !_.isEmpty(meta.system_date_format.meta_value) ? meta.system_date_format.meta_value : 'YYYY/MM/DD'
  } ${
    !_.isEmpty(meta.system_time_format.meta_value) ? meta.system_time_format.meta_value : 'HH:mm'
  }`

  return (
    <>
      <List
        header={<div>{t('items_in_ashes_arrangement')}</div>}
        dataSource={asheServiceItemList}
        renderItem={(item) => (
          <List.Item>
            {`${item.product_name} (Product Code: ${item.sku})`}
            <Button
              style={{marginLeft: '10px'}}
              shape="round"
              onClick={() => {
                navigator.clipboard.writeText(item.sku);
                notification.success({ message: 'Copied.' });
              }}
            >
              {t('copy')}
            </Button>
          </List.Item>
        )}
      />
      <Spin
        indicator={<LoadingOutlined spin />}
        size="large"
        spinning={dataLoading}
      >
        <Form
          form={orderItemForm}
          name="orderItemForm"
          layout="vertical"
          initialValues={orderItemInitialValues}
          onFinish={async (values) => {
            // console.log('values', values);
            // return;

            if (_.isUndefined(order_id)) {
              notification.error({
                message: "提交失敗",
                description: "請先提交客戶及寵物資料",
              });
              return;
            }
            values.order_id = order_id;
            setSubmitLoading(true)
            let data = await Service.call(
              "post",
              "/api/order_info/order_item",
              values
            );
            // console.log('data', data);
            setSubmitLoading(false)

            if (data.status !== 1) {
              notification.error({
                message: "提交失敗",
                description: data.errorMessage,
              });

              return;
            }
            data.data.forEach((item) => {
              // ignore stock -> skip checking
              if (!item.is_stock_product) return;
              if (item.stock_number <= 0) notification.warn({ message: `${item.item_name} (Product Code: ${item.item_sku}) is out of stock.` })
            })
            notification.success({ message: "提交成功" });
            props.onChange();
            fetchAllInfo();
            // Call order item check form page to fetch data
            dispatch(setSideEffect({
              OrderItemCheckForm: {
                action: 'fetchOrderItem',
              }
            }));
          }}
        >
          <Divider>
            ***
            {' '}
            {t('order_item_title')}
            {' '}
            ***
          </Divider>

          <Form.List name="order_item">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, dynamicRowIndex) => (
                    <Card key={field.key} style={{ marginBottom: 30, borderRadius: 15, borderWith: 2 }}>
                      <Row align="middle">
                        <Divider>
                          {t('order_item')}
                          (
                          {dynamicRowIndex + 1}
                          )
                        </Divider>
                        <Col span={22}>
                          <Row gutter={24}>
                            <Form.Item
                              {...field}
                              name={[field.name, "product_id"]}
                              fieldKey={[field.fieldKey, "product_id"]}
                              label="貨品ID"
                              hidden
                            >
                              <Input disabled />
                            </Form.Item>
                            <Col xs={24} sm={24} md={6}>
                              <Form.Item
                                {...field}
                                name={[field.name, 'diy_time_status']}
                                fieldKey={[field.fieldKey, 'diy_time_status']}
                                label={t("diy_time_status")}
                              >
                                <Select
                                  disabled={isReadOnly}
                                  style={{width: '100%'}}
                                  onChange={(value) => {
                                    calculateTotal();
                                  }}
                                >
                                  {_.map(finishList, (val, key) => {
                                    return (
                                      <Option key={val} value={val}>{t(`${key}`)}</Option>
                                    )
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={6}>
                              <Form.Item
                                {...field}
                                name={[field.name, "item_sku"]}
                                fieldKey={[field.fieldKey, "item_sku"]}
                                rules={[{ required: true, message: t('missing_sku') }]}
                                label={t('sku')}
                              >
                                <Select
                                  disabled={isReadOnly}
                                  style={{width: '100%'}}
                                  showSearch
                                  placeholder={t('search_sku')}
                                  // options={[{label: 'Jack', value: 'jacke'}]}
                                  options={productItemSku}
                                  optionFilterProp="children"
                                // filterSort={(a, b) => a.item_sku.localeCompare(b.item_sku)}
                                  onChange={(value, option) => {
                                    updateItemTable({
                                      index: dynamicRowIndex,
                                      targetColumn: "item_sku",
                                      option,
                                    });
                                  }}
                                // onFocus={onFocus}
                                // onBlur={onBlur}
                                // onSearch={onSearch}
                                  filterOption={(input, option) => {
                                    // console.log('option', option)
                                    // option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    return (
                                      option.value
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    );
                                  }}
                                >
                                  {/* <Option value="jack">Jack</Option>
                                <Option value="lucy">Lucy</Option>
                                <Option value="tom">Tom</Option> */}
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={6}>
                              <Form.Item
                                {...field}
                                name={[field.name, "item_name"]}
                                fieldKey={[field.fieldKey, "item_name"]}
                                rules={[{ required: true, message: t('missing_item_name') }]}
                                label={t('item_name')}
                              >
                                <Select
                                  showSearch
                                  disabled
                                  // disabled={isReadOnly}
                                  style={{width: '100%'}}
                                  placeholder={t('search_item_name')}
                                  options={productItemName}
                                  optionFilterProp="children"
                                  onChange={(value, option) => {
                                    updateItemTable({
                                      index: dynamicRowIndex,
                                      targetColumn: "item_name",
                                      option,
                                    });
                                  }}
                                  filterOption={(input, option) => {
                                    return (
                                      option.value
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>


                            <Col span={24}>
                              <Row gutter={24}>
                                <Col xs={24} sm={24} md={6}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "quantity"]}
                                    fieldKey={[field.fieldKey, "quantity"]}
                                    label={(
                                      <>
                                        {`${t('quantity')}(${t('current_stock')}:`}
                                        <span style={{color: getStock(dynamicRowIndex) > 0 ? 'blue' : 'red'}}>{getStock(dynamicRowIndex)}</span>
                                        )
                                      </>
                                    )}
                                  >
                                    <InputNumber
                                      disabled={isReadOnly}
                                      style={{ width: '100%' }}
                                      placeholder={t('quantity')}
                                      onChange={(value) => {
                                        calculateTotal();
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={6}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "item_price"]}
                                    fieldKey={[field.fieldKey, "item_price"]}
                                    label={t('item_price')}
                                  >
                                    <InputNumber
                                      disabled={isReadOnly}
                                      style={{ width: '100%' }}
                                      placeholder={t('item_price')}
                                      onChange={(value) => {
                                        calculateTotal();
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={6}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "item_total"]}
                                    fieldKey={[field.fieldKey, "item_total"]}
                                    label={t('item_total')}
                                  >
                                    <InputNumber
                                      // disabled={isReadOnly}
                                      style={{ width: '100%' }}
                                      placeholder={t('item_total')}
                                      disabled
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={24} sm={24} md={6}>
                              <Form.Item
                                {...field}
                                name={[field.name, "place"]}
                                fieldKey={[field.fieldKey, "place"]}
                                label={t('place')}
                              >
                                <AutoComplete
                                  disabled={isReadOnly}
                                  style={{ width: '100%' }}
                                  options={placeOptions}
                                  placeholder="點擊查看選項"
                                  filterOption={(inputValue, option) => {
                                    return (
                                      option.value
                                        .toUpperCase()
                                        .indexOf(inputValue.toUpperCase()) !== -1
                                    );
                                  }}
                                  onChange={(value) => {
                                    calculateTotal();
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={6}>
                              <Form.Item
                                {...field}
                                name={[field.name, "diy_time"]}
                                fieldKey={[field.fieldKey, "diy_time"]}
                                label={t("diy_time")}
                              >
                                <DatePicker
                                  showTime
                                  disabled={isReadOnly}
                                  showNow={false}
                                  format={dateFormat}
                                  style={{ width: "100%" }}
                                  onChange={(value) => {
                                    calculateTotal();
                                  }}
                                  placeholder={t('please_select')}
                                  allowClear
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={6}>
                              <Form.Item
                                {...field}
                                name={[field.name, "foot_color"]}
                                fieldKey={[field.fieldKey, "foot_color"]}
                                label={t('foot_color')}
                              >
                                <Select
                                  showSearch
                                  allowClear
                                  disabled={isReadOnly}
                                  style={{width: '100%'}}
                                  placeholder={t('foot_color')}
                                  options={footColorOptions}
                                  optionFilterProp="children"
                                  onChange={(value) => {
                                    calculateTotal();
                                  }}
                                  filterOption={(input, option) => {
                                    return (
                                      option.value
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={6}>
                              <Form.Item
                                {...field}
                                name={[field.name, "item_remark"]}
                                fieldKey={[field.fieldKey, "item_remark"]}
                                label={t('item_remark')}
                              >
                                <Input
                                  disabled={isReadOnly}
                                  style={{width: '100%'}}
                                  placeholder={t('item_remark')}
                                  onChange={(value) => {
                                    calculateTotal();
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Button
                            disabled={isReadOnly}
                            type="dashed"
                            danger
                            block
                            onClick={() => {
                              remove(field.name);
                            }}
                          >
                            {t('delete')}
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  ))}

                  <Row justify="end">
                    <Col span={6}>
                      <Form.Item
                        label={t('total_price')}
                        name="total_price"
                      >
                        <InputNumber
                          // disabled={isReadOnly}
                          style={{width: '100%'}}
                          placeholder={`${t('total_price')}`}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button
                      disabled={isReadOnly}
                      type="dashed"
                      onClick={() => {
                        add({ quantity: 1 });
                      }}
                      block
                    >
                      <PlusOutlined />
                      {t('add_new_item')}
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>

          <Form.Item>
            <Button
              loading={submitLoading}
              disabled={isReadOnly || submitLoading || dataLoading}
              type="primary"
              className="p_order_agreement_view"
              htmlType="submit"
            >
              {t('submit')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default OrderItemForm;
