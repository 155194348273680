import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Button, Divider, Form, Input, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import UserRoleForm from '../../components/UserRoleForm';

const debug = require('debug')('app:admin:client:src:AdvertisementList');

const title = "員工列表";
const selectedKey = 'user_role';
const tableIDName = "company_user_id";

const UserRoleList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [initialValues, setInitialValues] = useState({})
  const { t } = useTranslation()
  const [form] = Form.useForm();

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    let orderedDataList = [];
    try {
      let result = await Service.call('get', '/api/company/user_role');
      // console.log('result', result)
      let { data } = result;
      orderedDataList = _.orderBy(data, ['company_user_role_id']);
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setDataList(orderedDataList);
    }
  }

  const columns = [
    {
      title: t('operation'),
      dataIndex: tableIDName,
      render: (value, record) => {
        console.log('Record>>>>>', record);
        let status = (record.status === 1);
        let color = '#000000';
        let icon = '';
        let wordings = '';
        if (status) {
          color = '#AA0000'
          icon = 'stop';
          wordings = 'Disable';
        } else {
          color = '#00AA00'
          icon = 'check';
          wordings = 'Enable';
        }
        return (
          <span>
            <Tooltip title="Edit">
              <Button
                shape="circle"
                style={{ marginRight: 8, color: '#000000' }}
                icon={<EditOutlined />}
                onClick={() => {
                  setModalVisible(true);
                  setSelectedRecord(record)
                }}
              />
            </Tooltip>
          </span>
        )
      }
    },
    {
      title: t('company_user_role_id'),
      dataIndex: `company_user_role_id`,
      sorter: (a, b) => a.company_user_role_id - b.company_user_role_id,
    },
    {
      title: t('role_key'),
      dataIndex: 'role_key',
      sorter: (a, b) => a.role_key.localeCompare(b.role_key)
    },
    {
      title: t('role_name'),
      dataIndex: 'role_name',
      sorter: (a, b) => a.role_name.localeCompare(b.role_name),
      width: "15%"
    },
    {
      title: t('permssion'),
      dataIndex: 'permission',
      render: (text, record, index) => {
        let { permission } = record;
        let userPermission = permission.split(',');
        return (
          <>
            {_.map(userPermission, (val, key) => {
              return (
                <>
                  {val !== "p_any" && (<Tag style={{margin: "10px 10px 10px"}} color="cyan" value={val}>{t(`${val}`)}</Tag>)}
                  {val === "p_any" && (<Tag style={{margin: "10px 10px 10px"}} color="red" value={val}>{t(`${val}`)}</Tag>)}
                </>
              )
            })}
          </>
        );
      },
    },
  ]
  return (
    <NavAndSideFrame {...props} title={t('access_level_list')} selectedKey={selectedKey}>
      <Button
        type="primary"
        className='p_permission_edit'
        onClick={() => {
          setSelectedRecord({ company_user_role_id: 0 });
          setModalVisible(true);
        }}
      >
        {t('add')}
      </Button>
      <Divider className='p_permission_edit'/>
      {/* <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}

      /> */}
      <Table
        columns={columns}
        dataSource={dataList}
        rowKey={() => "area_district_option_lookup"}
      />
      <Modal
        title={t('add')}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 1200 }}
        width="90%"
        onCancel={() => { setModalVisible(false) }}
      >
        <UserRoleForm
          dataObj={
            selectedRecord
          }
          openModal={
            (value) => {
              getAllData();
              setModalVisible(value)
            }
          }
        />
      </Modal>
    </NavAndSideFrame>
  )
}

export default UserRoleList;
