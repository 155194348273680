import React, { useState, useEffect } from "react";
import {
  notification,
  Tabs,
  Table,
  Badge,
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Tag,
  Empty,
  Select,
  InputNumber,
  Space,
  Row,
  Col
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import _ from "lodash";
import * as Service from "../../core/Service";
import OptionForm from './OptionForm'
import NavAndSideFrame from "../../components/NavAndSideFrame";
import { InfoOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;
const { confirm } = Modal;

const PetCategorySpecies = (props) => {
  const { t } = useTranslation();

  const [dataList, setDataList] = useState([]);
  const [petSpecies, setPetSpecies] = useState([]);

  useEffect(() => {
    fetchData();
    fetchPetSpecies();
  }, []);

  const fetchData = async () => {
    let result = await Service.call("get", `/api/form_option/all/pet_category`);
    // console.log('result', result)

    if (result.status !== 1) {
      return notification.warning({ message: "獲取資料失敗" });
    }

    setDataList(result.data);
  };

  const fetchPetSpecies = async () => {
    let result = await Service.call(
      "get",
      `/api/form_option/all/pet_species`
    );
    // console.log('result', result)

    if (result.status !== 1) {
      return notification.warning({ message: "獲取資料失敗" });
    }

    setPetSpecies(result.data);
  };

  return (
    <NavAndSideFrame
      {...props}
      title={t("pet_category_species")}
      selectedKey="pet_category_species"
    >
      {_.isEmpty(dataList) && <Empty />}

      <Tabs type="card" defaultActiveKey="" onChange={(key) => null}>
        {dataList.map((item) => (
          <TabPane tab={item.name} key={item.name}>
            <TabComponent
              petCategoryObject={item}
              petSpecies={petSpecies}
            />
          </TabPane>
        ))}
      </Tabs>
    </NavAndSideFrame>
  );
};

const TabComponent = (props) => {
  const { petCategoryObject, petSpecies } = props;
  const { t } = useTranslation();

  const [dataList, setDataList] = useState([]);
  const [recordId, setRecordId] = useState(0);
  const [selectedFormData, setSelectedFormData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const columns = [
    {
      title: t("operation"),
      className: "p_form_option_edit",
      dataIndex: "pet_category_species_lookup_id",
      render: (value, record, index) => {
        return (
          <Space>
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                setRecordId(value);
                setSelectedFormData(record);
                setModalVisible(true);
              }}
            />
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => {
                confirm({
                  title: `確認刪除${record.pet_species_name}？`,
                  okText: "是",
                  okType: "danger",
                  cancelText: "否",
                  onOk: async () => {
                    let result = await Service.call(
                      "delete",
                      `/api/pet_category_species_lookup/${record.pet_category_species_lookup_id}`
                    );
                    // console.log('result', result);

                    if (result.status !== 1) {
                      return notification.error({
                        message: "刪除失敗",
                        description: t(result.errorMessage),
                      });
                    }

                    notification.success({ message: "刪除成功" });
                    getAllData();
                  },
                  onCancel: () => {
                    // console.log('Cancel');
                  },
                });
              }}
            />
          </Space>
        );
      },
    },
    {
      title: `${t("pet_species_name")}`,
      dataIndex: "pet_species_name",
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.pet_species_name.localeCompare(b.pet_species_name, 'zh-hant'),
    },
    {
      title: `${t("active")}`,
      dataIndex: "is_active",
      render: (text, record, index) => {
        let { is_active } = record;
        return (
          <>
            {is_active === 1 ? (
              <Tag color="green">{t("active")}</Tag>
            ) : (
              <Tag color="red">{t("inactive")}</Tag>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      let result = await Service.call(
        "get",
        `/api/pet_category_species_lookup/all?pet_category_id=${petCategoryObject.form_option_id}&is_active=all`
      );
      // console.log('result', result);

      if (result.status !== 1) {
        return notification.warning({
          message: t("failed_to_obtain_information"),
        });
      }

      setDataList(result.data);
    } catch (error) {
      console.error("error >>> ", error);
    }
  };

  return (
    <>
      <Button
        type="primary"
        className="p_form_option_edit"
        onClick={() => {
          setRecordId(0);
          setSelectedFormData({});
          setModalVisible(true);
        }}
      >
        {t("create")}
      </Button>

      <Modal
        title={recordId > 0 ? t("edit") : t("create")}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <PetCategorySpeciesForm
          recordId={recordId}
          selectedFormData={selectedFormData}
          petCategoryObject={petCategoryObject}
          petSpecies={petSpecies}
          closeMethod={async () => {
            getAllData();
            setModalVisible(false);
          }}
        />
      </Modal>

      <Table
        columns={columns}
        dataSource={dataList}
        rowKey={() => "pet_category_species_lookup"}
      />
    </>
  );
};

const PetCategorySpeciesForm = (props) => {
  const { t } = useTranslation();

  const {
    recordId,
    selectedFormData,
    petCategoryObject,
    petSpecies,
    closeMethod,
  } = props;
  let initialValues = { is_active: 1 };
  let combinedInitialValues = { ...initialValues, ...selectedFormData };
  const [modalVisible, setModalVisible] = useState(false)
  const [optionType, setOptionType] = useState(false)
  const [petSpeciesList, setPetSpeciesList] = useState(petSpecies)

  const [form] = Form.useForm();

  const fetchPetSpecies = async (type, id, value) => {
    let result = await Service.call(
      "get",
      `/api/form_option/all/pet_species`
    );
    // console.log('result', result)

    if (result.status !== 1) {
      return notification.warning({ message: "獲取資料失敗" });
    }
    form.setFieldsValue({ pet_species_id: id})
    setPetSpeciesList(result.data);
  };

  return (
    <>
      <Modal
        title={t('create') + t(optionType)}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <OptionForm
          selectedFormData={{}}
          type={optionType}
          isActive={1}
          categoryID={petCategoryObject.form_option_id}
          closeMethod={async (form_option_id, value) => {
            fetchPetSpecies(optionType, form_option_id, value);
            setModalVisible(false);
          }}
        />
      </Modal>
      <Form
        form={form}
        initialValues={combinedInitialValues}
        onFinish={async (values) => {
          values.pet_category_id = petCategoryObject.form_option_id;
          let method = "post";
          if (recordId !== 0) {
            //  Edit
            method = "patch";
            values.pet_category_species_lookup_id = recordId;
          }

          let result = await Service.call(
            method,
            "/api/pet_category_species_lookup",
            values
          );
          // console.log('result', result)

          if (result.status !== 1) {
            return notification.error({
              message: t("submission_failed"),
              description: result.errorMessage,
            });
          }

          notification.success({ message: t("submitted_successfully") });
          closeMethod();
        }}
      >
        <Row gutter={24}>
          <Col span={20}>
            <Form.Item
              label={t("pet_species")}
              name="pet_species_id"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                placeholder="搜尋"
                options={_.sortBy(petSpeciesList.map((item) => {
                  return { value: item.form_option_id, label: item.name };
                }), ['label'])}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={2}>
            <Button
              type="primary"
              shape="circle"
              onClick={() => {
                setModalVisible(true)
                setOptionType('pet_species')
              }}
              icon={<PlusOutlined />}
              />
          </Col>
        </Row>

        <Form.Item
          label={t("active")}
          name="is_active"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={1}>{t("active")}</Radio>
            <Radio value={0}>{t("inactive")}</Radio>
          </Radio.Group>
        </Form.Item>

        <Button type="primary" htmlType="submit">
          {t("submit")}
        </Button>
      </Form>
    </>
  );
};

export default PetCategorySpecies;
