import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  notification,
  Divider,
  InputNumber,
  AutoComplete,
  Row,
  Col,
  Grid,
  Affix,
  Modal,
  Space,
  Descriptions
} from "antd";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { InfoOutlined, PlusOutlined, LinkOutlined } from '@ant-design/icons';
import path from 'path'
import { useSelector } from 'react-redux'
import { setCompanyAdmin } from "../../redux/actions/common";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import OptionForm from './OptionForm'
import finishList from '../../data/finishList.json'

const debug = require('debug')('app:admin:pages:OrderInfoBasicForm');

const { useBreakpoint } = Grid;
const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;


const OrderInfoBasicForm = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { t, i18n } = useTranslation();
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)
  let { order_id } = queryString.parse(location.search);
  let { order_id: orderIdProp = 0 } = props
  if (orderIdProp > 0) {
    order_id = orderIdProp // Override order_id if prop existing
  }
  const meta = useSelector((state) => state.app.meta);
  const companyUser = useSelector((state) => state.app.company_user);

  const dateFormat = `${!_.isEmpty(meta.system_date_format.meta_value) ? meta.system_date_format.meta_value : 'YYYY/MM/DD'
    } ${!_.isEmpty(meta.system_time_format.meta_value) ? meta.system_time_format.meta_value : 'HH:mm'
    }`

  const petBirthdayDateFormat = `${!_.isEmpty(meta.system_date_format.meta_value) ? meta.system_date_format.meta_value : 'YYYY/MM/DD'
    }`

  const screens = useBreakpoint();
  const {
    xs, // screen < 576px
    sm, // screen ≥ 576px
    md, // screen ≥ 768px
    lg, // screen ≥ 992px
    xl, // screen ≥ 1200px
    xxl // screen ≥ 1600px
  } = screens

  const {
    initialValues,
    isReadOnly, //  From invoice page
    order_no, //  From invoice page
  } = props;

  const [basicFormInitialValues, setBasicFormInitialValues] = useState(
    initialValues
  );
  const [petCategoryOptions, setPetCategory] = useState([]);
  const [petSpeciesOptions, setPetSpecies] = useState([]);
  const [areaMapping, setAreaMapping] = useState({});
  const [districtMapping, setDistrictMapping] = useState({});
  const [typeForAreaDistrictAddressMapping, setTypeForAreaDistrictAddressMapping] = useState([])
  const [areaOptions, setArea] = useState([]);
  const [districtOptions, setDistrict] = useState([]);
  const [staffOptions, setStaffOption] = useState([]);
  const [formOptions, setFormOption] = useState([]);
  const [hiddenAreaDistrictBoolean, setHiddenAreaDistrict] = useState(false);
  const [addressLabel, setAddressLabel] = useState("address")
  const [chosenCategory, setChosenCategory] = useState(0)
  const [chosenAreaId, setChosenAreaId] = useState(0)
  const [chosenDistrictId, setChosenDistrictId] = useState(0)
  const [chosenAddressId, setChosenAddressId] = useState(0)
  const [chosenType, setChosenType] = useState('')
  const [pickUpType, setPickUpType] = useState([]);
  const [petWeightMeasure, setPetWeightMeasure] = useState('');
  const [modalVisible, setModalVisible] = useState(false)
  const [optionType, setOptionType] = useState(false)
  const [showAddressOptionList, setShowAddressOptionList] = useState(false)
  const [showStatusOption, setShowStatusOption] = useState(false)
  const [hiddenPetSpecies, setHiddenPetSpecies] = useState(true)
  const [hiddenPickUp, setHiddenPickUp] = useState(true)
  const [isPickUp, setIsPickUp] = useState(false)
  const [disableLocalChip, setDisableLocalChip] = useState(false)
  const [disableOutlandChip, setDisableOutlandChip] = useState(false)
  const [disableLocation, setDisableLocation] = useState(false)
  const [areaCode, setAreaCode] = useState({})
  const [showAddress, setShowAddress] = useState(true)

  const [form] = Form.useForm();

  useEffect(() => {
    if (!companyUser.company_user_id || !staffOptions) {return}
    form.setFieldsValue({agreement_consultant_staff_id: companyUser.company_user_id})
  }, [companyUser, form, staffOptions])

  useEffect(() => {
    fetchFormOption();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(areaMapping) && !_.isEmpty(districtMapping) && chosenType) {
      fetchTypeRelation();
    }
    setDisableLocation(chosenType)
  }, [chosenType, areaMapping, districtMapping]);

  useEffect(() => {
    if (_.isEmpty(typeForAreaDistrictAddressMapping) || _.isEmpty(areaMapping) || _.isEmpty(districtMapping)) {
      return
    }
    optionUpdate()
  }, [typeForAreaDistrictAddressMapping, areaMapping, districtMapping])

  useEffect(() => {
    let newDefaultValues = { ...basicFormInitialValues, ...initialValues, contactPersonMobilePrefix: '+852', prefixMobile: '+852' };
    if (newDefaultValues.pick_up_address_id !== 0 && !_.isUndefined(newDefaultValues.pick_up_address_id)) {
      setChosenAddressId(newDefaultValues.pick_up_address_id)
    }
    if (newDefaultValues.pet_category_id !== 0 && !_.isUndefined(newDefaultValues.pet_category_id)) setChosenCategory(newDefaultValues.pet_category_id);
    if (newDefaultValues.pick_up_area_id !== 0) setChosenAreaId(newDefaultValues.pick_up_area_id);
    onPickUpMethodChange(newDefaultValues.pick_up_type)
    if (newDefaultValues.pick_up_district_id !== 0) setChosenDistrictId(newDefaultValues.pick_up_district_id);
    if (!_.isNull(newDefaultValues.estimate_pick_up_time) && !_.isUndefined(newDefaultValues.estimate_pick_up_time)) setShowStatusOption(true);
    setDisableLocalChip(Number.isInteger(newDefaultValues.ignore_pet_chip_local) ? newDefaultValues.ignore_pet_chip_local === 1 : true);
    setDisableOutlandChip(Number.isInteger(newDefaultValues.ignore_pet_chip_outland) ? newDefaultValues.ignore_pet_chip_outland === 1 : true);
    setBasicFormInitialValues(newDefaultValues);
  }, [initialValues]);

  useEffect(() => {
    form.resetFields();
  }, [basicFormInitialValues]);

  useEffect(() => {
    if (order_no) {
      fetchOrderInfo();
    }
  }, [order_no]);

  useEffect(() => {
    if (chosenCategory !== 0 && !_.isUndefined(chosenCategory)) { fetchPetSpecies({}); setHiddenPetSpecies(false) }
  }, [chosenCategory])

  useEffect(() => {
    if (chosenCategory === 0 || _.isEmpty(petCategoryOptions)) { // Category is not selected before or option is not ready
      return;
    }

    let petCatIds = _.map(petCategoryOptions, 'value');
    if (_.includes(petCatIds, chosenCategory)) { // Selected record already existed in option list
      return;
    }

    // Append inactive record into option list if order picked record before
    fetchOptionById();
  }, [chosenCategory, petCategoryOptions])

  useEffect(() => {
    if (chosenDistrictId !== 0 && !_.isUndefined(chosenDistrictId) && !_.isUndefined(optionType)) { setHiddenPickUp(false) }
  }, [chosenDistrictId, optionType])

  const unsuccessfulStages = ["", "pending_car", "pending_driver"];

  const fetchOptionById = async () => {
    let result = await Service.call("get", `/api/form_option/id/${chosenCategory}`);
    const { status, data } = result;

    if (status !== 1) {
      return notification.warning({ message: "獲取資料失敗" });
    }

    const { form_option_id, name } = data;

    let petCatOption = _.concat(petCategoryOptions, { value: form_option_id, label: name });
    setPetCategory(petCatOption);
  }

  const fetchFormOption = async () => {
    fetchPetCategory();
    fetchAreaList();
    fetchDistrictList();
    fetchAreaCode();
    // fetchClinic();
    fetchPickUpType();
    fetchPetWeightMeasure();
    fetchStaff();
  };

  const fetchData = async (type, id, value) => {
    switch (type) {
      case "pet_category":
        fetchPetCategory(id, value);
        form.setFieldsValue({ pet_category: value, pet_category_id: id })
        break;
      case "pet_species":
        fetchPetSpecies({ id, value });
        form.setFieldsValue({ pet_species: value, pet_species_id: id })
        break;
      default:
        form.setFieldsValue({ pick_up_address: value, pick_up_address_id: id })
        break;
    }
  }

  const fetchAreaCode = async () => {
    let result = await Service.call('get', `/api/areaCode`);
    if (result.status !== 1) {
      return notification.warning({ message: '獲取區號失敗' });
    }

    let { data } = result;
    setAreaCode(data);
  }

  const fetchAreaList = async (id, value) => {
    let list = await Main.fetchFormOption("area", true);
    if (id) list.push({ value: id, label: value })
    let mapping = {}
    _.forEach(list, (item) => {
      mapping[item.value] = item
    })
    setAreaMapping(mapping);
  };

  const fetchDistrictList = async (id, value) => {
    let list = await Main.fetchFormOption("district", true);
    if (id) list.push({ value: id, label: value })
    let mapping = {}
    _.forEach(list, (item) => {
      mapping[item.value] = item
    })
    setDistrictMapping(mapping);
  };


  const fetchTypeRelation = async () => {
    let { status, data } = await Service.call("get", `/api/area_district_option_lookup/${chosenType}/${i18n.language}`);
    if (status !== 1) return;
    let mapping = _.map(data, ({
      area_id, district_id, form_option_id, form_option_name, type, remark
    }) => {
      let area = areaMapping[area_id] ? areaMapping[area_id].label : ''
      let district = districtMapping[district_id] ? districtMapping[district_id].label : ''
      return {
        area_id, area, district_id, district, form_option_id, form_option_name, type, remark
      }
    })
    setTypeForAreaDistrictAddressMapping(mapping)
  }

  const fetchPetCategory = async (id, value) => {
    let options = await Main.fetchFormOption("pet_category", true);
    if (id) { 
      options.push({ value: id, label: value }); 
      setChosenCategory(id); 
      form.setFieldsValue({ pet_species_id: 0 }); 
    }
    setPetCategory(options);
  };

  const fetchPetSpecies = async ({ id, value }) => {
    let options = await Main.fetchFormOption("pet_species", true);
    if (basicFormInitialValues.pet_species_id && basicFormInitialValues.pet_species) {
      options.push({ value: basicFormInitialValues.pet_species_id, label: basicFormInitialValues.pet_species, is_default: 0 })
    }
    if (chosenCategory !== 0 && !_.isUndefined(chosenCategory)) {
      let lookup = await Service.call("get", `/api/pet_category_species_lookup/all?pet_category_id=${chosenCategory}&lang=${i18n.language}`);
      let relatedPetSpecies = lookup.data.map((item) => {
        return ({ value: item.pet_species_id, label: item.pet_species_name, is_default: 0 })
      })
      options = _.intersectionWith(options, relatedPetSpecies, _.isEqual)
      let sorter = (array) => array.sort((a, b) => { return a.label.localeCompare(b.label, "zh-hant") })
      options = sorter(options)
    }
    if (id) options.push({ value: id, label: value })
    setPetSpecies(options);
  };

  const fetchStaff = async () => {
    let result = await Service.call("get", `/api/company/user`);
    if (result.status !== 1) return;
    let userInfoList = result.data;
    let staffOption = userInfoList.map((entity) => {
      return { label: entity.company_user_id + '-' + entity.first_name + " " + entity.last_name, value: entity.company_user_id }
    })
    setStaffOption(staffOption);
  };

  const fetchOrderInfo = async () => {
    let result = await Service.call(
      "get",
      `/api/order_info/search?order_no=${order_no}`
    );
    // console.log('result>>>', result)

    if (result.status !== 1) return;

    let [orderInfoObject] = result.data;
    orderInfoObject = Main.convertOrderInfoDisplayData(orderInfoObject);
    if (!_.isNull(orderInfoObject.estimate_pick_up_time)) setShowStatusOption(true);
    let newDefaultValues = { ...basicFormInitialValues, ...orderInfoObject };
    setChosenCategory(newDefaultValues.pet_category_id)
    setBasicFormInitialValues(newDefaultValues);
  };
  const fetchPickUpType = async () => {
    let pickUpTypeList = await Service.call('get', '/api/order_info/pick_up_type/list');
    setPickUpType(pickUpTypeList);
  }

  const fetchPetWeightMeasure = async () => {
    let metaWeight = "system_pet_weight_measurement"
    let metaKey = await Service.call('get', `/api/admin/meta_by_key?meta_key=${metaWeight}`);
    let [WeightMeasure] = metaKey.data;
    setPetWeightMeasure(WeightMeasure.meta_value)
  }

  const resetLocation = () => {
    form.setFieldsValue(
      {
        pick_up_type: '',
        pick_up_area: '',
        pick_up_district: '',
        pick_up_address: '',
        pick_up_address_remark: ''
      }
    );
    setDisableLocation(false)
    setOptionType(0)
    setChosenAreaId(0)
    setChosenDistrictId(0)
    setChosenAddressId(0)
    setChosenType(0)
    setShowAddressOptionList(false)
    setShowAddress(true)
    onPickUpMethodChange('')
  }

  const optionUpdate = () => {
    let currentArea = form.getFieldValue("pick_up_area") || ''
    let currentDistrict = form.getFieldValue("pick_up_district") || ''
    let currentAddress = form.getFieldValue("pick_up_address") || ''
    let filterRelation = _.filter(typeForAreaDistrictAddressMapping, (item) => {
      const { area, district, form_option_name } = item;
      if (!_.includes(area.toUpperCase(), currentArea.toUpperCase())) {
        return false;
      }

      if (!_.includes(district.toUpperCase(), currentDistrict.toUpperCase())) {
        return false;
      }

      if (!_.includes(form_option_name.toUpperCase(), currentAddress.toUpperCase())) {
        return false;
      }

      return true;
    })
    setArea(
      _.uniqBy(filterRelation.map(({ area, area_id }) => {
        return {
          key: area_id,
          value: area,
        }
      }), 'value')
    )
    setDistrict(
      _.uniqBy(filterRelation.map(({ district, district_id }) => {
        return {
          key: district_id,
          value: district
        }
      }), 'value')
    )
    setFormOption(
      _.uniqBy(filterRelation.map(({ form_option_name, form_option_id }) => {
        return {
          key: form_option_id,
          value: form_option_name
        }
      }), 'value')
    )
  }

  const onFinish = async (postObj) => {
    postObj.pick_up_area_id = chosenAreaId
    postObj.pick_up_district_id = chosenDistrictId
    postObj.pick_up_address_id = chosenAddressId
    // return console.log('onFinish with postObj', postObj);
    let method = "post";
    if (!_.isUndefined(order_id)) {
      method = "patch";
      postObj.order_id = order_id;
    }
    if (_.isNaN(_.toNumber(postObj.owner_mobile))) {
      message.error(t('mobile_type_error'));
      return;
    }
    if (_.includes(["clinic", "organization", "pet_hotel", "pet_shop", "hospice"], postObj.pick_up_type)) {
      try {
        let { status, data: configData } = await Service.call('get', `/api/form_option/all/${postObj.pick_up_type}`)
        if (status === 1 && !_.includes(_.map(configData, 'name'), postObj.pick_up_address)) {
          let postData = {
            type: postObj.pick_up_type,
            name: postObj.pick_up_address,
            remark: '',
            is_default: 0,
            is_active: 0,
            language: "",
          }
          let newdata = await Service.call('post', '/api/form_option', postData)
          // console.log("newdata", newdata)
        }
      } catch (err) {
        console.log(`error>>>`, err)
      }
    }
    let data = await Service.call(method, "/api/order_info", postObj);
    // console.log('data11', data);

    if (data.status !== 1) {
      notification.error({
        message: t("submission_failed"),
        description: data.errorMessage,
      });
      return;
    }

    notification.success({ message: t("submitted_successfully") });
    if (method === 'post') {
      await Modal.info({
        title: 'Confirm',
        icon: <InfoOutlined />,
        content:
          <Space>
            <Descriptions title="Result">
              <Descriptions.Item label="Order No.">
                {data.data.order_no_prefix}
                {data.data.order_no}
              </Descriptions.Item>
            </Descriptions>
            <Button
              // className="p_order_edit"
              className="p_order_agreement_edit"
              shape="circle"
              icon={<LinkOutlined />}
              onClick={() => {
                // let url = path.join(STATIC_SERVER_URL, `/order_info_public?order_id=${data.data.order_id}`)
                let url = `${window.location.origin}/order_info_public?order_id=${data.data.order_id}`
                let tempDiv = document.createElement('textarea');
                tempDiv.value = url
                document.body.appendChild(tempDiv);
                tempDiv.select();
                document.execCommand('copy');
                document.body.removeChild(tempDiv);
                notification.success({ message: 'Copied.' });
                // history.push(`/order_info_public?order_id=${value}`)
              }}
            />
          </Space>,
        okText: 'OK',
        onOk: async () => {
          history.push('/company/order_info')
        }
      });
    } else props.onChange();
  };

  const onPickUpMethodChange = (value) => {
    setOptionType(value)
    setChosenType(value)
    switch (value) {
      case "home":
      case "street":
      case "other":
        setHiddenAreaDistrict(false);
        setAddressLabel("address");
        setShowAddressOptionList(false)
        setShowAddress(true)
        break
      case "family_deliver":
      case "others_deliver":
        setHiddenAreaDistrict(true);
        setAddressLabel('address')
        setShowAddressOptionList(false)
        setShowAddress(false)
        break
      case "clinic":
      case "organization":
      case "pet_shop":
      case "pet_hotel":
      case "hospice":
        setHiddenAreaDistrict(false)
        setAddressLabel(value);
        setShowAddressOptionList(true)
        setShowAddress(true)
        break
      default:
        setHiddenAreaDistrict(false);
        setAddressLabel("address");
        setShowAddressOptionList(false)
        setShowAddress(true)
        break
    }
  }

  // console.log('raw', basicFormInitialValues)
  // console.log('basicFormInitialValues', basicFormInitialValues.order_stage)

  return (
    <>
      <Modal
        title={t('create') + t(optionType)}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <OptionForm
          selectedFormData={{}}
          type={optionType}
          categoryID={chosenCategory}
          areaID={chosenAreaId}
          districtID={chosenDistrictId}
          isPickUp={isPickUp}
          closeMethod={async (form_option_id, value) => {
            fetchData(optionType, form_option_id, value);
            setModalVisible(false);
          }}
        />
      </Modal>
      <Form
        form={form}
        name="orderInfoForm"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          ...basicFormInitialValues,
          pet_category_id: basicFormInitialValues.pet_category_id ? basicFormInitialValues.pet_category_id : undefined,
          pet_species_id: basicFormInitialValues.pet_species_id ? basicFormInitialValues.pet_species_id : undefined,
          orderNum: basicFormInitialValues.order_no_prefix + basicFormInitialValues.order_no
        }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="estimate_pick_up_time_status"
              label={t("estimate_pick_up_time_status")}
              hidden={!showStatusOption}
            >
              <Select disabled={isReadOnly}
                defaultValue={
                  (basicFormInitialValues?.agreement_signature_date) == null ?
                    t('notFinish') : t('finished')}>
                {_.map(finishList, (val, key) => {
                  return (
                    <Option value={val} key={key}>{t(`${key}`)}</Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider>
          ***
          {' '}
          {t("customer_and_pet_information")}
          {' '}
          ***
        </Divider>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={t("owner_mobile")}
              name="owner_mobile"
              rules={[
                {
                  required: true,
                  message: t("please_enter_this_field"),
                },
              ]}
            >
              <Search
                autoFocus
                placeholder=""
                addonBefore={(
                  <Form.Item name="prefixMobile" noStyle>
                    <AutoComplete style={{ width: 80 }} disabled={isReadOnly}>
                      {_.map(areaCode, (val, key) => {
                        return (
                          <Option value={val} key={key}>{val}</Option>
                        )
                      })}
                    </AutoComplete>
                  </Form.Item>
                )}
                onSearch={async (value, event) => {
                  event.preventDefault();
                  let mobile = `${form.getFieldValue("prefixMobile")}-${value}`;
                  // console.log("mobile01", mobile)
                  let userResult;
                  // console.log("id", order_id)
                  try {
                    // if (_.isUndefined(order_id)) {
                    userResult = await Service.call(
                      "get",
                      `/api/user?mobile=${mobile}`
                    );
                    // } else {
                    //   userResult = await Service.call(
                    //     "get",
                    //     `/api/order_info/mobile?mobile=${mobile}`
                    //   );
                    // }
                  } catch (error) {
                    console.error("error >>> ", error);
                  }
                  // console.log('userResult', userResult)

                  if (userResult.status !== 1) {
                    notification.error({
                      message: t("search_result"),
                      description: userResult.errorMessage,
                    });
                    return;
                  }

                  if (_.isEmpty(userResult.data)) {
                    notification.info({
                      message: t("search_result"),
                      description: t("no_matching_record"),
                    });
                    return;
                  }

                  let [userData] = userResult.data;
                  form.setFieldsValue({
                    owner_email: userData.owner_email || userData.email || "",
                    owner_first_name: userData.owner_first_name || userData.first_name || "",
                    owner_last_name: userData.owner_last_name || userData.last_name || "",
                    owner_salutation: userData.owner_salutation || userData.salutation || "",
                  });
                  notification.success({
                    message: t("search_result"),
                    description: t("found_match_record"),
                  });
                }}
                style={{ width: "100%" }}
                disabled={isReadOnly}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={t("owner_email")}
              name="owner_email"
              rules={[
                {
                  type: "email",
                  message: t("please_enter_a_valid_email"),
                }]}
            //   {
            //     required: true,
            //     message: t("please_enter_this_field"),
            //   },
            // ]}
            >
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label={t("order_no")} name="orderNum">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item label="寵物編號" name="pet_no">
              <Input disabled />
            </Form.Item>
          </Col> */}
        </Row>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              label={t("pick_up_time")}
              name="pick_up_time"
            // rules={[
            //   {
            //     required: true,
            //     message: t("please_enter_this_field"),
            //   },
            // ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                showTime
                format={dateFormat}
                disabled={isReadOnly}
                placeholder={t('select_date_option')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              label={t("estimate_pick_up_time")}
              name="estimate_pick_up_time"
            // rules={[{ required: true }]}
            >
              <DatePicker
                showTime
                showNow={false}
                disabled={isReadOnly}
                format={dateFormat}
                placeholder={t('select_date_option')}
                style={{ width: "100%" }}
                onChange={(value) => {
                  if (!value) {
                    return;
                  }
                  let calculateEstimatePickUpEndTime = moment(value).add(2, 'hours');
                  form.setFieldsValue({ estimate_pick_up_end_time: calculateEstimatePickUpEndTime });
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              label={t("estimate_pick_up_end_time")}
              name="estimate_pick_up_end_time"
            // rules={[{ required: true }]}
            >
              <DatePicker
                showTime
                showNow={false}
                disabled={isReadOnly}
                format={dateFormat}
                style={{ width: "100%" }}
                placeholder={t('select_date_option')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={3}>
            <Form.Item label={`${t("pet_estimate_weight")} (${petWeightMeasure})`} name="pet_estimate_weight">
              <InputNumber style={{ width: "100%" }} disabled={isReadOnly} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={3}>
            <Form.Item label={`${t("pet_weight")} (${petWeightMeasure})`} name="pet_weight">
              <InputNumber style={{ width: "100%" }} disabled={isReadOnly} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={8}>
            <Form.Item name="owner_salutation" label={t("owner_salutation")}>
              <Select disabled={isReadOnly}>
                <Option value="mr">{t("mr")}</Option>
                <Option value="mrs">{t("mrs")}</Option>
                <Option value="miss">{t("miss")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={t("owner_first_name")}
              name="owner_first_name"
            // rules={[{ required: true, message: t("please_enter_this_field") }]}
            >
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={t("owner_last_name")}
              name="owner_last_name"
            // rules={[{ required: true, message: t("please_enter_this_field") }]}
            >
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} align="middle">
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={t("pet_name")}
              name="pet_name"
            // rules={[{ required: true, message: t("please_enter_this_field") }]}
            >
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
          <Col xs={20} sm={20} md={6}>
            <Form.Item label={t("pet_category")} name="pet_category" hidden>
              <Input disabled />
            </Form.Item>
            <Form.Item label={t("pet_category")} name="pet_category_id">
              <Select
                showSearch
                disabled={isReadOnly}
                options={_.sortBy(petCategoryOptions, ['label'])}
                placeholder={t("click_to_view_options")}
                filterOption={(inputValue, option) => {
                  return (
                    option.label
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  );
                }}
                onChange={(value, option) => {
                  if (!value) {
                    form.setFieldsValue({ pet_category: '' });
                    form.setFieldsValue({ pet_species_id: null });
                    setChosenCategory([]);
                    return
                  }
                  form.setFieldsValue({ pet_category: option.label });
                  form.setFieldsValue({ pet_species_id: null });
                  setChosenCategory(option.value);
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={4} sm={4} md={2}>
            <Button
              disabled={isReadOnly}
              className="p_form_option_edit"
              type="primary"
              shape="circle"
              onClick={() => {
                setModalVisible(true)
                setOptionType('pet_category')
              }}
              icon={<PlusOutlined />}
            />
          </Col>

          <Col xs={20} sm={20} md={6}>
            <Form.Item label={t("pet_category")} name="pet_species" hidden>
              <Input disabled />
            </Form.Item>
            <Form.Item label={t("pet_species")} name="pet_species_id">
              <Select
                showSearch
                disabled={isReadOnly}
                options={_.sortBy(petSpeciesOptions, ['label'])}
                placeholder={t("click_to_view_options")}
                filterOption={(inputValue, option) => {
                  return (
                    option.label
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  );
                }}
                onChange={(value, option) => {
                  if (!value) return form.setFieldsValue({ pet_species: '' })
                  form.setFieldsValue({ pet_species: option.label });
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={4} sm={4} md={2}>
            {!hiddenPetSpecies && (
              <Button
                disabled={isReadOnly}
                type="primary"
                className="p_form_option_edit"
                shape="circle"
                onClick={() => {
                  setModalVisible(true)
                  setOptionType('pet_species')
                }}
                icon={<PlusOutlined />}
              />
            )}
          </Col>
        </Row>

        <Form.Item label={t("pet_category_name")} name="pet_category" hidden>
          <Input disabled />
        </Form.Item>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="pet_gender"
              label={t("pet_gender")}
            // rules={[{ required: true }]}
            >
              <Select disabled={isReadOnly}>
                <Option value="m">{t("male")}</Option>
                <Option value="f">{t("female")}</Option>
                <Option value="o">{t("unknown")}</Option>

              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label={t("pet_age")} name="pet_age">
              <InputNumber style={{ width: "100%" }} disabled={isReadOnly} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} align="middle">
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              label={
                (
                  <>
                    <span style={{ marginRight: '15px' }}>{t("pick_up_location")}</span>
                    <Button
                      disabled={isReadOnly}
                      type="primary"
                      shape="round"
                      onClick={resetLocation}
                    >
                      {t('reset')}
                    </Button>
                  </>
                )
              }
              name="pick_up_type"
            // rules={[{ required: true, message: "請輸入此欄位" }]}
            >
              <Select disabled={isReadOnly || disableLocation} onChange={onPickUpMethodChange}>
                {_.map(pickUpType, (val, key) => {
                  return (
                    <Option value={key} key={key}>{t(`${val}`)}</Option>
                  )
                })}
                {/* <Option value="home" selected>家中</Option>
                <Option value="family_deliver">家屬送來</Option>
                <Option value="clinic">診所</Option>
                <Option value="others_deliver">好心人送來</Option>
                <Option value="organization">機構</Option>
                <Option value="street">街上</Option>
                <Option value="pet_shop">寵物店</Option>
                <Option value="dog_hotel">狗酒店</Option>
                <Option value="other">其他</Option> */}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} align="middle">
          <Col xs={20} sm={20} md={20}>
            <Form.Item
              label={t(`${addressLabel}`)}// "地址" / "診所"
              name="pick_up_address"
              hidden={showAddressOptionList || !showAddress}
            >
              <Input disabled={isReadOnly} />
            </Form.Item>
            <Form.Item
              label={t(`${addressLabel}`)}// "地址" / "診所"
              name="pick_up_address"
              hidden={!showAddressOptionList || !showAddress}
            >
              <AutoComplete
                showSearch
                disabled={isReadOnly}
                options={formOptions}
                placeholder={t("click_to_view_options")}
                onChange={(value, option) => {
                  setChosenAddressId(option.key || 0)
                  optionUpdate()
                  let match = _.find(typeForAreaDistrictAddressMapping, { form_option_name: value })
                  if (match && value) {
                    form.setFieldsValue({
                      pick_up_area: match.area,
                      pick_up_district: match.district,
                      pick_up_address_remark: match.remark
                    })
                    setChosenAreaId(match.area_id)
                    setChosenDistrictId(match.district_id)
                    setChosenAddressId(match.form_option_id)
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={4}>
            {showAddressOptionList && !hiddenPickUp && (
              <Button
                type="primary"
                className="p_form_option_edit"
                shape="circle"
                onClick={() => {
                  setModalVisible(true)
                  setIsPickUp(true)
                }}
                icon={<PlusOutlined />}
              />
            )}
          </Col>
        </Row>
        <Row gutter={24} align="middle">
          <Col xs={24} sm={24} md={9}>
            <Form.Item
              label={t("area")}
              name="pick_up_area"
              hidden={hiddenAreaDistrictBoolean}
            >
              <AutoComplete
                showSearch
                disabled={isReadOnly}
                options={chosenType ? areaOptions : []}
                placeholder={t("click_to_view_options")}
                onChange={(value, option) => {
                  setChosenAreaId(option.key || 0)
                  form.setFieldsValue({ pick_up_district: '' });
                  setChosenDistrictId(0)
                  optionUpdate()
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={9}>
            <Form.Item
              label={t("district")}
              name="pick_up_district"
              hidden={hiddenAreaDistrictBoolean}
            >
              <AutoComplete
                showSearch
                disabled={isReadOnly || !form.getFieldValue("pick_up_area")}
                options={chosenType ? districtOptions : []}
                placeholder={t("click_to_view_options")}
                onChange={(value, option) => {
                  setChosenDistrictId(option.key || 0)
                  optionUpdate()
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={20} sm={20} md={20}>
            <Form.Item
              label={t(`pick_up_address_remark`)}// "地址" / "診所"
              name="pick_up_address_remark"
            >
              <Input disabled={!showAddressOptionList} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label={t('consultant_staff')}// "地址" / "診所"
              name="agreement_consultant_staff_id"
            >
              <Select
                showSearch
                disabled={isReadOnly}
                options={staffOptions}
                onChange={(value) => console.log({value})}
                placeholder={t("click_to_view_options")}
                filterOption={(inputValue, option) => {
                  return (
                    option.label
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>


        <Divider>
          ***
          {' '}
          {t("backup_data")}
          {' '}
          ***
        </Divider>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={4}>
            <Form.Item
              label={t("have_pet_chip_local")}
              name="ignore_pet_chip_local"
            >
              <Select
                disabled={isReadOnly}
                onChange={(value) => { setDisableLocalChip(value !== 0) }}
                placeholder={t("click_to_view_options")}
              >
                <Option value={0}>{t('yes')}</Option>
                <Option value={1}>{t('no')}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={t("pet_chip_local")}
              name="pet_chip_local"
              dependencies={['ignore_pet_chip_local']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (getFieldValue('ignore_pet_chip_local') === 0 && !value) {
                      return Promise.reject(new Error(t('please_enter_chip')));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input disabled={isReadOnly || disableLocalChip} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4}>
            <Form.Item
              label={t("have_pet_chip_outland")}
              name="ignore_pet_chip_outland"
            >
              <Select
                onChange={(value) => { setDisableOutlandChip(value !== 0) }}
                placeholder={t("click_to_view_options")}
                disabled={isReadOnly}
              >
                <Option value={0}>{t('yes')}</Option>
                <Option value={1}>{t('no')}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={t("pet_chip_outland")}
              name="pet_chip_outland"
              dependencies={['ignore_pet_chip_outland']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (getFieldValue('ignore_pet_chip_outland') === 0 && !value) {
                      return Promise.reject(new Error(t('please_enter_chip')));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input disabled={isReadOnly || disableOutlandChip} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label={t("pet_birthday")} name="pet_birthday">
              <DatePicker
                style={{ width: "100%" }}
                format={petBirthdayDateFormat}
                disabled={isReadOnly}
                placeholder={t('select_date_option')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={8}>
            <Form.Item label={t("contact_person")} name="contact_person">
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item label={t("contact_person_mobile")} name="contact_person_mobile">
              <Input
                addonBefore={(
                  <Form.Item name="contactPersonMobilePrefix" noStyle>
                    <Select style={{ width: 80 }} disabled={isReadOnly}>
                      <Option value="+852">+852</Option>
                      <Option value="+86">+86</Option>
                    </Select>
                  </Form.Item>
                )}
                style={{ width: "100%" }}
                disabled={isReadOnly}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item label={t("owner_other_email")} name="owner_other_email">
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item label={t("remark")} name="remark">
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label={t("source")} name="source" hidden>
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
        </Row>

        {sm && ( // Web View
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isReadOnly} className="p_order_agreement_edit">
              {t("submit")}
            </Button>
          </Form.Item>
        )}

        {xs && ( // Mobile View
          <Affix offsetBottom={0}>
            <Form.Item>
              <Button type="primary" block htmlType="submit" disabled={isReadOnly} className="p_order_agreement_edit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Affix>
        )}

      </Form>
    </>
  );
};

export default OrderInfoBasicForm;
