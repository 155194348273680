import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  notification,
  Divider,
  InputNumber,
} from "antd";
import moment from "moment";
import * as Service from "../../core/Service";
import * as Main from "../../core/Main";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";

import { useTranslation } from "react-i18next";

import OrderInfoForm from "./OrderInfoForm";

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const OrderInfoFormContainer = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { order_id } = queryString.parse(location.search);
  const { t } = useTranslation();

  return (
    <NavAndSideFrame
      {...props}
      title={!_.isUndefined(order_id) ? t("modify_order") : t("add_new_order")}
      selectedKey="order_info"
    >
      <OrderInfoForm />
    </NavAndSideFrame>
  );
};

export default OrderInfoFormContainer;
