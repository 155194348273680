import React, { useState, useEffect } from "react";
import { notification, Divider, Form, Input, Button, Row, Col, Modal, Comment, List, Tooltip, Avatar, Space } from 'antd';
import _ from "lodash";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Service from '../../core/Service';
import { setUnreadNotification } from '../../redux/actions/common'

const { TextArea } = Input;

const Editor = ({
  onChange, onSubmit, submitting, value
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item>
        <TextArea rows={4} onChange={onChange} value={value} />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
          {t('submit')}
        </Button>
      </Form.Item>
    </>
  )
};

const OrderCommentForm = ({ initialValues, order_id }) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [comments, setComments] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const {
    owner_first_name,
    owner_last_name,
    owner_mobile,
    pet_name,
    contactPersonMobilePrefix,
  } = initialValues;

  useEffect(() => {
    getAllData();
    // Service.call('patch', `/api/comment/read?order_id=${order_id}`);
  }, []);

  const getAllData = async () => {
    try {
      let result = await Service.call('get', `/api/comment/list?order_id=${order_id}`);
      if (result.status !== 1) {
        notification.warning({ message: '獲取資料失敗' });
        return;
      }
      let { data } = result;
      let orderedDataList = _.orderBy(data, ['ctime'], ['asc']);
      setDataList(orderedDataList);

      console.log('orderedDataList', orderedDataList);

      dispatch(setUnreadNotification())
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  const handleSubmit = async () => {
    if (!value) return;
    setSubmitting(true);
    setTimeout(async () => {
      setSubmitting(false);
      setValue('');
      comments.comment = value
      comments.order_id = order_id
      let result = await Service.call('post', '/api/comment', comments)
      console.log('result >>>>>>>>>>', result)
      if (result.status !== 1) {
        return notification.error({ message: t("submission_failed"), description: result.errorMessage });
      }
      notification.success({ message: t("submitted_successfully") });
      getAllData();
    }, 1000);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <>
      <List
        className="comment-list"
        header={(
          <div>
            {`${dataList.length} replies`}
            <Space size={24} style={{marginLeft: 48}}>
              {`${t(
                "owner_full_name"
              )}: ${owner_first_name} ${owner_last_name}`}
              {`${t("owner_mobile")}: ${contactPersonMobilePrefix}-${owner_mobile}`}
              {`${t("pet_name")}: ${pet_name}`}
            </Space>
          </div>
        )}
        itemLayout="horizontal"
        dataSource={dataList}
        renderItem={(item) => {
          const {staff_id, ctime, comment, orderInfo, staffInfo } = item;
          const {owner_first_name, owner_last_name} = orderInfo;
          const {first_name, last_name } = staffInfo;
          let full_name = '';
          if (staff_id === 0) {
            full_name = `${owner_first_name} ${owner_last_name}`
          } else {
            full_name = `${first_name} ${last_name} (Staff)`
          }
          return (
            <li>
              <Comment
                author={full_name}
                avatar={(
                  <Avatar style={{ margin: '0 10px' }}>
                    {full_name
                      ? _.capitalize(full_name).charAt(0)
                      : ''}
                  </Avatar>
                )}
                content={comment}
                datetime={moment(ctime * 1000).fromNow()}
              />
              <Divider />
            </li>
          )
        }}
      />
      <Comment
        author={t("add_new_comment")}
        content={(
          <Editor
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitting={submitting}
            value={value}
          />
      )}
      />
      <Divider />
    </>
  );
};
export default OrderCommentForm;
