import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  Button, Divider, Form, Input,
  message, Modal, notification, Radio,
  Select, Spin, Switch, Table, Upload
} from 'antd';
import moment from 'moment';
import _ from 'lodash';
import debugLib from 'debug';
import * as Service from '../../core/Service';
import FormUploadFile from '../../components/form/FormUploadFile';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NavAndSideFrame from '../../components/NavAndSideFrame';

const SiteLogoForm = (props) => {
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)
  const history = useHistory()
  const { t } = useTranslation()

  let location = useLocation();
  const { meta_key, id } = queryString.parse(location.search);

  const { query, editMethod } = props;
  const [loading, setLoading] = useState(false)
  const [postImageURL, setFileURL] = useState(null);
  const [filePath, setFilePath] = useState(null);
  const [form] = Form.useForm();
  const [header, setHeader] = useState('logo')
  let subdir = ''
  switch (meta_key) {
    case 'ashes_space_floor_plan':
    case 'plant_space_floor_plan':
      subdir = 'floor_plan';
      break;
    default:
      break;
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    if (_.isUndefined(id)) {
      return
    }

    const resp = await Service.call('get', `/api/admin/meta?site_meta_id=${id}`)
    // console.log('resp', resp)
    const { data } = resp
    let [metaData] = data
    let {
      meta_value
    } = metaData
    console.log(metaData)
    if (!data) {
      return message.error('錯誤：不能讀取圖片資料')
    }

    setFileURL(`${STATIC_SERVER_URL}/${meta_value}`);
    setFilePath(meta_value)
    setHeader(metaData.meta_key)
    if (metaData.meta_key === 'site_logo') setHeader('logo')
  }

  const uploadOnChange = (info) => {
    // console.log('info', info)
    const { status, response } = info.file;
    if (status === 'done') {
      message.destroy()
      if (response.status > 0) {
        message.success('Uploaded file');
        let data = {
          filename: response.filename,
          filepath: response.filepath,
          url: response.url,
        };
        setFilePath(data.filepath);
        setFileURL(data.url);
      } else {
        message.error(t('common:Failed'))
      }
    }
  };

  const onFinished = async (obj) => {
    const postData = {
      meta_key,
      meta_value: filePath,
    };

    let method = 'post'
    if (!_.isUndefined(id)) {
      method = 'patch'
      postData.site_meta_id = id
    }

    // return console.log('postData', postData)

    const resp = await Service.call(method, '/api/admin/meta', postData)
    // console.log('resp', resp)
    const { status } = resp
    if (status < 0) {
      return message.error('錯誤：失敗')
    }
    notification.success({ message: '提交成功' });
    history.push('/company/setting');
  }

  return (
    <NavAndSideFrame {...props} title={t('setting')} selectedKey="setting">
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinished}
      >
        <div style={{ marginBottom: '20px' }}>
          <h4>{t(`${header}`)}</h4>
          <FormUploadFile
            data={{ scope: "public", subdir }}
            onChange={uploadOnChange}
            // onRemove={onRemove}
            imageURL={postImageURL}
          />
        </div>
        {
          loading
            ? (
              <div style={{ textAlign: 'center' }}><Spin /></div>
            )
            : (
              <Form.Item>
                <Button type="primary" htmlType="submit" className='p_setting_edit'>
                  {t('submit')}
                </Button>
              </Form.Item>
            )
        }
      </Form>
    </NavAndSideFrame>
  )
}

export default SiteLogoForm
