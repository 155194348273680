import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import _ from 'lodash';
import {
  Tabs, Spin, Button, Badge, Table, Modal, notification, Form, Input, Select, Tooltip, Space, Row, Col
} from 'antd';
import {
  EditOutlined, DeleteOutlined, PlusOutlined, FilePdfOutlined, ClearOutlined
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Fuse from 'fuse.js';

import { Document, Page, pdfjs } from 'react-pdf';
import debugLib from 'debug';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import * as Service from '../../core/Service';
import TinymceWrapper from '../../components/form/TinymceWrapper';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const debug = debugLib('app:pages/Setting');

let fuse = [];

const { confirm } = Modal;
const { TabPane } = Tabs;
const { Option } = Select;

const Setting = (props) => {
  const { t } = useTranslation()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    // Service.call('post', '/api/client/data', reqData)
  }

  return (
    <NavAndSideFrame {...props} title={t('setting')} selectedKey="setting">
      <Tabs type="card">
        <TabPane tab={t('logo')} key="logo">
          <TabComponent metaKey="site_logo" pathname="/company/site_logo_form" />
        </TabPane>
        <TabPane tab={t('company_info')} key="company_info">
          <TabComponent metaKey="company_info" />
        </TabPane>
        <TabPane tab={t("media")} key="media">
          <TabComponent metaKey="media" />
        </TabPane>
        <TabPane tab={t("ashes_space_floor_plan")} key="ashes_space_floor_plan">
          <TabComponent metaKey="ashes_space_floor_plan" pathname="/company/site_logo_form" />
        </TabPane>
        <TabPane tab={t("plant_space_floor_plan")} key="plant_space_floor_plan">
          <TabComponent metaKey="plant_space_floor_plan" pathname="/company/site_logo_form" />
        </TabPane>
        <TabPane tab={t('system')} key="system">
          <TabComponent metaKey="system" />
        </TabPane>
        <TabPane tab={t('tc')} key="tc">
          <TabComponent metaKey="tc" />
        </TabPane>
        <TabPane tab={t('pdf_template')} key="pdf_template">
          <TabComponent metaKey="pdf_template" />
        </TabPane>
        {/* <TabPane tab="橫額" key="banner">
          <TabComponent metaName="banner" t={t} metaKey="banner" pathname="/admin/banner" />
        </TabPane> */}
        {/* <TabPane tab="靜態內容" key="static">
          <TabComponent metaName="static" t={t} metaKey="static" pathname="/admin/static_content_form" />
        </TabPane> */}
      </Tabs>
    </NavAndSideFrame>
  )
}

const TabComponent = (props) => {
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)
  const history = useHistory();
  const { t } = useTranslation();

  const {
    metaKey, pathname
  } = props;

  const [loading, setLoading] = useState(true)
  const [dataSource, setDataSource] = useState([])
  const [showAddButton, setShowAddButton] = useState(true)
  const [recordId, setRecordId] = useState(0);
  const [selectedFormData, setSelectedFormData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [pdfPath, setPdfPath] = useState('')

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await Service.call('get', `/api/admin/meta_by_key?meta_key=${metaKey}`)
    // console.log('result', result)
    if (!_.isEmpty(result.data)) setShowAddButton(false);
    else setShowAddButton(true);

    const fuseOptions = {
      findAllMatches: true,
      ignoreLocation: true,
      includeScore: true,
      threshold: 0,
      keys: [
        'meta_key'
      ]
    };
    fuse = new Fuse(result.data, fuseOptions);

    setDataSource(result.data);
    setLoading(false)
  }

  const getColumns = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: 'site_meta_id',
        key: 'site_meta_id',
        render: (value, record, index) => {
          let { site_meta_id, meta_key, meta_value } = record;
          // console.log("value", meta_value)

          return (
            <Space direction='horizontal'>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  style={{ color: '#00AA00' }}
                  onClick={() => {
                    switch (meta_key) {
                      case 'site_logo': //  Media
                      case 'ashes_space_floor_plan':
                      case 'plant_space_floor_plan':
                        history.push(`${pathname}?meta_key=${metaKey}&id=${site_meta_id}`)
                        break;

                      default: //  Input Form
                        setRecordId(value)
                        setSelectedFormData(record)
                        setModalVisible(true)
                        break;
                    }
                  }}
                />
              </Tooltip>
              {/* <Button shape="circle" icon={<DeleteOutlined />} style={{color: '#AA0000' }} onClick={() => onDelete(site_meta_id, record)} /> */}

              {_.includes(['ashes_space_floor_plan', 'plant_space_floor_plan'], meta_key) && meta_value && (
                <Tooltip title="查看PDF">
                  <Button
                    shape="circle"
                    icon={<FilePdfOutlined />}
                    onClick={async () => {
                      setPdfModalVisible(true)
                      setPdfPath(`${STATIC_SERVER_URL}/${meta_value}`)
                    }}
                  />
                </Tooltip>
              )}

              {(meta_key.startsWith("pdf_template") || meta_key.startsWith("tc")) && (
                <Tooltip title={t('reset')}>
                  <Button
                    shape="circle"
                    icon={<ClearOutlined />}
                    onClick={async () => {
                      let values = {
                        site_meta_id: site_meta_id,
                        meta_key: meta_key,
                        meta_value: ""
                      }
                      let result = await Service.call('patch', '/api/admin/meta', values)
                      // console.log('result', result)
            
                      if (result.status !== 1) {
                        return notification.error({ message: '重設失敗', description: result.errorMessage });
                      }
            
                      notification.success({ message: '重設成功' });
                      setLoading(true)
                      await fetchData()
                    }}
                  />
                </Tooltip>
              )}
            </Space>
          )
        }
      },
      {
        title: t('key'),
        dataIndex: 'meta_key',
        key: 'meta_key',
        render: (value) => {
          return `${t(value)} (${value})`
        }
      },
      {
        title: t('value'),
        dataIndex: 'meta_value',
        key: 'meta_value',
        render: (value, record) => {
          let { meta_key } = record;
          if (meta_key.startsWith("tc_") || meta_key.startsWith("pdf_template_")) {
            return htmlToReactParser.parse(value);
          }

          return value;
        }
      },
    ]

    return columns;
  }

  const onDelete = (id, record) => {
    confirm({
      title: `確認剛除${record.meta_key}？`,
      okText: '是',
      okType: 'danger',
      cancelText: '否',
      onOk: () => {
        confirmDelete(id);
      },
      onCancel: () => {
        // console.log('Cancel');
      },
    });
  }

  const confirmDelete = async (site_meta_id) => {
    let result = await Service.call('delete', '/api/admin/meta', { site_meta_id });

    if (!result || result.status <= 0) {
      notification.error({
        message: '刪除失敗，請稍後再試',
        description: '',
      });
      return;
    }

    notification.success({
      message: '成功刪除記綠',
      description: '',
    });

    fetchData();
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const filterData = async (filterObj = {}) => {
    if (_.isEmpty(filterObj.searchWord)) { //  Return original data without search
      return setDataSource(fuse._docs)
    }

    const fuzzyResults = fuse.search(filterObj.searchWord).map((result) => result.item)
    setDataSource(fuzzyResults)
  }

  return (
    <Spin spinning={loading}>
      {showAddButton && (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            switch (metaKey) {
              // case 'company_info_name_en':
              // case 'company_info_name_zh_hant':
              // case 'company_info_address_en':
              // case 'company_info_address_zh_hant':
              // break;

              case 'site_logo':
                history.push(`${props.pathname}?meta_key=${metaKey}`)
                break;

              default:
                notification.info({ message: t('not_supported') });
                break;
            }
          }}
          style={{ marginBottom: 10 }}
        >
          {t('create')}
        </Button>
      )}

      <>
        {t('search')}
      </>
      <Input
        placeholder={t('please_input_keyword')}
        onChange={(event) => {
          filterData({ searchWord: event.target.value })
        }}
      />

      <Table rowKey={() => 'site_meta'} scroll={{ x: true }} dataSource={dataSource} columns={getColumns()} />

      <Modal
        title={recordId > 0 ? t('edit') : t('create')}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <SiteMetaForm
          recordId={recordId}
          selectedFormData={selectedFormData}
          closeMethod={async () => {
            fetchData();
            setModalVisible(false);
          }}
        />
      </Modal>

      <Modal
        visible={pdfModalVisible}
        footer={null}
        width="99%"
        destroyOnClose
        onCancel={() => {
          setPdfModalVisible(false);
        }}
      >
        <div>
          <Document
            file={pdfPath}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {_.times(numPages, (index) => { return index + 1 }).map((page) => (
              <Page pageNumber={page} width={1200}>
                Page
                {' '}
                {page}
                {' '}
                of
                {' '}
                {numPages}
              </Page>
            ))}
          </Document>
        </div>
      </Modal>
    </Spin>
  )
}

const SiteMetaForm = (props) => {
  const { t } = useTranslation();

  const { recordId, selectedFormData, closeMethod } = props

  const [contentValue, setContentValue] = useState(selectedFormData.meta_value);
  const [templateHTML, setTemplateHTML] = useState('');
  const [templateModalVisible, setTemplateModalVisible] = useState(false);

  const [form] = Form.useForm();

  const determineInputType = (meta_key) => {
    if (meta_key.startsWith("pdf_template") || meta_key.startsWith("tc_")) {
      return (
        <Space direction="vertical">
          {meta_key.startsWith("pdf_template") && (
            <Button
              onClick={async() => {
                const result = await Service.call('get', `/api/order_info/pdf/template/${meta_key}`);
                console.log(`result >> `, result);
                const { data } = result;
                setTemplateHTML(data);
                setTemplateModalVisible(true);
                // const tab = window.open('about:blank', '_blank');
                // tab.document.write(data); // where 'html' is a variable containing your HTML
                // tab.document.close(); // to finish loading the page
              }}
            >
              View default template
            </Button>
          )}
          <TinymceWrapper
            initialValue={contentValue}
            onChange={setContentValue}
          />
        </Space>
      )
    }

    // if (meta_key.startsWith("tc_")) {
    //   return (
    //     <ReactQuill
    //       // readOnly
    //       // theme={"bubble"}
    //       theme="snow"
    //       value={contentValue}
    //       onChange={setContentValue}
    //       modules={{
    //         toolbar: {
    //           container: [
    //             [{ header: [1, 2, false] }],
    //             ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    //             [
    //               { align: 'center' },
    //               { list: 'ordered' },
    //               { list: 'bullet' },
    //               { indent: '-1' },
    //               { indent: '+1' },
    //             ],
    //             ['link'],
    //             ['clean'],
    //           ],
    //         },
    //       }}
    //     />
    //   )
    // }

    if (meta_key === "company_info_phone") {
      return (<Input placeholder="+852-12345678" />)
    }

    if (meta_key === "system_app_order_procedure") {
      let localContentValue = JSON.parse(contentValue);
      return _.map(localContentValue, (defaultValue, key) => {
        return (
          <Row gutter={24} justify="space-around">
            <Col span={12}>{t(key)}</Col>
            <Col span={4}>
              <Select
                defaultValue={defaultValue}
                onChange={(value) => {
                  localContentValue = {...localContentValue, [key]: value};
                  localContentValue = JSON.stringify(localContentValue)
                  setContentValue(localContentValue);
                }}
                key={key}
              >
                <Option value={1}>{t('yes')}</Option>
                <Option value={0}>{t('no')}</Option>
              </Select>
            </Col>
          </Row>
        )
      })
    }

    return (<Input />)
  }

  return (
    <>
      <Form
        form={form}
        initialValues={selectedFormData}
        onFinish={async (values) => {
          let method = 'post'
          if (recordId !== 0) { //  Edit
            method = 'patch'
            values.site_meta_id = recordId
          }

          if (values.meta_key.startsWith("tc_") || values.meta_key.startsWith("pdf_template") || values.meta_key === "system_app_order_procedure") { // Get value from editor
            values.meta_value = contentValue
          }

          if (!_.includes(['company_info_bank_name_2_en', 'company_info_bank_name_2_zh_hant', 'company_info_bank_account_2'], selectedFormData.meta_key)) {
            if (!values.meta_value) {
              return notification.error({ message: '提交失敗', description: '內容不可空白' });
            }
          }

          let result = await Service.call(method, '/api/admin/meta', values)
          // console.log('result', result)

          if (result.status !== 1) {
            return notification.error({ message: '提交失敗', description: result.errorMessage });
          }

          notification.success({ message: '提交成功' });
          closeMethod()
        }}
      >
        <Form.Item
          label={t('key')}
          name="meta_key"
          rules={[{
            required: false
          }]}
        >
          <Select disabled={recordId}>
            <Option value="company_info_name_zh_hant">{`${t('company_name')} (${t('zh_hant')})`}</Option>
            <Option value="company_info_name_en">{`${t('company_name')} (${t('en')})`}</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={t('value')}
          name={_.includes(selectedFormData.meta_key, 'pdf_template') ? undefined : "meta_value"} // Pass undefined for tinymce fixed cursor problem when type the last char
        >
          {determineInputType(selectedFormData.meta_key)}
        </Form.Item>

        <Button type="primary" htmlType="submit" className='p_setting_edit'>
          {t('submit')}
        </Button>
      </Form>

      <Modal
        title={null}
        visible={templateModalVisible}
        footer={null}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setTemplateModalVisible(false);
        }}
      >
        {templateHTML}
      </Modal>
    </>
  )
}

export default Setting;
