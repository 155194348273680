import React, { useState, useEffect } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { Select } from 'antd';
import _ from 'lodash';
import LanguageList from '../data/LanguageList.json';

const { Option } = Select;

const LanguageSelector = (props) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Select
      style={{ width: 150 }}
      value={i18n.language}
      onChange={(value) => {
        changeLanguage(value)
      }}
    >
      {_.map(LanguageList, (val, key) => {
        return (
          <Option value={key} key={key}>{val}</Option>
        )
      })}
    </Select>
  )
}

export default LanguageSelector;
