import React from "react";
import { Button, Tooltip } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import _ from "lodash";
import { saveAs } from "file-saver";
import * as Service from "../core/Service";

const DownloadPdfButton = ({
  title,
  className = "",
  apiPath,
  preferred_language,
  type,
  order_no_with_prefix,
  invoice_or_receipt_no_with_prefix = "",
  buttonStyle = {},
}) => {
  const pdfPrefix = () => {
    let language = !_.isEmpty(preferred_language)
      ? preferred_language
      : "zh_hant";
    switch (type) {
      case "invoice":
        return language === "zh_hant" ? "發票" : "Invoice";
      case "receipt":
        return language === "zh_hant" ? "收據" : "Receipt";
      case "agreement":
        return language === "zh_hant" ? "授權書" : "Agreement";
      case "service_form":
        return language === "zh_hant" ? "服務細節表" : "ServiceForm";
      case "ashes_rental":
        return language === "zh_hant" ? "骨灰靈位租用" : "AshesRental";
      case "plant_rental":
        return language === "zh_hant" ? "種植靈位租用" : "PlantRental";
      case "hydration_certificate":
        return language === "zh_hant" ? "水化證書" : "HydrationCertificate"
      default:
        return type;
    }
  };
  return (
    <Tooltip title={title}>
      <Button
        style={buttonStyle}
        shape="circle"
        className={className}
        icon={<FilePdfOutlined />}
        onClick={async () => {
          let result = await Service.call("get", apiPath, {}, {responseType: "blob"});
          // console.log('result', result)
          if (!result) return;
          const pdfBlob = new Blob([result], { type: "application/pdf" });

          let filename = [];
          filename.push(pdfPrefix());
          filename.push(`${order_no_with_prefix}`);
          if (invoice_or_receipt_no_with_prefix) {
            filename.push(`-${invoice_or_receipt_no_with_prefix}`);
          }
          saveAs(pdfBlob, _.join(filename, ""));
        }}
      />
    </Tooltip>
  );
};

export default DownloadPdfButton;
