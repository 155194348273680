import React, { useState, useEffect } from 'react';
import {
  Button, Layout, Table, Tooltip, Modal, notification, Select, Badge, Space
} from 'antd';
import {
  DeleteOutlined, EditOutlined, FileAddOutlined, FilePdfOutlined, CloseOutlined, CheckOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import _ from 'lodash';
import * as Service from '../../core/Service';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import * as Main from "../../core/Main";
import HydrationMachinePlaceForm from './HydrationMachinePlaceForm'

const { confirm } = Modal;
const { Option } = Select

const HydrationMachinePlaceTable = (props) => {
  const { dataObj, fetchData, hydration_batch_id } = props
  const [dataList, setDataList] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const tableIDName = "hydration_machine_lookup_id";
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    console.log(dataObj);
    setDataList(dataObj)
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t('ctime'),
        dataIndex: `ctime`,
        render: (value) => {
          return Main.displayMoment(value);
        }
      },
      {
        title: t('utime'),
        dataIndex: `utime`,
        render: (value) => {
          return Main.displayMoment(value);
        }
      },
      {
        title: t('hydration_machine_name'),
        dataIndex: `hydration_machine_name`,
      },
      {
        title: t('hydration_machine_place_name'),
        dataIndex: `hydration_machine_space_name`,
      },
      {
        title: t('order_no'),
        dataIndex: `order_no_with_prefix`,
      },
      {
        title: t('owner_mobile'),
        dataIndex: `owner_mobile`,
      },
      {
        title: t('pet_name'),
        dataIndex: `pet_name`,
      },
    ];
    return columns;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
      />
    </Layout>
  )
}

export default HydrationMachinePlaceTable;
