import React, {
  Component, useState, useEffect, useRef
} from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import debugLib from 'debug';
import moment from 'moment';
import {
  setConfig, setAdmin, setAuth, setCompanyAdmin, setCompanyUser, setAllMeta, setUnreadNotification
} from './redux/actions/common'
import * as Service from './core/Service';
import AppRoute from './AppRoute';
// import * as Service from './core/Service';
import i18n from './i18n';
import SyncSocket from './lib-client/sync-socket';

let configOrigin = '';

message.config({
  top: '75%',
  maxCount: 1,
});

const AppContainer = () => {
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const app = useSelector((state) => state.app);
  const auth = useSelector((state) => state.app.auth);
  const prevAuth = usePrevious(auth);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  // const [expires, setExpires] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (!app.config.WS_SERVER_URL) return;
    initSocket();
  }, [app.config.WS_SERVER_URL])

  const initSocket = async () => {
    console.log(`initSocket`)
    // const SyncSocket = require('../lib-client/sync-socket')
    SyncSocket.start(app.config.WS_SERVER_URL, {
      onMessage: (data) => {
        console.log(`SyncSocket :: data >> ${data}`);
        try {
          const command = JSON.parse(data);
          // commandModel.exec(command.action, command.data);
          switch (command.action) {
            case 'fetch_notification_data':
              // console.log("received>>>")
              dispatch(setUnreadNotification())
              break;
            default:
              break;
          }
        } catch (err) {
          console.error(err);
        }
      },
      onTimeout: () => {
        console.log(`SyncSocket timeout`);
      },
      // headers: {
      //   token: '123',
      // }
    });
  }

  // useEffect(() => {
  //   if (!expires) {
  //     return;
  //   }

  //   setSessionTimeout();
  // }, [expires])

  useEffect(() => {
    if (prevAuth === true && auth === false) { // Session expiry
      setModalVisible(true);
    }
  }, [auth])

  const init = async () => {
    // setLoading(true);
    let allPermission = [];
    let resp = await Service.call('get', '/api/config');

    if (resp && resp.status > 0) {
      dispatch(setConfig(resp));
      allPermission = resp.allPermission;
    } else {
      throw new Error('failed to get app config');
    }

    const { DEBUG_MODE, DEBUG } = resp
    if (DEBUG_MODE) {
      debugLib.enable(DEBUG);
    } else {
      debugLib.disable();
    }

    resp = await Service.call('get', "/api/admin/allmeta");
    if (resp && resp.status > 0) {
      let result = _.keyBy(resp.meta, "meta_key")
      dispatch(setAllMeta(result));
    }

    resp = await Service.call('get', `/api/admin`);
    if (resp && resp.status > 0) {
      dispatch(setAdmin(resp.userData[0]));
      dispatch(setAuth(true));
      setLoading(false);
      return;
    }

    resp = await Service.call('get', `/api/login/get_company_admin`);
    if (resp && resp.status > 0) {
      dispatch(setCompanyAdmin(resp.userData));
      dispatch(setAuth(true));
      setLoading(false);
      return;
    }

    resp = await Service.call('get', `/api/login/get_company_user`);
    if (resp && resp.status > 0) {
      // const { expires: userExpiresTime } = resp;
      // setExpires(userExpiresTime);

      let formattedData = resp.userData;

      // Set Permission By CSS
      let myPermission = resp.permission.split(",")
      formattedData = {...formattedData, myPermission}
      if (!_.includes(myPermission, 'p_any')) {
        const missingPermission = _.difference(allPermission, myPermission);

        const el = document.getElementsByTagName('body')[0];
        _.each(missingPermission, (perm) => {
          el.classList.add(`${perm}_none`);
        });
      }

      dispatch(setCompanyUser(formattedData));
      dispatch(setAuth(true));
      dispatch(setUnreadNotification())
      setLoading(false);
      return;
    }

    dispatch(setAuth(false));
    setLoading(false);
  }

  // const setSessionTimeout = async() => {
  //   const countDownSecond = moment(expires).unix() - (Date.now() / 1000);
  //   setTimeout(function() {
  //     setModalVisible(true);
  //   }, countDownSecond * 1000);
  // }

  // async componentDidMount() {
  //   let allPermission = [];
  //   console.log('originObj>>>', originObj)
  //   if (originObj) {
  //     configOrigin = originObj.origin;
  //     allPermission = originObj.allPermission;
  //     // allPermission = originObj.allPermission;
  //   }
  //   getStore().dispatch(ActionCreators.setOrigin(configOrigin));

  //   let resp = await Service.call('get', '/api/admin');
  //   // console.log(`resp >> `, resp);

  //   if (!resp || resp.status <= 0) {
  //     // invalid session
  //   } else {
  //     this.props.setAdmin(resp);
  //     this.props.setAuth(true);
  //   }

  //   // Set Permission By CSS
  //   let myPermssion = []
  //   myPermssion = resp.permission.split(",")
  //   // console.log('myPermssion>>>', resp.permission)
  //   // const myPermssion = ['p_news', 'p_admin'];
  //   if (!_.includes(myPermssion, 'p_superadmin')) {
  //     const missingPermission = _.difference(allPermission, myPermssion);
  //     console.log('missingPermission>>>', missingPermission)
  //     const el = document.getElementsByTagName('body')[0];

  //     _.each(missingPermission, (perm) => {
  //       el.classList.add(`${perm}_none`);
  //     });
  //   }

  //   this.setState({loading: false});
  // }

  if (loading) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
        <Spin indicator={antIcon} />
      </div>
    );
  }

  return (
    <div>
      <AppRoute />
      <Modal
        title=""
        visible={modalVisible}
        onOk={() => {
          window.location.replace('/company/login');
        }}
        onCancel={() => setModalVisible(false)}
      >
        <p>{i18n.t('session_expired_please_login_again')}</p>
      </Modal>
    </div>
  );
}

export default AppContainer;
