import React, { useState, useEffect } from 'react';
import {
  notification, Tabs, Table, Badge, Button, Modal, Form, Input, Radio, DatePicker, Row, Col, Select
} from 'antd';
import {
  DeleteOutlined, EditOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import _ from 'lodash';
import moment from 'moment';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import * as Service from '../../core/Service';
import * as Main from "../../core/Main";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import debugLib from 'debug';
import DayEndReport from './DayEndReport';
import StockBalanceReportWithCost from './StockBalanceReportWithCost';

const debug = debugLib('app:pages/Report');

const { TabPane } = Tabs;
const { Option } = Select;

const Report = (props) => {
  const { t } = useTranslation();
  const reportOptions = [
    'sales_report',
    'raw_table_report',
    'souvenir_report',
    'souvenir_revenue_report',
  ];

  return (
    <NavAndSideFrame {...props} title={t('sales_report')} selectedKey="report">
      <Tabs type="card" defaultActiveKey="sales_report" onChange={(key) => null}>
        {reportOptions.map((item) => (
          <TabPane tab={t(item)} key={item}>
            <TabComponent type={item} />
          </TabPane>
        ))}
        <TabPane tab={t('day_end_report')} key="day_end_report">
          <DayEndReport type="day_end_report" />
        </TabPane>
        <TabPane tab={t('stock_balance_report_with_cost')} key="stock_balance_report_with_cost">
          <StockBalanceReportWithCost type="stock_balance_report_with_cost" />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  )
}

const TabComponent = (props) => {
  const { type } = props
  const { t, i18n } = useTranslation()
  const app = useSelector((state) => state.app);
  const myPermission = useSelector((state) => state.app.company_user.myPermission);

  const [salesReportForm] = Form.useForm();
  const [rawTableReportForm] = Form.useForm();
  const [souvenirReportForm] = Form.useForm();
  const [souvenirRevenueReportForm] = Form.useForm();
  const [dataList, setDataList] = useState([])
  const [isAllowSouvenirReport, setIsAllowSouvenirReport] = useState(false);

  const columns = [
    {
      title: `${t('time')}`,
      dataIndex: 'time',
      render: (value) => {
        return Main.displayMoment(value);
      }
    },
    {
      title: `${t('year')}`,
      dataIndex: 'year',
    },
    {
      title: `${t('month')}`,
      dataIndex: 'month',
    },
    {
      title: `${t('day')}`,
      dataIndex: 'day',
    },
    {
      title: `${t('sales_total')}`,
      dataIndex: 'sales_total',
    },
    {
      title: `${t('order_no_total')}`,
      dataIndex: 'order_no_total',
    },
    {
      title: `${t('average_amount')}`,
      dataIndex: 'average_amount',
    },
  ]

  useEffect(() => {
    getAllData();
    setAllowReportGeneration();
  }, []);

  const setAllowReportGeneration = () => {
    let isAllowPermission = _.intersection(myPermission, ['p_report_price_view', 'p_any'])
    if (!_.isEmpty(isAllowPermission)) {
      setIsAllowSouvenirReport(true);
    }
  }

  const getAllData = async () => {
    try {
      let result = await Service.call('get', `/api/report/sales_report`);
      console.log('result', result);

      if (result.status !== 1) {
        notification.warning({ message: '獲取資料失敗' });
        return;
      }

      let { data } = result;
      setDataList(data);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  // ger redux site meta value
  const meta = useSelector((state) => state.app.meta);

  const dateFormat = `${
    !_.isEmpty(meta.system_date_format.meta_value) ? meta.system_date_format.meta_value : 'YYYY/MM/DD'
  }`

  return (
    <>
      {/* Conditions */}
      { type === 'sales_report' && (
      <div className="p_sales_report_view">
        <Form
          form={salesReportForm}
          onFinish={async (values) => {
            const { start_date, end_date, group } = values
            let start_date_unix = moment(start_date).startOf('day').unix()
            let end_date_unix = moment(end_date).endOf('day').unix()
            window.location = `${app.config.API_SERVER_URL}/api/report/sales_report/byDate?start_date_unix=${start_date_unix}&end_date_unix=${end_date_unix}&language=${i18n.language}&group=${group}`;
          }}
        >

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label={t('start_date')}
                name="start_date"
                rules={[{ required: true }]}
              >
                <DatePicker
                  format={dateFormat}
                  disabledDate={(current) => (current && current >= moment().startOf('day'))}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={t('end_date')}
                name="end_date"
                rules={[{ required: true }]}
              >
                <DatePicker
                  format={dateFormat}
                  disabledDate={(current) => (current && current >= moment().startOf('day'))}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={t('type')}
                name="group"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={t('please_select')}
                >
                  <Option value="year">{t('year')}</Option>
                  <Option value="month">{t('month')}</Option>
                  <Option value="day">{t('day')}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              {t('export')}
            </Button>
          </Form.Item>
        </Form>
      </div>
      )}

      { type === 'raw_table_report' && (
      <div className="p_sales_report_view">
        <Form
          form={rawTableReportForm}
          onFinish={async (values) => {
            const { start_date, end_date, group } = values
            let start_date_unix = moment(start_date).startOf('day').unix()
            let end_date_unix = moment(end_date).endOf('day').unix()
            let win = window.open(`${app.config.API_SERVER_URL}/api/report/raw_table_report/byDate?start_date_unix=${start_date_unix}&end_date_unix=${end_date_unix}&language=${i18n.language}&group=${group}`, '_blank');
            win.focus();
          }}
        >

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label={t('start_date')}
                name="start_date"
                rules={[{ required: true }]}
              >
                <DatePicker
                  format={dateFormat}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={t('end_date')}
                name="end_date"
                rules={[{ required: true }]}
              >
                <DatePicker
                  format={dateFormat}
                />
              </Form.Item>
            </Col>

          </Row>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              {t('export')}
            </Button>
          </Form.Item>
        </Form>
      </div>
      )}
      { type === 'souvenir_report' && (
      <div className="p_report_edit">
        <Form
          form={souvenirReportForm}
          onFinish={async (values) => {
            const { start_date, end_date, group } = values
            let start_date_unix = moment(start_date).startOf('day').unix()
            let end_date_unix = moment(end_date).endOf('day').unix()
            let win = window.open(`${app.config.API_SERVER_URL}/api/report/souvenir_report/byDate?start_date_unix=${start_date_unix}&end_date_unix=${end_date_unix}&language=${i18n.language}&group=${group}&type=quantity`, '_blank');
            win.focus();
          }}
        >

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label={t('start_date')}
                name="start_date"
                rules={[{ required: true }]}
              >
                <DatePicker
                  format={dateFormat}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={t('end_date')}
                name="end_date"
                rules={[{ required: true }]}
              >
                <DatePicker
                  format={dateFormat}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={t('type')}
                name="group"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={t('please_select')}
                >
                  <Option value="year">{t('year')}</Option>
                  <Option value="month">{t('month')}</Option>
                  <Option value="day">{t('day')}</Option>
                </Select>
              </Form.Item>
            </Col>

          </Row>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              {t('export')}
            </Button>
          </Form.Item>
        </Form>
      </div>
      )}
      { type === 'souvenir_revenue_report' && (
      <div className="p_sales_report_view">
        <Form
          form={souvenirRevenueReportForm}
          onFinish={async (values) => {
            // console.log("isAllow", isAllowSouvenirReport)
            if (!isAllowSouvenirReport) {
              return notification.error({ message: `${t('permission_denied')} [${t('p_report_price_view')}]` });
            }
            const { start_date, end_date, group } = values
            let start_date_unix = moment(start_date).startOf('day').unix()
            let end_date_unix = moment(end_date).endOf('day').unix()
            let win = window.open(`${app.config.API_SERVER_URL}/api/report/souvenir_report/byDate?start_date_unix=${start_date_unix}&end_date_unix=${end_date_unix}&language=${i18n.language}&group=${group}&type=revenue`, '_blank');
            win.focus();
          }}
        >

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label={t('start_date')}
                name="start_date"
                rules={[{ required: true }]}
              >
                <DatePicker
                  format={dateFormat}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={t('end_date')}
                name="end_date"
                rules={[{ required: true }]}
              >
                <DatePicker
                  format={dateFormat}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={t('type')}
                name="group"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={t('please_select')}
                >
                  <Option value="year">{t('year')}</Option>
                  <Option value="month">{t('month')}</Option>
                  <Option value="day">{t('day')}</Option>
                </Select>
              </Form.Item>
            </Col>

          </Row>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              {t('export')}
            </Button>
          </Form.Item>
        </Form>
      </div>
      )}

      {/* Debug */}
      {/* <Table
        columns={columns}
        dataSource={dataList}
        rowKey={(record) => record.sales_report_id}
      /> */}
    </>
  )
}

export default Report;
