import React from "react";
import { Button, Tooltip, notification } from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { useTranslation } from "react-i18next";
import * as Service from "../core/Service";

const CreatePdfButton = ({
  title,
  params,
  className = "",
  apiPath,
  type = "",
  fetchData,
  buttonStyle = {},
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={title}>
      <Button
        style={buttonStyle}
        shape="circle"
        className={className}
        icon={<FileAddOutlined />}
        onClick={async () => {
          let result = await Service.call("post", apiPath, params);
          // console.log('result', result)
          const {status, errorMessage} = result;
          if (status !== 1) {
            if (errorMessage) {
              notification.error({ message: t(errorMessage) });
            }
            return;
          }
          notification.success({ message: t("success") });
          if (
            result.data.alert === 1 &&
            (type === "invoice" || type === "receipt")
          ) {
            notification.warn({ message: "提醒！該用戶已超過信貸金額！" });
          }
          fetchData();
        }}
      />
    </Tooltip>
  );
};

export default CreatePdfButton;
