import React, { Component, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Divider,
  Form,
  Icon,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CompanyUserForm from "./CompanyUserForm";
import CanpanyUserPasswordForm from "./CompanyUserPasswordForm";

const CompanyUserTable = (props) => {
  const { t } = useTranslation()
  const involvedModelName = "company";
  const title = "Company User";
  const selectedKey = 3;
  const tableIDName = "company_user_id";
  const admin = useSelector((state) => state.app.admin);
  const { pageNumber, setPageNumber, pageSize, pageTotal, loading } = props;

  const [dataList, setDataList] = useState(props.dataObj);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordRecord, setPasswordRecord] = useState({});
  const adminPath = [
    `/admin/user/access_log`,
    `/admin/user/door`,
    `/admin/user/door_qrcode`,
  ];
  const companyAdminPath = [
    `/company/companyuser/accesslog`,
    `/company/company/user/door`,
    ``,
  ];

  useEffect(() => {
    setDataList(props.dataObj);
  }, [props.dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record) => {
          // console.log("Record>>>>>", record);
          let status = record.status === 1;
          let color = "#000000";
          let icon = "";
          let wordings = "";
          if (status) {
            color = "#AA0000";
            icon = "stop";
            wordings = "Disable";
          } else {
            color = "#00AA00";
            icon = "check";
            wordings = "Enable";
          }
          return (
            <span>
              <Tooltip title="Edit">
                <Button
                  shape="circle"
                  style={{ marginRight: 8, color: "#000000" }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record);
                  }}
                />
              </Tooltip>
              {/* <Tooltip title="Password">
                                <Button
                                    shape="circle"
                                    style={{ marginRight: 8, color: '#000000' }}
                                    icon={<KeyOutlined />}
                                    onClick={() => {
                                        setPasswordVisible(true);
                                        setPasswordRecord(record);
                                    }}
                                />
                            </Tooltip> */}
              {/* <Link to={{
                                pathname: admin.admin_id != null ? adminPath[0]: companyAdminPath[0],
                                state: {
                                    company_user_id: record.company_user_id
                                }
                            }}>
                                <Tooltip title="Access Log">
                                    <Button
                                        style={{ marginRight: 8, color: '#000000' }}
                                        shape="circle"
                                        icon={<FileTextOutlined />}
                                    />
                                </Tooltip>
                            </Link> */}
              {/* <Link to={{
                                pathname: admin.admin_id != null ? adminPath[1]:companyAdminPath[1],
                                state: {
                                    company_user_id: record.company_user_id,
                                    company_id: record.company_id,
                                    company_name: record.company_name
                                }
                            }}>
                                <Tooltip title="User Doors">
                                    <Button
                                        style={{ marginRight: 8, color: '#000000' }}
                                        shape="circle"
                                        icon={<BankOutlined />}
                                    />
                                </Tooltip>
                            </Link> */}
              {/* <Link to={{
                                pathname: '/admin/user/door_qrcode',
                                state: {
                                    company_user_id: record.company_user_id,
                                    company_name: record.company_name
                                }
                            }}>
                                <Tooltip title="User QR Codes">
                                    <Button
                                        style={{ color: '#000000' }}
                                        shape="circle"
                                        icon={<QrcodeOutlined />}
                                    />
                                </Tooltip>
                            </Link> */}
            </span>
          );
        },
      },
      {
        title: t('company_user_id'),
        dataIndex: `company_user_id`,
        sorter: (a, b) => a.company_user_id - b.company_user_id,
      },
      {
        title: t('activity'),
        dataIndex: "is_active",
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active,
      },
      // {title: 'Username', dataIndex: 'username', align: 'center'},
      // {title: 'Password', dataIndex: 'password'},
      {
        title: t('first_name'),
        dataIndex: "first_name",
        sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      },
      {
        title: t('last_name'),
        dataIndex: "last_name",
        sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      },
      // {
      //     title: 'Company',
      //     dataIndex: `company_name`,
      //     sorter: (a, b) => a.company_name.localeCompare(b.company_name)
      // },
      // {title: 'Nickname', dataIndex: 'nickname'},
      // {
      //     title: 'Role',
      //     dataIndex: 'company_user_role',
      //     sorter: (a, b) => a.company_user_role.localeCompare(b.company_user_role)
      // },
      // {
      //     title: 'Admin Permission',
      //     dataIndex: 'is_admin',
      //     render: (value) => displayStatus(value),
      //     sorter: (a, b) => a.is_admin - b.is_admin,
      //     align: 'center'
      // },
      // {
      //     title: 'QR Ccode Permission',
      //     dataIndex: 'privilege_scan_qrcode',
      //     render: (value) => displayStatus(value),
      //     sorter: (a, b) => a.privilege_scan_qrcode - b.privilege_scan_qrcode,
      //     align: 'center'
      // },
      {
        title: t('gender'),
        dataIndex: "gender",
        sorter: (a, b) => a.gender.localeCompare(b.gender),
        align: "center",
      },
      {
        title: t('mobile'),
        dataIndex: "mobile",
        sorter: (a, b) => a.mobile.localeCompare(b.mobile),
      },
      // {
      //     title: 'Mobile Verify',
      //     dataIndex: 'is_verified_mobile',
      //     render: (value) => displayStatus(value),
      //     sorter: (a, b) => a.is_verified_mobile - b.is_verified_mobile,
      //     align: 'center'
      // },
      {
        title: t('email'),
        dataIndex: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      // {
      //     title: 'Email Verify',
      //     dataIndex: 'is_verified_email',
      //     render: (value) => displayStatus(value),
      //     sorter: (a, b) => a.is_verified_email - b.is_verified_email,
      //     align: 'center'
      // },
      // {title: 'Is active', dataIndex: 'is_active', render: (value) => displayStatus(value)},
    ];
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "Active";
        tagColor = "green";
        break;
      case 0:
        displayStr = "Inactive";
        tagColor = "red";
        break;
      default:
        displayStr = "Error";
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const displayStatus = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "Yes";
        tagColor = "green";
        break;
      case 0:
        displayStr = "No";
        tagColor = "red";
        break;
      case -1:
        displayStr = "Disable";
        tagColor = "#9A9A9A";
        break;
      default:
        displayStr = "Error";
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList}
        columns={setTableHeader()}
        pagination={{
          current: pageNumber,
          onChange: setPageNumber,
          pageSize,
          total: pageTotal,
        }}
        loading={loading}
      />
      <Modal
        title={t('modify')}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <CompanyUserForm
          dataObj={selectedRecord}
          openModal={(value) => {
            // getAllData();
            setModalVisible(value);
            if (admin.admin_id != null) props.adminChangeData();
            else props.companyChangeData();
          }}
        />
      </Modal>
      <Modal
        title="Edit"
        visible={passwordVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        onCancel={() => {
          setPasswordVisible(false);
        }}
      >
        <CanpanyUserPasswordForm
          dataObj={passwordRecord}
          openModal={(value) => {
            // getAllData();
            setPasswordVisible(value);
            if (admin.admin_id != null) props.adminChangeData();
            else props.companyChangeData();
          }}
        />
      </Modal>
    </Layout>
  );
};

export default CompanyUserTable;
