import _ from 'lodash';
import moment from 'moment';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import { notification } from 'antd';

import i18n from "i18next"
import * as Service from './Service'
import { getStore } from '../redux/store/configureStore';
import { ActionCreators } from '../redux/actions';

import LanguageList from '../data/LanguageList.json'
const debug = require('debug')('app:admin:core:Main');

// export function getUser() {
//   return getStore().getState().app.user;
// }

// export function getCompany() {
//   return getStore().getState().app.company;
// }

// export function signOut() {
//   localStorage.setItem('user');
//   localStorage.setItem('auth', JSON.stringify(false));
//   localStorage.removeItem('company');
// }

export function mergeByKey(arr, subArr, key) {
  return _.each(arr, (item) => {
    _.find(subArr, (subItem) => {
      return item[key] === subItem[key] ? _.assign(item, subItem) : null;
    })
  })
}

export function convertOrderInfoDisplayData(orderInfoObject) {
  //  Basic Info
  let [prefixMobile, owner_mobile] = _.split(orderInfoObject.owner_mobile, '-');
  if (!_.isEmpty(prefixMobile)) orderInfoObject.prefixMobile = prefixMobile;
  if (!_.isEmpty(owner_mobile)) orderInfoObject.owner_mobile = owner_mobile;
  if (_.isInteger(orderInfoObject.pick_up_time)) orderInfoObject.pick_up_time = moment.unix(orderInfoObject.pick_up_time);
  if (_.isInteger(orderInfoObject.estimate_pick_up_time)) orderInfoObject.estimate_pick_up_time = moment.unix(orderInfoObject.estimate_pick_up_time);
  if (_.isInteger(orderInfoObject.estimate_pick_up_end_time)) orderInfoObject.estimate_pick_up_end_time = moment.unix(orderInfoObject.estimate_pick_up_end_time);

  //  Backup Info
  if (!_.isEmpty(orderInfoObject.pet_birthday)) orderInfoObject.pet_birthday = moment(orderInfoObject.pet_birthday);
  let [contactPersonMobilePrefix, contact_person_mobile] = _.split(orderInfoObject.contact_person_mobile, '-');
  if (!_.isEmpty(contactPersonMobilePrefix)) orderInfoObject.contactPersonMobilePrefix = contactPersonMobilePrefix;
  if (!_.isEmpty(contactPersonMobilePrefix)) orderInfoObject.contact_person_mobile = contact_person_mobile;

  //  Deposit
  if (_.isInteger(orderInfoObject.payment_date)) orderInfoObject.payment_date = moment.unix(orderInfoObject.payment_date);

  //  Service Detail
  if (_.isInteger(orderInfoObject.meet_up_time)) orderInfoObject.meet_up_time = moment.unix(orderInfoObject.meet_up_time);
  if (_.isInteger(orderInfoObject.ceremony_time)) orderInfoObject.ceremony_time = moment.unix(orderInfoObject.ceremony_time);
  if (_.isInteger(orderInfoObject.ceremony_end_time)) orderInfoObject.ceremony_end_time = moment.unix(orderInfoObject.ceremony_end_time);
  if (_.isInteger(orderInfoObject.ashes_pick_up_time)) orderInfoObject.ashes_pick_up_time = moment.unix(orderInfoObject.ashes_pick_up_time);
  if (_.isInteger(orderInfoObject.confirmed_ashes_pick_up_time)) orderInfoObject.confirmed_ashes_pick_up_time = moment.unix(orderInfoObject.confirmed_ashes_pick_up_time);
  if (_.isInteger(orderInfoObject.plant_space_meet_up_time)) orderInfoObject.plant_space_meet_up_time = moment.unix(orderInfoObject.plant_space_meet_up_time);
  if (_.isInteger(orderInfoObject.ashes_space_meet_up_time)) orderInfoObject.ashes_space_meet_up_time = moment.unix(orderInfoObject.ashes_space_meet_up_time);
  if (!_.isEmpty(orderInfoObject.ceremony_hair_treatment)) {
    orderInfoObject.ceremony_hair_treatment = JSON.parse(orderInfoObject.ceremony_hair_treatment);
  } else {
    orderInfoObject.ceremony_hair_treatment = []
  }
  if (!_.isEmpty(orderInfoObject.ceremony_hair_treatment_id)) {
    orderInfoObject.ceremony_hair_treatment_id = JSON.parse(orderInfoObject.ceremony_hair_treatment_id);
  } else {
    orderInfoObject.ceremony_hair_treatment_id = []
  }

  //  Progress
  if (_.isInteger(orderInfoObject.progress_in_call_completed_date)) orderInfoObject.progress_in_call_completed_date = moment.unix(orderInfoObject.progress_in_call_completed_date);
  if (_.isInteger(orderInfoObject.progress_receive_completed_date)) orderInfoObject.progress_receive_completed_date = moment.unix(orderInfoObject.progress_receive_completed_date);
  if (_.isInteger(orderInfoObject.progress_ceremony_completed_date)) orderInfoObject.progress_ceremony_completed_date = moment.unix(orderInfoObject.progress_ceremony_completed_date);
  if (_.isInteger(orderInfoObject.progress_detail_item_completed_date)) orderInfoObject.progress_detail_item_completed_date = moment.unix(orderInfoObject.progress_detail_item_completed_date);
  if (_.isInteger(orderInfoObject.progress_clean_completed_date)) orderInfoObject.progress_clean_completed_date = moment.unix(orderInfoObject.progress_clean_completed_date);
  if (_.isInteger(orderInfoObject.progress_hydration_completed_date)) orderInfoObject.progress_hydration_completed_date = moment.unix(orderInfoObject.progress_hydration_completed_date);
  if (_.isInteger(orderInfoObject.progress_ashes_completed_date)) orderInfoObject.progress_ashes_completed_date = moment.unix(orderInfoObject.progress_ashes_completed_date);
  if (_.isInteger(orderInfoObject.progress_souvenir_completed_date)) orderInfoObject.progress_souvenir_completed_date = moment.unix(orderInfoObject.progress_souvenir_completed_date);
  if (_.isInteger(orderInfoObject.progress_procedure_photo_completed_date)) orderInfoObject.progress_procedure_photo_completed_date = moment.unix(orderInfoObject.progress_procedure_photo_completed_date);
  if (_.isInteger(orderInfoObject.progress_review_completed_date)) orderInfoObject.progress_review_completed_date = moment.unix(orderInfoObject.progress_review_completed_date);
  if (_.isInteger(orderInfoObject.progress_driver_completed_date)) orderInfoObject.progress_driver_completed_date = moment.unix(orderInfoObject.progress_driver_completed_date);
  if (_.isInteger(orderInfoObject.progress_driver_driving_completed_date)) orderInfoObject.progress_driver_driving_completed_date = moment.unix(orderInfoObject.progress_driver_driving_completed_date);
  if (_.isInteger(orderInfoObject.progress_bones_broken_completed_date)) orderInfoObject.progress_bones_broken_completed_date = moment.unix(orderInfoObject.progress_bones_broken_completed_date);
  if (_.isInteger(orderInfoObject.progress_air_dried_completed_date)) orderInfoObject.progress_air_dried_completed_date = moment.unix(orderInfoObject.progress_air_dried_completed_date);
  if (_.isInteger(orderInfoObject.progress_enter_hydration_completed_date)) orderInfoObject.progress_enter_hydration_completed_date = moment.unix(orderInfoObject.progress_enter_hydration_completed_date);
  if (_.isInteger(orderInfoObject.progress_pet_refrigerated_completed_date)) orderInfoObject.progress_pet_refrigerated_completed_date = moment.unix(orderInfoObject.progress_pet_refrigerated_completed_date);  
  if (_.isInteger(orderInfoObject.progress_before_hydration_completed_date)) orderInfoObject.progress_before_hydration_completed_date = moment.unix(orderInfoObject.progress_before_hydration_completed_date);
  if (_.isInteger(orderInfoObject.progress_pet_weighed_completed_date)) orderInfoObject.progress_pet_weighed_completed_date = moment.unix(orderInfoObject.progress_pet_weighed_completed_date);
  if (_.isInteger(orderInfoObject.progress_send_ceremony_completed_date)) orderInfoObject.progress_send_ceremony_completed_date = moment.unix(orderInfoObject.progress_send_ceremony_completed_date);
  if (_.isInteger(orderInfoObject.progress_pending_ceremony_completed_date)) orderInfoObject.progress_pending_ceremony_completed_date = moment.unix(orderInfoObject.progress_pending_ceremony_completed_date);
  if (_.isInteger(orderInfoObject.progress_ceremony_starting_completed_date)) orderInfoObject.progress_ceremony_starting_completed_date = moment.unix(orderInfoObject.progress_ceremony_starting_completed_date);
  if (_.isInteger(orderInfoObject.progress_ceremony_completed_date)) orderInfoObject.progress_ceremony_completed_date = moment.unix(orderInfoObject.progress_ceremony_completed_date);
  if (_.isInteger(orderInfoObject.progress_pet_received_completed_date)) orderInfoObject.progress_pet_received_completed_date = moment.unix(orderInfoObject.progress_pet_received_completed_date);
  if (_.isInteger(orderInfoObject.progress_hydration_completed_completed_date)) orderInfoObject.progress_hydration_completed_completed_date = moment.unix(orderInfoObject.progress_hydration_completed_completed_date);
  if (_.isInteger(orderInfoObject.progress_air_dried_completed_date)) orderInfoObject.progress_air_dried_completed_date = moment.unix(orderInfoObject.progress_air_dried_completed_date);
  if (_.isInteger(orderInfoObject.progress_bones_broken_completed_date)) orderInfoObject.progress_bones_broken_completed_date = moment.unix(orderInfoObject.progress_bones_broken_completed_date);
  if (_.isInteger(orderInfoObject.progress_receive_completed_date)) orderInfoObject.progress_receive_completed_date = moment.unix(orderInfoObject.progress_receive_completed_date);
  if (_.isInteger(orderInfoObject.progress_order_completed_completed_date)) orderInfoObject.progress_order_completed_completed_date = moment.unix(orderInfoObject.progress_order_completed_completed_date);

  //  Ashes Space
  if (_.isInteger(orderInfoObject.ashes_space_start_date)) orderInfoObject.ashes_space_start_date = moment.unix(orderInfoObject.ashes_space_start_date);
  if (_.isInteger(orderInfoObject.ashes_space_end_date)) orderInfoObject.ashes_space_end_date = moment.unix(orderInfoObject.ashes_space_end_date);

  //  Plant Space
  if (_.isInteger(orderInfoObject.plant_space_start_date)) orderInfoObject.plant_space_start_date = moment.unix(orderInfoObject.plant_space_start_date);
  if (_.isInteger(orderInfoObject.plant_space_end_date)) orderInfoObject.plant_space_end_date = moment.unix(orderInfoObject.plant_space_end_date);

  // Signature
  if (_.isInteger(orderInfoObject.agreement_signature_date)) orderInfoObject.agreement_signature_date = moment.unix(orderInfoObject.agreement_signature_date).format('YYYY/MM/DD HH:mm');
  if (_.isInteger(orderInfoObject.service_signature_date)) orderInfoObject.service_signature_date = moment.unix(orderInfoObject.service_signature_date).format('YYYY/MM/DD HH:mm');
  if (_.isInteger(orderInfoObject.ashes_space_signature_date)) orderInfoObject.ashes_space_signature_date = moment.unix(orderInfoObject.ashes_space_signature_date).format('YYYY/MM/DD HH:mm');
  if (_.isInteger(orderInfoObject.plant_space_signature_date)) orderInfoObject.plant_space_signature_date = moment.unix(orderInfoObject.plant_space_signature_date).format('YYYY/MM/DD HH:mm');
  if (_.isInteger(orderInfoObject.order_confirmed_signature_date)) orderInfoObject.order_confirmed_signature_date = moment.unix(orderInfoObject.order_confirmed_signature_date).format('YYYY/MM/DD HH:mm');

  return orderInfoObject
}

export async function fetchFormOption(type, isValueId = false, isPublic = false) {
  let results;
  if (isPublic) results = await Service.call('get', `/api/form_option_public/${type}`);
  else results = await Service.call('get', `/api/form_option/${type}`);
  let options = [];
  if (results.status !== 1) {
    return;
    // return {status: results.status, errorMessage: results.errorMessage};
  }
  // let alternateLang = (i18n.language === (_.keys(LanguageList))[0]) ? (_.keys(LanguageList))[1] : (_.keys(LanguageList))[0];

  let DefaultLanguage = (_.keys(LanguageList))[0];
  let translationResult;
  if (isPublic) translationResult = await Service.call('get', '/api/translation_public/list');
  else translationResult = await Service.call('get', '/api/translation/list');

  if (translationResult.status !== 1) {
    return notification.warning({ message: '獲取資料失敗' });
  }

  // return notification.warning({ message: alternateLang });
  options = results.data
  if (i18n.language !== DefaultLanguage) {
    let optionObject = await Service.call('post', '/api/translation/translateOption', { optionsArray: options, lang: i18n.language });
    options = optionObject.data
  }

  if (_.includes(["clinic", "organization", "pet_shop", "pet_hotel", "hospice"], type)) {
    options = isValueId
      ? options.map((item) => {
        const { form_option_id, name, is_default, remark } = item
        return { value: form_option_id, label: name, is_default, remark }
      })
      : options.map((item) => {
        const { name, is_default, remark } = item
        return { value: name, is_default, remark }
      })
    return options
  }

  options = isValueId
    ? options.map((item) => {
      const { form_option_id, name, is_default } = item
      return { value: form_option_id, label: name, is_default }
    })
    : options.map((item) => {
      const { name, is_default } = item
      return { value: name, is_default }
    })
  return options
}

export async function exportToCSV(dataList, fileName) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(dataList);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

export async function fetchProductCategoryOption(product_category_id = 0) {
  let result = await Service.call('get', `/api/product_category/list`);
  // console.log('result', result)

  if (result.status !== 1) {
    return notification.error({ message: '錯誤', description: result.errorMessage });
  }

  let allowPick = _.filter(result.data, function (o) { // filter options
    //  Filter out self record
    if (o.product_category_id === product_category_id) {
      return false;
    }

    let value = o.parent_index.split('-');
    let length = value.length;
    if (value[0] === "") { // correct the length of root categories
      length = 0
    }
    if (length >= 4) { // Filter out max layer record
      return false;
    }
    return true;
  })

  // console.log('allowPick', allowPick)

  let categoryMap = _.groupBy(allowPick, 'parent_index');

  const getChildren = (productCategoryId, index) => {
    let childParentIndex = index.length > 0 ? `${index}-${productCategoryId}` : _.toString(productCategoryId);
    let childNodes = categoryMap[childParentIndex];
    // console.log(`childNodes >> `, childNodes);
    if (!childNodes) {
      return null;
    }

    return childNodes.map((rc) => {
      return {
        value: rc.product_category_id,
        label: rc.category_name,
        children: getChildren(rc.product_category_id, rc.parent_index)
      };
    });
  };

  const cascaderOptions = [];
  const rootNodes = categoryMap[""];

  _.forEach(rootNodes, (rc) => {
    cascaderOptions.push({
      value: rc.product_category_id,
      label: rc.category_name,
      children: getChildren(rc.product_category_id, rc.parent_index)
    });
  });

  return cascaderOptions;
}

export function displayMoment(unixTime) {

  const meta = getStore().getState().app.meta;

  // console.log("meta",meta)
  let outputFormat = `${!_.isEmpty(meta.system_date_format.meta_value) ? meta.system_date_format.meta_value : 'YYYY/MM/DD'
    } 
    ${!_.isEmpty(meta.system_time_format.meta_value) ? meta.system_time_format.meta_value : 'HH:mm'
    }`

  if (unixTime !== 0) {
    return moment.unix(unixTime).format(outputFormat);
  }
  return '-';
}
