import React, { useState, useEffect } from "react";
import {
  Button,
  Layout,
  Table,
  Tooltip,
  Modal,
  notification,
  Badge,
  Space,
  Tag
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  CloseOutlined,
  CheckOutlined,
  FileAddOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import "antd/dist/antd.css";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import * as Service from "../../core/Service";

const { confirm } = Modal;

const ProductItemTable = (props) => {
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)

  const { t } = useTranslation();
  // console.log("object >> ", props.dataObj);

  const { dataObj, productCategoryData } = props;

  const [dataList, setDataList] = useState([]);
  const tableIDName = "product_id";
  const history = useHistory();
  const [productCategoryList, setProductCategoryList] = useState([])

  useEffect(() => {
    getProductCategoryList()
  }, [productCategoryData]);

  useEffect(() => {
    setDataList(dataObj);
  }, [dataObj]);


  const getProductCategoryList = async () => {
    let productCatList = []
    let resp = _.uniqBy((_.map(productCategoryData, 'category_name')))

    _.forEach(resp, (value) => {
      productCatList.push({ text: value, value })
    })
    setProductCategoryList(productCatList)
  }

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record) => {
          let { is_active } = record;
          return (
            <Space>
              <Tooltip title={t("modify")}>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    history.push(
                      `/company/product_item_form?product_id=${value}`
                    );
                  }}
                />
              </Tooltip>
              <Button
                // className="p_product_name_edit"
                shape="circle"
                icon={is_active ? <CloseOutlined /> : <CheckOutlined />}
                style={{ color: is_active ? "#AA0000" : "#00AA00" }}
                onClick={() => toggleActive(record)}
              />
              <Tooltip title={t("create_qrcode")}>
                <Button
                  shape="circle"
                  icon={<FileAddOutlined />}
                  onClick={async () => {
                    let result = await Service.call('post', `/api/product/qrcode/${record.product_id}`)
                    // console.log('result', result)
                    if (result.status !== 1) return

                    notification.success({ message: t("success") });
                    props.fetchData()
                  }}
                />
              </Tooltip>
              <Tooltip title={t("download_qrcode")}>
                <Button
                  shape="circle"
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    window.open(`${STATIC_SERVER_URL}/${record.sku_filepath}`)
                  }}
                />
              </Tooltip>
              <Button
                // className="p_product_name_edit"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => toggleDelete(record)}
              />
            </Space>
          );
        },
      },
      {
        title: t("activity"),
        dataIndex: "is_active",
        key: "is_active",
        sorter: (a, b) => (a.is_active - b.is_active),
        filters: [
          { text: t('active'), value: 1 },
          { text: t('inactive'), value: 0 }
        ],
        onFilter: (value, record) => record.is_active === value,
        render: (text, record, index) => {
          let { is_active } = record;
          return (
            <span>
              {is_active === 1 ? (
                <Badge status="processing" />
              ) : (
                <Badge color="#555" />
              )}
            </span>
          );
        },
      },
      {
        title: t("sku"),
        dataIndex: `sku`,
        sorter: (a, b) => (_.toString(a.sku) < _.toString(b.sku) ? -1 : 1),
      },
      {
        title: t("product_name"),
        dataIndex: `product_name`,
      },
      {
        title: t("level_quantity"),
        dataIndex: `level_quantity`,
        render: (value, record) => {
          if (!record.is_stock_product) return '-'
          return value
        }
      },
      {
        title: t("stock_number"),
        dataIndex: `stock_number`,
        render: (value, record) => {
          if (!record.is_stock_product) return '-'
          return value
        }
      },
      {
        title: t("price"),
        dataIndex: `price`,
        // className: "p_product_price_view"
      },
      {
        title: t("cost"),
        dataIndex: `cost`,
        className: "p_product_cost_view",
        render: (value, record) => {
          if (!record.is_stock_product) return '-'
          return value
        }
      },
      {
        title: t("wholesale_price"),
        dataIndex: `wholesale_price`,
        className: "p_product_wholesale_price_view",
        render: (value, record) => {
          if (!record.is_stock_product) return '-'
          return value
        }
      },
      {
        title: t("inventory_message"),
        dataIndex: `tableIDName`,
        render: (value, record) => {
          let { stock_number, level_quantity, is_stock_product } = record;
          if (!is_stock_product) return '-'
          return (
            <>
              {stock_number > level_quantity && (<Tag style={{ margin: "10px 10px 10px" }} color="green">{t("normal_inventory")}</Tag>)}
              {stock_number <= level_quantity && (<Tag style={{ margin: "10px 10px 10px" }} color="red">{t("abnormal_inventory")}</Tag>)}
            </>
          )
        }
      },
      {
        title: t("product_category"),
        dataIndex: `product_category_lookup`,
        filters: productCategoryList,
        sorter: (a, b) => _.toString(a.product_category_lookup).localeCompare(_.toString(b.product_category_lookup)),
        onFilter: (value, record) => record.product_category_lookup.indexOf(value) >= 0,
        render: (value, record) => {
          if (!_.isEmpty(value)) {
            return _.toString(value)
          }
          return "-";
        }
      },
      {
        title: t("is_stock_product"),
        dataIndex: `is_stock_product`,
        sorter: (a, b) => _.toString(a.is_stock_product).localeCompare(_.toString(b.is_stock_product)),
        onFilter: (value, record) => record.is_stock_product.indexOf(value) >= 0,
        render: (value, record) => {
          if (value === 1) {
            return "Yes"
          }
          return "No";
        }
      },
    ];
    return columns;
  };

  // const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm') => {
  //   if (unixTime !== 0) {
  //     return moment.unix(unixTime).format(outputFormat);
  //   }
  //   return '-';
  // }

  const toggleActive = (record) => {
    confirm({
      title: `${record.is_active ? t("confirm_deactivation") : t("confirm_permission")} ${record.product_name}？`,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      onOk: async () => {
        let postObject = {
          product_id: record.product_id,
          is_active: record.is_active ? 0 : 1,
        };
        let result = await Service.call("patch", "/api/product", postObject);
        // console.log('result', result);

        if (result.status !== 1) {
          notification.error({
            message: t("submission_failed"),
            description: result.errorMessage,
          });
          return;
        }

        notification.success({ message: t("submitted_successfully") });
        props.fetchData();
      },
      onCancel: () => {
        // console.log('Cancel');
      },
    });
  };

  const toggleDelete = (record) => {
    confirm({
      title: `${t("confirm_delete")} ${record.product_name}？`,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      onOk: async () => {
        let result = await Service.call(
          "delete",
          `/api/product/${record.product_id}`
        );
        // console.log('result', result);

        if (result.status !== 1) {
          return notification.error({
            message: t("fail_to_delete"),
            description: t(result.errorMessage),
          });
        }

        notification.success({ message: t("successfully_deleted") });
        props.fetchData();
      },
      onCancel: () => {
        // console.log('Cancel');
      },
    });
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList}
        columns={setTableHeader()}
      />
    </Layout>
  );
};

export default ProductItemTable;
