import React, { useState, useEffect } from 'react';
import {
  Button, Layout, Table, Tooltip, Modal, notification, Select, Tag
} from 'antd';
import {
  DeleteOutlined, EditOutlined, FileAddOutlined, FilePdfOutlined, LockOutlined, UnlockOutlined, LinkOutlined, DownloadOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import path from 'path'
import { useSelector } from 'react-redux'
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import CreatePdfButton from '../../components/CreatePdfButton';
import DownloadPdfButton from '../../components/DownloadPdfButton';

const { confirm } = Modal;
const { Option } = Select

const OrderInfoTable = (props) => {
  const { fetchData, pagination, paginationOnChange, isLoading } = props
  const [dataList, setDataList] = useState([]);
  const tableIDName = "order_id";
  const history = useHistory();
  const { t } = useTranslation();
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)

  useEffect(() => {
    setDataList(props.dataObj)
  }, [props.dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record) => {
          let {
            customer_authorization_agreement_pdf, service_form_pdf, ashes_space_rental_pdf, plant_space_rental_pdf, hydration_certificate_pdf, procedure_certificate_pdf
          } = record
          return (
            <span>
              <Tooltip title={t("modify")}>
                <Button
                  // className="p_order_edit"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    history.push(`/company/order_info_form?order_id=${value}&order_status=${record.order_status}`)
                  }}
                  style={{ marginRight: 8, color: '#000000' }}
                />
              </Tooltip>
              <Tooltip title={record.locked ? t("order_locked") : t("order_unlocked")}>
                <Button
                  className="p_order_unlock"
                  shape="circle"
                  icon={record.locked ? <LockOutlined /> : <UnlockOutlined />}
                  onClick={async () => {
                    const resp = await Service.call('patch', '/api/order_info', {
                      locked: record.locked ? 0 : 1,
                      order_id: record.order_id
                    });
                    if (resp.errorMessage) {
                      return notification.error({
                        message: resp.errorMessage
                      });
                    }
                    await fetchData()
                  }}
                  style={{ marginRight: 8, color: '#000000' }}
                />
              </Tooltip>
              <Tooltip title={t("delete")}>
                <Button
                  // className="p_order_edit"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    onDelete(value, record);
                  }}
                  style={{ marginRight: 8, color: '#000000' }}
                />
              </Tooltip>
              <Tooltip title={t("create_pet_qrcode")}>
                <Button
                  shape="circle"
                  icon={<FileAddOutlined />}
                  onClick={async () => {
                    let result = await Service.call('post', `/api/pet/qrcode/${record.order_no}`)
                    // console.log('result', result)
                    if (result.status !== 1) return

                    notification.success({ message: t("success") });
                    await fetchData()
                  }}
                  style={{ marginRight: 8, color: '#000000' }}
                />
              </Tooltip>
              <Tooltip title={t("download_pet_qrcode")} style={{marginRight: 8}}>
                <Button
                  shape="circle"
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    window.open(`${STATIC_SERVER_URL}/${record.order_no_filepath}`)
                  }}
                  style={{ marginRight: 8, color: '#000000' }}
                />
              </Tooltip>
              <CreatePdfButton
                title={t("create_authorization_pdf")}
                type="agreement"
                params={{ order_info_id: value }}
                // className="p_order_edit"
                apiPath="/api/order_info/pdf/customer_authorization_agreement"
                fetchData={fetchData}
                buttonStyle={{ marginRight: 8, color: '#000000' }}
              />
              {/* <Tooltip title={t("create_authorization_pdf")}>
                <Button
                  className="p_order_edit"
                  shape="circle"
                  icon={<FileAddOutlined />}
                  onClick={async () => {
                    let result = await Service.call('post', '/api/order_info/pdf/customer_authorization_agreement', { order_info_id: value })
                    // console.log('result', result)
                    if (result.status !== 1) return

                    notification.success({ message: t("success") });
                    fetchData()
                  }}
                  style={{ marginRight: 8, color: '#000000' }}
                />
              </Tooltip> */}
              {customer_authorization_agreement_pdf && (
                <DownloadPdfButton
                  title={t("view_authorization_pdf")}
                  apiPath={`/api/order_info/pdf/customer_authorization_agreement/${customer_authorization_agreement_pdf}`}
                  preferred_language={record.preferred_language}
                  type="agreement"
                  order_no_with_prefix={record.order_no_with_prefix}
                  buttonStyle={{ marginRight: 8, color: '#000000' }}
                />
                // <Tooltip title={t("view_authorization_pdf")}>
                //   <Button
                //     shape="circle"
                //     icon={<FilePdfOutlined />}
                //     onClick={async () => {
                //       let result = await Service.call('get', `/api/order_info/pdf/customer_authorization_agreement/${customer_authorization_agreement_pdf}`, {}, { responseType: 'blob' })
                //       // console.log('result', result)
                //       if (!result) return
                //       const pdfBlob = new Blob([result], { type: 'application/pdf' });

                //       let filename = []
                //       let language = !_.isEmpty(record.preferred_language) ? record.preferred_language : 'zh_hant'
                //       if (language === 'zh_hant') filename.push('授權書')
                //       if (language === 'en') filename.push('Agreement')
                //       filename.push(record.order_no_with_prefix)
                //       saveAs(pdfBlob, _.join(filename, ''))
                //     }}
                //     style={{ marginRight: 8, color: '#000000' }}
                //   />
                // </Tooltip>
              )}

              <CreatePdfButton
                title={t("create_service_detail_form_pdf")}
                type="service_form"
                params={{ order_info_id: value }}
                // className="p_order_edit"
                apiPath="/api/order_info/pdf/service_form"
                fetchData={fetchData}
                buttonStyle={{ marginRight: 8, color: '#000000' }}
              />
              {/* <Tooltip title={t("create_service_detail_form_pdf")}>
                <Button
                  className="p_order_edit"
                  shape="circle"
                  icon={<FileAddOutlined />}
                  onClick={async () => {
                    let result = await Service.call('post', '/api/order_info/pdf/service_form', { order_info_id: value })
                    // console.log('result', result)
                    if (result.status !== 1) return

                    notification.success({ message: t("success") });
                    fetchData()
                  }}
                  style={{ marginRight: 8, color: '#000000' }}
                />
              </Tooltip> */}
              {service_form_pdf && (
                <DownloadPdfButton
                  title={t("view_service_detail_form_pdf")}
                  apiPath={`/api/order_info/pdf/service_form/${service_form_pdf}`}
                  preferred_language={record.preferred_language}
                  type="service_form"
                  order_no_with_prefix={record.order_no_with_prefix}
                  buttonStyle={{ marginRight: 8, color: '#000000' }}
                />
                // <Tooltip title={t("view_service_detail_form_pdf")}>
                //   <Button
                //     shape="circle"
                //     icon={<FilePdfOutlined />}
                //     onClick={async () => {
                //       let result = await Service.call('get', `/api/order_info/pdf/service_form/${service_form_pdf}`, {}, { responseType: 'blob' })
                //       // console.log('result', result)
                //       if (!result) return
                //       const pdfBlob = new Blob([result], { type: 'application/pdf' });

                //       let filename = []
                //       let language = !_.isEmpty(record.preferred_language) ? record.preferred_language : 'zh_hant'
                //       if (language === 'zh_hant') filename.push('服務細節表')
                //       if (language === 'en') filename.push('ServiceForm')
                //       filename.push(record.order_no_with_prefix)
                //       saveAs(pdfBlob, _.join(filename, ''))
                //     }}
                //     style={{ marginRight: 8, color: '#000000' }}
                //   />
                // </Tooltip>
              )}
              <CreatePdfButton
                title={t("create_ashes_space_rental_form_pdf")}
                type="ashes_rental"
                params={{ order_info_id: value }}
                // className="p_order_edit"
                apiPath="/api/order_info/pdf/ashes_space_rental"
                fetchData={fetchData}
                buttonStyle={{ marginRight: 8, color: '#000000' }}
              />
              {/* <Tooltip title={t("create_ashes_space_rental_form_pdf")}>
                <Button
                  className="p_order_edit"
                  shape="circle"
                  icon={<FileAddOutlined />}
                  onClick={async () => {
                    let result = await Service.call('post', '/api/order_info/pdf/ashes_space_rental', { order_info_id: value })
                    // console.log('result', result)
                    if (result.status !== 1) return

                    notification.success({ message: t("success") });
                    fetchData()
                  }}
                  style={{ marginRight: 8, color: '#000000' }}
                />
              </Tooltip> */}
              {ashes_space_rental_pdf && (
                <DownloadPdfButton
                  title={t("view_ashes_space_rental_form_pdf")}
                  apiPath={`/api/order_info/pdf/ashes_space_rental/${ashes_space_rental_pdf}`}
                  preferred_language={record.preferred_language}
                  type="ashes_rental"
                  order_no_with_prefix={record.order_no_with_prefix}
                  buttonStyle={{ marginRight: 8, color: '#000000' }}
                />
                // <Tooltip title={t("view_ashes_space_rental_form_pdf")}>
                //   <Button
                //     shape="circle"
                //     icon={<FilePdfOutlined />}
                //     onClick={async () => {
                //       let result = await Service.call('get', `/api/order_info/pdf/ashes_space_rental/${ashes_space_rental_pdf}`, {}, { responseType: 'blob' })
                //       // console.log('result', result)
                //       if (!result) return
                //       const pdfBlob = new Blob([result], { type: 'application/pdf' });

                //       let filename = []
                //       let language = !_.isEmpty(record.preferred_language) ? record.preferred_language : 'zh_hant'
                //       if (language === 'zh_hant') filename.push('骨灰靈位租用')
                //       if (language === 'en') filename.push('AshesRental')
                //       filename.push(record.order_no_with_prefix)
                //       saveAs(pdfBlob, _.join(filename, ''))
                //     }}
                //     style={{ marginRight: 8, color: '#000000' }}
                //   />
                // </Tooltip>
              )}

              <CreatePdfButton
                title={t("create_plant_space_rental_form_pdf")}
                type="plant_rental"
                params={{ order_info_id: value }}
                // className="p_order_edit"
                apiPath="/api/order_info/pdf/plant_space_rental"
                fetchData={fetchData}
                buttonStyle={{ marginRight: 8, color: '#000000' }}
              />
              {/* <Tooltip title={t("create_plant_space_rental_form_pdf")}>
                <Button
                  className="p_order_edit"
                  shape="circle"
                  icon={<FileAddOutlined />}
                  onClick={async () => {
                    let result = await Service.call('post', '/api/order_info/pdf/plant_space_rental', { order_info_id: value })
                    // console.log('result', result)
                    if (result.status !== 1) return

                    notification.success({ message: t("success") });
                    fetchData()
                  }}
                  style={{ marginRight: 8, color: '#000000' }}
                />
              </Tooltip> */}
              {plant_space_rental_pdf && (
                <DownloadPdfButton
                  title={t("view_plant_space_rental_form_pdf")}
                  apiPath={`/api/order_info/pdf/plant_space_rental/${plant_space_rental_pdf}`}
                  preferred_language={record.preferred_language}
                  type="plant_rental"
                  order_no_with_prefix={record.order_no_with_prefix}
                  buttonStyle={{ marginRight: 8, color: '#000000' }}
                />
                // <Tooltip title={t("view_plant_space_rental_form_pdf")}>
                //   <Button
                //     shape="circle"
                //     icon={<FilePdfOutlined />}
                //     onClick={async () => {
                //       let result = await Service.call('get', `/api/order_info/pdf/plant_space_rental/${plant_space_rental_pdf}`, {}, { responseType: 'blob' })
                //       // console.log('result', result)
                //       if (!result) return
                //       const pdfBlob = new Blob([result], { type: 'application/pdf' });
                //       let filename = []
                //       let language = !_.isEmpty(record.preferred_language) ? record.preferred_language : 'zh_hant'
                //       if (language === 'zh_hant') filename.push('種植靈位租用')
                //       if (language === 'en') filename.push('PlantRental')
                //       filename.push(record.order_no_with_prefix)
                //       saveAs(pdfBlob, _.join(filename, ''))
                //     }}
                //     style={{ marginRight: 8, color: '#000000' }}
                //   />
                // </Tooltip>
              )}

              <CreatePdfButton
                title={t("create_hydration_certificate_pdf")}
                type="hydration_certificate"
                params={{ order_info_id: value }}
                // className="p_order_edit"
                apiPath="/api/order_info/pdf/hydration_certificate"
                fetchData={fetchData}
                buttonStyle={{ marginRight: 8, color: '#000000' }}
              />

              {hydration_certificate_pdf && (
                <DownloadPdfButton
                  title={t("view_hydration_certificate_pdf")}
                  apiPath={`/api/order_info/pdf/hydration_certificate/${hydration_certificate_pdf}`}
                  preferred_language={record.preferred_language}
                  type="hydration_certificate"
                  order_no_with_prefix={record.order_no_with_prefix}
                  buttonStyle={{ marginRight: 8, color: '#000000' }}
                />
              )}

              <CreatePdfButton
                title={t("create_procedure_certificate_pdf")}
                type="procedure_certificate"
                params={{ order_info_id: value }}
                // className="p_order_edit"
                apiPath="/api/order_info/pdf/procedure_certificate"
                fetchData={fetchData}
                buttonStyle={{ marginRight: 8, color: '#000000' }}
              />

              {procedure_certificate_pdf && (
                <DownloadPdfButton
                  title={t("view_procedure_certificate_pdf")}
                  apiPath={`/api/order_info/pdf/procedure_certificate/${procedure_certificate_pdf}`}
                  preferred_language={record.preferred_language}
                  type="procedure_certificate"
                  order_no_with_prefix={record.order_no_with_prefix}
                  buttonStyle={{ marginRight: 8, color: '#000000' }}
                />
              )}

              <Tooltip title="URL">
                <Button
                  // className="p_order_edit"
                  shape="circle"
                  icon={<LinkOutlined />}
                  onClick={() => {
                    // let url = `${STATIC_SERVER_URL}/order_info_public?order_id=${value}`
                    let url = `${window.location.origin}/order_info_public?order_id=${value}`
                    console.log('url: ', url)
                    let tempDiv = document.createElement('textarea');
                    tempDiv.value = url
                    document.body.appendChild(tempDiv);
                    tempDiv.select();
                    document.execCommand('copy');
                    document.body.removeChild(tempDiv);
                    notification.success({ message: 'Copied.' });
                    // history.push(`/order_info_public?order_id=${value}`)
                  }}
                  style={{ marginRight: 8, color: '#000000' }}
                />
              </Tooltip>
            </span>
          )
        }
      },
      {
        title: t('order_status'),
        dataIndex: `order_status`,
        render: (value, record) => {
          let orderStatusDisplay = ''
          switch (value) {
            case 'd':
              orderStatusDisplay = (<Tag color="red">{t('void')}</Tag>)
              break;

            case '':
            default:
              orderStatusDisplay = (<Tag color="green">{t('normal')}</Tag>)
              break;
          }
          return orderStatusDisplay
        }
      },
      {
        title: t("order_stage"),
        dataIndex: `order_stage`,
        render: (value, record) => {
          return (
            <>
              <Select
                defaultValue={value}
                style={{ width: 200 }}
                disabled
                onChange={async (selectedValue) => {
                  let data = await Service.call('patch', '/api/order_info', { order_id: record.order_id, order_stage: selectedValue });
                  // console.log('data', data);

                  if (data.status !== 1) {
                    notification.error({ message: t("submission_failed"), description: data.errorMessage });
                    return;
                  }

                  notification.success({ message: `${record.order_no_prefix}${record.order_no}${t("submitted_successfully")}` });
                  fetchData();
                }}
              >
                <Option value="">{t('receive_order')}</Option>
                <Option value="pending_car">{t('pending_car')}</Option>
                <Option value="pending_driver">{t('pending_driver')}</Option>
                <Option value="driver_driving">{t('driver_driving')}</Option>
                <Option value="pet_received">{t('pet_received')}</Option>
                <Option value="before_hydration">{t('before_hydration')}</Option>
                <Option value="enter_hydration">{t('enter_hydration')}</Option>
                {/* <Option value="pet_weighed">{t('pet_weighed')}</Option>
                <Option value="pet_refrigerated">{t('pet_refrigerated')}</Option>
                <Option value="pet_cleaned">{t('pet_cleaned')}</Option>
                <Option value="ceremony_room">{t('ceremony_room')}</Option>
                <Option value="ceremony_completed">{t('ceremony_completed')}</Option>
                <Option value="pet_refrigerated_after_ceremony">{t('pet_refrigerated_after_ceremony')}</Option>
                <Option value="hydration_machine">{t('hydration_machine')}</Option> */}
                <Option value="hydration_started">{t('hydration_started')}</Option>
                <Option value="hydration_completed">{t('hydration_completed')}</Option>
                <Option value="air_dried">{t('air_dried')}</Option>
                <Option value="bones_broken">{t('bones_broken')}</Option>
                <Option value="final_checking">{t('final_checking')}</Option>
                <Option value="order_completed">{t('order_completed')}</Option>
              </Select>
            </>
          )
        }
      },
      {
        title: t("order_no"),
        dataIndex: `order_no`,
        render: (value, record) => {
          return (`${record.order_no_prefix}${value}`)
        }
      },
      {
        title: t("owner_first_name"),
        dataIndex: `owner_first_name`,
      },
      {
        title: t("owner_last_name"),
        dataIndex: `owner_last_name`,
      },
      {
        title: t("owner_mobile"),
        dataIndex: `owner_mobile`,
      },
      // {
      //   title: `寵物編號`,
      //   dataIndex: `pet_no`,
      // },
      {
        title: t("pet_name"),
        dataIndex: `pet_name`,
      },
      {
        title: t("pick_up_time"),
        dataIndex: `pick_up_time`,
        render: (value) => {
          if (value !== null) {
            return Main.displayMoment(value);
          }
        }
      },
      {
        title: t("pick_up_address"),
        dataIndex: `pick_up_address`,
      },
      {
        title: t('ctime'),
        dataIndex: `ctime`,
        render: (value) => {
          return Main.displayMoment(value);
        }
      },
      {
        title: t('utime'),
        dataIndex: `utime`,
        render: (value) => {
          return Main.displayMoment(value);
        }
      },
      {
        title: t('user_id'),
        dataIndex: `user_id`
      },
    ];
    return columns;
  }

  // const displayMoment = ( // system date/time setting config
  //   unixTime
  // ) => {
  //   return Main.displayMoment(unixTime)
  // }

  const onDelete = (id, record) => {
    confirm({
      title: `${t("confirm_delete")} ${record.pet_name}？`,
      okText: t("yes"),
      okType: 'danger',
      cancelText: t("no"),
      onOk: async () => {
        let result = await Service.call('delete', `/api/order_info/${record.order_id}`);

        if (result.status !== 1) {
          notification.error({ message: t("failed_to_delete"), description: result.errorMessage });
          return;
        }

        notification.success({ message: t("successfully_deleted") });
        fetchData();
      },
      onCancel: () => {
        // console.log('Cancel');
      },
    });
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        pagination={pagination}
        onChange={paginationOnChange}
        loading={isLoading}
      />
    </Layout>
  )
}

export default OrderInfoTable;
