import React, { useState, useEffect, useRef, useContext } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Radio, Popconfirm, Spin, Table, Space, TreeSelect
} from 'antd';
import {
  DeleteOutlined, EditOutlined
} from '@ant-design/icons';
import moment from 'moment';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormUploadFile from '../../components/form/FormUploadFile';
import HydrationMachinePlace from './HydrationMachinePlace'

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const HydrationMachineForm = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { hydration_machine_id } = queryString.parse(location.search);
  const { t } = useTranslation();
  const isNeededOptions = [{ label: t('yes'), value: 'yes' }, { label: t('no'), value: 'no' }]
  const myPermission = useSelector((state) => state.app.company_user.myPermission);

  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({is_active: 1});
  const [options, setOption] = useState([])
  const [isAllowHydrationPlaceTap, setIsAllowHydrationPlaceTap] = useState(false);
  // const [cost, setCost] = useState(0)

  useEffect(() => {
    if (!_.isUndefined(hydration_machine_id)) {
      fetchData();
      // fetchCost(hydration_machine_id);
    }
    setTabVisible();
  }, []);

  useEffect(() => {
    form.resetFields();
    // if (!_.isUndefined(hydration_machine_id)) {
    //   form.setFieldsValue({ price: cost });
    // }
  }, [initialValues]);


  const setTabVisible = () => {
    let isAllowPermission = _.intersection(myPermission, ['p_order_hydration_edit', 'p_any'])
    if (!_.isEmpty(isAllowPermission)) {
      setIsAllowHydrationPlaceTap(true);
    }
  }

  const fetchData = async () => {
    try {
      let hydrationMachineResult = await Service.call('get', `/api/hydration_machine/${hydration_machine_id}`);
      let newDefaultValues = { ...initialValues, ...hydrationMachineResult.data };
      console.log("Values", newDefaultValues)
      setInitialValues(newDefaultValues);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  return (
    <NavAndSideFrame {...props} title={!_.isUndefined(hydration_machine_id) ? t("modify_machine_item") : t("add_new_item")} selectedKey="hydration_machine_setting">
      <Tabs type="hydration_machine" defaultActiveKey="hydration_machine_info" onChange={(key) => null}>
        <TabPane tab={t("hydration_machine_info")} key="hydration_machine_info">
          <Form
            form={form}
            name="hydrationMachineForm"
            layout="vertical"
            onFinish={async (postObj) => {
              let method = 'post';
              if (!_.isUndefined(hydration_machine_id)) {
                method = 'patch';
                postObj.hydration_machine_id = hydration_machine_id;
              }
              // console.log('object >>> ', postObj);
              let data = await Service.call(method, '/api/hydration_machine', postObj);
              // console.log('data', data);

              if (data.status !== 1) {
                notification.error({ message: t("submission_failed"), description: data.errorMessage });
                return;
              }

              notification.success({ message: t("submitted_successfully") });
              history.push(`/company/hydration_machine_setting`);
            }}
            initialValues={initialValues}
          >

            <Form.Item
              label={t("hydration_machine_name")}
              name="name"
              rules={[{ required: true }]}
            >
              <Input autoFocus />
            </Form.Item>

            <Form.Item
              label={t("hydration_machine_no")}
              name="machine_no"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("hydration_machine_max_weight")}
              name="max_weight"
            >
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item
              label={t("hydration_machine_max_pet")}
              name="max_pet"
            >
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item
              label={t("activity")}
              name="is_active"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group>
                <Radio value={1}>{t("active")}</Radio>
                {/* 活動 */}
                <Radio value={0}>{t("inactive")}</Radio>
                {/* 不活動 */}
              </Radio.Group>
            </Form.Item>

            <Form.Item>
              <Button
                // className="p_product_name_edit"
                className="p_order_hydration_edit"
                type="primary"
                htmlType="submit"
              >
                {t("submit")}
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
        {isAllowHydrationPlaceTap && (
          <TabPane tab={t("hydration_machine_place")} key="hydration_machine_place" disabled={!hydration_machine_id}>
            <HydrationMachinePlace
              fetchData={fetchData}
              hydrationMachineObject={initialValues}
            />
          </TabPane>
        )}
      </Tabs>
    </NavAndSideFrame>
  )
}

export default HydrationMachineForm;
