import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, AutoComplete, Row, Col, Space
} from 'antd';
import {
  CalculatorOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const { Search, TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const HydrationMachinePlaceForm = (props) => {
  const { t } = useTranslation();
  let location = useLocation();
  const {
    closeMethod,
    machine_space_id = 0,
    productCostValue = 0,
    hydration_machine_id
  } = props

  const [form] = Form.useForm();

  useEffect(() => {
    getHydrationMachineSpaceById()
  }, [machine_space_id])

  const getHydrationMachineSpaceById = async () => {
    let spaceResult = await Service.call('get', `/api/hydration_machine_place/placeID?hydration_machine_lookup_id=${machine_space_id}`)
    if (!_.isEmpty(spaceResult.data)) {
      let [space] = spaceResult.data;
      form.setFieldsValue({name: space.name})
    }
  }

  return (
    <Form
      form={form}
      // initialValues={initialValues}
      onFinish={async (values) => {
        let method = 'post';
        // console.log('values', values)
        values.hydration_machine_id = hydration_machine_id

        if (machine_space_id !== 0) {
          method = 'patch';
          values.hydration_machine_lookup_id = machine_space_id
        }

        let result = await Service.call(method, '/api/hydration_machine_place', values)

        if (result.status !== 1) {
          return notification.error({ message: '提交失敗', description: result.errorMessage });
        }

        notification.success({ message: '提交成功' });
        closeMethod()
      }}
    >
      <Form.Item
        label={t('hydration_machine_place_name')}
        name="name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Button type="primary" htmlType="submit">
        {t('submit')}
      </Button>
    </Form>
  )
}

export default HydrationMachinePlaceForm;
