import React, {
  useState, useEffect, useRef, useCallback
} from 'react';
import {
  Button, Divider, notification, Modal, Select, Input, Space, DatePicker
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Fuse from 'fuse.js';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import ReceiptInfoTable from './ReceiptInfoTable';

const filterableField = [
  'mobile',
  'order_no_with_prefix',
  'invoice_no_with_prefix',
  'receipt_no_with_prefix',
]
const fuseOptions = {
  findAllMatches: true,
  includeScore: true,
  threshold: 0.15,
  keys: filterableField
};

const DEFAULT_PAGINATION = {pageSize: 10, current: 1, total: 0}

const ReceiptInfoList = (props) => {
  const [dataList, setDataList] = useState([]);
  const history = useHistory();
  const inputRef = useRef();
  const [modalVisible, setModalVisible] = useState(false)
  const [invoiceNoOptions, setInvoiceNoOptions] = useState([])
  const [invoiceNo, setInvoiceNo] = useState('')
  const [fuse, setFuse] = useState([])
  const { t } = useTranslation();

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [searchInput, setSearchInput] = useState('')

  // Range Picker
  const [dateValue, setDateValue] = useState([moment().subtract(7, 'days').startOf('day'), moment().endOf('day')]);
  const [dates, setDates] = useState(null);
  const [loading, setLoading] = useState(true);

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  useEffect(() => {
    if (!dates) {
      getAllData();
    }
    fetchInvoiceNo();
  }, [dates]);

  useEffect(() => {
    if (_.isEmpty(fuse)) {
      return;
    }
    filterData({searchWord: inputRef.current.input.value})
  }, [fuse])

  const getAllData = async (tablePagination = pagination, searchWord = searchInput) => {
    try {
      setLoading(true);
      const from = moment().subtract(7, 'days').startOf('day').unix();
      const to = moment().endOf('day').unix();
      let result = await Service.call('get', '/api/receipt_info/list/pagination', {
        from,
        to,
        ...tablePagination,
        searchWord
      });
      setLoading(false);
      // console.log('result', result);

      if (result.status !== 1) {
        notification.warning({ message: '獲取資料失敗' });
        return;
      }

      let { data, total } = result;
      let orderedDataList = _.orderBy(data, ['ctime'], ['desc']);
      setDataList(orderedDataList);
      setPagination({...tablePagination, total})
    } catch (error) {
      setLoading(false);
      console.error('error >>> ', error);
    }
  }

  const fetchInvoiceNo = async () => {
    let result = await Service.call('get', '/api/invoice_info/number');
    // console.log('result', result);
    if (!result) return

    let invoiceNoList = result.map((item) => {
      return {
        value: item.invoice_no,
        label: `${item.invoice_no_prefix}${item.invoice_no}`
      }
    })
    let orderedinvoiceNo = _.orderBy(invoiceNoList, ['value'], ['desc']);
    setInvoiceNoOptions(orderedinvoiceNo)
  }

  const debouncedSearch = useRef(
    _.debounce(async (searchWord) => {
      getAllData(DEFAULT_PAGINATION, searchWord)
    }, 300)
  ).current;

  const filterData = async (filterObj = {}) => {
    setSearchInput(filterObj.searchWord)
    debouncedSearch(filterObj.searchWord)
  }

  const paginationOnChange = useCallback((tablePagination) => {
    getAllData(tablePagination)
  }, [getAllData, pagination])

  return (
    <NavAndSideFrame {...props} title={t("receipt_info_list")} selectedKey="receipt_info">
      <Space direction="horizontal">
        <Button
          block
          type="primary"
          className="p_receipt_edit"
          onClick={() => {
            setModalVisible(true)
          }}
        >
          {t("add_new_receipt")}
        </Button>

        <>
          {t('search')}
        </>
        <Input
          ref={inputRef}
          placeholder={t('please_input_keyword')}
          onChange={(event) => {
            filterData({ searchWord: event.target.value })
          }}
        />
        {/* <RangePicker
          value={dates || dateValue}
          disabledDate={disabledDate}
          onCalendarChange={(val) => setDates(val)}
          onChange={(val) => setDateValue(val)}
          onOpenChange={onOpenChange}
          disabled={loading}
        /> */}
      </Space>
      <Divider />
      <ReceiptInfoTable
        dataObj={dataList}
        fetchData={getAllData}
        loading={loading}
        pagination={pagination}
        paginationOnChange={paginationOnChange}
      />

      <Modal
        title={t("please_select_invoice")}
        visible={modalVisible}
        onOk={(e) => {
          if (!_.isInteger(invoiceNo)) {
            console.log(`invoiceNo is not integer`)
            return;
          }
          history.push(`/company/receipt_info_form?invoice_no=${invoiceNo}`)
        }}
        onCancel={(e) => {
          setModalVisible(false)
        }}
      >
        <Select
          showSearch
          style={{ width: 400 }}
          placeholder={t("search_invoice_number")}
          options={invoiceNoOptions}
          optionFilterProp="children"
          onChange={(value) => {
            // console.log('value', value)
            setInvoiceNo(value)
          }}
          filterOption={(input, option) => {
            const {label, value} = option;
            return _.toString(label).toLowerCase().indexOf(input.toLowerCase()) >= 0
          }}
        />
      </Modal>
    </NavAndSideFrame>
  )
}

export default ReceiptInfoList;
