import React, { useState, useEffect } from 'react';
import {
  Button, Layout, Table, Tooltip, Modal, notification, Select
} from 'antd';
import {
  DeleteOutlined, EditOutlined, FileAddOutlined, FilePdfOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import _ from 'lodash';
import * as Service from '../../core/Service';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import * as Main from "../../core/Main";

const { confirm } = Modal;
const { Option } = Select

const ProductInventoryHistoryTable = (props) => {
  const { dataObj, fetchData } = props
  const [dataList, setDataList] = useState([]);
  const tableIDName = "product_inventory_stock_history_id";
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    setDataList(dataObj)
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t('ctime'),
        dataIndex: `ctime`,
        render: (value) => {
          return Main.displayMoment(value);
        }
      },
      {
        title: t('utime'),
        dataIndex: `utime`,
        render: (value) => {
          return Main.displayMoment(value);
        }
      },
      {
        title: t('stock_no'),
        dataIndex: `stock_no`,
      },
      {
        title: t('staff_id'),
        dataIndex: `staff_id`,
      },
      {
        title: t('last_name'),
        dataIndex: `last_name`,
      },
      {
        title: t('first_name'),
        dataIndex: `first_name`,
      },
      {
        title: t('product_id'),
        dataIndex: `product_id`,
      },
      {
        title: t('order_no'),
        dataIndex: `order_no`,
      },
      {
        title: t('original_stock'),
        dataIndex: `original_stock`,
      },
      {
        title: t('final_stock'),
        dataIndex: `final_stock`,
      },
      {
        title: t('stock_in_out'),
        dataIndex: `stock_in_out`,
        render: ((value) => {
          if (value === 'in') return t("input")
          return t("output")
        })
      },
      {
        title: t('stock_change'),
        dataIndex: `stock_change`,
      },
      {
        title: t('cost'),
        dataIndex: `cost`,
        className: 'p_product_cost_view'
      },
      // {
      //   title: t('status'),
      //   dataIndex: `status`,
      // },
      {
        title: t('reason'),
        dataIndex: `remark`,
      },
      {
        title: t('remark'),
        dataIndex: `remark_2`,
      },
      {
        title: t('status'),
        dataIndex: 'is_active',
        key: 'is_active',
        render: ((value) => {
          if (value === 1) return t("active")
          return t("inactive")
        })
      },
    ];
    return columns;
  }

  // const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm') => {
  //   if (unixTime !== 0) {
  //     return moment.unix(unixTime).format(outputFormat);
  //   }
  //   return '-';
  // }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
      />
    </Layout>
  )
}

export default ProductInventoryHistoryTable;
