import React, { useState } from 'react'
import {
  Avatar, Badge, Popover, Row, Col, Button, notification
} from 'antd';
import { BellOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NotificationAlert = () => {
  const unreadNotification = useSelector((state) => state.app.unreadNotification)
  const [visible, setVisible] = useState(false);
  // const history = useHistory();

  console.log("redux", unreadNotification)

  const navigateToOrderInfo = (order_id) => {
    window.location = `/company/order_info_form?order_id=${order_id}&activeTab=order_comment`;
    // history.push(`/company/order_info_form?order_id=${order_id}&activeTab=order_comment&redirectTab=${!prev}`)
  }

  const content = (
    <div>
      {_.map(unreadNotification, (item) => {
        return (
          <Row style={{marginBottom: 10}}>
            <Col span={24}>
              <Badge count={item.unread}>
                <Button
                  type="text"
                  onClick={() => {
                    navigateToOrderInfo(item.order_id)
                    setVisible(false)
                  }}
                >
                  {item.order_no}
                </Button>
              </Badge>
            </Col>
          </Row>
        )
      })}
    </div>
  );

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  return (
    <>
      <Popover
        content={content}
        title="Notification List"
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <Badge count={_.size(unreadNotification)}>
          <Avatar
            shape="circle"
            icon={<BellOutlined />}
          />
        </Badge>
      </Popover>
    </>
  )
}

export default NotificationAlert
