const _ = require('lodash');
const debug = require('debug')('app:lib/sync-socket');

// const logger = {
//   info: (...args) => {
//     console.info(...args);
//   },
//   log: (...args) => {
//     console.info(...args);
//   },
//   warn: (...args) => {
//     console.info(...args);
//   },
//   error: (...args) => {
//     console.info(...args);
//   },
// };

const HEARTBEAT_INTERVAL = 15000; // milliseconds
const HEARTBEAT_TIMEOUT = 60000; // milliseconds

let wsUrl;
let connectionHeaders = {};
let onMessageCallback = _.noop();
let onTimeoutCallback = _.noop();

let ws;
let monitorTimer;
let heartbeatTime = 0;

const resetStatus = () => {
  heartbeatTime = 0;
};

/**
 * @see https://github.com/websockets/ws#how-to-detect-and-close-broken-connections
 */
function heartbeat() {
  debug(`heartbeat`);

  heartbeatTime = Math.floor(Date.now() / 1000);

  clearTimeout(this.pingTimeout);

  if (ws !== this) {
    // Terminate websocket which is not active websocket
    // this.terminate();
    this.close();
    ws = null;
    return;
  }

  // Use `WebSocket#terminate()`, which immediately destroys the connection,
  // instead of `WebSocket#close()`, which waits for the close timer.
  // Delay should be equal to the interval at which your server
  // sends out pings plus a conservative assumption of the latency.
  this.pingTimeout = setTimeout(() => {
    // this.terminate();
    this.close();
  }, HEARTBEAT_TIMEOUT);
}

/**
 * Connection websocket for synchronization
 */
const connect = () => {
  debug(`connect`);
  debug(`headers >> `, connectionHeaders);

  resetStatus();

  // Only one websocket is allowed
  // Terminate previous socket if exists
  if (ws) {
    // ws.terminate();
    ws.close();
    ws = null;
  }

  ws = new WebSocket(wsUrl);
  // ws = new WebSocket(wsUrl)
  debug(`ws>>>`, ws)

  // ws.on('open', function open() {
  //   heartbeat.call(this);
  // });

  // Connection opened
  ws.addEventListener('open', function (event) {
    debug(`addEventListener open`)
    ws.send('Hello Server!');
    heartbeat.call(this);
  });

  ws.addEventListener('message', function incoming(e) {
    debug(`onMessage :: ${e.data}`);
    if (ws !== this) {
      console.warn('SyncSocket :: not active websocket!!!');
      // this.terminate();
      this.close();
      return;
    }
    onMessageCallback(e.data);
  });

  // Listen for messages
  ws.addEventListener('message', function (event) {
    console.log('Message from server ', event.data);
  });

  // ws.on('ping', heartbeat);
  // ws.on('pong', heartbeat);

  // ws.on('close', function close(code, reason) {
  //   debug(`onClose :: ${code} :: ${reason}`);
  //   this.terminate();
  //   if (ws === this) {
  //     // Terminate websocket which is not active websocket
  //     ws = null;
  //   }
  // });

  ws.addEventListener('error', function error(err) {
    console.error(err);
    // this.terminate();
    // if (ws === this) {
    //   // Terminate websocket which is not active websocket
    //   ws = null;
    // }
  });
};

/**
 * Reconnet websocket
 */
const reconnect = () => {
  resetStatus();

  if (ws) {
    // ws.terminate();
    ws.close();
    ws = null;
  }

  connect();
};

/**
 * Monitor websocket connection and reconnect if websocket is not exists
 */
const startConnectionMonitor = () => {
  debug(`startConnectionMonitor`);

  // Only one connetion monitor timer is allowed
  clearInterval(monitorTimer);
  monitorTimer = null;

  monitorTimer = setInterval(() => {
    // debug(`check connection`);

    // Connect the socket if not exists or not connected
    if (!ws || ws.readyState === WebSocket.CLOSED) {
      onTimeoutCallback();
      reconnect();
      return;
    }

    const nowTs = Math.floor(Date.now() / 1000);
    const isHeartbeatTimeout = nowTs - heartbeatTime > (HEARTBEAT_TIMEOUT / 1000);
    if (isHeartbeatTimeout) {
      console.warn(`SyncSocket :: connection timeout, websocket idle for ${HEARTBEAT_TIMEOUT}`);
      onTimeoutCallback();
      reconnect();
    }

    // debug(`ws.readyState >> ${ws.readyState}`);

    // ws.ping();
  }, HEARTBEAT_INTERVAL);
};

exports.start = (url, {
  onMessage,
  onTimeout,
  headers,
}) => {
  wsUrl = url;
  // if (headers) {
  //   const stringified = queryString.stringify(headers)
  //   wsUrl += `?${stringified}`
  // }
  onMessageCallback = onMessage || _.noop();
  onTimeoutCallback = onTimeout || _.noop();
  connectionHeaders = headers;
  connect();
  startConnectionMonitor();
};

exports.getStatus = () => {
  return {
    heartbeatTime,
  };
};
