import React, {
  useState, useEffect, useRef, useCallback
} from 'react';
import {
  Button, Divider, notification, Space, Input, DatePicker
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import OrderInfoTable from './OrderInfoTable';
import FormUploadExcel from '../../components/form/FormUploadExcel';

const debug = require('debug')('app:admin:client:src:orderInfo');

let fuse = [];

const DEFAULT_PAGINATION = {pageSize: 10, current: 1, total: 0}

const OrderInfo = (props) => {
  const history = useHistory();
  const { t } = useTranslation()
  const inputRef = useRef();
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [searchInput, setSearchInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [dataList, setDataList] = useState([]);

  // Range Picker
  const [dateValue, setDateValue] = useState([moment().subtract(7, 'days').startOf('day'), moment().endOf('day')]);
  const [dates, setDates] = useState(null);

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  useEffect(() => {
    if (!dates) {
      getAllData();
    }
  }, [dates]);

  const getAllData = useCallback(async (tablePagination = pagination, searchWord = searchInput) => {
    try {
      setIsLoading(true);
      // const from = dateValue ? dateValue[0].startOf('day').unix() : moment().subtract(7, 'days').startOf('day').unix();
      // const to = dateValue ? dateValue[1].startOf('day').unix() : moment().endOf('day').unix();
      let resp = await Service.call('get', '/api/order_info/list/pagination', {
        // from,
        // to,
        ...tablePagination,
        searchWord
      });
      // console.log('data', data);

      if (resp.status !== 1) {
        notification.warning({ message: '獲取資料失敗' });
        return;
      }

      const orderList = resp.data.map((item) => {
        let entity = item;
        entity.order_no_with_prefix = item.order_no_prefix + item.order_no;
        return entity;
      })

      // const fuseOptions = {
      //   findAllMatches: true,
      //   ignoreLocation: true,
      //   includeScore: true,
      //   threshold: 0,
      //   keys: [
      //     'order_no_with_prefix', 'owner_ first_name', 'owner_last_name', 'owner_mobile', 'pet_name', 'pick_up_time', 'pick_up_address'
      //   ]
      // };
      // fuse = new Fuse(orderList, fuseOptions);

      setDataList(orderList);
      // filterData({searchWord: inputRef.current.input.value})
      setPagination({...tablePagination, total: resp.total})
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setIsLoading(false)
    }
  }, [pagination])

  const importOrderCSV = async (filepath) => {
    let resp = await Service.call('post', '/api/order_info/import', {
      filepath,
    });
    let description = " "
    if (resp.arr.length > 0) {
      description = `Row ${resp.arr} import failed`
    }

    notification.success({
      message: `Order import done. ${resp.succeed} succeed, ${resp.failed} failed.`,
      description,
      duration: 0
    });

    getAllData()
  }

  const debouncedSearch = useRef(
    _.debounce(async (searchWord) => {
      getAllData(DEFAULT_PAGINATION, searchWord)
    }, 300)
  ).current;

  const filterData = async (filterObj = {}) => {
    setSearchInput(filterObj.searchWord)
    debouncedSearch(filterObj.searchWord)
  }

  const paginationOnChange = useCallback((tablePagination) => {
    getAllData(tablePagination)
  }, [getAllData, pagination])

  return (
    <NavAndSideFrame {...props} title={t("order_info_list")} selectedKey="order_info">
      <Space direction="horizontal">
        <Button
          // className="p_order_agreement_edit p_order_edit"
          className="p_order_agreement_edit"
          type="primary"
          onClick={() => {
            history.push('/company/order_info_form')
          }}
        >
          {t("add_new_order")}
        </Button>

        <Button
          type="primary"
          onClick={() => {
            Main.exportToCSV(dataList, 'excel')
          }}
        >
          {t('export')}
        </Button>

        <FormUploadExcel
          onSuccess={(resp) => {
            const {
              originalname,
              filename,
              filepath,
              url,
            } = resp;
            importOrderCSV(filepath)
          }}
        />

        <>
          {t('search')}
        </>
        <Input
          ref={inputRef}
          placeholder={t('please_input_keyword')}
          onChange={(event) => {
            filterData({ searchWord: event.target.value })
          }}
        />
        {/* <RangePicker
          value={dates || dateValue}
          disabledDate={disabledDate}
          onCalendarChange={(val) => setDates(val)}
          onChange={(val) => setDateValue(val)}
          onOpenChange={onOpenChange}
          disabled={isLoading}
        /> */}
      </Space>

      <Divider />
      <OrderInfoTable
        dataObj={dataList}
        fetchData={getAllData}
        pagination={pagination}
        paginationOnChange={paginationOnChange}
        isLoading={isLoading}
      />
    </NavAndSideFrame>
  )
}

export default OrderInfo;
