import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Col, Form, Input, Layout, Modal,
  notification, Radio, Row, Select, Tabs, Upload, message,
  Transfer
} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import { formItemLayout, tailLayout } from "./ModalLayout";

const debug = require('debug')('app:admin:components:UserRoleForm');

const { Option } = Select;

const UserRoleForm = (props) => {
  const { t } = useTranslation();

  const { dataObj } = props
  const { company_user_id = 0 } = dataObj

  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [permissionSet, setPermissionSet] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const company_admin = useSelector((state) => state.app.company_admin);

  useEffect(() => {
    console.log(dataObj)
    if (dataObj.company_user_role_id !== 0) {
      let dataSet = dataObj.permission.split(',')
      setTargetKeys(dataSet)
    }
    form.resetFields();
    passwordForm.resetFields(); // Reset password when changing user selected
  }, [dataObj]);

  const getAllPermission = async () => {
    let result = await Service.call('get', '/api/company/user_role_permission')
    console.log('result', result)
    if (result.status !== 1) {
      return notification.warning({ message: '獲取資料失敗' });
    }
    let dataSet = result.data.map((item) => {
      return {key: item, title: t(item)}
    })
    setPermissionSet(dataSet)
  }

  useEffect(() => {
    getAllPermission();
  }, [])

  const onChange = (nextTargetKeys, direction, moveKeys) => {
    console.log('targetKeys:', nextTargetKeys);
    console.log('direction:', direction);
    console.log('moveKeys:', moveKeys);
    setTargetKeys(nextTargetKeys);
    form.setFieldsValue({ permission: nextTargetKeys})
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    console.log('sourceSelectedKeys:', sourceSelectedKeys);
    console.log('targetSelectedKeys:', targetSelectedKeys);
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onFinish = async (postObj) => {
    let url = `/api/company/user_role`;
    console.log("Post Object", postObj)
    // Patch
    if (dataObj.company_user_role_id > 0) {
      postObj.company_user_role_id = dataObj.company_user_role_id;

      let data = await Service.call('patch', url, postObj);
      if (data.errorMessage) {
        message.error(data.errorMessage);
        return props.openModal(true);
      }
      message.success('success');
      return props.openModal(false);
    }

    // POST
    postObj.create_time = moment().unix();
    let data = await Service.call('post', url, postObj);
    if (data.errorMessage) {
      message.error(data.errorMessage);
      return props.openModal(true);
    }
    message.success('success');
    return props.openModal(false);
  };

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="time_related_controls"
        onFinish={onFinish}
        initialValues={dataObj}
      >
        {/* <Form.Item
        label="Company"
        name="company_id"
        rules={[{ required: true, message: 'Please select one.' }]}
      >
        <Select
          placeholder="- select one -"
          disabled={dataObj.company_id}
          onChange={(value)=> {
            getCompanyRole(value)
          }}
        >
          {companyList.map(({ company_id, company_name }) =>
            (<Option value={company_id}>{`(${company_id}) `+company_name}</Option>)
          )}
        </Select>
      </Form.Item> */}
        <Form.Item
          label={t('role_name')}
          name="role_name"
          rules={[{ required: true, message: 'Please input role name.' }]}
        >
          <Input autoFocus />
        </Form.Item>
        <Form.Item
          label={t('role_key')}
          name="role_key"
          rules={[{ required: true, message: 'Please input role key.' }]}
        >
          {/* <Input /> */}
          <Select
            placeholder={`- ${t('please_select')} -`}
          >
            <Option value="admin">admin</Option>
            <Option value="superadmin">superadmin</Option>
            <Option value="driver">driver</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t('permission')}
          name="permission"
        >
          <Transfer
            dataSource={permissionSet}
            titles={['Source', 'Target']}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={onChange}
            onSelectChange={onSelectChange}
            render={(item) => item.title}
            listStyle={{
              width: 300,
              height: 300,
            }}
          />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            htmlType="submit"
            className='p_permission_edit'
          >
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default UserRoleForm;
