import React, { Component, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Divider,
  Form,
  Icon,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  message,
  notification,
  Space,
  Input,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  CheckOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from 'query-string';
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import FormUploadFile from '../../components/form/FormUploadFile';
import PPSignature from '../../components/PPSignature';

const debug = require('debug')('app:admin:client:src:pages:OrderInfoConfirmedSignatureForm');

const OrderInfoConfirmedSignatureForm = (props) => {
  const history = useHistory();
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)
  const { t } = useTranslation()
  let location = useLocation();

  let { order_id } = queryString.parse(location.search);
  let { initialValues, order_id: orderIdProp = 0, isReadOnly } = props
  if (orderIdProp > 0) {
    order_id = orderIdProp // Override order_id if prop existing
  }

  const { order_confirmed_signature } = initialValues

  const [form] = Form.useForm();
  const [signatureFormInitialValues, setSignatureFormInitialValues] = useState(initialValues);
  const [postImageURL, setFileURL] = useState(null);
  const [filePath, setFilePath] = useState(null);

  useEffect(() => {
    setFileURL(`${STATIC_SERVER_URL}/${order_confirmed_signature}`);
    setFilePath(order_confirmed_signature)
  }, [order_confirmed_signature])

  useEffect(() => {
    let newDefaultValues = { ...signatureFormInitialValues, ...initialValues };
    setSignatureFormInitialValues(newDefaultValues);
  }, [initialValues])

  useEffect(() => {
    form.resetFields();
  }, [signatureFormInitialValues]);

  const uploadOnChange = (info) => {
    console.log('info', info)
    const { status, response } = info.file;
    if (status === 'done') {
      message.destroy()
      if (response.status > 0) {
        message.success('Uploaded file');
        let data = {
          filename: response.filename,
          filepath: response.filepath,
          url: response.url,
        };
        setFilePath(data.filepath);
        setFileURL(data.url);
      } else {
        message.error(t('common:Failed'))
      }
    }
  };

  const onFinished = async (obj) => {
    if (_.isEmpty(filePath)) {
      return message.error(t('missing_filePath'))
    }

    const postData = {
      order_id,
      order_confirmed_signature: filePath,
    };

    // return debug('postData', postData)

    const data = await Service.call('patch', '/api/order_info', postData)
    if (data.status !== 1) {
      notification.error({ message: '提交失敗', description: data.errorMessage });
      return;
    }

    notification.success({ message: '提交成功' });
    props.onChange()
  }

  return (
    <>
      <PPSignature isReadOnly={isReadOnly} />

      <Form
        layout="vertical"
        form={form}
        initialValues={signatureFormInitialValues}
        onFinish={onFinished}
      >
        <Form.Item
          label={t('upload_signature')}
        >
          <FormUploadFile
            isReadOnly={isReadOnly}
            data={{ scope: "public" }}
            onChange={uploadOnChange}
            imageURL={postImageURL}
          />
        </Form.Item>

        <Form.Item
          label={t("signature_date")}
          name="order_confirmed_signature_date"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item>
          <Button
            disabled={isReadOnly}
            type="primary"
            className="p_order_agreement_view"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default OrderInfoConfirmedSignatureForm;
