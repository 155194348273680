import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Row, Col, Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, AutoComplete, Tag, Switch
} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import debugLib from 'debug';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
// import NavAndSideFrame from '../../components/NavAndSideFrame';
import LanguageList from '../../data/LanguageList.json';

const debug = debugLib('app:pages/UserAccountForm');

const { Search, TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const UserAccountForm = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { t, i18n } = useTranslation()
  const { user_id } = queryString.parse(location.search);

  const { initialValues } = props

  const [UserForm] = Form.useForm();
  const [userType, setUseType] = useState({})
  const [areaCode, setAreaCode] = useState({})
  const [areaOptions, setArea] = useState([]);
  const [distGroupbyAddr, setDistGroupbyAddr] = useState({})
  const [districtOptions, setDistrict] = useState([]);
  const [staffOptions, setStaffOption] = useState([]);
  const [districtDisable, setDistrictDisable] = useState(true)
  const [showOrganization, setShowOrganization] = useState(false)

  useEffect(() => {
    fetchFormOption();
    fetchUserType()
    fetchAreaCode()
  }, []);

  useEffect(() => {
    UserForm.resetFields()
  }, [initialValues])

  useEffect(() => {
    // district options is depend on the current addr_area
    if (initialValues.addr_area && !_.isEmpty(distGroupbyAddr)) {
      setDistrict(distGroupbyAddr[initialValues.addr_area])
    }
    setDistrictDisable(!initialValues.addr_area)
  }, [initialValues.addr_area, distGroupbyAddr])

  useEffect(() => {
    showOrgInfo(initialValues.type)
  }, [initialValues.type])

  const showOrgInfo = (type) => {
    setShowOrganization((type === 'monthly' || type === 'wholesale'))
  }

  const fetchFormOption = async () => {
    fetchArea();
    fetchAreaDistrictLookup();
    fetchStaff();
  };

  const fetchStaff = async () => {
    let result = await Service.call("get", `/api/company/user`);
    if (result.status !== 1) return;
    let userInfoList = result.data;
    let staffOption = userInfoList.map((entity) => {
      return {label: entity.company_user_id + '-' + entity.first_name + " " + entity.last_name, value: entity.company_user_id}
    })
    setStaffOption(staffOption);
  };

  const fetchArea = async () => {
    let options = await Main.fetchFormOption("area");
    setArea(options);
  };

  const fetchAreaDistrictLookup = async () => {
    let {status, data} = await Service.call("get", `/api/area_district_option_lookup/all?area_id=0&lang=${i18n.language}`);
    if (status !== 1) return;
    let allAreaDistrict = data.map((item) => {
      let {area_name: area, district_name: value} = item
      return {area, value}
    })
    let districtGroup = {}
    _.forEach(_.groupBy(allAreaDistrict, 'area'), (districts, addr) => {
      districtGroup[addr] = districts.map(({value}) => {
        return {
          value
        }
      })
    })
    setDistGroupbyAddr(districtGroup);
  }

  const fetchUserType = async () => {
    let result = await Service.call('get', `/api/user_type`);
    if (result.status !== 1) {
      return notification.warning({ message: '獲取資料失敗' });
    }

    let { data } = result;
    setUseType(data);
  }

  const fetchAreaCode = async () => {
    let result = await Service.call('get', `/api/areaCode`);
    if (result.status !== 1) {
      return notification.warning({ message: '獲取區號失敗' });
    }

    let { data } = result;
    setAreaCode(data);
  }

  useEffect(() => {
    if (_.isEmpty(initialValues.type) || _.isNil(initialValues.type)) {
      UserForm.setFieldsValue({type: Object.keys(userType)[0]})
      return
    }
    UserForm.setFieldsValue({type: initialValues.type})
  }, [initialValues, userType])

  return (
    <>
      <Form
        form={UserForm}
        name="UserForm"
        layout="vertical"
        initialValues={initialValues}
        onFinish={async (postObj) => {
          let method = 'post';
          if (!_.isUndefined(user_id)) {
            method = 'patch';
            postObj.status = postObj.status ? 1 : 0
            postObj.user_id = user_id;
          }
          if (_.isNaN(_.toNumber(postObj.mobile))) {
            message.error(t('mobile_type_error'));
            return;
          }
          let results = await Service.call(method, '/api/user', postObj)
          // console.log('results', results)

          if (results.status !== 1) {
            return notification.error({ message: '提交失敗', description: results.errorMessage });
          }

          notification.success({ message: '提交成功' });
          history.push(`/company/user_account`);
        }}
      >
        <Row gutter={24}>
          <Col span={3}>
            <Form.Item
              label={t('user_id')}
              name="user_id"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t('ctime')}
              name="ctime"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label={`${t('activity')}`}
              name="statusText"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label={t('remain_credit_balance')}
              name="remain_credit_balance"
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item
              label={t('status')}
              name="status"
              valuePropName="checked"
            >
              <Switch checkedChildren={t('active')} unCheckedChildren={t('inactive')} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item
              label={t('type')}
              name="type"
            >
              <Select onChange={(value) => { showOrgInfo(value) }}>
                {_.map(userType, (val, key) => {
                  return (
                    <Option value={key} key={key}>{t(val)}</Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div style={{display: showOrganization ? 'block' : 'none' }}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label={t('organization')}
                name="organization_name"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t('credit_balance')}
                name="credit_balance"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label={t('organization_invoice_address')}
                name="organization_invoice_address"
              >
                <TextArea rows={1} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t('organization_shipping_address')}
                name="organization_shipping_address"
              >
                <TextArea rows={1} />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Row gutter={24}>
          <Col span={3}>
            <Form.Item
              label={t('salutation')}
              name="salutation"
            >
              <Select>
                <Option value="mr">{t("mr")}</Option>
                <Option value="mrs">{t("mrs")}</Option>
                <Option value="miss">{t("miss")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t('first_name')}
              name="first_name"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t('last_name')}
              name="last_name"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={t('mobile')}
              name="mobile"
              rules={[{ required: true, message: t('input_mobile') }]}
            >
              <Input
                addonBefore={(
                  <Form.Item
                    name="prefixMobile"
                    noStyle
                    rules={[{ required: true, message: t('input_mobile_prefix') }]}
                  >
                    <AutoComplete style={{ width: 80 }}>
                      {_.map(areaCode, (val, key) => {
                        return (
                          <Option value={val} key={key}>{val}</Option>
                        )
                      })}
                    </AutoComplete>
                  </Form.Item>
                )}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t('backup_telephone')}
              name="telephone"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={t('email')}
              name="email"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label={t('area')}
              name="addr_area"
            // rules={[{ required: false, message: 'Please input address area.' }]}
            >
              <AutoComplete
                showSearch
                options={areaOptions}
                placeholder={t("click_to_view_options")}
                filterOption={(inputValue, option) => {
                  return (
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  );
                }}
                onChange={(value, option) => {
                  setDistrictDisable(!value)
                  setDistrict(distGroupbyAddr[value])
                  UserForm.setFieldsValue({ addr_district: '' });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t('district')}
              name="addr_district"
            // rules={[{ required: false, message: 'Please input address district.' }]}
            >
              <AutoComplete
                showSearch
                filterOption={(inputValue, option) => {
                  return (
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  );
                }}
                options={districtOptions}
                placeholder={t("click_to_view_options")}
                disabled={districtDisable}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Form.Item
          label={t('region')}
          name="addr_region"
        // rules={[{ required: false, message: 'Please input address region.' }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          label={t('address')}
          name="address"
        // rules={[{ required: false, message: 'Please input address.' }]}
        >
          <Input />
        </Form.Item>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label={t('preferred_language')}
              name="preferred_language"
            >
              <Select>
                {_.map(LanguageList, (val, key) => {
                  return (
                    <Option value={key} key={key}>{val}</Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label={t('currency')}
              name="currency"
            >
              <Input />
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={t('remark')}
              name="remark"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label={t('consultant_staff')}// "地址" / "診所"
              name="create_staff_id"
            >
              <Select
                showSearch
                options={staffOptions}
                placeholder={t("click_to_view_options")}
                filterOption={(inputValue, option) => {
                  return (
                    option.label
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            className="p_user_edit"
            type="primary"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default UserAccountForm;
