import React, { useState, useEffect } from 'react';
import {
  Button, Layout, Table, Tooltip, Modal, notification, Select, Badge, Space
} from 'antd';
import {
  DeleteOutlined, EditOutlined, FileAddOutlined, FilePdfOutlined, CloseOutlined, CheckOutlined, DownloadOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux'
import * as Service from '../../core/Service';
import * as Main from "../../core/Main";
import HydrationMachinePlaceForm from './HydrationMachinePlaceForm'

const { confirm } = Modal;
const { Option } = Select

const HydrationMachinePlaceTable = (props) => {
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)
  const { dataObj, fetchData, hydration_machine_id } = props
  const [dataList, setDataList] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const tableIDName = "hydration_machine_lookup_id";
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    console.log(dataObj);
    setDataList(dataObj)
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record) => {
          let { is_active, qr_code } = record;
          return (
            <Space>
              <Tooltip title={t("modify")}>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSelectedSpace(value)
                    setModalVisible(true)
                  }}
                />
              </Tooltip>
              <Button
                shape="circle"
                icon={is_active ? <CloseOutlined /> : <CheckOutlined />}
                style={{ color: is_active ? "#AA0000" : "#00AA00" }}
                onClick={() => toggleActive(record)}
              />
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => toggleDelete(record)}
              />
              <Button
                shape="circle"
                icon={<FileAddOutlined />}
                onClick={async () => {
                  let result = await Service.call('post', `/api/hydration_machine_place/qrcode/${value}`)
                  // console.log('result', result)
                  if (result.status !== 1) return

                  notification.success({ message: t("success") });
                  fetchData();
                }}
              />
              {qr_code
              && (
              <Button
                shape="circle"
                icon={<DownloadOutlined />}
                onClick={() => {
                  window.open(`${STATIC_SERVER_URL}/${qr_code}`)
                }}
              />
              )}
            </Space>
          );
        },
      },
      {
        title: t('ctime'),
        dataIndex: `ctime`,
        render: (value) => {
          return Main.displayMoment(value);
        }
      },
      {
        title: t('utime'),
        dataIndex: `utime`,
        render: (value) => {
          return Main.displayMoment(value);
        }
      },
      {
        title: t('hydration_machine_place_name'),
        dataIndex: `name`,
      },
      {
        title: t('active'),
        dataIndex: `is_active`,
        render: (text, record, index) => {
          let { is_active } = record;
          return (
            <span>
              {is_active === 1 ? (
                <Badge status="processing" />
              ) : (
                <Badge color="#555" />
              )}
            </span>
          );
        },
      },
    ];
    return columns;
  }

  const toggleActive = (record) => {
    confirm({
      title: `${record.is_active ? t("confirm_deactivation") : t("confirm_permission")} ${record.name}？`,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      onOk: async () => {
        let postObject = {
          hydration_machine_lookup_id: record.hydration_machine_lookup_id,
          is_active: record.is_active ? 0 : 1,
        };
        let result = await Service.call("patch", "/api/hydration_machine_place", postObject);
        // console.log('result', result);

        if (result.status !== 1) {
          notification.error({
            message: t("submission_failed"),
            description: result.errorMessage,
          });
          return;
        }

        notification.success({ message: t("submitted_successfully") });
        props.fetchData();
      },
      onCancel: () => {
        // console.log('Cancel');
      },
    });
  };

  const toggleDelete = (record) => {
    confirm({
      title: `${t("confirm_delete")} ${record.name}？`,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      onOk: async () => {
        let result = await Service.call(
          "delete",
          `/api/hydration_machine_place/${record.hydration_machine_lookup_id}`
        );
        // console.log('result', result);

        if (result.status !== 1) {
          return notification.error({
            message: t("fail_to_delete"),
            description: t(result.errorMessage),
          });
        }

        notification.success({ message: t("successfully_deleted") });
        props.fetchData();
      },
      onCancel: () => {
        // console.log('Cancel');
      },
    });
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
      />
      <Modal
        title={t('edit_hydration_machine_place')}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <HydrationMachinePlaceForm
          machine_space_id={selectedSpace}
          hydration_machine_id={hydration_machine_id}
          closeMethod={async () => {
            fetchData()
            setModalVisible(false);
          }}
        />
      </Modal>
    </Layout>
  )
}

export default HydrationMachinePlaceTable;
