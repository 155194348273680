import React, {
  useState, useEffect, useRef, useContext
} from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Radio, Popconfirm, Spin, Table, Space, TreeSelect
} from 'antd';
import {
  DeleteOutlined, EditOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';

import FormUploadFile from '../../components/form/FormUploadFile';
import HydrationBatchDetail from './HydrationBatchDetail'

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const HydrationBatchForm = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { hydration_batch_id } = queryString.parse(location.search);
  const { t, i18n } = useTranslation();
  const isNeededOptions = [{ label: t('yes'), value: 'yes' }, { label: t('no'), value: 'no' }]

  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({is_active: 1});
  const [options, setOptions] = useState([])
  const [statusOptions, setStatusOptions] = useState([])
  // const [cost, setCost] = useState(0)
  useEffect(() => {
    if (!_.isUndefined(hydration_batch_id)) {
      fetchData();
      // fetchCost(hydration_batch_id);
    }
    getAllHydrationMachine()
  }, []);

  useEffect(() => {
    getStatusOption()
  }, [i18n.language])

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const fetchData = async () => {
    try {
      let hydrationBatchResult = await Service.call('get', `/api/hydration_batch/${hydration_batch_id}`);
      let newDefaultValues = { ...initialValues, ...hydrationBatchResult.data };
      console.log("Values", newDefaultValues)
      setInitialValues(newDefaultValues);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  const getAllHydrationMachine = async () => {
    try {
      let hydrationMachineResult = await Service.call('get', '/api/hydration_machine');
      let hydrationMachine = hydrationMachineResult.data;
      if (!_.isEmpty(hydrationMachine)) {
        let hydrationMachineOptions = _.map(hydrationMachine, (item) => {
          return {label: item.name, value: item.hydration_machine_id}
        })
        setOptions(hydrationMachineOptions);
      }
    } catch (error) {
      console.error('error >>>', error);
    }
  }

  const getStatusOption = async () => {
    try {
      let statusResult = await Service.call('get', '/api/hydration_batch_status');
      let statusList = statusResult.data;
      if (!_.isEmpty(statusList)) {
        const editedStatusList = _.map(statusList, (item) => {
          return {
            label: t(item.label),
            value: item.value
          }
        })
        setStatusOptions(editedStatusList);
      }
    } catch (error) {
      console.error('error >>>', error);
    }
  }

  return (
    <NavAndSideFrame {...props} title={!_.isUndefined(hydration_batch_id) ? t("modify_item") : t("add_new_item")} selectedKey="hydration_batch_setting">
      <Tabs type="hydration_batch" defaultActiveKey="hydration_batch_info" onChange={(key) => null}>
        <TabPane tab={t("hydration_batch_info")} key="hydration_batch_info">
          <Form
            form={form}
            name="hydrationBatchForm"
            layout="vertical"
            onFinish={async (postObj) => {
              let method = 'post';
              if (!_.isUndefined(hydration_batch_id)) {
                method = 'patch';
                postObj.hydration_batch_id = hydration_batch_id;
              }
              // console.log('object >>> ', postObj);
              let data = await Service.call(method, '/api/hydration_batch', postObj);
              // console.log('data', data);

              if (data.status !== 1) {
                notification.error({ message: t("submission_failed"), description: data.errorMessage });
                return;
              }

              notification.success({ message: t("submitted_successfully") });
              history.push(`/company/hydration_batch_setting`);
            }}
            initialValues={initialValues}
          >

            <Form.Item
              label={t("hydration_machine_name")}
              name="hydration_machine_id"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                placeholder={t('search')}
                options={options}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              />
            </Form.Item>

            {hydration_batch_id && (
              <Form.Item
                label={t("hydration_batch_status")}
                name="status"
              >
                <Select
                  showSearch
                  placeholder={t('search')}
                  options={statusOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                />
              </Form.Item>
            )}

            <Form.Item
              label={t("activity")}
              name="is_active"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group>
                <Radio value={1}>{t("active")}</Radio>
                {/* 活動 */}
                <Radio value={0}>{t("inactive")}</Radio>
                {/* 不活動 */}
              </Radio.Group>
            </Form.Item>

            <Form.Item>
              <Button
                // className="p_product_name_edit"
                className="p_order_hydration_edit"
                type="primary"
                htmlType="submit"
              >
                {t("submit")}
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
        <TabPane tab={t("hydration_batch_detail")} key="hydration_batch_detail" disabled={!hydration_batch_id}>
          <HydrationBatchDetail
            fetchData={fetchData}
            hydrationBatchObject={initialValues}
          />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  )
}

export default HydrationBatchForm;
