import React, { useState, useEffect } from 'react';
import {
  notification, Tabs, Table, Badge, Button, Modal, Form, Input, Radio
} from 'antd';
import {
  DeleteOutlined, EditOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import LanguageList from '../../data/LanguageList.json'

const OptionForm = (props) => {
  const { t, i18n } = useTranslation();
  const { TextArea } = Input
  const {
    selectedFormData, type, closeMethod, categoryID, areaID, districtID, isPickUp, isActive, isPublic
  } = props
  const [dataList, setDataList] = useState([])
  let initialValues = { is_active: 1 }
  let combinedInitialValues = { ...initialValues, ...selectedFormData }
  // console.log("selectedFormData", selectedFormData);
  const [form] = Form.useForm();
  // console.log("sadasdasd", type);
  useEffect(() => {
    getAllData();
  }, []);
  const getAllData = async () => {
    try {
      let result = await Service.call('get', `/api/form_option/all/${type}`);

      if (result.status !== 1) {
        notification.warning({ message: t("failed_to_obtain_information") });
        return;
      }
      let { data } = result;
      // console.log("dasdadas", data);
      setDataList(data);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }
  return (
    <Form
      form={form}
      initialValues={combinedInitialValues}
      onFinish={async (values) => {
        values.type = type
        values.language = i18n.language
        if (isActive === 1) values.is_active = 1; else values.is_active = 0
        let method = 'post'
        if (type === "ceremony_item") {
          _.map(dataList, async (item) => {
            if (item.form_option_id !== selectedFormData && item.is_default !== 0) {
              let off = { ...item }
              off.is_default = 0
              if (isPublic === 1) await Service.call("patch", '/api/form_option_public', off)
              else await Service.call("patch", '/api/form_option', off)
            }
          })
        }
        let result
        if (isPublic === 1) result = await Service.call(method, '/api/form_option_public', values)
        else { result = await Service.call(method, '/api/form_option', values) }
        if (categoryID !== 0 && type === 'pet_species' && isActive !== 1) {
          let petCategorySpeciesResult = await Service.call("post", '/api/pet_category_species_lookup', {pet_category_id: categoryID, pet_species_id: result.data.form_option_id, is_active: 0})
          console.log(petCategorySpeciesResult);
        }
        if (areaID !== 0 && type === 'district') {
          let areaDistrictResult = await Service.call("post", '/api/area_district_option_lookup', {area_id: areaID, district_id: result.data.form_option_id, is_active: 1})
          console.log(areaDistrictResult);
        }
        if (areaID !== 0 && districtID !== 0 && isPickUp) {
          let pickUpRelationResult = await Service.call("post", '/api/area_district_option_lookup', {area_id: areaID, district_id: districtID, form_option_id: result.data.form_option_id, type, is_active: 1 })
          console.log(pickUpRelationResult);
        }
        // console.log('result', result)

        if (result.status !== 1) {
          return notification.error({ message: t("submission_failed"), description: result.errorMessage });
        }

        notification.success({ message: t("submitted_successfully") });
        closeMethod(result.data.form_option_id, result.data.name)
      }}
    >

      {i18n.language !== (_.keys(LanguageList))[0] && (
      <Form.Item
        label={t('name')}
        name="name"
        rules={[{ required: true }]}
      >
        <Input disabled autoFocus />
      </Form.Item>
      )}

      {i18n.language === (_.keys(LanguageList))[0] && (
      <Form.Item
        label={t('name')}
        name="name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      )}

      {i18n.language !== (_.keys(LanguageList))[0] && (
      <Form.Item
        label={t('remark')}
        name="remark"
      >
        <TextArea rows={4} disabled />
      </Form.Item>
      )}

      {i18n.language === (_.keys(LanguageList))[0] && (
      <Form.Item
        label={t('remark')}
        name="remark"
      >
        <TextArea rows={4} />
      </Form.Item>
      )}

      {type === "ceremony_item" && (
        <Form.Item
          label={t('default')}
          name="is_default"
        >
          <Radio.Group>
            <Radio value={1}>{t('default')}</Radio>
            <Radio value={0}>{t('non_default')}</Radio>
          </Radio.Group>
        </Form.Item>
      )}

      <Button type="primary" htmlType="submit">
        {t('submit')}
      </Button>
    </Form>
  )
}

export default OptionForm;
