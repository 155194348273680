import React, { useState, useEffect } from 'react';
import {
  Button, Layout, Table, Tooltip, Modal, notification, Badge
} from 'antd';
import {
  DeleteOutlined, EditOutlined, CloseOutlined, CheckOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';

const { confirm } = Modal;

const ReceiptInfoTable = (props) => {
  const [dataList, setDataList] = useState([]);
  const tableIDName = "receipt_info_id";
  const history = useHistory();
  const { t } = useTranslation();
  const meta = useSelector((state) => state.app.meta);
  const { loading, pagination, paginationOnChange } = props;

  useEffect(() => {
    setDataList(props.dataObj)
  }, [props.dataObj])

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record) => {
          let { is_active } = record
          return (
            <span>
              <Tooltip title={t("modify")}>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    history.push(`/company/receipt_info_form?receipt_info_id=${value}`)
                  }}
                  style={{ marginRight: 8, color: '#000000' }}
                />
              </Tooltip>
            </span>
          )
        }
      },
      {
        title: t("order_no"),
        dataIndex: `order_no_with_prefix`,
      },
      {
        title: t("invoice_no"),
        dataIndex: `invoice_no_with_prefix`,
      },
      {
        title: t("receipt_no"),
        dataIndex: `receipt_no_with_prefix`,
      },
      {
        title: t("receipt_date"),
        dataIndex: `receipt_date`,
        render: (value) => {
          return Main.displayMoment(value);
        }
      },
      // {
      //   title: `總金額`,
      //   dataIndex: `receipt_total`,
      // },
      {
        title: t("telephone"),
        dataIndex: `mobile`,
      },
      {
        title: t("payment_method"),
        dataIndex: `payment_method`,
      },
      {
        title: t("payment_total"),
        dataIndex: `payment_total`,
      },
    ];

    return columns;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        loading={loading}
        pagination={pagination}
        onChange={paginationOnChange}
      />
    </Layout>
  )
}

export default ReceiptInfoTable;
