import React, { Component } from 'react';
import { LockOutlined, MailOutlined, KeyOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Content, Input, Layout, Row, Select, Tabs, message, Form, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth, setCompanyAdmin, setCompanyUser } from '../../redux/actions/common'
// import 'antd/dist/antd.css';
// import '@ant-design/compatible/assets/index.css';
import * as Service from '../../core/Service';
import logo from '../../assets/Logo_PTN.png';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";

const debug = require("debug")("app:/pages/company");

const Login = () => {
    const recaptchaRef = React.useRef();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const config = useSelector(state=> state.app.config)
    const { RECAPTCHA_KEY } = config;
    const auth = useSelector(state => state.app.auth)
    const onFinish =  async (formData) => {
      let {username, password} = formData;

      let postData = {
        username,
        password
      }
      await recaptchaRef.current.execute();
      let recaptchaToken = recaptchaRef.current.getValue();
      postData.recaptchaToken = recaptchaToken

      let data = await Service.call('post', `/api/login/company_user`, postData);

      // if (data.errorMessage) return message.error(data.errorMessage);
      
      // let adminData = await Service.call('get', `/api/login/get_company_admin`);
      // if (adminData.errorMessage) return dispatch(setAuth(false));
      // if (_.isEmpty(adminData.userData)) return dispatch(setAuth(false));
      // console.log('AdminData>>>', adminData);
      // dispatch(setCompanyAdmin(adminData.userData));
      // dispatch(setAuth(true));
      // history.push('/company/home')

      if (data.status !== 1) {
        return message.error(data.errorMessage);
      }
      notification.success({ message: t("success") });
      dispatch(setCompanyUser(data.userData));
      dispatch(setAuth(true));
      // Clear cache
      setTimeout(() => {
        window.location.replace('/company/home');
      }, 2000)
    }

    const displayLogin = () => {
      return (
        <div className="" style={{display: 'flex', justifyContent: 'center'}}>
          <Form 
            className="login-form col-12 mt-2"
            style={{width: '100%'}}
            form={form}
            onFinish={onFinish}
          >
            {/* <Form.Item
              name="company_key"
              rules={[{ required: true, message: 'Please input company key.' }]}
            >
              <Input
                prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Company Key"
              />
            </Form.Item> */}
            <Form.Item
              name="username"
              rules={[{ required: true,
                message: 'Please input username.'}]}
              //   type: 'email' }]}
            >
              <Input
                prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Username"
              />
            </Form.Item>
            {/* <Form.Item
              name="mobile"
              rules={[{ required: true, message: 'Please input mobile.' }]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Mobile"
              />
            </Form.Item> */}

            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input password.' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (/(?=.*[a-z])(?=.*[A-Z])((?=.*\d)|(?=.*[-+_!@#$%^&*.,?]))(?=.{6,})/.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject("At least 6 characters with combination of one Uppercase, one Lowercase and one number/symbol.");
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Password"
              />
            </Form.Item>

            <Button
              style={{width: "100%"}}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              登入
            </Button>
            {/* <p className="forgot-password text-right">
                Login with <a href="#" onClick={(e) => this.changeLoginType(e)}>{this.state.isMobile ? 'email' : 'mobile'}</a>
            </p> */}

            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={RECAPTCHA_KEY}
            />

          </Form>
        </div>
      );
    }

  return (
      <Layout>
        <Layout.Content style={{padding: '50px', backgroundColor: 'white'}}>
          <Row type="flex" justify="center">
            <Col xs={20} sm={16} md={14} lg={10} xl={8} className="pt-5 pb-3" style={{alignItems: 'center', justifyContent: 'center'}}>
              <div style={{textAlign: 'center', marginTop: '15px'}}>
                <div>
                  <img alt="" src={logo} style={{width: '100%', maxWidth: '300px', marginBottom: '30px'}} />
                </div>
                <h2>Pet to Nature</h2>
                <h3>Admin Panel</h3>
              </div>
            </Col>
          </Row>
          <Row type="flex" justify="center" style={{marginTop: '15px'}}>
            <Col
              xs={22}
              sm={16}
              md={14}
              lg={10}
              xl={8}
              style={{alignItems: 'center', justifyContent: 'center'}}
            >
              {
                displayLogin()
              }
            </Col>
          </Row>
          {/* <Row type="flex" justify="center">
            <Col xs={20} sm={16} md={14} lg={10} xl={8} className="pt-5 pb-3" style={{alignItems: 'center', justifyContent: 'center'}}>
              <a href={`${config.STATIC_SERVER_URL}/admin/login`}>Technine admin login</a> 
            </Col>
          </Row> */}
        </Layout.Content>
      </Layout>
    )
}

export default Login;

// function mapStateToProps(state) {
//   return {
//     app: state.app
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(ActionCreators, dispatch);
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Login);

