import React, {
  useState, useEffect, useRef, useContext
} from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Radio, Popconfirm, Spin, Table, Space, TreeSelect, Modal
} from 'antd';
import {
  DeleteOutlined, EditOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';

import FormUploadFile from '../../components/form/FormUploadFile';
import ProductInventory from './ProductInventory'

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const ProductItemForm = (props) => {
  const history = useHistory();
  let location = useLocation();
  const myPermission = useSelector((state) => state.app.company_user.myPermission)
  const { product_id } = queryString.parse(location.search);
  const { t } = useTranslation();
  const isNeededOptions = [{ label: t('yes'), value: 'yes' }, { label: t('no'), value: 'no' }]

  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const [options, setOption] = useState([])

  const [isAllowProductCostEdit, setIsAllowProductCostEdit] = useState(false);
  const [isAllowWholesaleCostEdit, setIsAllowWholesaleCostEdit] = useState(false);
  // const [cost, setCost] = useState(0)

  useEffect(() => {
    if (!_.isUndefined(product_id)) {
      fetchData();
      // fetchCost(product_id);
    }
    fetchProductCategoryOption()
    setFeildsEditable();
  }, []);

  useEffect(() => {
    form.resetFields();
    // if (!_.isUndefined(product_id)) {
    //   form.setFieldsValue({ price: cost });
    // }
  }, [initialValues]);

  const setFeildsEditable = () => {
    let isAllowPermission = _.intersection(myPermission, ['p_product_cost_edit', 'p_any'])
    if (!_.isEmpty(isAllowPermission)) {
      setIsAllowProductCostEdit(true);
    }
    isAllowPermission = _.intersection(myPermission, ['p_product_wholesale_price_edit', 'p_any'])
    if (!_.isEmpty(isAllowPermission)) {
      setIsAllowWholesaleCostEdit(true);
    }
  }

  const fetchData = async () => {
    try {
      let productItemResult = await Service.call('get', `/api/product/${product_id}`);
      // console.log('productItemResult', productItemResult)
      // get the lastest cost price from 庫存記錄
      // let productItemResultHistoryResult = await Service.call('get', `/api/product_inventory_stock_history?product_id=${product_id}`);
      // let result = _.maxBy(productItemResultHistoryResult.data, 'ctime')
      // let costPrice = result.cost
      // if (productItemResult.status !== 1) {
      //   return;
      // }

      let newDefaultValues = { ...initialValues, ...productItemResult.data };
      setInitialValues(newDefaultValues);
      // // set cost price at貨品資料 by 庫存記錄
      // newDefaultValues.cost = costPrice
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  // const fetchCost = async (product_id) => {
  //   let result = await Service.call('get', `/api/product_inventory_stock_history?product_id=${product_id}`)
  //   let latestCost = _.maxBy(result.data, "utime")
  //   setCost(latestCost.cost)
  // }

  const fetchProductCategoryOption = async () => {
    let cascaderOptions = await Main.fetchProductCategoryOption()
    setOption(cascaderOptions);
  }

  return (
    <NavAndSideFrame {...props} title={!_.isUndefined(product_id) ? t("modify_item") : t("add_new_item")} selectedKey="product_item">
      <Tabs type="product" defaultActiveKey="product_info" onChange={(key) => null}>
        <TabPane tab={t("item_info")} key="product_info">
          <Form
            form={form}
            name="productItemForm"
            layout="vertical"
            onFinish={async (postObj) => {
              let method = 'post';
              if (!_.isUndefined(product_id)) {
                method = 'patch';
                postObj.product_id = product_id;
              }
              // console.log('object >>> ', postObj);
              let data = await Service.call(method, '/api/product', postObj);
              // console.log('data', data);

              if (data.status !== 1) {
                notification.error({ message: t("submission_failed"), description: data.errorMessage });
                return;
              }

              notification.success({ message: t("submitted_successfully") });
              history.push(`/company/product_item`);
            }}
            initialValues={initialValues}
          >

            <Form.Item
              label={t("product_name")}
              name="product_name"
              rules={[{ required: true }]}
            >
              <Input autoFocus />
            </Form.Item>

            <Form.Item
              label={t("sku")}
              name="sku"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("product_category_lookup")}
              name="product_category_lookup"
            >
              <TreeSelect
                treeData={options}
                multiple
                onChange={() => null}
              />
            </Form.Item>

            <Form.Item
              label={t('hydration_preparation')}
              name="hydration_preparation"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: 80 }}
                options={isNeededOptions}
                filterOption={(inputValue, option) => {
                  return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                }}
              />
            </Form.Item>

            <Form.Item
              label={t('is_stock_product')}
              name="is_stock_product"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: 80 }}
              >
                <Option value={1}>{t('yes')}</Option>
                <Option value={0}>{t('no')}</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={t("stock_number")}
              name="stock_number"
            >
              <InputNumber disabled />
            </Form.Item>
            <Form.Item
              // className="p_product_inventory_edit"
              label={t("level_quantity")}
              name="level_quantity"
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              // className="p_product_price_edit"
              label={t("selling_price")}
              name="price"
              // value="cost"
            >
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item
              className="p_product_cost_view"
              label={t("cost")}
              name="cost"
            >
              <InputNumber min={0} disabled={!isAllowProductCostEdit} />
            </Form.Item>

            <Form.Item
              className="p_product_wholesale_price_view"
              label={t("wholesale_price")}
              name="wholesale_price"
            >
              <InputNumber min={0} disabled={!isAllowWholesaleCostEdit} />
            </Form.Item>


            <Form.Item
              label={t("description")}
              name="description"
            >
              {/* <Input /> */}
              <Input.TextArea rows={4} />
            </Form.Item>

            <Form.Item
              label={t("activity")}
              name="is_active"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group>
                <Radio value={1}>{t("active")}</Radio>
                {/* 活動 */}
                <Radio value={0}>{t("inactive")}</Radio>
                {/* 不活動 */}
              </Radio.Group>
            </Form.Item>

            <Form.Item>
              <Button
                // className="p_product_name_edit"
                type="primary"
                htmlType="submit"
              >
                {t("submit")}
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
        <TabPane tab={t("image")} key="image" disabled={!product_id}>
          <ImageUpload product_id={product_id} />
        </TabPane>
        <TabPane
          tab={t("inventory")}
          key="inventory"
          disabled={!product_id}
        >
          <ProductInventory
            fetchData={fetchData}
            productObject={initialValues}
          />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  )
}

const ImageUpload = () => {
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)
  let location = useLocation();
  let history = useHistory();
  const { product_id } = queryString.parse(location.search);
  const { t } = useTranslation();

  // const [previewVisible, setPreviewVisible] = useState(false)
  // const [previewImage, setPreviewImage] = useState('')
  // const [previewTitle, setPreviewTitle] = useState('')
  const [fileinfo, setFileinfo] = useState([])
  const [defaultValue, setDefaultValue] = useState({})
  const [loading, setLoading] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  useEffect(() => {
    fetchImageData()
  }, [])

  const showPreview = (path) => {
    Modal.info({
      icon: null,
      width: '60vw',
      title: <h1 style={{ textAlign: "center"}}>Image Preview</h1>,
      content: (
        <div style={{ textAlign: "center"}}>
          <img src={path} width="60%" height="auto" />
        </div>
      ),
      onOk() {},
    });
  }
  const EditableContext = React.createContext();

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef();
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async (e) => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
          <EditOutlined />
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const handleSave = (row) => {
    const newData = [...fileinfo];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setFileinfo(newData)
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  let columns = [
    {
      title: 'image',
      dataIndex: 'image',
      render: (text, record) => {
        let path = `${STATIC_SERVER_URL}/${record.filepath}`
        return (
          <>
            <img src={path} width="50px" height="50px" />
            <a style={{paddingLeft: '10px'}} href="javascript:void(0);" onClick={() => { showPreview(path) }}>{path}</a>
          </>
        )
      },
    },
    {
      title: 'alt',
      dataIndex: 'alt',
      key: 'alt',
      editable: true,
    },
    {
      title: 'description',
      dataIndex: 'description',
      key: 'description',
      editable: true,
    },
    {
      title: 'actions',
      dataIndex: 'operation',
      // className: "p_product_name_edit",
      render: (text, record) => (fileinfo.length >= 1 ? (
        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
          <Button shape="circle" icon={<DeleteOutlined />} style={{ color: '#AA0000' }} />
        </Popconfirm>
      ) : null),
    },
  ];

  columns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const fetchImageData = async () => {
    let data = await Service.call('get', `/api/product/image?product_id=${product_id}`)
    console.log('fetchImageData data', data)
    setLoading(false)

    let index = [];
    data.images.forEach((element, idx) => {
      if (element.is_default === 1) {
        index = [idx + 1]
      }
      element.key = idx + 1
      delete element.is_default;
    })
    // console.log('selected index', index)

    setFileinfo(data.images)
    setSelectedRowKeys([...index])
  }

  const handleDelete = async (record) => {
    //  Remove from UI
    setFileinfo(fileinfo.filter((item) => item.key !== record.key))

    if (!record.product_image_id) {
      return
    }

    //  Delete from DB
    await Service.call('delete', '/api/admin/product/image', { data: { product_image_id: record.product_image_id } })
  };

  const uploadOnChange = (info) => {
    const { status, response } = info.file;
    if (status === 'done') {
      message.destroy()
      if (response.status <= 0) {
        message.error(t('common:Failed'))
        return
      }

      message.success(t('common:Uploaded'))

      let data = {
        filename: info.file.response.filename,
        filepath: info.file.response.filepath,
        alt: "",
        description: "",
        key: fileinfo.length + 1
      }

      console.log('this.state.fileinfo', fileinfo)
      console.log('new file data', data)
      setFileinfo([...fileinfo, data])
    }
  }

  const onSubmit = async () => {
    if (selectedRowKeys.length === 0) {
      notification.error({ message: t('Failed'), description: 'Please select Featured Photo' });
      return;
    }
    let data = {};
    data.product_images = _.cloneDeep(fileinfo);
    let key = selectedRowKeys[0]
    let index = _.findIndex(data.product_images, ['key', key])
    _.assignIn(data.product_images[index], { is_default: 1 }) // set cover photo
    data.product_id = product_id;
    let result = await Service.call('post', '/api/admin/product/image', data);
    // console.log('result', result)
    if (result.status !== 1) {
      notification.error({ message: t('Failed') })
      return
    }

    notification.success({ message: t('Success') })
    history.push(`/company/product_item`);
  }

  return (
    <div style={{ padding: '10px' }}>
      <Spin spinning={loading}>
        <FormUploadFile onChange={uploadOnChange} data={{ scope: "public" }} onRemove={null} />
        <div style={{ paddingTop: '10px' }}>
          {fileinfo.length >= 1 && (
            <Table
              rowSelection={{
                type: "radio",
                columnTitle: "Feature",
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                  setSelectedRowKeys(selectedRowKeys)
                }
              }}
              components={components}
              rowClassName={() => 'editable-row'}
              dataSource={fileinfo}
              columns={columns}
            />
          )}

          <Button
            type="primary"
            // className="p_product_name_edit"
            onClick={onSubmit}
          >
            {t("submit")}
          </Button>

        </div>
      </Spin>
    </div>
  )
}

export default ProductItemForm;
