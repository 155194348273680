import React, { useState, useEffect, useRef, useCallback} from 'react';
import {
  Button, Divider, notification, Modal, Select, Input, Space, Tabs, DatePicker
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import Fuse from 'fuse.js';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import InvoiceInfoTable from './InvoiceInfoTable';

const debug = require('debug')('app:admin:pages:InvoiceInfoList');

const { TabPane } = Tabs;

let fuse = [];

const DEFAULT_PAGINATION = {pageSize: 10, current: 1, total: 0}

const InvoiceInfoList = (props) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const history = useHistory();
  const myPermission = useSelector((state) => state.app.company_user.myPermission);
  const inputRef = useRef();
  const [modalVisible, setModalVisible] = useState(false)
  const [userOptions, setUserOptions] = useState([])
  const [orderNoOptions, setOrderNoOptions] = useState([])
  const [orderNo, setOrderNo] = useState([])
  const [invoiceType, setInvoiceType] = useState('general')
  const [userId, setUserId] = useState(0)
  const [isAllowAddInvoice, setIsAllowAddInvoice] = useState(false);

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [searchInput, setSearchInput] = useState('')

  // Range Picker
  const [dateValue, setDateValue] = useState([moment().subtract(7, 'days').startOf('day'), moment().endOf('day')]);
  const [dates, setDates] = useState(null);
  const [loading, setLoading] = useState(true);

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  useEffect(() => {
    if (!dates) {
      getAllData();
    }
    addInvoicePermission();
  }, [dates]);

  useEffect(() => {
    fetchUserList();
  }, [invoiceType])

  useEffect(() => {
    if (!userId) {
      setOrderNoOptions([])
      return;
    }

    fetchOrderNo();
  }, [userId])

  const addInvoicePermission = () => {
    let isAllowPermission = _.intersection(myPermission, ['p_invoice_edit', 'p_invoice_monthly_edit', 'p_any'])
    if (!_.isEmpty(isAllowPermission)) {
      setIsAllowAddInvoice(true);
    }
  }

  const getAllData = async (tablePagination = pagination, searchWord = searchInput) => {
    try {
      setLoading(true);
      let result = await Service.call('get', '/api/invoice_info/pagination', {
        ...tablePagination,
        searchWord
      });
      // console.log('result', result);

      if (result.status !== 1) {
        notification.warning({ message: '獲取資料失敗' });
        setLoading(false);
        return;
      }

      let { data, total } = result;
      // let orderedDataList = _.orderBy(data, ['invoice_info_id']);
      let orderedDataList = data;
      orderedDataList = orderedDataList.map((item) => {
        let entity = item;
        entity.order_info = item.order_info.map((order) => {
          return {...order, order_no_with_prefix: order.order_no_prefix + order.order_no}
        })
        entity.invoice_no_with_prefix = item.invoice_no_prefix + item.invoice_no;
        return entity
      })

      setDataList(orderedDataList);
      setPagination({...tablePagination, total})
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setLoading(false);
    }
  }

  const fetchOrderNo = async () => {
    let result = await Service.call('get', `/api/order_info/number?user_id=${userId}`);
    // console.log('result', result);

    if (result.status !== 1) {
      return notification.error({ message: "失敗", description: result.errorMessage });
    }

    let dataResult = _.orderBy(result.data, ['order_no'], ['desc'])
    let orderNoList = dataResult.map((item) => {
      return { value: item.order_no }
    })
    setOrderNoOptions(orderNoList)
  }

  const fetchUserList = async () => {
    let result = await Service.call('get', `/api/user/by_type?type=${invoiceType}`);
    // debug('result', result);

    if (result.status !== 1) {
      return notification.error({ message: "失敗", description: result.errorMessage });
    }

    let userSelectOptions = result.data.map((item) => {
      return { value: item.user_id, label: `${item.first_name} ${item.last_name} (${item.mobile})` }
    })
    setUserOptions(userSelectOptions)
  }

  const debouncedSearch = useRef(
    _.debounce(async (searchWord) => {
      getAllData(DEFAULT_PAGINATION, searchWord)
    }, 300)
  ).current;

  const filterData = async (filterObj = {}) => {
    setSearchInput(filterObj.searchWord)
    debouncedSearch(filterObj.searchWord)
  }

  const paginationOnChange = useCallback((tablePagination) => {
    getAllData(tablePagination)
  }, [getAllData, pagination])

  return (
    <NavAndSideFrame {...props} title={t('invoice_list')} selectedKey="invoice_info">

      <Space direction="horizontal">
        {isAllowAddInvoice && (
          <Button
            type="primary"
            onClick={() => {
              setModalVisible(true)
            }}
            // className="p_invoice_edit"
          >
            {t("add_invoice")}
          </Button>
        )}
        <>
          {t('search')}
        </>
        <Input
          ref={inputRef}
          placeholder={t('please_input_keyword')}
          onChange={(event) => {
            filterData({ searchWord: event.target.value })
          }}
        />
        {/* <RangePicker
          value={dates || dateValue}
          disabledDate={disabledDate}
          onCalendarChange={(val) => setDates(val)}
          onChange={(val) => setDateValue(val)}
          onOpenChange={onOpenChange}
          disabled={loading}
        /> */}
      </Space>

      <Divider />
      <InvoiceInfoTable
        dataObj={dataList}
        fetchData={getAllData}
        loading={loading}
        pagination={pagination}
        paginationOnChange={paginationOnChange}
        isLoading={loading}
      />

      <Modal
        title={t("please_select_an_order")}
        visible={modalVisible}
        onOk={(e) => {
          if (_.isEmpty(orderNo)) return
          history.push(`/company/invoice_info_form?order_no=${orderNo}&invoice_type=${invoiceType}&user_id=${userId}`)
        }}
        onCancel={(e) => {
          setModalVisible(false)
        }}
      >
        <>
          <Tabs type="card" defaultActiveKey="general" onChange={(key) => setInvoiceType(key)}>
            <TabPane tab={t('general')} key="general" className="p_invoice_edit">
              <OrderNoSelector
                userOptions={userOptions}
                orderNoOptions={orderNoOptions}
                setOrderNo={setOrderNo}
                setUserId={setUserId}
              />
            </TabPane>
            <TabPane tab={t('monthly')} key="monthly" className="p_invoice_monthly_edit">
              <OrderNoSelector
                userOptions={userOptions}
                orderNoOptions={orderNoOptions}
                setOrderNo={setOrderNo}
                setUserId={setUserId}
              />
            </TabPane>
            <TabPane tab={t('wholesale')} key="wholesale" className="p_invoice_monthly_edit">
              <OrderNoSelector
                userOptions={userOptions}
                orderNoOptions={orderNoOptions}
                setOrderNo={setOrderNo}
                setUserId={setUserId}
              />
            </TabPane>
          </Tabs>

        </>
      </Modal>
    </NavAndSideFrame>
  )
}

const OrderNoSelector = (props) => {
  const { t } = useTranslation();

  const {
    userOptions, orderNoOptions, setOrderNo, setUserId
  } = props

  return (
    <Space direction="vertical">
      <Select
        showSearch
        style={{ width: 400 }}
        placeholder={t('user')}
        options={userOptions}
        optionFilterProp="children"
        onChange={(value) => {
          setUserId(value)
        }}
        filterOption={(input, option) => {
          return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }}
      />

      <Select
        mode="multiple"
        allowClear
        showSearch
        style={{ width: 400 }}
        placeholder={t("search_order_number")}
        options={orderNoOptions}
        optionFilterProp="children"
        onChange={(value) => {
          // console.log('value', value)
          setOrderNo(value)
        }}
        filterOption={(input, option) => {
          return _.toString(option.value).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }}
      />
    </Space>
  )
}

export default InvoiceInfoList;
