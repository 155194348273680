import React, { Component } from 'react';
import {
  Layout, Menu, Avatar, Space
} from 'antd';
import 'antd/dist/antd.css';
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux';
import { Redirect} from "react-router-dom";
import _ from 'lodash'
import { ActionCreators } from '../redux/actions';
import logo from '../assets/Logo_PTN.png';
import LanguageSelector from './LanguageSelector';
import NotificationAlert from './NotificationAlert';
const { Header } = Layout;

class Navbar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      redirectHome: false,
    };
  }

  redirectHome() {
    this.setState({redirectHome: true})
  }

  render() {
    const {
      admin,
      company_admin,
      company_user
    } = this.props.app;

    // console.log(`user >> `, user);
    let nickname = '';
    if (company_user.company_user_id != null) {
      nickname = company_user.nickname;
    }

    if (company_admin.company_admin_id != null) {
      nickname = company_admin.nickname;
    }

    if (admin.admin_id != null) {
      nickname = admin.nickname;
    }

    return (
      <Header className="header" style={{background: 'linear-gradient(to right,#006ABF 0,#054f8a 100%)'}}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{width: 180}}>
            <img onClick={() => { this.redirectHome(); }} style={{width: 180}} src={logo} alt="" />
          </div>
          <div>
            <Space direction="horizontal">
              <LanguageSelector />
              <div style={{marginLeft: 20}}>
                <NotificationAlert />
              </div>
              <Menu
                theme="dark"
                mode="horizontal"
                style={{ lineHeight: '64px', float: 'right', background: 'rgba(255, 0, 0, 0)'}}
              >
                <Menu.Item
                  style={{
                    color: '#fff', fontWeight: '600'
                  }}
                >
                  <Avatar style={{ margin: '0 3px' }}>
                    {company_user.first_name
                      ? _.capitalize(company_user.first_name).charAt(0)
                      : ''}
                  </Avatar>
                  {`${_.capitalize(company_user.first_name)} ${_.capitalize(company_user.last_name)}`}
                </Menu.Item>
              </Menu>
            </Space>
          </div>
        </div>
        {
          this.state.redirectHome === true
            ? <Redirect to="/" />
            : null
        }
      </Header>
    )
  }
}

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
