import React, { useState, useEffect } from "react";
import {
  Button,
  Layout,
  Table,
  Tooltip,
  Modal,
  notification,
  Badge,
  Space,
  Tag
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  CloseOutlined,
  CheckOutlined,
  FileAddOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import { useSelector } from 'react-redux'
import "antd/dist/antd.css";
import _ from "lodash";
import * as Service from "../../core/Service";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

const { confirm } = Modal;

const WorkflowTable = (props) => {
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)
  const {fetchData} = props
  const { t } = useTranslation();
  // console.log("object >> ", props.dataObj);

  const [dataList, setDataList] = useState([]);
  const tableIDName = "workflow_id";
  const history = useHistory();

  useEffect(() => {
    setDataList(props.dataObj);
  }, [props.dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("workflow_name"),
        dataIndex: `name`,
      },
      {
        title: t("is_allow_skip"),
        dataIndex: `is_allow_skip`,
      },
      {
        title: t("workflow_step"),
        dataIndex: `step`,
      },
    ];
    return columns;
  };

  // const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm') => {
  //   if (unixTime !== 0) {
  //     return moment.unix(unixTime).format(outputFormat);
  //   }
  //   return '-';
  // }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList}
        columns={setTableHeader()}
      />
    </Layout>
  );
};

export default WorkflowTable;
