import React, { useState, useEffect } from "react";
import { notification, Divider, Input, Button, Row, Col } from 'antd';
import _ from "lodash";
import Fuse from 'fuse.js';
import ReceiptInfoTable from "./ReceiptInfoTable";
import * as Service from '../../core/Service';
import InvoiceSelection from "../../components/InvoiceSelection";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const OrderReceiptForm = ({ order_id }) => {
  const { t } = useTranslation();
  const myPermission = useSelector((state) => state.app.company_user.myPermission);
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isAbleAddReceipt, setIsAbleAddReceipt] = useState(false);

  useEffect(() => {
    getAllData();
    checkPermission();
  }, []);

  const checkPermission = () => {
    let isAllowPermission = _.intersection(myPermission, ['p_invoice_view', 'p_invoice_monthly_view', 'p_any'])
    if (!_.isEmpty(isAllowPermission)) {
      setIsAbleAddReceipt(true);
    }
  }

  const getAllData = async () => {
    try {
      let result = await Service.call('get', `/api/receipt_info/list?order_id=${order_id}`);
      if (result.status !== 1) {
        notification.warning({ message: result.errorMessage });
        return;
      }
      let { data } = result;
      let orderedDataList = _.orderBy(data, ['receipt_no_with_prefix'], ['desc']);
      setDataList(orderedDataList);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }
  return (
    <>
      <Row gutter={[24, 12]}>
        <Col xs={24} sm={24} md={8} lg={4}>
          <Button
            block
            type="primary"
            className="p_receipt_edit"
            onClick={() => {
              if (!isAbleAddReceipt) {
                return notification.error({ message: `${t('permission_denied')} [${t('p_invoice_view')} / ${t('p_invoice_monthly_view')}]` });
              }
              setModalVisible(true)
            }}
          >
            {t("add_new_receipt")}
          </Button>

        </Col>
      </Row>
      <Divider />
      <ReceiptInfoTable dataObj={dataList} fetchData={getAllData} />
      <InvoiceSelection
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        order_id={order_id}
      />
    </>
  );
};

export default OrderReceiptForm;
