import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, AutoComplete, Row, Col, Space, Modal, Tooltip
} from 'antd';
import { PlusOutlined, FilePdfOutlined, FileAddOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';

import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import OptionForm from './OptionForm'

// import NavAndSideFrame from '../../components/NavAndSideFrame';

// import OrderInfoBasicForm from './OrderInfoBasicForm';
// import OrderInfoServiceDetailForm from './OrderInfoServiceDetailForm';
// import OrderInfoAshesSpaceForm from './OrderInfoAshesSpaceForm';
// import OrderItemForm from './OrderItemForm';
// import OrderInfoPlantSpaceForm from './OrderInfoPlantSpaceForm';
// import OrderInfoAshesServiceForm from './OrderInfoAshesServiceForm';


const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const InvoiceInfoBasicForm = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  let location = useLocation();
  const { invoice_info_id } = queryString.parse(location.search);

  const { initialValues, fetchOrderInfo, isFromOrder, reloadInvoice } = props

  const [invoiceInfoForm] = Form.useForm();
  const [hydrationServiceOptions, setHydrationServiceOptions] = useState([]);
  const [staffOptions, setStaffOption] = useState([]);
  const [userInvoiceList, setUserInvoiceList] = useState([]);
  const [duplicated, setDuplicated] = useState(false);
  const [discountReasonOptions, setDiscountReasonOptions] = useState([]);
  const [modalVisible, setModalVisible] = useState(false)
  const [optionType, setOptionType] = useState('')


  useEffect(() => {
    fetchHydrationService()
    fetchStaff();
    fetchInvoiceList();
    fetchDiscountReasonOption()
  }, [])

  useEffect(() => {
    invoiceInfoForm.resetFields();
    order_no_with_prefix();
    if (invoiceInfoForm.getFieldValue().order_info) {
      checkDuplicated(moment(), moment().format("YYYY/MM/DD"));
    }
  }, [initialValues])

  // useEffect(() => {
  //   checkDuplicated(moment(), moment().format("YYYY/MM/DD"), 'first')
  // }, [userInvoiceList])

  const fetchStaff = async () => {
    let result = await Service.call("get", `/api/company/user`);
    if (result.status !== 1) return;
    let userInfoList = result.data;
    let staffOption = userInfoList.map((entity) => {
      return { label: entity.company_user_id + '-' + entity.first_name + " " + entity.last_name, value: entity.company_user_id }
    })
    setStaffOption(staffOption);
  };

  const fetchHydrationService = async () => {
    let options = await Main.fetchFormOption('hydration_service', true)
    setHydrationServiceOptions(options);
  }

  const calculateTotal = () => {
    let order_info = invoiceInfoForm.getFieldValue().order_info;
    let invoiceItemTotal = invoiceInfoForm.getFieldValue().invoice_item_total;
    // new invoice does not have openingBalance(上月結欠)
    let openingBalance = invoiceInfoForm.getFieldValue().opening_balance || 0;
    let totalDeposit = invoiceInfoForm.getFieldValue().deposit || 0;
    // let hydrationServiceTotal = initialValues.hydration_service_total
    let hydrationServiceTotal = 0;
    // console.log("hydration_service_total222", hydrationServiceTotal)
    order_info.map((item) => {
      hydrationServiceTotal += item.hydration_service_total
    });
    let discount = 0;
    if (!_.isUndefined(invoiceInfoForm.getFieldValue().discount)) {
      discount = invoiceInfoForm.getFieldValue().discount;
    }
    invoiceInfoForm.setFieldsValue({
      hydration_service_total: hydrationServiceTotal,
      invoice_sum: invoiceItemTotal + hydrationServiceTotal + openingBalance,
      invoice_total: invoiceItemTotal + hydrationServiceTotal + openingBalance - discount - totalDeposit
    });
  };

  const order_no_with_prefix = async () => {
    if (initialValues.order_info) {
      let orderInfo = [];
      _.map(initialValues.order_info, (item) => {
        orderInfo.push({
          ...item,
          prefix_order_no: `${item.order_no_prefix}${item.order_no}`
        })
      })
      invoiceInfoForm.setFieldsValue({order_info: orderInfo});
      // console.log("orderInfo>>",orderInfo)
    }
  }

  const fetchInvoiceList = async () => {
    let allInvoiceList = await Service.call("get", '/api/invoice_info');
    // console.log("ID", initialValues.user_id)
    let filterInvoiceList = _.filter(allInvoiceList.data, {invoice_status: "", user_id: _.toNumber(initialValues.user_id)});
    // console.log(filterInvoiceList);
    setUserInvoiceList(filterInvoiceList)
  }

  // check duplicate invoice in the same month and update opening balance
  const checkDuplicated = async (date, dateString) => {
    if (!date || !dateString) {
      return
    }
    let duplicatedInvoice = false;
    let today = date.format("YYYY-MM");
    // console.log("Today", today);
    // console.log("userInvoiceList", userInvoiceList);
    userInvoiceList.every((entity) => {
      let targetDate = moment.unix(entity.invoice_date).format("YYYY-MM");
      if (targetDate === today) {
        duplicatedInvoice = true;
        return false;
      }
      return true;
    })

    // console.log("Balance", initialValues)

    if (initialValues.opening_balance === 0 || _.isUndefined(initialValues.opening_balance)) {
      let balance = await Service.call("get", `/api/invoice_info_pass_balance?user_id=${initialValues.user_id}&chosenDate=${dateString}`)
      // console.log("Balance", balance);
      invoiceInfoForm.setFieldsValue({opening_balance: balance.data})
    }
    setDuplicated(duplicatedInvoice)
    calculateTotal();
  }

  // ger redux site meta value
  const meta = useSelector((state) => state.app.meta);

  const dateFormat = `${
    !_.isEmpty(meta.system_date_format.meta_value) ? meta.system_date_format.meta_value : 'YYYY/MM/DD'
  }`

  const fetchDiscountReasonOption = async (id, value) => {
    let options = await Main.fetchFormOption('discount_reason', true)
    console.log({
      id, value
    })
    // console.log('options', options)
    // options.push({value: id, label: value})
    // console.log(options)
    setDiscountReasonOptions(options);
  }


  const fetchData = async (type, id, value) => {
    switch (type) {
      case "discount_reason":
        fetchDiscountReasonOption(id, value);
        invoiceInfoForm.setFieldsValue({ discount_reason: value, discount_reason_id: id})
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Modal
        title={t('create') + t(optionType)}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <OptionForm
          isActive={1}
          selectedFormData={{}}
          type={optionType}
          closeMethod={async (form_option_id, value) => {
            fetchData(optionType, form_option_id, value);
            setModalVisible(false);
          }}
        />
      </Modal>
      {invoice_info_id && (
        <>
          <Tooltip title={`${t('create_invoice')} PDF`}>
            {initialValues.invoice_type === 'general' && (
              <Button
                shape="circle"
                className="p_invoice_edit"
                icon={<FileAddOutlined />}
                onClick={async () => {
                  let result = await Service.call('post', '/api/invoice_info/generate_pdf', { invoice_info_id })
                  // console.log('result', result)
                  if (result.status !== 1) return
                  notification.success({ message: t('success') });
                  if (result.data.alert === 1) notification.warn({ message: '提醒！該用戶已超過信貸金額！'})
                  reloadInvoice()
                }}
              />
            )}
            {initialValues.invoice_type !== 'general' && (
            <Button
              shape="circle"
              className="p_invoice_monthly_edit"
              icon={<FileAddOutlined />}
              onClick={async () => {
                let result = await Service.call('post', '/api/invoice_info/generate_pdf', { invoice_info_id })
                // console.log('result', result)
                if (result.status !== 1) return
                notification.success({ message: t('success') });
                if (result.data.alert === 1) notification.warn({ message: '提醒！該用戶已超過信貸金額！'})
                reloadInvoice()
              }}
            />
            )}
          </Tooltip>
          {initialValues.invoice_pdf && (
            <Tooltip title={`${t('download_invoice')} PDF`}>
              {initialValues.invoice_type === 'general' && (
              <Button
                shape="circle"
                className="p_invoice_edit"
                icon={<FilePdfOutlined />}
                onClick={async () => {
                  let order_info = initialValues.order_info[0]
                  let x = _.find(userInvoiceList, ({item}) => initialValues.invoice_info_id === item.invoice_info_id)
                  console.log(x)
                  console.log(initialValues, userInvoiceList)
                  let result = await Service.call('get', `/api/invoice_info/invoice_pdf/${initialValues.invoice_pdf}`, {}, { responseType: 'blob' })
                  // console.log('result', result)
                  if (!result) return
                  const pdfBlob = new Blob([result], { type: 'application/pdf' });

                  let filename = []
                  let language = initialValues.preferred_language ? initialValues.preferred_language : 'zh_hant'
                  if (language === 'zh_hant') filename.push('發票')
                  if (language === 'en') filename.push('Invoice')
                  filename.push(`${order_info.order_no_prefix + order_info.order_no}-`)
                  filename.push(`${initialValues.invoice_no_prefix + initialValues.invoice_no}`)
                  saveAs(pdfBlob, _.join(filename, ''))
                }}
              />
              )}
              {initialValues.invoice_type !== 'general' && (
              <Button
                shape="circle"
                className="p_invoice_monthly_edit"
                icon={<FilePdfOutlined />}
                onClick={async () => {
                  let order_info = initialValues.order_info[0]
                  let result = await Service.call('get', `/api/invoice_info/invoice_pdf/${initialValues.invoice_pdf}`, {}, { responseType: 'blob' })
                  // console.log('result', result)
                  if (!result) return
                  const pdfBlob = new Blob([result], { type: 'application/pdf' });
                  let filename = []
                  let language = initialValues.preferred_language ? initialValues.preferred_language : 'zh_hant'
                  if (language === 'zh_hant') filename.push('發票')
                  if (language === 'en') filename.push('Invoice')
                  filename.push(`${order_info.order_no_prefix + order_info.order_no}-`)
                  filename.push(`${initialValues.invoice_no_prefix + initialValues.invoice_no}`)
                  saveAs(pdfBlob, _.join(filename, ''))
                }}
              />
              )}
            </Tooltip>
          )}
          <Tooltip title={`${t('create_receipt')} PDF`}>
            {initialValues.invoice_type === 'general' && (
            <Button
              shape="circle"
              className="p_invoice_edit"
              icon={<FileAddOutlined />}
              onClick={async () => {
                let result = await Service.call('post', '/api/invoice_info/generate_receipt_pdf', { invoice_info_id })
                // console.log('result', result)
                if (result.status !== 1) return
                if (result.data.alert === 1) notification.warn({ message: '提醒！該用戶已超過信貸金額！'})

                notification.success({ message: '成功' });
                reloadInvoice()
              }}
            />
            )}
            {initialValues.invoice_type !== 'general' && (
            <Button
              shape="circle"
              className="p_invoice_monthly_edit"
              icon={<FileAddOutlined />}
              onClick={async () => {
                let result = await Service.call('post', '/api/invoice_info/generate_receipt_pdf', { invoice_info_id })
                // console.log('result', result)
                if (result.status !== 1) return
                if (result.data.alert === 1) notification.warn({ message: '提醒！該用戶已超過信貸金額！'})

                notification.success({ message: '成功' });
                reloadInvoice()
              }}
            />
            )}
          </Tooltip>
          {initialValues.receipt_pdf && (
          <Tooltip title={`${t('download_receipt')} PDF`}>
            {initialValues.invoice_type === 'general' && (
            <Button
              shape="circle"
              className="p_invoice_edit"
              icon={<FilePdfOutlined />}
              onClick={async () => {
                let order_info = initialValues.order_info[0]
                let result = await Service.call('get', `/api/invoice_info/receipt_pdf/${initialValues.receipt_pdf}`, {}, { responseType: 'blob' })
                // console.log('result', result)
                if (!result) return
                const pdfBlob = new Blob([result], { type: 'application/pdf' });

                let filename = []
                let language = initialValues.preferred_language ? initialValues.preferred_language : 'zh_hant'
                if (language === 'zh_hant') filename.push('收據')
                if (language === 'en') filename.push('Receipt')
                filename.push(`${order_info.order_no_prefix + order_info.order_no}-`)
                filename.push(`${initialValues.invoice_no_prefix + initialValues.invoice_no}`)
                saveAs(pdfBlob, _.join(filename, ''))
              }}
            />
            )}
            {initialValues.invoice_type !== 'general' && (
            <Button
              shape="circle"
              className="p_invoice_monthly_edit"
              icon={<FilePdfOutlined />}
              onClick={async () => {
                let result = await Service.call('get', `/api/invoice_info/receipt_pdf/${initialValues.receipt_pdf}`, {}, { responseType: 'blob' })
                // console.log('result', result)
                if (!result) return
                const pdfBlob = new Blob([result], { type: 'application/pdf' });
                saveAs(pdfBlob, initialValues.receipt_pdf)
              }}
            />
            )}
          </Tooltip>
          )}
        </>
      )}
      <Form
        form={invoiceInfoForm}
        name="invoiceInfoForm"
        layout="vertical"
        initialValues={{
          ...initialValues,
          discount_reason_id: initialValues.discount_reason_id === 0 ? undefined : initialValues.discount_reason_id
        }}
        onFinish={async (postObj) => {
          let method = 'post';
          if (!_.isUndefined(invoice_info_id)) {
            method = 'patch';
            postObj.invoice_info_id = invoice_info_id;
          }
          if (method === 'post') {
            if (duplicated) {
              notification.error({ message: '提交失敗', description: "每個月只能新增一張發票" });
              return;
            }
          }
          // console.log("post Obj: ", postObj)
          let results = await Service.call(method, '/api/invoice_info', postObj)
          // console.log('results', results)

          if (results.status !== 1) {
            notification.error({ message: '提交失敗', description: results.errorMessage });
            return;
          }

          notification.success({ message: '提交成功' });
          if (isFromOrder) history.push(`/company/order_info_form?order_id=${initialValues.order_info[0].order_id}&isFromInvoice=true&&order_status=`)
          else history.push(`/company/invoice_info`);
        }}
      >

        <Form.Item
          label={t('type')}
          name="invoice_type"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label={t('invoice_no')}
          name="invoice_no"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label={t('invoice_date')}
          name="invoice_date"
          rules={[
            {
              required: true,
              message: t('please_enter_this_field')
            },
          ]}
        >
          <DatePicker
            format={dateFormat}
            onChange={checkDuplicated}
            disabled={initialValues.payment_total}
          />
        </Form.Item>

        {!_.isEmpty(initialValues.order_info) && (
        <Form.List name="order_info">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, dynamicRowIndex) => (
                  <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="start">
                    <Form.Item
                      {...field}
                      name={[field.name, 'order_id']}
                      fieldKey={[field.fieldKey, 'order_id']}
                      label={dynamicRowIndex === 0 ? t('order_id') : ''}
                    >
                      <Input disabled />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, 'prefix_order_no']}
                      fieldKey={[field.fieldKey, 'prefix_order_no']}
                      label={dynamicRowIndex === 0 ? t('order_no') : ''}
                    >
                      <Input disabled />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, 'hydration_service_id']}
                      fieldKey={[field.fieldKey, 'hydration_service_id']}
                      label={dynamicRowIndex === 0 ? t('hydration_service_id') : ''}
                      hidden
                    >
                      <Input disabled />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, 'hydration_service']}
                      fieldKey={[field.fieldKey, 'hydration_service']}
                      label={dynamicRowIndex === 0 ? t('hydration_service') : ''}
                    >
                      <Input disabled />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, 'hydration_service_total']}
                      fieldKey={[field.fieldKey, 'hydration_service_total']}
                      label={dynamicRowIndex === 0 ? t('hydration_service_total') : ''}
                    >
                      <InputNumber
                        style={{width: '100%'}}
                        className="rightAlignNumber"
                        onChange={() => {
                          calculateTotal()
                        }}
                        disabled={initialValues.payment_total}
                      />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, invoice_info_id ? 'item_total' : 'OrderItemDataTotal']}
                      fieldKey={[field.fieldKey, 'item_total']}
                      label={dynamicRowIndex === 0 ? t('item_total') : ''}
                    >
                      <Input disabled className="rightAlignNumber" />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, 'pet_weight']}
                      fieldKey={[field.fieldKey, 'pet_weight']}
                      label={dynamicRowIndex === 0 ? t('pet_weight') : ''}
                    >
                      <Input disabled />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, 'pet_category_id']}
                      fieldKey={[field.fieldKey, 'pet_category_id']}
                      label={dynamicRowIndex === 0 ? t('pet_category_id') : ''}
                      hidden
                    >
                      <Input disabled />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, 'pet_category']}
                      fieldKey={[field.fieldKey, 'pet_category']}
                      label={dynamicRowIndex === 0 ? t('pet_category') : ''}
                    >
                      <Input disabled />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, 'deposit']}
                      fieldKey={[field.fieldKey, 'deposit']}
                      label={dynamicRowIndex === 0 ? t('deposit') : ''}
                    >
                      <Input disabled className="rightAlignNumber" />
                    </Form.Item>
                  </Space>
                ))}
              </>
            );
          }}
        </Form.List>
        )}

        <Form.Item
          label={t('opening_balance')}
          name="opening_balance"
        >
          <InputNumber
            className="rightAlignNumber"
            disabled
          />
        </Form.Item>

        <Form.Item
          label={t('bio_crematiion_service_total')}
          name="hydration_service_total"
        >
          <InputNumber
            className="rightAlignNumber"
            disabled
            // style={{ width: 200 }}
          // enterButton={(
          //   <>
          //     <CalculatorOutlined />
          //     {t('update')}
          //   </>
          // )}
          // onSearch={async () => {
          //   fetchOrderInfo(initialValues.order_no)
          // }}
            onChange={() => {
              calculateTotal()
            }}
          />
        </Form.Item>

        <Form.Item
          label={t('invoice_item_total')}
          name="invoice_item_total"
        >
          <InputNumber disabled className="rightAlignNumber" />
        </Form.Item>

        <Divider dashed style={{borderColor: 'black'}} />

        <Form.Item
          label={t('total')}
          name="invoice_sum"
        >
          <InputNumber
            className="rightAlignNumber"
            disabled
            onChange={() => {
              calculateTotal()
            }}
          />
        </Form.Item>

        <br />

        <Form.Item
          label={t('deposit_total')}
          name="deposit"
        >
          <InputNumber disabled className="rightAlignNumber" />
        </Form.Item>

        <Row gutter={24}>
          <Col span={4}>
            <Form.Item
              label={t('discount')}
              name="discount"
            >
              <InputNumber
                className="rightAlignNumber"
                onChange={() => {
                  calculateTotal()
                }}
                disabled={initialValues.payment_total}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="discount_reason"
              hidden
            >
              <Input disabled />
            </Form.Item>
            <Row align="middle" gutter={[30, 0]}>
              <Col span={12}>
                <Form.Item
                  label={t('discount_reason')}
                  name="discount_reason_id"
                >
                  <Select
                    showSearch
                    options={discountReasonOptions}
                    placeholder={t('click_to_view_options')}
                    // disable the select box when "p_form_option_view" permission deny API fetching
                    disabled={discountReasonOptions === undefined}
                    filterOption={(inputValue, option) => {
                      return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                    }}
                    onChange={async (value, option) => {
                      let discount_reason = ''
                      if (value) {
                        discount_reason = option.label
                      }
                      invoiceInfoForm.setFieldsValue({ discount_reason })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  type="primary"
                  shape="circle"
                  className="p_form_option_edit"
                  onClick={() => {
                    setModalVisible(true)
                    setOptionType('discount_reason')
                  }}
                  icon={<PlusOutlined />}
                />

              </Col>
            </Row>
          </Col>
        </Row>

        <Divider dashed style={{borderColor: 'black'}} />

        <Form.Item
          label={t('invoice_amount')}
          name="invoice_total"
        >
          <InputNumber disabled className="rightAlignNumber" />
        </Form.Item>

        <Form.Item
          label={t('remark')}
          name="invoice_remark"
        >
          <Input disabled={initialValues.payment_total} />
        </Form.Item>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label={t('consultant_staff')}// "地址" / "診所"
              name="invoice_consultant_staff_id"
            >
              <Select
                showSearch
                options={staffOptions}
                placeholder={t("click_to_view_options")}
                filterOption={(inputValue, option) => {
                  return (
                    option.label
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  );
                }}
                disabled={initialValues.payment_total}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          {initialValues.invoice_type === 'general' && (
          <Button
            className="p_invoice_edit"
            type="primary"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
          )}
          {initialValues.invoice_type !== 'general' && (
          <Button
            className="p_invoice_monthly_edit"
            type="primary"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
          )}
        </Form.Item>
      </Form>
    </>
  )
}

export default InvoiceInfoBasicForm;
