import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Col, Form, Input, Layout, Modal,
  notification, Radio, Row, Select, Tabs, Upload, message, AutoComplete
} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { T } from 'antd/lib/upload/utils';
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import { formItemLayout, tailLayout } from "./ModalLayout";

const debug = require('debug')('app:admin:components:CompanyUserForm');

const { Option } = Select;

const CompanyUserForm = (props) => {
  const { t } = useTranslation();

  const { dataObj } = props
  const { company_user_id = 0 } = dataObj
  if (dataObj.mobile) {
    [dataObj.prefixMobile, dataObj.mobile] = dataObj.mobile.split('-')
  }

  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [roleList, setRoleList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [areaCode, setAreaCode] = useState({})
  const company_admin = useSelector((state) => state.app.company_admin);

  useEffect(() => {
    fetchAreaCode();
  }, []);

  useEffect(() => {
    getCompanyUserRole();
    form.resetFields();
    passwordForm.resetFields(); // Reset password when changing user selected
  }, [dataObj]);

  const getCompanyUserRole = async () => {
    let result = await Service.call('get', '/api/company/user_role')
    console.log('result', result)
    if (result.status !== 1) {
      return notification.warning({ message: '獲取資料失敗' });
    }
    setRoleList(result.data)
  }


  const fetchAreaCode = async () => {
    let result = await Service.call('get', `/api/areaCode`);
    if (result.status !== 1) {
      return notification.warning({ message: '獲取區號失敗' });
    }
    let { data } = result;
    setAreaCode(data);
  }

  const onFinish = async (postObj) => {
    if (_.isNaN(_.toNumber(postObj.mobile))) {
      message.error(t('mobile_type_error'));
      return props.openModal(true);
    }
    postObj.mobile = postObj.prefixMobile + '-' + postObj.mobile
    delete postObj.prefixMobile
    let url = `/api/company/user`;
    if (company_admin.company_admin_id != null) {
      postObj.company_id = company_admin.company_id;
      postObj.company_admin_id = company_admin.company_admin_id;
    }
    // Patch
    if (dataObj.company_user_id > 0) {
      postObj.company_admin_id = dataObj.company_admin_id;
      postObj.company_user_id = dataObj.company_user_id;

      let data = await Service.call('patch', url, postObj);
      if (data.errorMessage) {
        message.error(data.errorMessage);
        return props.openModal(true);
      }
      message.success('success');
      return props.openModal(false);
    }

    // POST
    postObj.create_time = moment().unix();
    let data = await Service.call('post', url, postObj);
    if (data.errorMessage) {
      message.error(data.errorMessage);
      return props.openModal(true);
    }
    message.success('success');
    return props.openModal(false);
  };

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="time_related_controls"
        onFinish={onFinish}
        initialValues={dataObj}
      >
        {/* <Form.Item
        label="Company"
        name="company_id"
        rules={[{ required: true, message: 'Please select one.' }]}
      >
        <Select
          placeholder="- select one -"
          disabled={dataObj.company_id}
          onChange={(value)=> {
            getCompanyRole(value)
          }}
        >
          {companyList.map(({ company_id, company_name }) =>
            (<Option value={company_id}>{`(${company_id}) `+company_name}</Option>)
          )}
        </Select>
      </Form.Item> */}
        <Form.Item
          label={t("username")}
          name="username"
          rules={[{ required: true, message: 'Please input username.' }]}
        >
          <Input disabled={dataObj.username} autoFocus />
        </Form.Item>
        <Form.Item
          label={t("first_name")}
          name="first_name"
          rules={[{ required: true, message: 'Please input first name.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("last_name")}
          name="last_name"
          rules={[{ required: true, message: 'Please input last name.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("nickname")}
          name="nickname"
          rules={[{ required: false, message: 'Please input nickname.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("department")}
          name="department"
          rules={[{ required: false, message: 'Please input department.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("gender")}
          name="gender"
          rules={[{ required: true, message: 'Please select one.' }]}
        >
          <Radio.Group>
            <Radio value="M">{t('male')}</Radio>
            <Radio value="F">{t('female')}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={t("mobile")}
          name="mobile"
          rules={[{ required: true, message: 'Please input mobile.' }]}
        >
          <Input
            addonBefore={(
              <Form.Item
                name="prefixMobile"
                noStyle
                rules={[{ required: true, message: t('input_mobile_prefix') }]}
              >
                <AutoComplete style={{ width: 80 }}>
                  {_.map(areaCode, (val, key) => {
                    return (
                      <Option value={val} key={key}>{val}</Option>
                    )
                  })}
                </AutoComplete>
              </Form.Item>
          )}
          />
        </Form.Item>
        <Form.Item
          label={t("email")}
          name="email"
        >
          <Input />
          {/* <Input disabled={dataObj.email} /> */}

        </Form.Item>
        {/* <Form.Item
        label="QR Code Permission"
        name="privilege_scan_qrcode"
        rules={[{ required: true, message: 'Please select one.', type: 'number' }]}
      >
        <Radio.Group>
          <Radio value={1}>Yes</Radio>
          <Radio value={0}>No</Radio>
        </Radio.Group>
      </Form.Item> */}
        {/* <Form.Item
        label="Admin Permission"
        name="is_admin"
        rules={[{ required: true, message: 'Please select one.' }]}
      >
        <Radio.Group>
          <Radio value={1}>Yes</Radio>
          <Radio value={0}>No</Radio>
        </Radio.Group>
      </Form.Item> */}

        <Form.Item
          label={t("access_level")}
          name="company_user_role_id"
          rules={[{ required: true, message: 'Please select one.' }]}
        >
          <Select placeholder="- select one -">
            {roleList.map(({ company_user_role_id, role_name }) => (<Option value={company_user_role_id}>{role_name}</Option>))}
          </Select>
        </Form.Item>

        {!company_user_id && (
          <>
            <Form.Item
              label={t("password")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t('please_enter_this_field'),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (/(?=.*[a-z])(?=.*[A-Z])((?=.*\d)|(?=.*[-+_!@#$%^&*.,?]))(?=.{6,})/.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject("At least 6 characters with combination of one Uppercase, one Lowercase and one number/symbol.");
                  },
                }),
              ]}
            >
              <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            </Form.Item>

            <Form.Item
              label={t("passwordConfirm")}
              name="passwordConfirm"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: t('please_enter_this_field'),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Password not matched.");
                  },
                }),
              ]}
            >
              <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            </Form.Item>
          </>
        )}

        <Form.Item
          label={t("is_active")}
          name="is_active"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group>
            <Radio value={1}>{t('active')}</Radio>
            <Radio value={0}>{t('inactive')}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button
            className="p_company_user_edit"
            type="primary"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>

      {company_user_id > 0 && (
        <Form
          {...formItemLayout}
          form={passwordForm}
          onFinish={async (values) => {
            values.company_user_id = company_user_id;
            let result = await Service.call("patch", "/api/company/user/pw", values);
            const { status, errorMessage } = result;
            if (status !== 1) {
              return notification.error({ message: t("submission_failed"), description: errorMessage });
            }

            notification.success({ message: t("submitted_successfully") });
            return props.openModal(false);
          }}
        >
          <Form.Item
            label={t("password")}
            name="password"
            rules={[
              {
                required: true,
                message: "密碼必填",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (/(?=.*[a-z])(?=.*[A-Z])((?=.*\d)|(?=.*[-+_!@#$%^&*.,?]))(?=.{6,})/.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject("At least 6 characters with combination of one Uppercase, one Lowercase and one number/symbol.");
                },
              }),
            ]}
          >
            <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>

          <Form.Item
            label={t('passwordConfirm')}
            name="passwordConfirm"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: "確認密碼必填",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Password not matched.");
                },
              }),
            ]}
          >
            <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button
              className="p_company_user_edit"
              type="primary"
              htmlType="submit"
            >
              {t('submit_password_change')}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  )
}

export default CompanyUserForm;
