import React, { useState, useEffect } from 'react';
import {
  notification, Tabs, Table, Badge, Button, Modal, Form, Input, Radio, DatePicker, Row, Col, Select
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import debugLib from 'debug';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import * as Service from '../../core/Service';
import * as Main from "../../core/Main";
import HydrationServiceWorkflowReport from './HydrationServiceWorkflowReport';
import StockBalanceReportWithCost from './StockBalanceReportWithCost';
import StockCardReport from './StockCardReport';
import StockBalanceReportWithPrice from './StockBalanceReportWithPrice';

const { Search } = Input;
const debug = debugLib('app:pages/ReportGeneral');

const { TabPane } = Tabs;
const { Option } = Select;

const ReportGeneral = (props) => {
  const { t } = useTranslation();
  const myPermission = useSelector((state) => state.app.company_user.myPermission)
  const [dataList, setDataList] = useState([])
  const [isPermissionDenied, setIsPermissionDenied] = useState(false)

  useEffect(() => {
    // getAllData();
    getOrderAllData()
    setTapNotification();
  }, []);

  const setTapNotification = () => {
    let isAllowPermission = _.intersection(myPermission, ['p_order_agreement_view', 'p_any'])
    if (!_.isEmpty(isAllowPermission)) {
      setIsPermissionDenied(true);
    }
  }

  const getOrderAllData = async () => {
    try {
      let result = await Service.call('get', `/api/order_info/list`);
      console.log('orderResult', result);
      if (result.status !== 1) {
        return notification.warning({message: result.errorMessage});
      }
      let { data } = result;
      setDataList(data);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  return (
    <NavAndSideFrame {...props} title={t('general_report')} selectedKey="general_report">
      <Tabs
        type="card" 
        defaultActiveKey="customer_rental_history_report" 
        onChange={(activeKey) => {
          switch (activeKey) {
            case 'customer_rental_history_report':
            case 'hydration_service_workflow_report':
              // Check p_order_agreement_view
              if (!isPermissionDenied) {
                notification.warning({ message: `${t('permission_denied')} [${t('p_order_agreement_view')}]` });
              }
              break;
            default:
              break;
          }
        }}
      >
        <TabPane tab={t('customer_rental_history_report')} key="customer_rental_history_report">
          <CustomerRentalHistoryReport type="customer_rental_history_report" dataList={dataList} />
        </TabPane>
        <TabPane tab={t('hydration_service_workflow_report')} key="hydration_service_workflow_report">
          <HydrationServiceWorkflowReport type="hydration_service_workflow_report" dataList={dataList} />
        </TabPane>
        {/* <TabPane tab={t('stock_balance_report_with_cost')} key="stock_balance_report_with_cost">
          <StockBalanceReportWithCost type="stock_balance_report_with_cost" dataList={dataList} />
        </TabPane> */}
        <TabPane tab={t('stock_balance_report_with_price')} key="stock_balance_report_with_price">
          <StockBalanceReportWithPrice type="stock_balance_report_with_price" dataList={dataList} />
        </TabPane>
        <TabPane tab={t('stock_card_report')} key="stock_card_report">
          <StockCardReport type="stock_card_report" dataList={dataList} />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  )
}

const CustomerRentalHistoryReport = (props) => {
  const { type, dataList } = props
  const { t, i18n } = useTranslation()
  const app = useSelector((state) => state.app);
  const [customerReportForm] = Form.useForm();
  const [petDataList, setPetDataList] = useState([])
  const [loading, setLoading] = useState(false)
  const [mobileDataList, setMobileDataList] = useState([])

  useEffect(() => {
    let ownerMobileArr = _.uniqBy(dataList, 'owner_mobile');
    console.log("ownerMobileArr", ownerMobileArr)
    setMobileDataList(ownerMobileArr)
  }, [dataList])

  // ger redux site meta value
  const meta = useSelector((state) => state.app.meta);

  const dateFormat = `${
    !_.isEmpty(meta.system_date_format.meta_value) ? meta.system_date_format.meta_value : 'YYYY/MM/DD'
  }`
  const onSearchPet = (value) => {
    customerReportForm.setFieldsValue({'pet_name': []})
    let getPetData = _.filter(dataList, ['owner_mobile', value]);
    setPetDataList(getPetData)
  };

  return (
    <div>
      <div className="p_general_report_view">
        <Form
          form={customerReportForm}
          onFinish={async (values) => {
            const { owner_mobile, pet_name } = values
            let order_id = _.sortBy(pet_name);
            window.location = `${app.config.API_SERVER_URL}/api/report/order_report?owner_mobile=${owner_mobile}&order_id=${order_id}&language=${i18n.language}`;
          }}
        >

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label={t('owner_mobile')}
                name="owner_mobile"
                rules={[{ required: true, message: 'Please input mobile number.' }]}
              >
                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  loading={loading}
                  onChange={(value) => onSearchPet(value)}
                  rules={[{ required: true }]}
                >
                  {_.map(mobileDataList, (mobileRc) => (
                    <Option key={mobileRc.owner_mobile}>{mobileRc.owner_mobile}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={t('pet_name')}
                name="pet_name"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  rules={[{ required: true }]}
                >
                   <Option key={0}>ALL</Option>
                  {_.map(petDataList, (petRc) => (
                    <Option key={petRc.order_id}>{petRc.pet_name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              {t('export')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}


export default ReportGeneral;
