import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  AutoComplete, Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Space, Checkbox, Row, Col, Modal, Calendar, Badge, Layout, Table, Empty
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const OrderInfoCeremonyContainer = (props) => {
  const { t } = useTranslation()
  const [ceremonyData, setCeremonyData] = useState([])
  const [roomCeremonyMapping, setRoomCeremonyMapping] = useState({})

  const { selectedDate } = props
  // console.log('selectedDate', selectedDate)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const selectedDateUnix = moment(selectedDate).unix()

    let result = await Service.call('get', `/api/order_info/ceremony?dateUnix=${selectedDateUnix}&mode=day`);
    // console.log('result', result);

    if (result.status !== 1) {
      return notification.error({ message: '獲取失敗', description: result.errorMessage });
    }

    const { data } = result
    // console.log('data', data)
    setCeremonyData(data)

    //  Build room data mapping
    let roomCeremonyMap = {}
    data.map((item) => {
      if (!roomCeremonyMap[item.ceremony_room_id]) {
        roomCeremonyMap[item.ceremony_room_id] = []
      }
      roomCeremonyMap[item.ceremony_room_id].push(item)
    })
    // console.log('roomCeremonyMap', roomCeremonyMap)
    setRoomCeremonyMapping(roomCeremonyMap)
  }

  return (
    <>
      {_.isEmpty(roomCeremonyMapping) && (<Empty />)}

      <Tabs defaultActiveKey="" onChange={(key) => null}>
        {Object.keys(roomCeremonyMapping).map((ceremony_room_id) => {
          return (
            <TabPane tab={roomCeremonyMapping[ceremony_room_id][0].ceremony_room} key={ceremony_room_id}>
              <CeremonyList
                ceremonyData={roomCeremonyMapping[ceremony_room_id]}
              />
            </TabPane>
          )
        })}
      </Tabs>
    </>
  )
}

const CeremonyList = (props) => {
  const { t } = useTranslation()

  const { ceremonyData } = props
  console.log('ceremonyData', ceremonyData)

  const columns = [
    {
      title: `${t('ceremony_time')}`,
      dataIndex: 'ceremony_time',
      render: (value) => {
        return displayMoment(value);
      }
    },
    {
      title: `${t('ceremony_end_time')}`,
      dataIndex: 'ceremony_end_time',
      render: (value) => {
        return displayMoment(value);
      }
    },
    {
      title: `${t('ceremony_room')}`,
      dataIndex: 'ceremony_room',
    },
    {
      title: `${t('owner_first_name')}`,
      dataIndex: 'owner_first_name',
    },
    {
      title: `${t('owner_last_name')}`,
      dataIndex: 'owner_last_name',
    },
    {
      title: `${t('owner_mobile')}`,
      dataIndex: 'owner_mobile',
    },
  ]

  const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm') => {
    if (unixTime !== 0) {
      return moment.unix(unixTime).format(outputFormat);
    }
    return '-';
  }

  return (
    <Layout>
      <Table
        rowKey={() => 'order_info'}
        scroll={{ x: 'max-content' }}
        dataSource={ceremonyData}
        columns={columns}
      />
    </Layout>
  )
}

export default OrderInfoCeremonyContainer;
