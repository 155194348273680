import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  notification,
  Divider,
  InputNumber,
  AutoComplete,
  Row,
  Col,
  Modal,
  Spin
} from "antd";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { InfoOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import FormItem from "antd/lib/form/FormItem";
import * as Service from "../../core/Service";
import * as Main from "../../core/Main";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import { setCompanyAdmin } from "../../redux/actions/common";
import LanguageList from "../../data/LanguageList.json"
import OptionForm from '../company/OptionForm'
import LanguageSelector from '../../components/LanguageSelector';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { confirm } = Modal;

const OrderPublicForm = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { t, i18n } = useTranslation();
  const { order_id } = queryString.parse(location.search);
  let initialValues = {};
  let isReadOnly = false;
  const [basicFormInitialValues, setBasicFormInitialValues] = useState(
    initialValues
  );
  const [petCategoryOptions, setPetCategory] = useState([]);
  const [petSpeciesOptions, setPetSpecies] = useState([]);
  const [areaMapping, setAreaMapping] = useState({});
  const [districtMapping, setDistrictMapping] = useState({});
  const [typeForAreaDistrictAddressMapping, setTypeForAreaDistrictAddressMapping] = useState([])
  const [areaOptions, setArea] = useState([]);
  const [districtOptions, setDistrict] = useState([]);
  const [staffOptions, setStaffOption] = useState([]);
  const [formOptions, setFormOption] = useState([]);
  const [hiddenAreaDistrictBoolean, setHiddenAreaDistrict] = useState(false);
  const [addressLabel, setAddressLabel] = useState("address")
  const [chosenCategory, setChosenCategory] = useState(0)
  const [chosenAreaId, setChosenAreaId] = useState(0)
  const [chosenDistrictId, setChosenDistrictId] = useState(0)
  const [chosenAddressId, setChosenAddressId] = useState(0)
  const [chosenType, setChosenType] = useState('')
  const [pickUpType, setPickUpType] = useState([]);
  const [petWeightMeasure, setPetWeightMeasure] = useState('');
  const [modalVisible, setModalVisible] = useState(false)
  const [optionType, setOptionType] = useState(false)
  const [showAddressOptionList, setShowAddressOptionList] = useState(false)
  const [showAddress, setShowAddress] = useState(true)
  const [showStatusOption, setShowStatusOption] = useState(false)
  const [hiddenPetSpecies, setHiddenPetSpecies] = useState(true)
  const [hiddenPickUp, setHiddenPickUp] = useState(true)
  const [isPickUp, setIsPickUp] = useState(false)
  const [langBoolean, setLangBoolean] = useState(false)
  const [verifyPhoneModalVisible, setVerifyPhoneModalVisible] = useState(true)
  // const [loading, setLoading] = useState(true)
  const [verifiedToURL, setVerifiedToURL] = useState(false)
  const [disableLocation, setDisableLocation] = useState(false)
  const [areaCode, setAreaCode] = useState({})
  const [publicFormSubmitTime, setPublicFormSubmitTime] = useState('')

  const [form] = Form.useForm();

  useEffect(() => {
    if (!_.isUndefined(order_id)) {
      fetchData();
    }
  }, []);

  // useEffect(() => {
  //   fetchFormOptions();
  // }, []);

  useEffect(() => {
    if (!_.isEmpty(areaMapping) && !_.isEmpty(districtMapping) && chosenType) {
      fetchTypeRelation();
    }
    setDisableLocation(chosenType)
  }, [chosenType, areaMapping, districtMapping]);

  useEffect(() => {
    if (_.isEmpty(typeForAreaDistrictAddressMapping) || _.isEmpty(areaMapping) || _.isEmpty(districtMapping)) {
      return
    }
    optionUpdate()
  }, [typeForAreaDistrictAddressMapping, areaMapping, districtMapping])

  useEffect(() => {
    form.resetFields();
  }, [basicFormInitialValues]);

  useEffect(() => {
    if (chosenCategory !== 0 && !_.isUndefined(chosenCategory)) { fetchPetSpecies({}); setHiddenPetSpecies(false) }
  }, [chosenCategory, basicFormInitialValues.pet_species_id])

  useEffect(() => {
    if (chosenDistrictId !== 0 && !_.isUndefined(chosenDistrictId) && !_.isUndefined(optionType)) { setHiddenPickUp(false) }
  }, [chosenDistrictId, optionType])

  useEffect(() => {
    if (chosenCategory === 0 || _.isEmpty(petCategoryOptions)) { // Category is not selected before or option is not ready
      return;
    }

    let petCatIds = _.map(petCategoryOptions, 'value');
    if (_.includes(petCatIds, chosenCategory)) { // Selected record already existed in option list
      return;
    }

    // Append inactive record into option list if order picked record before
    fetchOptionById();
  }, [chosenCategory, petCategoryOptions])

  useEffect(() => {
    fetchPetCategory();
    fetchAreaList();
    fetchDistrictList();
  }, [i18n.language])

  // useEffect(() => {
  //   if (order_no) {
  //     fetchOrderInfo();
  //   }
  // }, [order_no]);

  const fetchOptionById = async () => {
    let result = await Service.call("get", `/api/form_option/id/${chosenCategory}`);
    const { status, data } = result;

    if (status !== 1) {
      return notification.warning({ message: "獲取資料失敗" });
    }

    const { form_option_id, name } = data;

    let petCatOption = _.concat(petCategoryOptions, {value: form_option_id, label: name});
    setPetCategory(petCatOption);
  }

  const fetchPickUpType = async () => {
    let pickUpTypeList = await Service.call('get', '/api/pick_up_type/list');
    setPickUpType(pickUpTypeList);
  }

  const fetchFormOptions = async () => {
    fetchPetCategory();
    fetchAreaList();
    fetchDistrictList();
    fetchAreaCode();
    fetchPickUpType();
    fetchPetWeightMeasure();
  };

  const fetchOption = async (type, id, value) => {
    switch (type) {
      case "pet_category":
        fetchPetCategory(id, value);
        form.setFieldsValue({ pet_category: value, pet_category_id: id})
        break;
      case "pet_species":
        fetchPetSpecies({id, value});
        form.setFieldsValue({ pet_species: value, pet_species_id: id})
        break;
      default:
        form.setFieldsValue({ pick_up_address: value, pick_up_address_id: id})
        break;
    }
  }

  const fetchAreaCode = async () => {
    let result = await Service.call('get', `/api/areaCode`);
    if (result.status !== 1) {
      return notification.warning({ message: '獲取區號失敗' });
    }

    let { data } = result;
    setAreaCode(data);
  }

  const fetchAreaList = async (id, value) => {
    let list = await Main.fetchFormOption("area", true, true);
    if (id) list.push({value: id, label: value})
    let mapping = {}
    _.forEach(list, (item) => {
      mapping[item.value] = item
    })
    setAreaMapping(mapping);
  };


  const fetchDistrictList = async (id, value) => {
    let list = await Main.fetchFormOption("district", true, true);
    if (id) list.push({value: id, label: value})
    let mapping = {}
    _.forEach(list, (item) => {
      mapping[item.value] = item
    })
    setDistrictMapping(mapping);
  };

  const fetchTypeRelation = async () => {
    let {status, data} = await Service.call("get", `/api/area_district_option_lookup/${chosenType}/${i18n.language}`);
    if (status !== 1) return;
    let mapping = _.map(data, ({
      area_id, district_id, form_option_id, form_option_name, type, remark
    }) => {
      let area = areaMapping[area_id] ? areaMapping[area_id].label : ''
      let district = districtMapping[district_id] ? districtMapping[district_id].label : ''
      return {
        area_id, area, district_id, district, form_option_id, form_option_name, type, remark
      }
    })
    setTypeForAreaDistrictAddressMapping(mapping)
  }

  const fetchPetCategory = async (id, value) => {
    let options = await Main.fetchFormOption("pet_category", true, true);
    if (id) { options.push({value: id, label: value}); setChosenCategory(id); form.setFieldsValue({pet_species_id: 0}); }
    setPetCategory(options);
  };

  const fetchPetSpecies = async ({id, value}) => {
    let options = await Main.fetchFormOption("pet_species", true, true);
    if (basicFormInitialValues.pet_species_id && basicFormInitialValues.pet_species) {
      options.push({value: basicFormInitialValues.pet_species_id, label: basicFormInitialValues.pet_species, is_default: 0})
    }
    if (chosenCategory !== 0 && !_.isUndefined(chosenCategory)) {
      let lookup = await Service.call("get", `/api/pet_category_species_lookup/all?pet_category_id=${chosenCategory}&lang=${i18n.language}`);
      let relatedPetSpecies = lookup.data.map((item) => {
        return ({value: item.pet_species_id, label: item.pet_species_name, is_default: 0})
      })
      options = _.intersectionWith(options, relatedPetSpecies, _.isEqual);
    }
    if (id) options.push({value: id, label: value})
    setPetSpecies(options);
  };

  // const fetchOrderInfo = async () => {
  //   let result = await Service.call(
  //     "get",
  //     `/api/order_info_public/search?order_no=${order_no}`
  //   );
  //   console.log('result', result)

  //   if (result.status !== 1) return;

  //   let [orderInfoObject] = result.data;
  //   orderInfoObject = Main.convertOrderInfoDisplayData(orderInfoObject);
  //   let newDefaultValues = { ...basicFormInitialValues, ...orderInfoObject };
  //   setBasicFormInitialValues(newDefaultValues);
  //   console.log('newDefaultValues: ', newDefaultValues)
  // };

  const fetchData = async () => {
    try {
      let orderInfoResult = await Service.call(
        "get",
        `/api/order_info_public/${order_id}`
      );
      if (orderInfoResult.status !== 1) {
        return;
      }

      let orderInfoObject = orderInfoResult.results;
      let userResult = await Service.call("get", `/api/public/user/${orderInfoObject.user_id}`)
      if (userResult.status !== 1) {
        return;
      }
      let userObj = userResult.data;
      let lang = _.isNull(userObj.preferred_language) ? _.keys(LanguageList)[0] : userObj.preferred_language;

      // Append user preferred language into order object
      orderInfoObject.preferred_language = lang;

      if (lang === _.keys(LanguageList)[0]) setLangBoolean(true);
      await i18n.changeLanguage(lang)
      fetchFormOptions();
      orderInfoObject = Main.convertOrderInfoDisplayData(orderInfoObject);
      let newDefaultValues = { ...initialValues, ...orderInfoObject };
      if (newDefaultValues.pick_up_address_id !== 0 && !_.isUndefined(newDefaultValues.pick_up_address_id)) {
        setChosenAddressId(newDefaultValues.pick_up_address_id)
      }
      if (newDefaultValues.pet_category_id !== 0) setChosenCategory(newDefaultValues.pet_category_id);
      if (newDefaultValues.pick_up_area_id !== 0) setChosenAreaId(newDefaultValues.pick_up_area_id);
      if (newDefaultValues.pick_up_district_id !== 0) setChosenDistrictId(newDefaultValues.pick_up_district_id);
      // console.log('newDefaultValues', newDefaultValues)
      onPickUpMethodChange(newDefaultValues.pick_up_type)
      setBasicFormInitialValues(newDefaultValues);
      setPublicFormSubmitTime(newDefaultValues.public_form_submit_time)
    } catch (error) {
      console.error("error >>> ", error);
    }
  };
  const fetchPetWeightMeasure = async () => {
    let metaWeight = "system_pet_weight_measurement"
    let metaKey = await Service.call('get', `/api/public/meta_by_key?meta_key=${metaWeight}`);
    let [WeightMeasure] = metaKey.data;
    setPetWeightMeasure(WeightMeasure.meta_value)
  }

  const resetLocation = () => {
    form.setFieldsValue(
      {
        pick_up_type: '',
        pick_up_area: '',
        pick_up_district: '',
        pick_up_address: '',
        pick_up_address_remark: ''
      }
    );
    setDisableLocation(false)
    setOptionType(0)
    setChosenAreaId(0)
    setChosenDistrictId(0)
    setChosenAddressId(0)
    setChosenType(0)
    setShowAddressOptionList(false)
    setShowAddress(true)
    onPickUpMethodChange('')
  }

  const optionUpdate = () => {
    let currentArea = form.getFieldValue("pick_up_area") || ''
    let currentDistrict = form.getFieldValue("pick_up_district") || ''
    let currentAddress = form.getFieldValue("pick_up_address") || ''
    let filterRelation = _.filter(typeForAreaDistrictAddressMapping, (item) => {
      const { area, district, form_option_name } = item;
      if (!_.includes(area.toUpperCase(), currentArea.toUpperCase())) {
        return false;
      }

      if (!_.includes(district.toUpperCase(), currentDistrict.toUpperCase())) {
        return false;
      }

      if (!_.includes(form_option_name.toUpperCase(), currentAddress.toUpperCase())) {
        return false;
      }

      return true;
    })
    setArea(
      _.uniqBy(filterRelation.map(({area, area_id}) => {
        return {
          key: area_id,
          value: area,
        }
      }), 'value')
    )
    setDistrict(
      _.uniqBy(filterRelation.map(({district, district_id}) => {
        return {
          key: district_id,
          value: district
        }
      }), 'value')
    )
    setFormOption(
      _.uniqBy(filterRelation.map(({form_option_name, form_option_id}) => {
        return {
          key: form_option_id,
          value: form_option_name
        }
      }), 'value')
    )
  }

  const onFinish = async (postObj) => {
    // return console.log('onFinish with postObj', postObj);
    postObj.pick_up_area_id = chosenAreaId
    postObj.pick_up_district_id = chosenDistrictId
    postObj.pick_up_address_id = chosenAddressId
    postObj.pet_category_id = _.toInteger(postObj.pet_category_id)
    postObj.pet_species_id = _.toInteger(postObj.pet_species_id)

    let method = "post";
    if (!_.isUndefined(order_id)) {
      method = "patch";
      postObj.order_id = order_id;
    }

    let data = await Service.call(method, "/api/order_info_public", postObj);
    // console.log('data', data);

    if (data.status !== 1) {
      notification.error({
        message: t("submission_failed"),
        description: data.errorMessage,
      });
      return;
    }

    // notification.success({ message: t("submitted_successfully") });
    history.push(`/order_info_public/succeed?order_id=${order_id}`);
  };

  const onPickUpMethodChange = (value) => {
    setOptionType(value)
    setChosenType(value)
    switch (value) {
      case "home":
      case "street":
      case "other":
        setHiddenAreaDistrict(false);
        setAddressLabel("address");
        setShowAddressOptionList(false)
        setShowAddress(true)
        break
      case "family_deliver":
      case "others_deliver":
        setHiddenAreaDistrict(true);
        setAddressLabel('address')
        setShowAddressOptionList(false)
        setShowAddress(false)
        break
      case "clinic":
      case "organization":
      case "pet_shop":
      case "pet_hotel":
      case "hospice":
        setHiddenAreaDistrict(false)
        setAddressLabel(value);
        setShowAddressOptionList(true)
        setShowAddress(true)
        break
      default:
        setHiddenAreaDistrict(false);
        setAddressLabel("address");
        setShowAddressOptionList(false)
        setShowAddress(true)
        break
    }
  }

  const showConfirm = (dataObj) => {
    confirm({
      title: '此連結只供一次性輸入',
      icon: <ExclamationCircleOutlined />,
      content: '提交前請確保資料正確無誤，如提交後需更改資料，請通知善終顧問作處理。',
      onOk() {
        onFinish(dataObj);
      },
    });
  }

  return (
    <>
      <Modal
        title={
          !(publicFormSubmitTime) ? (
            <Row justify="space-between" align="middle">
              <Col>{t('verify_phone_number')}</Col>
              <Col>
                <LanguageSelector />
              </Col>
            </Row>
          ) : '警告'
        }

        visible={verifyPhoneModalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        closable={false}
      >
        { !(publicFormSubmitTime)
          ? (
            <Form
              onFinish={async (value) => {
                // console.log("basicFormInitialValues.owner_mobile", basicFormInitialValues)
                let {phoneNumber} = value
                if (phoneNumber === basicFormInitialValues.owner_mobile) {
                  setVerifyPhoneModalVisible(false)
                  setVerifiedToURL(true)
                } else notification.error({ message: t("invaild_phone_number") });
              }}
            >
              <Form.Item
                label={t('verify_phone_number')}
                name="phoneNumber"
                rules={[{ required: true, message: t("invaild_phone_number")}]}
              >
                <Input placeholder="Eg: 21110000" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t('submit')}
                </Button>
              </Form.Item>
            </Form>

          )
          : '此連結只供一次性輸入'}

      </Modal>

      <Modal
        title={t('create') + t(optionType)}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <OptionForm
          selectedFormData={{}}
          type={optionType}
          categoryID={chosenCategory}
          areaID={chosenAreaId}
          districtID={chosenDistrictId}
          isPickUp={isPickUp}
          isPublic={1}
          closeMethod={async (form_option_id, value) => {
            fetchOption(optionType, form_option_id, value);
            setModalVisible(false);
          }}
        />
      </Modal>

      { verifiedToURL
      && (
      <Form
        form={form}
        name="orderInfoForm"
        layout="vertical"
        onFinish={showConfirm}
        initialValues={{
          ...basicFormInitialValues,
          pet_category_id: basicFormInitialValues.pet_category_id ? basicFormInitialValues.pet_category_id : undefined,
          pet_species_id: basicFormInitialValues.pet_species_id ? basicFormInitialValues.pet_species_id : undefined
        }}
        style={{ margin: 5 }}
      >
        {/* <Row justify="end">
          <Col><LanguageSelector bordered={false} /></Col>
        </Row> */}
        <Divider>
          ***
          {' '}
          {t("customer_and_pet_information")}
          {' '}
          ***
        </Divider>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label={t("order_no")} name="order_no">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item label="寵物編號" name="pet_no">
              <Input disabled />
            </Form.Item>
          </Col> */}
        </Row>

        <Row gutter={24}>
          {/* <Col span={12}>
            <Form.Item
              label={t("pick_up_time")}
              name="pick_up_time"
              rules={[
                {
                  required: true,
                  message: t("please_enter_this_field"),
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                showTime
                disabled={isReadOnly}
              />
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label={`${t("pet_estimate_weight")}(${petWeightMeasure})`}
              name="pet_estimate_weight"
              // rules={[{ required: true, message: "請輸入此欄位" }]}
            >
              <InputNumber style={{ width: "100%" }} disabled={isReadOnly} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="owner_salutation"
              label={t("owner_salutation")}
              rules={[{
                required: true,
                message: t('please_enter_this_field'),
              }]}
            >
              <Select disabled={isReadOnly}>
                <Option value="mr">{t("mr")}</Option>
                <Option value="mrs">{t("mrs")}</Option>
                <Option value="miss">{t("miss")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={t("owner_first_name")}
              name="owner_first_name"
            // rules={[{ required: true, message: t("please_enter_this_field") }]}
            >
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={t("owner_last_name")}
              name="owner_last_name"
              rules={[{ required: true, message: t("please_enter_this_field") }]}
            >
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={t("owner_mobile")}
              name="owner_mobile"
              rules={[
                {
                  required: true,
                  message: t("please_enter_this_field"),
                },
              ]}
            >
              <Input
                placeholder=""
                addonBefore={(
                  <Form.Item name="prefixMobile" noStyle>
                    <Select>
                      {_.map(areaCode, (val, key) => {
                        return (
                          <Option value={val} key={key}>{val}</Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                )}
                style={{ width: "100%" }}
                disabled={isReadOnly}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={t("owner_email")}
              name="owner_email"
              rules={[
                {
                  type: "email",
                  message: t("please_enter_a_valid_email"),
                },
                {
                  // required: true,
                  // message: t("please_enter_this_field"),
                },
              ]}
            >
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} align="middle">
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={t("pet_name")}
              name="pet_name"
              rules={[{ required: true, message: t("please_enter_this_field") }]}
            >
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
          <Col xs={20} sm={20} md={6}>
            <Form.Item
              label={t("pet_category")}
              name="pet_category_id"
              rules={[{
                required: true,
                message: t('please_enter_this_field'),
              }]}
            >
              <Select
                showSearch
                disabled={isReadOnly}
                options={_.sortBy(petCategoryOptions, ['label'])}
                placeholder={t("click_to_view_options")}
                filterOption={(inputValue, option) => {
                  return (
                    option.label
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  );
                }}
                onChange={(value, option) => {
                  form.setFieldsValue({ pet_category: option.label });
                  form.setFieldsValue({ pet_species_id: null });
                  setChosenCategory(option.value);
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={4} sm={4} md={2}>
            {langBoolean && (
            <Button
              type="primary"
              shape="circle"
              onClick={() => {
                setModalVisible(true)
                setOptionType('pet_category')
              }}
              icon={<PlusOutlined />}
            />
            )}
          </Col>

          <Col xs={20} sm={20} md={6}>
            <Form.Item label={t("pet_species")} name="pet_species_id">
              <Select
                showSearch
                allowClear
                disabled={isReadOnly}
                options={_.sortBy(petSpeciesOptions, ['label'])}
                placeholder={t("click_to_view_options")}
                filterOption={(inputValue, option) => {
                  return (
                    option.label
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  );
                }}
                onChange={(value, option) => {
                  let pet_species = ''
                  if (option) pet_species = option.label;
                  form.setFieldsValue({ pet_species });
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={4} sm={4} md={2}>
            {(langBoolean && !hiddenPetSpecies) && (
            <Button
              type="primary"
              shape="circle"
              onClick={() => {
                setModalVisible(true)
                setOptionType('pet_species')
              }}
              icon={<PlusOutlined />}
            />
            )}
          </Col>
        </Row>
        {/* Order No. / Title / Last Name / Country Calling Code / Mobile / Pet Name / Animal Code /
          Estimate Weight / Pick up Type... Addresss... / Vehicle Code / Pet Funeral Planner Code */}
        <Form.Item label={t("pet_category_name")} name="pet_category" hidden>
          <Input disabled />
        </Form.Item>
        <Form.Item label={t("pet_species")} name="pet_species" hidden>
          <Input disabled />
        </Form.Item>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="pet_gender"
              label={t("pet_gender")}
            // rules={[{ required: true }]}
            >
              <Select disabled={isReadOnly}>
                <Option value="m">{t("male")}</Option>
                <Option value="f">{t("female")}</Option>
                <Option value="o">{t("unknown")}</Option>

              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label={t("pet_age")} name="pet_age">
              <InputNumber disabled={isReadOnly} style={{width: "100%"}} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} align="middle">
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              rules={[{ required: true, message: t('please_enter_this_field'), }]}
              label={
                (
                  <>
                    <span style={{marginRight: '15px'}}>{t("pick_up_location")}</span>
                    <Button type="primary" shape="round" onClick={resetLocation}>
                      {t('reset')}
                    </Button>
                  </>
                )
              }
              name="pick_up_type"
            // rules={[{ required: true, message: "請輸入此欄位" }]}
            >
              <Select disabled={isReadOnly || disableLocation} onChange={onPickUpMethodChange}>
                {_.map(pickUpType, (val, key) => {
                  return (
                    <Option value={key} key={key}>{t(`${val}`)}</Option>
                  )
                })}
                {/* <Option value="home" selected>家中</Option>
                <Option value="family_deliver">家屬送來</Option>
                <Option value="clinic">診所</Option>
                <Option value="others_deliver">好心人送來</Option>
                <Option value="organization">機構</Option>
                <Option value="street">街上</Option>
                <Option value="pet_shop">寵物店</Option>
                <Option value="dog_hotel">狗酒店</Option>
                <Option value="other">其他</Option> */}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} align="middle">
          <Col xs={20} sm={20} md={20}>
            <Form.Item
              label={t(`${addressLabel}`)}// "地址" / "診所"
              name="pick_up_address"
              hidden={showAddressOptionList || !showAddress}
            >
              <Input disabled={isReadOnly} />
            </Form.Item>
            <Form.Item
              label={t(`${addressLabel}`)}// "地址" / "診所"
              name="pick_up_address"
              hidden={!showAddressOptionList || !showAddress}
            >
              <AutoComplete
                showSearch
                disabled={isReadOnly}
                options={formOptions}
                placeholder={t("click_to_view_options")}
                onChange={(value, option) => {
                  setChosenAddressId(option.key || 0)
                  optionUpdate()
                  let match = _.find(typeForAreaDistrictAddressMapping, {form_option_name: value})
                  if (match && value) {
                    form.setFieldsValue({
                      pick_up_area: match.area,
                      pick_up_district: match.district,
                      pick_up_address_remark: match.remark
                    })
                    setChosenAreaId(match.area_id)
                    setChosenDistrictId(match.district_id)
                    setChosenAddressId(match.form_option_id)
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={4}>
            {(showAddressOptionList && langBoolean && !hiddenPickUp) && (
            <Button
              type="primary"
              shape="circle"
              onClick={() => {
                setModalVisible(true)
                setIsPickUp(true)
              }}
              icon={<PlusOutlined />}
            />
            )}
          </Col>
        </Row>
        <Row gutter={24} align="middle">
          <Col xs={24} sm={24} md={9}>
            <Form.Item
              label={t("area")}
              name="pick_up_area"
              hidden={hiddenAreaDistrictBoolean}
            >
              <AutoComplete
                showSearch
                disabled={isReadOnly}
                options={chosenType ? areaOptions : []}
                placeholder={t("click_to_view_options")}
                onChange={(value, option) => {
                  setChosenAreaId(option.key || 0)
                  form.setFieldsValue({ pick_up_district: '' });
                  setChosenDistrictId(0)
                  optionUpdate()
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} align="middle">
          <Col xs={24} sm={24} md={9}>
            <Form.Item
              label={t("district")}
              name="pick_up_district"
              hidden={hiddenAreaDistrictBoolean}
            >
              <AutoComplete
                showSearch
                disabled={isReadOnly || !form.getFieldValue("pick_up_area")}
                options={chosenType ? districtOptions : []}
                placeholder={t("click_to_view_options")}
                onChange={(value, option) => {
                  setChosenDistrictId(option.key || 0)
                  optionUpdate()
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={20} sm={20} md={20}>
            <Form.Item
              label={t(`pick_up_address_remark`)}// "地址" / "診所"
              name="pick_up_address_remark"
            >
              <Input disabled={!showAddressOptionList} />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col xs={20} sm={20} md={20}>
            <Form.Item
              label={t(`preferred_language`)}
              name="preferred_language"
            >
              <Select>
                {_.map(LanguageList, (val, key) => {
                  return (
                    <Option value={key} key={key}>{val}</Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>


        {/* <Divider>*** {t("backup_data")} ***</Divider>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label={t("pet_birthday")} name="pet_birthday">
              <DatePicker
                style={{ width: "100%" }}
                format="YYYY-MM-DD"
                disabled={isReadOnly}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label={t("contact_person")} name="contact_person">
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("contact_person_mobile")} name="contact_person_mobile">
              <Input
                addonBefore={(
                  <Form.Item name="contactPersonMobilePrefix" noStyle>
                    <Select style={{ width: 80 }} disabled={isReadOnly}>
                      <Option value="+852">+852</Option>
                      <Option value="+86">+86</Option>
                    </Select>
                  </Form.Item>
                )}
                style={{ width: "100%" }}
                disabled={isReadOnly}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("owner_other_email")} name="owner_other_email">
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label={t("owner_other_email")} name="pet_other_info">
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("remark")} name="remark">
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("source")} name="source">
              <Input disabled={isReadOnly} />
            </Form.Item>
          </Col>
        </Row> */}
        <p>
          <strong>{`*${t('this_link_is_for_one_time_input_only')}`}</strong>
        </p>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={isReadOnly}>
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
      )}
    </>
  );
};

export default OrderPublicForm;
