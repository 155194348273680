import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Select,
  Tabs,
  notification,
  Divider,
  Modal,
  Tooltip,
  Space,
  Tag,
  Table,
  Layout,
} from "antd";
import moment from "moment";
import queryString from "query-string";
import { saveAs } from 'file-saver';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  DeleteOutlined, EditOutlined, CloseOutlined, CheckOutlined, FilePdfOutlined, FileAddOutlined
} from '@ant-design/icons';
import Fuse from 'fuse.js';
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import InvoiceInfoTable from "./InvoiceInfoTable"

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const debug = require('debug')('app:admin:pages:InvoiceInfoList');

// let fuse = [];
const { confirm } = Modal;

const OrderInfoInvoiceFrom = (props) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [userType, setUserType] = useState()
  const [modalVisible, setModalVisible] = useState(false)
  const [userOptions, setUserOptions] = useState([])
  const [orderNoOptions, setOrderNoOptions] = useState([])
  const [orderNo, setOrderNo] = useState([])
  const [userId, setUserId] = useState(0)

  let { order_id } = queryString.parse(location.search);
  let { order_id: orderIdProp = 0, isReadOnly } = props
  if (orderIdProp > 0) {
    order_id = orderIdProp // Override order_id if prop existing
  }

  const [initialValues, setInitialValues] = useState({});
  const tableIDName = "invoice_info_id";

  // useEffect(() => {

  // }, []);

  useEffect(() => {
    if (!_.isUndefined(order_id)) {
      getAllData();
      fetchData();
    }
  }, []);


  const getAllData = async () => {
    try {
      let result = await Service.call('get', `/api/invoice_info/order/${order_id}`);
      // console.log("ssssss>", result);
      if (result.status !== 1) {
        notification.warning({ message: '獲取資料失敗' });
        return;
      }

      let { data } = result;
      let orderedDataList = data;
      orderedDataList = orderedDataList.map((item) => {
        let entity = item;
        entity.order_info = item.order_info.map((order) => {
          return {...order, order_no_with_prefix: order.order_no_prefix + order.order_no}
        })
        entity.invoice_no_with_prefix = item.invoice_no_prefix + item.invoice_no;
        return entity
      })
      setDataList(orderedDataList);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  const fetchData = async () => {
    try {
      let orderInfoResult = await Service.call(
        "get",
        `/api/order_info/${order_id}`
      );
      // console.log('object >>> ', orderInfoResult)

      if (orderInfoResult.status !== 1) {
        return;
      }

      let orderInfoObject = orderInfoResult.results;
      orderInfoObject = Main.convertOrderInfoDisplayData(orderInfoObject);
      let newDefaultValues = { ...props.initialValues, ...orderInfoObject };
      console.log('newDefaultValues', newDefaultValues)
      let user = await Service.call('get', `/api/user/${newDefaultValues.user_id}`);
      setUserType(user.data.type);
      setInitialValues(newDefaultValues);
    } catch (error) {
      console.error("error >>> ", error);
    }
  };

  const getUserTypeByID = async () => {
    let {user_id} = initialValues;
    let user = await Service.call('get', `/api/user/${user_id}`);
    setUserType(user.data.type);
    return user.data.type;
  }

  return (
    <>
      <Space direction="horizontal">
        {userType === 'general' && (
        <Button
          disabled={isReadOnly}
          type="primary"
          className="p_invoice_edit"
          onClick={async () => {
            const user_invoice_type = await getUserTypeByID();
            history.push(`/company/invoice_info_form?order_no=${initialValues.order_no}&invoice_type=${user_invoice_type}&isFromOrder=true`)
          }}
        >
          {t("add_invoice")}
        </Button>
        )}
        {userType !== 'general' && (
          <Button
          disabled={isReadOnly}
          type="primary"
          className="p_invoice_monthly_edit"
          onClick={async () => {
            const user_invoice_type = await getUserTypeByID();
            history.push(`/company/invoice_info_form?order_no=${initialValues.order_no}&invoice_type=${user_invoice_type}&isFromOrder=true`)
          }}
        >
          {t("add_invoice")}
        </Button>
        )}
      </Space>
      <Divider />
      <Layout>
        <InvoiceInfoTable isReadOnly={isReadOnly} dataObj={dataList} fetchData={getAllData} />
      </Layout>
    </>
  )
}
export default OrderInfoInvoiceFrom;
