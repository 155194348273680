import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  AutoComplete, Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Space, Checkbox, Row, Col, Modal, Calendar, Badge
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from "react-i18next";
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';

import OrderInfoCeremonyContainer from './OrderInfoCeremonyContainer'

import '../../calendar.css';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const OrderInfoCalendar = (props) => {
  const [dataList, setDataList] = useState([])
  const [dateCeremonyMapping, setDateCeremonyMapping] = useState({})
  const [selectedDate, setSelectedDate] = useState(moment())
  const [modalVisible, setModalVisible] = useState(false);
  const { t, i18n } = useTranslation();

  // console.log('selectedDate', selectedDate)
  useEffect(() => {
    fetchData(moment())
  }, [])

  const fetchData = async (momentDate) => {
    // let dateUnix = moment(selectedDate).unix()
    let dateUnix = moment(momentDate).unix()
    let result = await Service.call('get', `/api/order_info/ceremony?dateUnix=${dateUnix}&mode=three_month`);
    // console.log('result', result);

    if (result.status !== 1) {
      return notification.error({ message: '獲取失敗', description: result.errorMessage });
    }

    const { data } = result
    // console.log('data', data)
    let dateCeremonyMap = {}
    data.map((item) => {
      let ceremonyData = moment.unix(item.ceremony_time).format('YYYY-MM-DD')
      let ceremonyStartTime = moment.unix(item.ceremony_time).format('HH:mm')
      let ceremonyEndTime = moment.unix(item.ceremony_end_time).format('HH:mm')
      item.ceremony_start_time = ceremonyStartTime
      item.ceremony_end_time = ceremonyEndTime

      if (!dateCeremonyMap[ceremonyData]) {
        dateCeremonyMap[ceremonyData] = []
      }
      dateCeremonyMap[ceremonyData].push(item)
    })
    // console.log('dateCeremonyMap', dateCeremonyMap)
    setDateCeremonyMapping(dateCeremonyMap)
  }

  const onSelect = (value) => {
    // console.log('onSelect value ', value)
    setSelectedDate(value)

    //  Pop-up dialog show detail
    setModalVisible(true);
  };

  const onPanelChange = (value, mode) => {
    // console.log(value.format('YYYY-MM-DD'), mode);

    switch (mode) {
      case 'month':
        fetchData(value)
        break;

      case 'year':
      default:
        break;
    }
  }

  const getListData = (value) => {
    if (_.isEmpty(dateCeremonyMapping)) {
      return [];
    }

    let renderDate = moment(value).format('YYYY-MM-DD')

    if (!dateCeremonyMapping[renderDate]) {
      return [];
    }

    let listData = dateCeremonyMapping[renderDate].map((item) => {
      return { type: 'default', content: `${item.ceremony_start_time} - ${item.ceremony_end_time} [${item.ceremony_room}]` }
    })

    return listData || [];
  }

  const dateCellRender = (value) => {
    // console.log('dateCellRender with value', value)
    // console.log('renderDate', renderDate)
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  }

  const getMonthData = (value) => {
    // if (value.month() === 8) {
    //   return 1394;
    // }
  }

  const monthCellRender = (value) => {
    return null;
    // const num = getMonthData(value);
    // return num ? (
    //   <div className="notes-month">
    //     <section>{num}</section>
    //     <span>Backlog number</span>
    //   </div>
    // ) : null;
  }

  return (
    <>
      <Calendar
        // value={selectedDate}
        locale={{
          lang: {
            locale: i18n.language,
            month: t('month'),
            year: t('year'),
          }
        }}
        onSelect={onSelect}
        onPanelChange={onPanelChange}
        dateCellRender={dateCellRender}
        monthCellRender={monthCellRender}
      />

      <Modal
        visible={modalVisible}
        footer={null}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <OrderInfoCeremonyContainer
          selectedDate={selectedDate}
        />
      </Modal>
    </>
  )
}

export default OrderInfoCalendar;
