import React, { useState, useEffect } from 'react';
import {
  // eslint-disable-next-line max-len
  notification, Tabs, Table, Badge, Button, Modal, Form, Input, Radio, DatePicker, Row, Col, Select, Space
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import debugLib from 'debug';
import NavAndSideFrame from '../../components/NavAndSideFrame';

import * as Service from '../../core/Service';
import * as Main from "../../core/Main";

const { Option } = Select;

const DayEndReport = () => {
  const app = useSelector((state) => state.app);
  const myPermission = useSelector((state) => state.app.company_user.myPermission);
  const [dayEndReport] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [date, setDate] = useState(0)
  const [startOfDate, setStartOfDate] = useState(0)
  const [endOfDate, setEndOfDate] = useState(0)
  const dateFormat = 'YYYY/MM/DD';

  const isAllowDayEndReport = React.useMemo(() => {
    return !_.isEmpty(_.intersection(myPermission, ['p_report_price_view', 'p_any']));
  }, [myPermission])

  function onChangefrom(dateTime, dateString) {
    let dateUnix = moment(dateString).unix()
    setDate(dateUnix)

    let dateStartOfDate = moment(dateString).startOf("day").unix();
    let dateEndOfDate = moment(dateString).endOf("day").unix();

    setStartOfDate(dateStartOfDate)
    setEndOfDate(dateEndOfDate)
  }

  return (
    <Form
      form={dayEndReport}
      onFinish={async (values) => {
        if (!isAllowDayEndReport) {
          return notification.error({ message: `${t('permission_denied')} [${t('p_report_price_view')}]` });
        }
        let win = window.open(`${app.config.API_SERVER_URL}/api/report/day_end_report?date=${date}&startOfDate=${startOfDate}&endOfDate=${endOfDate}&language=${i18n.language}`, '_blank');
        win.focus();
      }}
    >
      <Col span={8}>
        <Form.Item
          label={t("date")}
          name="date"
        >
          <DatePicker onChange={onChangefrom} format={'YYYY/MM/DD'}/>

        </Form.Item>
      </Col>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
        >
          {t('export')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DayEndReport;
