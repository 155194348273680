import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber
} from 'antd';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import NavAndSideFrame from '../../components/NavAndSideFrame';
import * as Service from '../../core/Service';

import OrderInfoBasicForm from './OrderInfoBasicForm';
import OrderInfoServiceDetailForm from './OrderInfoServiceDetailForm';
import OrderInfoAshesSpaceForm from './OrderInfoAshesSpaceForm';
import OrderItemForm from './OrderItemForm';
import OrderInfoPlantSpaceForm from './OrderInfoPlantSpaceForm';
import OrderInfoAshesServiceForm from './OrderInfoAshesServiceForm';
import InvoiceInfoBasicForm from './InvoiceInfoBasicForm';
import InvoiceItemForm from './InvoiceItemForm';
import InvoiceHistoryTable from './InvoiceHistoryTable';
import ReceiptInfoTable from './ReceiptInfoTable';

const debug = require('debug')('app:admin:pages:InvoiceInfoForm');

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const InvoiceInfoForm = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  let location = useLocation();
  const staff_id = useSelector((state) => state.app.company_user.company_user_id);
  const myPermission = useSelector((state) => state.app.company_user.myPermission);
  const {
    //  New
    order_no,
    invoice_type,
    user_id,
    //  Edit
    invoice_info_id,
    // Both
    isFromOrder,
  } = queryString.parse(location.search);
  // debug(`invoice_type>>>`, invoice_type)

  const [isAllowReceiptTap, setIsAllowReceiptTap] = useState(false);
  const [initialValues, setInitialValues] = useState({
    order_no, invoice_type, user_id, invoice_date: moment(), invoice_consultant_staff_id: staff_id
  });
  const [receiptInfoData, setReceiptInfoData] = useState([])
  let orderNoArray = []

  useEffect(() => {
    if (!_.isUndefined(invoice_info_id)) {
      fetchData();
      fetchReceiptByInvoice();
    } else if (!_.isUndefined(order_no)) { // New record
      fetchOrderInfo(order_no);
    }
    setTabVisible();
  }, []);

  const setTabVisible = () => {
    let isAllowPermission = _.intersection(myPermission, ['p_receipt_view', 'p_any'])
    if (!_.isEmpty(isAllowPermission)) {
      setIsAllowReceiptTap(true);
    }
  }

  const fetchData = async () => {
    let result = await Service.call('get', `/api/invoice_info/${invoice_info_id}`)
    if (result.status !== 1) return;

    let invoiceInfoObject = result.data
    // console.log('invoiceInfoObject', invoiceInfoObject)

    //  Convert data
    if (_.isInteger(invoiceInfoObject.invoice_date)) invoiceInfoObject.invoice_date = moment.unix(invoiceInfoObject.invoice_date)
    _.forEach(invoiceInfoObject.order_info, ((item) => {
      let lookUpItem = invoiceInfoObject.invoice_order_lookup.find((lookUp) => lookUp.order_id === item.order_id)
      item.hydration_service_total = lookUpItem ? lookUpItem.hydration_service_total : 0;
      item.item_total = lookUpItem ? lookUpItem.item_total : 0
    }))
    let newInitialValues = { ...initialValues, ...invoiceInfoObject }
    if (newInitialValues.agreement_consultant_staff_id === 0) newInitialValues.agreement_consultant_staff_id = initialValues.agreement_consultant_staff_id
    setInitialValues(newInitialValues)
  }

  const fetchReceiptByInvoice = async () => {
    try {
      let result = await Service.call('get', `/api/receipt_info/invoice?invoice_info_id=${invoice_info_id}`);
      // console.log('result', result);

      if (result.status !== 1) {
        return notification.warning({ message: '獲取資料失敗' });
      }

      let { data } = result;
      setReceiptInfoData(data)
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  const fetchOrderInfo = async (orderNo) => {
    orderNoArray = orderNo.split(",")

    let orderResults = await Promise.all(
      orderNoArray.map(async (item) => {
        let response = await Service.call('get', `/api/order_info/search?order_no=${item}`);
        let [orderObj] = response.data
        // console.log("orderObj>>>>>",orderObj)
        return orderObj;
      })
    )

    // console.log('orderResults', orderResults)

    let totalDeposit = _.reduce(orderResults, function (sum, item) {
      return sum + item.deposit;
    }, 0);
    // console.log('totalDeposit', totalDeposit)

    // hydration_service_total is recorded by each order, so no need to check look up
    let hydrationServiceTotal = _.reduce(orderResults, function (sum, item) {
      return sum + item.hydration_service_total;
    }, 0);

    // console.log('hydrationServiceTotal', hydrationServiceTotal)

    let OrderItemData = await Promise.all(
      orderResults.map(async (item) => {
        let results = await Service.call('get', `/api/order_info/order_item/${item.order_id}`)
        let allItemTotal = _.sum(_.map(results.data, "item_total"))
        return allItemTotal;
      })
    )

    let OrderItemDataTotal = _.reduce(OrderItemData, function (sum, item, index) {
      orderResults[index].OrderItemDataTotal = item;
      return sum + item;
    }, 0);

    // console.log("OrderItemDataTotal",OrderItemDataTotal)

    let invoiceSum = hydrationServiceTotal + OrderItemDataTotal;
    let invoiceTotal = hydrationServiceTotal + OrderItemDataTotal - totalDeposit;

    let defaultOrderInfoObject = { deposit: totalDeposit, order_info: orderResults }
    let defaultTotalObject = {
      hydration_service_total: hydrationServiceTotal, invoice_item_total: OrderItemDataTotal, invoice_total: invoiceTotal, invoice_sum: invoiceSum,
    }

    let newInitialValues = { ...initialValues, ...defaultOrderInfoObject, ...defaultTotalObject }

    setInitialValues(newInitialValues)
  }

  return (
    <NavAndSideFrame
      {...props}
      title={!_.isUndefined(invoice_info_id) ? t('edit_invoice') : t('add_invoice')}
      selectedKey="invoice_info"
    >

      <Tabs type="invoice" defaultActiveKey="invoice_info" onChange={(key) => null}>
        <TabPane tab={t('invoice_info')} key="invoice_info">
          <InvoiceInfoBasicForm
            initialValues={initialValues}
            fetchOrderInfo={fetchOrderInfo}
            isFromOrder={isFromOrder}
            reloadInvoice={fetchData}
          />
        </TabPane>

        {!_.isEmpty(initialValues.order_info) && (
          initialValues.order_info.map((item, index) => {
            return (
              <TabPane tab={`${t('customer_and_pet_information')}${index + 1}`} key={`order_info_basic_${index}`}>
                <OrderInfoBasicForm
                  order_no={item.order_no}
                  initialValues={{}}
                  isReadOnly
                />
              </TabPane>
            )
          })
        )}

        <TabPane tab={t('invoice_item')} key="invoice_item" disabled={!invoice_info_id}>
          <InvoiceItemForm
            initialValues={initialValues}
            isReadOnly
          />
        </TabPane>

        {isAllowReceiptTap && (
          <TabPane tab={t('receipt_information')} key="receipt_info" disabled={!invoice_info_id}>

            <Button
              type="primary"
              onClick={() => {
                history.push(`/company/receipt_info_form?invoice_no=${initialValues.invoice_no}`)
              }}
            >
              {t('add')}
            </Button>

            <ReceiptInfoTable
              dataObj={receiptInfoData}
              fetchData={null}
            />
          </TabPane>
        )}

        <TabPane tab={`PDF${t('record')}`} key="invoice_history" disabled={!invoice_info_id}>
          <InvoiceHistoryTable
            invoice_info_id={invoice_info_id}
          />
        </TabPane>
      </Tabs>

    </NavAndSideFrame>
  )
}

export default InvoiceInfoForm;
