export const SET_ADMIN = 'SET_ADMIN';
export const SET_COMPANY_ADMIN = 'SET_COMPANY_ADMIN';
export const SET_COMPANY_USER = 'SET_COMPANY_USER';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_AUTH = 'SET_AUTH';
export const SET_ORIGIN = 'SET_ORIGIN';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_LOADING = 'SET_LOADING';
export const SET_ALL_META = 'SET_ALL_META';
export const SET_SIDE_EFFECT = 'SET_SIDE_EFFECT';
export const SET_UNREAD_NOTIFICATION = 'SET_UNREAD_NOTIFICATION';
