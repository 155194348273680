import React, { useState, useEffect } from 'react';
import {
  Button, Divider, notification
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import ProductCategoryTable from './ProductCategoryTable';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProductCategoryList = (props) => {
  const [dataList, setDataList] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      let result = await Service.call('get', '/api/product_category/list');
      // console.log('result', result);

      if (result.status !== 1) {
        return notification.warning({message: result.errorMessage});
      }

      let { data } = result;
      let orderedDataList = _.orderBy(data, ['ctime'], ['desc']);
      setDataList(orderedDataList);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  return (
    <NavAndSideFrame {...props} title={t("product_category_list")} selectedKey="product_category">
      <Button
        type="primary"
        className="p_product_category_edit"
        onClick={() => {
          history.push('/company/product_category_form')
        }}
      >
        {t("add_new_product_category")}
      </Button>
      <Divider />
      <ProductCategoryTable
        dataObj={dataList}
        fetchData={getAllData}
      />
    </NavAndSideFrame>
  )
}

export default ProductCategoryList;
