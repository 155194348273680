import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  notification,
  Divider,
  InputNumber,
} from "antd";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import * as Service from "../../core/Service";
import * as Main from "../../core/Main";
import NavAndSideFrame from "../../components/NavAndSideFrame";

import OrderInfoBasicForm from "./OrderInfoBasicForm";
import OrderInfoDepositForm from "./OrderInfoDepositForm";
import OrderInfoServiceDetailForm from "./OrderInfoServiceDetailForm";
import OrderInfoProgressForm from "./OrderInfoProgressForm";
import OrderInfoAshesSpaceForm from "./OrderInfoAshesSpaceForm";
import OrderItemForm from "./OrderItemForm";
import OrderItemCheckForm from "./OrderItemCheckForm";
import OrderInfoPlantSpaceForm from "./OrderInfoPlantSpaceForm";
import OrderInfoAshesServiceForm from "./OrderInfoAshesServiceForm";
import OrderInfoInvoiceFrom from "./OrderInfoInvoiceFrom"
import OrderReceiptForm from "./OrderReceiptForm";
import OrderCommentForm from "./OrderCommentForm"
import OrderInfoAgreementSignatureForm from "./OrderInfoAgreementSignatureForm"
import OrderInfoServiceSignatureForm from "./OrderInfoServiceSignatureForm"
import OrderInfoAshesSpaceSignatureForm from "./OrderInfoAshesSpaceSignatureForm"
import OrderInfoPlantSpaceSignatureForm from "./OrderInfoPlantSpaceSignatureForm"
// import PPSignature from "../../components/PPSignature";
import OrderInfoConfirmedSignatureForm from "./OrderInfoConfirmedSignatureForm";
import OrderInfoPhotoPreviewForm from "./OrderInfoPhotoPreviewForm";

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const OrderInfoForm = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState("order_info")
  const staff_id = useSelector((state) => state.app.company_user.company_user_id);

  let {
    order_id, order_status, isFromInvoice, activeTab
  } = queryString.parse(location.search);
  let { order_id: orderIdProp } = props
  // from Calendar
  let { type, setModalVisible = () => {} } = props
  if (orderIdProp > 0) {
    order_id = orderIdProp
  }

  const [initialValues, setInitialValues] = useState({
    // prefixMobile: "+852",
    // pet_gender: "m",
    // // pick_up_area: "hong_kong",
    // contactPersonMobilePrefix: "+852",
    // agreement_consultant_staff_id: staff_id,
    // service_consultant_staff_id: staff_id,
    // ashes_space_consultant_staff_id: staff_id,
    // plant_space_consultant_staff_id: staff_id,
    // invoice_consultant_staff_id: staff_id
  });

  const [isReadOnly, setIsReadOnly] = useState(false);

  // useEffect(() => {
  //   if (!_.isUndefined(order_id) && !_.isUndefined(activeTab)) {
  //     setActiveKey(activeTab)
  //   }
  // }, [redirectTab])

  useEffect(() => {
    if (!_.isUndefined(order_id)) {
      fetchData();
      setActiveKey(activeTab)
    }
  }, [order_id]);

  // useEffect(() => {
  //   console.log(initialValues)
  // }, [initialValues])

  useEffect(() => {
    console.log("Checking", type)
    if (!_.isUndefined(type)) {
      switch (type) {
        case "ceremony_time":
        case "meet_up_time":
          setActiveKey("service_detail_form");
          break;
        case "ashes_pick_up_time":
        case "plant_time":
          setActiveKey("ashes_service_form");
          break;
        case "ashes_space_meet_up_time":
          setActiveKey("ashes_space_rental_form");
          break;
        case "plant_space_meet_up_time":
          setActiveKey("plant_space_rental_form");
          break;
        case "diy_time":
          setActiveKey("item_form");
          break;
        default:
          setActiveKey("order_info");
          break;
      }
    }
  }, [])

  useEffect(() => {
    if (isFromInvoice) setActiveKey("invoice_information")
  }, []);

  const fetchData = async () => {
    try {
      let orderInfoResult = await Service.call(
        "get",
        `/api/order_info/${order_id}`
      );

      if (orderInfoResult.status !== 1) {
        return;
      }
      // console.log('orderInfoResult: ', orderInfoResult)
      let orderInfoObject = orderInfoResult.results;
      orderInfoObject = await Main.convertOrderInfoDisplayData(orderInfoObject);
      let newDefaultValues = { ...initialValues, ...orderInfoObject };
      if (newDefaultValues.agreement_consultant_staff_id === 0) newDefaultValues.agreement_consultant_staff_id = initialValues.agreement_consultant_staff_id
      if (newDefaultValues.service_consultant_staff_id === 0) newDefaultValues.service_consultant_staff_id = initialValues.service_consultant_staff_id
      if (newDefaultValues.ashes_space_consultant_staff_id === 0) newDefaultValues.ashes_space_consultant_staff_id = initialValues.ashes_space_consultant_staff_id
      if (newDefaultValues.plant_space_consultant_staff_id === 0) newDefaultValues.plant_space_consultant_staff_id = initialValues.plant_space_consultant_staff_id
      if (newDefaultValues.invoice_consultant_staff_id === 0) newDefaultValues.invoice_consultant_staff_id = initialValues.invoice_consultant_staff_id
      console.log('newDefaultValues>>', newDefaultValues)
      console.log(`initialValues>>>`, initialValues)
      if (!_.isEmpty(order_status)) setIsReadOnly(true)

      if (newDefaultValues.locked) setIsReadOnly(true)
      setInitialValues(newDefaultValues);
    } catch (error) {
      console.error("error >>> ", error);
    }
  };


  return (
    <Tabs type="order" defaultActiveKey="order_info" activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
      <TabPane tab={t("authorization_letter")} className="p_order_agreement_view" key="order_info">
        <OrderInfoBasicForm
          initialValues={initialValues}
          order_id={order_id}
          isReadOnly={isReadOnly}
          onChange={async () => {
            setModalVisible(false)
            await fetchData()
          }}
        />
      </TabPane>
      <TabPane
        tab={t("deposit_form")}
        key="deposit_form"
        className="p_order_agreement_view"
        disabled={!order_id}
      >
        <OrderInfoDepositForm
          initialValues={initialValues}
          order_id={order_id}
          isReadOnly={isReadOnly}
          onChange={async () => {
            setModalVisible(false)
            await fetchData()
          }}
        />
      </TabPane>
      <TabPane
        tab={t("authorization_letter_signature")}
        key="authorization_letter_signature"
        className="p_order_agreement_view"
        disabled={!order_id}
      >
        <OrderInfoAgreementSignatureForm
          initialValues={initialValues}
          order_id={order_id}
          isReadOnly={isReadOnly}
          onChange={async () => {
            setModalVisible(false)
            await fetchData()
          }}
        />
      </TabPane>
      <TabPane
        tab={t("service_detail_form")}
        key="service_detail_form"
        // className="p_order_ceremony_view"
        disabled={!order_id}
      >
        <OrderInfoServiceDetailForm
          initialValues={initialValues}
          order_id={order_id}
          isReadOnly={isReadOnly}
          onChange={async () => {
            setModalVisible(false)
            await fetchData()
          }}
        />
      </TabPane>
      <TabPane
        tab={t("ashes_service_form")}
        key="ashes_service_form"
        // className="p_order_ceremony_view p_order_hydration_view"
        disabled={!order_id}
      >
        <OrderInfoAshesServiceForm
          initialValues={initialValues}
          isReadOnly={isReadOnly}
          order_id={order_id}
          onChange={async () => {
            setModalVisible(false)
            await fetchData()
          }}
        />
      </TabPane>
      <TabPane
        tab={t("service_signature")}
        key="service_signature"
        // className="p_order_ceremony_view"
        disabled={!order_id}
      >
        <OrderInfoServiceSignatureForm
          initialValues={initialValues}
          order_id={order_id}
          isReadOnly={isReadOnly}
          onChange={async () => {
            setModalVisible(false)
            await fetchData()
          }}
        />
      </TabPane>
      <TabPane tab={t("item_form")} key="item_form" className="p_order_agreement_view" disabled={!order_id}>
        <OrderItemForm
          initialValues={initialValues}
          order_id={order_id}
          isReadOnly={isReadOnly}
          onChange={async () => {
            setModalVisible(false)
            await fetchData()
          }}
        />
      </TabPane>

      <TabPane tab={t("item_form_check")} key="item_form_check" className="p_order_agreement_view" disabled={!order_id}>
        <OrderItemCheckForm
          initialValues={initialValues}
          order_id={order_id}
          isReadOnly={isReadOnly}
          onChange={async () => {
            setModalVisible(false)
            await fetchData()
          }}
        />
      </TabPane>

      <TabPane
        tab={t("ashes_space_rental_form")}
        key="ashes_space_rental_form"
        // className="p_order_ceremony_view"
        disabled={!order_id}
      >
        <OrderInfoAshesSpaceForm
          initialValues={initialValues}
          order_id={order_id}
          isReadOnly={isReadOnly}
          onChange={async () => {
            setModalVisible(false)
            await fetchData()
          }}
        />
      </TabPane>
      <TabPane
        tab={t("ashes_space_rental_signature")}
        key="ashes_space_rental_signature"
        // className="p_order_ceremony_view"
        disabled={!order_id}
      >
        <OrderInfoAshesSpaceSignatureForm
          initialValues={initialValues}
          order_id={order_id}
          isReadOnly={isReadOnly}
          onChange={async () => {
            setModalVisible(false)
            await fetchData()
          }}
        />
      </TabPane>
      <TabPane
        tab={t("plant_space_rental_form")}
        key="plant_space_rental_form"
        // className="p_order_ceremony_view"
        disabled={!order_id}
      >
        <OrderInfoPlantSpaceForm
          initialValues={initialValues}
          order_id={order_id}
          isReadOnly={isReadOnly}
          onChange={async () => {
            setModalVisible(false)
            await fetchData()
          }}
        />
      </TabPane>
      <TabPane
        tab={t("plant_space_rental_signature")}
        key="plant_space_rental_signature"
        // className="p_order_ceremony_view"
        disabled={!order_id}
      >
        <OrderInfoPlantSpaceSignatureForm
          initialValues={initialValues}
          order_id={order_id}
          isReadOnly={isReadOnly}
          onChange={async () => {
            setModalVisible(false)
            await fetchData()
          }}
        />
      </TabPane>
      <TabPane
        tab={t("invoice")}
        key="invoice_information"
        className="p_order_agreement_view"
        disabled={!order_id}
      >
        <OrderInfoInvoiceFrom
          isReadOnly={isReadOnly}
          initialValues={initialValues}
          order_id={order_id}
        />
      </TabPane>
      <TabPane
        tab={t("receipt_information")}
        key="receipt_information"
        className="p_order_agreement_view"
        disabled={!order_id}
      >
        <OrderReceiptForm
          isReadOnly={isReadOnly}
          initialValues={initialValues}
          order_id={order_id}
        />
      </TabPane>
      <TabPane
        tab={t("progress_form")}
        key="progress_form"
        // className="p_order_status_view"
        disabled={!order_id}
      >
        <OrderInfoProgressForm
          initialValues={initialValues}
          order_id={order_id}
          isReadOnly={isReadOnly}
          onChange={async () => {
            setModalVisible(false)
            await fetchData()
          }}
        />
      </TabPane>
      <TabPane
        tab={t("order_confirmed_signature")}
        key="order_confirmed_signature"
        className="p_order_agreement_view"
        disabled={!order_id}
      >
        <OrderInfoConfirmedSignatureForm
          initialValues={initialValues}
          isReadOnly={isReadOnly}
          order_id={order_id}
          onChange={async () => {
            setModalVisible(false)
            await fetchData()
          }}
        />
      </TabPane>
      <TabPane
        tab={t("photo_preview")}
        key="photo_preview"
        className="p_order_agreement_view"
        disabled={!order_id}
      >
        <OrderInfoPhotoPreviewForm
          initialValues={initialValues}
          isReadOnly={isReadOnly}
          order_id={order_id}
        />
      </TabPane>
      <TabPane
        tab={t("order_comment")}
        key="order_comment"
        className="p_order_agreement_view"
        disabled={!order_id}
      >
        <OrderCommentForm
          initialValues={initialValues}
          order_id={order_id}
        />
      </TabPane>
    </Tabs>
  );
};

export default OrderInfoForm;
