import React, { Component } from 'react';
import {
  BrowserRouter, Redirect, Switch , Route
} from "react-router-dom";
import 'antd/dist/antd.css';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { ActionCreators } from './redux/actions';
import Login from './pages/admin/Login';
import Home from './pages/admin/Home';
import CompanyUserList from './pages/company/CompanyUserList';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import CompanyAdminLogin from './pages/company/Login';
import CompanyAdminHome from './pages/company/Home';
import OrderInfo from './pages/company/OrderInfo';
import OrderInfoFormContainer from './pages/company/OrderInfoFormContainer';
import ProductItemList from './pages/company/ProductItemList';
import ProductItemForm from './pages/company/ProductItemForm';
import ProductCategoryList from './pages/company/ProductCategoryList';
import ProductCategoryForm from './pages/company/ProductCategoryForm';
import InvoiceInfoList from './pages/company/InvoiceInfoList';
import InvoiceInfoForm from './pages/company/InvoiceInfoForm';
import ReceiptInfoList from './pages/company/ReceiptInfoList';
import ReceiptInfoForm from './pages/company/ReceiptInfoForm';
import Report from './pages/company/Report';
import ReportGeneral from './pages/company/ReportGeneral';
import UserAccount from './pages/company/UserAccount';
import UserAccountContainer from './pages/company/UserAccountContainer';
import Setting from './pages/company/Setting';
import SiteLogoForm from './pages/company/SiteLogoForm';
import OptionSetting from './pages/company/OptionSetting';
import PetServiceCharge from './pages/company/PetServiceCharge';
import PetCategorySpecies from './pages/company/PetCategorySpecies';
import AreaDistrictPickUp from './pages/company/AreaDistrictPickUp';
import TranslationList from './pages/company/TranslationList';
import UserRoleList from './pages/company/UserRoleList';
import SpaceRentalList from './pages/company/SpaceRentalList';
import orderPublicForm from './pages/public/orderPublicForm'
import orderPublicResult from './pages/public/orderPublicResult'
import AdminAccountList from './pages/admin/AdminAccountList';
import AdminAudit from './pages/company/ActionAudit'
import CalendarView from './pages/company/CalendarView'
import HydrationMachineList from './pages/company/HydrationMachineList'
import HydrationMachineForm from './pages/company/HydrationMachineForm'
import HydrationBatchList from './pages/company/HydrationBatchList'
import HydrationBatchForm from './pages/company/HydrationBatchForm'
import ActionInfoList from './pages/company/ActionInfoList'
import WorkflowList from './pages/company/WorkflowList'

class AppRoute extends React.PureComponent {
  render() {
    // console.log('Pure component AppRoute');
    return (
      <BrowserRouter>
        <Switch>
          {/* Admin Router */}
          <PublicRoute restricted path="/admin/login" component={Login} exact />
          <PrivateRoute restricted path="/admin/home" component={Home} privilege={'admin'} exact />
          {/* <PrivateRoute restricted path="/admin/list" component={AdminAccountList} privilege={'admin'} exact /> */}

          {/* Company Router */}
          <PublicRoute restricted path="/company/login" component={CompanyAdminLogin} exact />
          <PrivateRoute restricted path="/company/home" component={CompanyAdminHome} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/order_info" component={OrderInfo} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/order_info_form" component={OrderInfoFormContainer} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/calendar_view" component={CalendarView} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/product_item" component={ProductItemList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/product_item_form" component={ProductItemForm} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/product_category" component={ProductCategoryList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/product_category_form" component={ProductCategoryForm} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/invoice_info" component={InvoiceInfoList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/invoice_info_form" component={InvoiceInfoForm} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/receipt_info" component={ReceiptInfoList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/receipt_info_form" component={ReceiptInfoForm} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/report" component={Report} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/general_report" component={ReportGeneral} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/user_account" component={UserAccount} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/user_account_form" component={UserAccountContainer} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/user" component={CompanyUserList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/setting" component={Setting} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/site_logo_form" component={SiteLogoForm} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/option_setting" component={OptionSetting} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/hydration_machine_setting" component={HydrationMachineList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/hydration_machine_form" component={HydrationMachineForm} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/hydration_batch_setting" component={HydrationBatchList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/hydration_batch_form" component={HydrationBatchForm} privilege={'company'} exact />
          {/* <PrivateRoute restricted path="/company/action_info_setting" component={ActionInfoList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/workflow_setting" component={WorkflowList} privilege={'company'} exact /> */}
          <PrivateRoute restricted path="/company/pet_service_charge" component={PetServiceCharge} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/pet_category_species" component={PetCategorySpecies} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/area_district_pickUp" component={AreaDistrictPickUp} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/translation" component={TranslationList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/space_rental" component={SpaceRentalList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/user_role" component={UserRoleList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/audit_log" component={AdminAudit} privilege={'company'} exact />
          {/* Test for Public URL */}
          <Route restricted path="/order_info_public" component={orderPublicForm} exact />
          <Route path="/order_info_public/succeed" component={orderPublicResult} exact />
          <Redirect exact from="/admin/*" to="/admin/home" />
          <Redirect exact from="*" to="/company/home" />
        </Switch>
      </BrowserRouter>
    )
  }
}

function mapStateToProps(state) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRoute);
