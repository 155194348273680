import React, { Component, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Divider,
  Form,
  Icon,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  notification
} from "antd";
import {
  EditOutlined,
  KeyOutlined,
  StopOutlined,
  CheckOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Operation from "antd/lib/transfer/operation";
import * as Service from "../core/Service";

const { confirm } = Modal;

const UserAccountTable = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [dataList, setDataList] = useState([]);
  // const [selectedRecord, setSelectedRecord] = useState([]);
  // const [modalVisible, setModalVisible] = useState(false);
  const tableIDName = "user_id";
  // const admin = useSelector(state => state.app.admin);
  // console.log('Table Data', props.dataObj);
  const { paginationOnChange, pagination, loading } = props;

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t('active');
        tagColor = "green";
        break;
      case 0:
        displayStr = t('inactive');
        tagColor = "red";
        break;
      default:
        displayStr = "Error";
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const displayIsDeleted = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 0:
        displayStr = "Active";
        tagColor = "green";
        break;
      case 1:
        displayStr = "Deleted";
        tagColor = "red";
        break;
      default:
        displayStr = "Error";
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  useEffect(() => {
    setDataList(props.dataObj);
  }, [props.dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    history.push(`/company/user_account_form?user_id=${value}`);
                  }}
                  style={{ marginRight: 8, color: "#000000" }}
                />
              </Tooltip>
              <Tooltip title={t("reset_password")}>
                <Button
                  shape="circle"
                  icon={<KeyOutlined />}
                  onClick={() => {
                    confirm({
                      title: t("confirm_reset_password"),
                      okText: t("yes"),
                      okType: "danger",
                      cancelText: t("no"),
                      onOk: async () => {
                        let postObj = {
                          user_id: record.user_id,
                          password: _.split(record.mobile, "-")[1]
                        }
                        let resp = await Service.call('patch', '/api/user/pw', postObj)
                        if (resp.status !== 1) {
                          return notification.error({ message: t('submission_failed'), description: resp.errorMessage });
                        }
                        notification.success({ message: t('submitted_successfully') });
                        history.push(`/company/user_account`);
                      },
                      onCancel: () => {
                        console.log('cancel');
                      },
                    });
                  }}
                />
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: t('account_status'),
        dataIndex: "status",
        render: (value) => displayIsActive(value),
      },
      {
        title: t('user_is_deleted'),
        dataIndex: "is_deleted",
        render: (value) => displayIsDeleted(value),
      },
      {
        title: t('user_id'),
        dataIndex: `user_id`
      },
      {
        title: t("create_staff_id"),
        dataIndex: `create_staff_id`,
      },
      {
        title: t("salutation"),
        dataIndex: `salutation`,
        render: (text) => t(text)
      },
      {
        title: t("first_name"),
        dataIndex: `first_name`,
      },
      {
        title: t("last_name"),
        dataIndex: `last_name`,
      },
      {
        title: t("mobile"),
        dataIndex: `mobile`,
      },
      {
        title: t("organization_name"),
        dataIndex: `organization_name`,
      },
      {
        title: t("email"),
        dataIndex: `email`,
      },
    ];
    return columns;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList}
        onChange={paginationOnChange}
        columns={setTableHeader()}
        pagination={pagination}
        loading={loading}
      />
    </Layout>
  );
};

export default UserAccountTable;
