import React, { useState, useEffect, useDebugValue, useRef } from 'react';
import {
  Button, Divider, notification, Space, Input, Modal, Select, Form, Row, Col
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import ProductItemTable from './ProductItemTable';
import FormUploadExcel from '../../components/form/FormUploadExcel';
import { InventoryControlPanel, InventoryForm } from './ProductInventory';

let fuse = [];
const { Option } = Select;
const ProductItemList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [productCategoryId, setProductCategoryId] = useState();
  const [isStockProduct, setIsStockProduct] = useState('');
  const [productCategoryOptions, setProductCategoryOptions] = useState([]);
  const [productCatList, setProductCategoryList] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const inputRef = useRef();

  useEffect(() => {
    getAllData();
    getProductCategoryId();
  }, []);

  useEffect(() => {
    console.log(productCategoryId)
  }, [productCategoryId])

  const getAllData = async () => {
    try {
      let result = await Service.call('get', '/api/product');
      // console.log('result', result);

      if (result.status !== 1) {
        return notification.warning({message: result.errorMessage});
      }

      let { data } = result;
      let orderedDataList = _.orderBy(data, ['ctime'], ['desc']);
      const fuseOptions = {
        findAllMatches: true,
        includeScore: true,
        threshold: 0,
        keys: [
          'sku', 'product_name', 'product_category_lookup'
        ]
      };
      fuse = new Fuse(orderedDataList, fuseOptions);
      setDataList(orderedDataList);
      filterData({searchWord: inputRef.current.input.value})
    } catch (error) {
      console.error('error >>> ', error);
    }
  }
  const getProductCategoryId = async () => {
    try {
      let result = await Service.call('get', '/api/product_category/list');
      // console.log('result', result);

      if (result.status !== 1) {
        return notification.warning({message: result.errorMessage});
      }

      let { data } = result;
      setProductCategoryList(data);
      let options = data.map((item) => {
        return {label: item.category_name, value: item.product_category_id}
      })
      setProductCategoryOptions(options);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }


  const filterData = async (filterObj = {}) => {
    if (_.isEmpty(filterObj.searchWord)) { //  Return original data without search
      return setDataList(fuse._docs)
    }

    const fuzzyResults = fuse.search(filterObj.searchWord).map((result) => result.item)
    setDataList(fuzzyResults)
  }

  const importProductCSV = async (filepath) => {
    let resp = await Service.call('post', '/api/product/import', {
      filepath,
    });
    let description = " "
    if (resp.arr.length > 0) {
      description = `Row ${resp.arr} import failed`
    }

    notification.success({
      message: `Product item import done. ${resp.succeed} succeed, ${resp.failed} failed.`,
      description,
      duration: 0
    });

    getAllData()
  }

  return (
    <NavAndSideFrame {...props} title={t("product_item_list")} selectedKey="product_item">
      <Modal
        title={t("export_Product")}
        visible={modalVisible}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onOk={async (e) => {
          if (_.isUndefined(productCategoryId)) return
          window.location = `${app.config.API_SERVER_URL}/api/product/report?product_category_id=${productCategoryId}&is_stock_product=${isStockProduct}`;
          setIsStockProduct('')
          setProductCategoryId('')
          setModalVisible(false)
        }}
        onCancel={(e) => {
          setModalVisible(false)
          setIsStockProduct('')
          setProductCategoryId('')
        }}
      >
        <Form
          layout="vertical"
        >
          <Form.Item
            label={t('product_category')}
          >
            <Select
              showSearch
              value={productCategoryId}
              style={{ width: '100%' }}
              placeholder={t('product_category')}
              options={productCategoryOptions}
              optionFilterProp="children"
              onChange={(value) => {
                setProductCategoryId(value)
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            />
          </Form.Item>
          <Form.Item
            label={t('is_stock_product')}
          >
            <Select
              defaultValue=""
              value={isStockProduct}
              style={{ width: '100%' }}
              placeholder={t('is_stock_product')}
              onChange={(value) => {
                setIsStockProduct(value)
              }}
            >
              <Option value="">{t('all')}</Option>
              <Option value={1}>{t('yes')}</Option>
              <Option value={0}>{t('no')}</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Space>
        <Button
          // className="p_product_name_edit"
          type="primary"
          onClick={() => {
            history.push('/company/product_item_form')
          }}
        >
          {t("add_new_item")}
        </Button>
        <Button
          // className="p_product_price_edit"
          type="primary"
          onClick={() => {
            setModalVisible(true)
          }}
        >
          {t("export_Product")}
        </Button>
        <FormUploadExcel
          onSuccess={(resp) => {
            const {
              originalname,
              filename,
              filepath,
              url,
            } = resp;
            importProductCSV(filepath)
          }}
        />
        <>
          {t('search')}
        </>
        <Input
          ref={inputRef}
          placeholder={t('please_input_keyword')}
          onChange={(event) => {
            filterData({ searchWord: event.target.value })
          }}
        />
      </Space>
      <Divider />
      <InventoryControlPanel productList={dataList} closeCallback={() => { getAllData() }} />
      <Divider />
      <ProductItemTable
        dataObj={dataList}
        productCategoryData={productCatList}
        fetchData={getAllData}
      />
    </NavAndSideFrame>
  )
}

export default ProductItemList;
