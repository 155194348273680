import React from "react";
import _ from "lodash";
import { Image, Table } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const OrderInfoPreviewPhotoForm = ({ initialValues }) => {
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const { STATIC_SERVER_URL } = app.config;
  const photoKey = [
    {
      product_name: "received",
      product_path: "progress_pet_received_photo_1"
    },
    {
      product_name: "weighing",
      product_path: "progress_pet_weighed_photo_1"
    },
    {
      product_name: "pet_refrigerated",
      product_path: "progress_pet_refrigerated_photo_1"
    },
    {
      product_name: "cleaning",
      product_path: "progress_clean_photo_1"
    },
    {
      product_name: "send_to_funeral",
      product_path: "progress_send_ceremony_photo_1"
    },
    {
      product_name: "waiting_for_funeral",
      product_path: "progress_pending_ceremony_photo_1"
    },
    {
      product_name: "starting_funeral",
      product_path: "progress_ceremony_starting_photo_1"
    },
    {
      product_name: "end_of_funeral",
      product_path: "progress_ceremony_completed_photo_1"
    },
    {
      product_name: "enter_hydration",
      product_path: "progress_enter_hydration_photo_1"
    },
    {
      product_name: "air_dried",
      product_path: "progress_air_dried_photo_1"
    },
    {
      product_name: "bones_broken",
      product_path: "progress_bones_broken_photo_1"
    }
  ];
  const getColumns = () => {
    const columns = [
      {
        title: `${t('status')}`,
        dataIndex: "photoName",
        render: (name) => <div>{name}</div>,
      },
      {
        title: `${t('photo')}`,
        dataIndex: "path",
        render: (path) => {
          return path ? (
            <Image width={200} src={`${STATIC_SERVER_URL}/${path}`} />
          ) : null;
        },
      },
    ];
    return columns;
  };

  const getDataSources = () => {
    return photoKey.map(({product_name, product_path}) => {
      return {
        photoName: t(product_name),
        path: initialValues ? initialValues[product_path] : "",
      };
    });
  };
  return <Table columns={getColumns()} dataSource={getDataSources()} />;
};

export default OrderInfoPreviewPhotoForm;
