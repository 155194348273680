import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  AutoComplete,
  Button,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  notification,
  Divider,
  InputNumber,
  Space,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import queryString from "query-string";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import * as Service from "../../core/Service";

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const InvoiceItemForm = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  let location = useLocation();
  const { invoice_info_id } = queryString.parse(location.search);

  // isReadOnly is from invoiceInfo Form
  const { initialValues, isReadOnly } = props;

  const [invoiceItemInitialValues, setInvoiceItemInitialValues] = useState(
    initialValues
  );
  const [invoiceItemForm] = Form.useForm();
  const [productItemSku, setProductItemSku] = useState([]);
  const [productItemName, setProductItemName] = useState([]);

  useEffect(() => {
    fetchProductItem();
    fetchInvoiceItem();
  }, []);

  useEffect(() => {
    invoiceItemForm.resetFields();
  }, [invoiceItemInitialValues]);

  const fetchProductItem = async () => {
    let results = await Service.call("get", "/api/product?is_active=1");
    // console.log('results', results);
    if (results.status !== 1) {
      return;
    }

    let skuOptions = results.data.map((item) => {
      return {
        value: item.sku,
        product_id: item.product_id,
        item_name: item.product_name,
        item_price: item.price,
        item_wholesale_price: item.wholesale_price,
      };
    });

    let productNameOptions = results.data.map((item) => {
      return {
        value: item.product_name,
        product_id: item.product_id,
        item_sku: item.sku,
        item_price: item.price,
        item_wholesale_price: item.wholesale_price,
      };
    });

    setProductItemSku(skuOptions);
    setProductItemName(productNameOptions);
  };

  const fetchInvoiceItem = async () => {
    let results = await Service.call(
      "get",
      `/api/invoice_info/invoice_item/${invoice_info_id}`
    );
    // console.log("results", results);
    if (results.status !== 1 || _.isEmpty(results.data)) {
      return;
    }
    // console.log('invoiceItemInitialValues', invoiceItemInitialValues);

    let invoiceItemObject = {};
    invoiceItemObject.invoice_item = results.data.map((item) => item);

    let nextInvoiceItemInitialValues = {
      ...invoiceItemInitialValues,
      ...invoiceItemObject,
    };
    // console.log('nextInvoiceItemInitialValues', nextInvoiceItemInitialValues)

    setInvoiceItemInitialValues(nextInvoiceItemInitialValues);
  };

  const updateItemTable = async ({ index, targetColumn, option }) => {
    let invoice_item = invoiceItemForm.getFieldValue().invoice_item;
    // get user type first
    // change whole sales price

    // const { order_id } = queryString.parse(location.search);
    let orderInfo = await Service.call(
      "get",
      `/api/invoice_info/${invoice_info_id}`
    );
    // console.log("orderInfo>", orderInfo);

    let userId = orderInfo.data.user_id;
    // console.log("id>", userId);

    // let user_id = await Service.call('get', '/api/user/list');
    let user_id = await Service.call("get", `/api/user/${userId}`);
    // console.log("ggog>>", user_id);

    let user_type = user_id.data.type;
    // console.log("type", user_type);
    switch (targetColumn) {
      case "item_sku":
        invoice_item[index].product_id = option.product_id;
        invoice_item[index].item_name = option.item_name;
        invoice_item[index].item_price = option.item_wholesale_price;
        break;

      case "item_name":
        invoice_item[index].product_id = option.product_id;
        invoice_item[index].item_sku = option.item_sku;
        invoice_item[index].item_price = option.item_wholesale_price;
        break;

      default:
        break;
    }
    if (user_type === "wholesale") {
      invoice_item[index].item_price = option.item_wholesale_price;
    } else {
      invoice_item[index].item_price = option.item_price;
    }
    invoiceItemForm.setFieldsValue({ invoice_item });
    calculateTotal();
  };

  const calculateTotal = () => {
    let invoice_item = invoiceItemForm.getFieldValue().invoice_item;
    // console.log('invoice_item', invoice_item)

    invoice_item.map((item) => {
      item.item_total = item.quantity * item.item_price;
    });

    invoiceItemForm.setFieldsValue({ invoice_item });
  };

  return (
    <Form
      form={invoiceItemForm}
      name="invoiceItemForm"
      layout="vertical"
      initialValues={invoiceItemInitialValues}
      onFinish={async (values) => {
        // console.log('values', values);
        // return;

        values.invoice_info_id = invoice_info_id;
        let data = await Service.call(
          "post",
          "/api/invoice_info/invoice_item",
          values
        );
        // console.log('data', data);

        if (data.status !== 1) {
          notification.error({
            message: "提交失敗",
            description: data.errorMessage,
          });
          return;
        }

        notification.success({ message: "提交成功" });
        history.push(`/company/invoice_info`);
      }}
    >
      <Form.List name="invoice_item">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, dynamicRowIndex) => (
                <Space
                  key={field.key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="start"
                >
                  <Form.Item
                    {...field}
                    name={[field.name, "product_id"]}
                    fieldKey={[field.fieldKey, "product_id"]}
                    label={dynamicRowIndex === 0 ? "貨品ID" : ""}
                    hidden
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "item_sku"]}
                    fieldKey={[field.fieldKey, "item_sku"]}
                    rules={[{ required: true, message: t('missing_sku') }]}
                    label={dynamicRowIndex === 0 ? t('sku') : ""}
                  >
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder={t('search_sku')}
                      options={productItemSku}
                      optionFilterProp="children"
                      disabled={isReadOnly}
                      onChange={(value, option) => {
                        updateItemTable({
                          index: dynamicRowIndex,
                          targetColumn: "item_sku",
                          option,
                        });
                      }}
                      // onFocus={onFocus}
                      // onBlur={onBlur}
                      // onSearch={onSearch}
                      filterOption={(input, option) => {
                        // console.log('option', option)
                        // option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        return (
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, "item_name"]}
                    fieldKey={[field.fieldKey, "item_name"]}
                    rules={[{ required: true, message: t('missing_item_name') }]}
                    label={dynamicRowIndex === 0 ? t('item_name') : ''}
                  >
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder={t('search_item_name')}
                      options={productItemName}
                      optionFilterProp="children"
                      disabled={isReadOnly}
                      onChange={(value, option) => {
                        // console.log('value', value)
                        // console.log('option', option)
                        updateItemTable({
                          index: dynamicRowIndex,
                          targetColumn: "item_name",
                          option,
                        });
                      }}
                      filterOption={(input, option) => {
                        return (
                          option.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, "quantity"]}
                    fieldKey={[field.fieldKey, "quantity"]}
                    label={dynamicRowIndex === 0 ? t('quantity') : ""}
                  >
                    <InputNumber
                      placeholder={t('quantity')}
                      disabled={isReadOnly}
                      onChange={(value) => {
                        // calculateTotal({
                        //   index: dynamicRowIndex,
                        //   quantity: value
                        // })
                        calculateTotal();
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, "item_price"]}
                    fieldKey={[field.fieldKey, "item_price"]}
                    label={dynamicRowIndex === 0 ? t('price') : ""}
                  >
                    <InputNumber
                      placeholder={t('price')}
                      disabled={isReadOnly}
                      onChange={(value) => {
                        // calculateTotal({
                        //   index: dynamicRowIndex,
                        //   item_price: value
                        // })
                        calculateTotal();
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, "item_total"]}
                    fieldKey={[field.fieldKey, "item_total"]}
                    label={dynamicRowIndex === 0 ? t('item_total') : ""}
                  >
                    <InputNumber placeholder={t('item_total')} disabled />
                  </Form.Item>

                  <MinusCircleOutlined
                    hidden={isReadOnly}
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                </Space>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  disabled={isReadOnly}
                  onClick={() => {
                    add({ quantity: 1 });
                  }}
                  block
                >
                  <PlusOutlined />
                  新增貨品
                </Button>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>

      <Form.Item>
          {initialValues.invoice_type === 'general' && (
          <Button
            className="p_invoice_edit"
            type="primary"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
          )}
          {initialValues.invoice_type !== 'general' && (
          <Button
            className="p_invoice_monthly_edit"
            type="primary"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
          )}
        </Form.Item>
    </Form>
  );
};

export default InvoiceItemForm;
