import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Select, Form, Button, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import * as Service from "../../core/Service";


const HydrationServiceWorkflowReport = ({ dataList }) => {
  const app = useSelector((state) => state.app);
  const [orderNoList, setOrderNoList] = useState([]);
  const [reportForm] = Form.useForm();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let list = _.map(dataList, ({ order_no_prefix, order_no, order_id}) => {
      return {
        label: order_no_prefix + order_no,
        value: order_id,
      };
    });
    setOrderNoList(list);
  }, [dataList]);
  return (
    <Form
      form={reportForm}
      onFinish={async (values) => {
        let {order_id} = values;
        window.location = `${app.config.API_SERVER_URL}/api/report/hydration_service_workflow_report?order_id=${order_id}&language=${i18n.language}`;
      }}
    >
      <Row>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item
            rules={[{ required: true }]}
            label={t("order_no")}
            name="order_id"
          >
            <Select
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              placeholder="Please select"
              options={orderNoList}
              rules={[{ required: true }]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t("export")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default HydrationServiceWorkflowReport;
