import React, { useState, useEffect } from "react";
import {
  notification,
  Tabs,
  Table,
  Badge,
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Tag,
  Empty,
  Select,
  InputNumber,
  Space,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import _ from "lodash";
import * as Service from "../../core/Service";
import * as Main from "../../core/Main";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;
const { Option } = Select;
const { confirm } = Modal;

const AreaDistrictPickUp = (props) => {
  const { t } = useTranslation();

  const [dataList, setDataList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [pickUpType, setPickUpType] = useState([]);
  const [district, setDistrict] = useState([]);

  useEffect(() => {
    fetchData();
    fetchDistrict();
    fetchPickUpType();
  }, []);

  const fetchData = async () => {
    let tabOption = [{
      form_option_id: 0,
      type: "normal",
      name: "一般",
    }]
    let result = await Service.call("get", `/api/form_option/all/area`);
    // console.log('result', result)

    if (result.status !== 1) {
      return notification.warning({ message: "獲取資料失敗" });
    }
    tabOption = _.concat(tabOption, result.data)
    setAreaList(result.data);
    setDataList(tabOption);
  };

  const fetchDistrict = async () => {
    let result = await Service.call(
      "get",
      `/api/form_option/all/district`
    );
    // console.log('result', result)

    if (result.status !== 1) {
      return notification.warning({ message: "獲取資料失敗" });
    }

    setDistrict(result.data);
  };

  const fetchPickUpType = async () => {
    let pickUpTypeList = await Service.call(
      "get",
      `/api/area_district_option_lookup/pick_up`
    );
    setPickUpType(pickUpTypeList);
  };


  return (
    <NavAndSideFrame
      {...props}
      title={t("area_district_pickUp")}
      selectedKey="area_district_pickUp"
    >
      {_.isEmpty(dataList) && <Empty />}

      <Tabs type="card" defaultActiveKey="" onChange={(key) => null}>
        {dataList.map((item) => (
          <TabPane tab={item.name} key={item.name}>
            <TabComponent
              AreaObject={item}
              areaList={areaList}
              district={district}
              pickUpType={pickUpType}
            />
          </TabPane>
        ))}
      </Tabs>
    </NavAndSideFrame>
  );
};

const TabComponent = (props) => {
  const { AreaObject, district, pickUpType, areaList } = props;
  const { t, i18n } = useTranslation();

  const [dataList, setDataList] = useState([]);
  const [recordId, setRecordId] = useState(0);
  const [selectedFormData, setSelectedFormData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const columns = [
    {
      title: t("operation"),
      className: "p_form_option_edit",
      dataIndex: "area_district_option_lookup_id",
      render: (value, record, index) => {
        return (
          <Space>
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                setRecordId(value);
                setSelectedFormData(record);
                setModalVisible(true);
              }}
            />
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => {
                confirm({
                  title: `確認刪除${record.district_name}？`,
                  okText: "是",
                  okType: "danger",
                  cancelText: "否",
                  onOk: async () => {
                    let result = await Service.call(
                      "delete",
                      `/api/area_district_option_lookup/${record.area_district_option_lookup_id}`
                    );
                    // console.log('result', result);

                    if (result.status !== 1) {
                      return notification.error({
                        message: "刪除失敗",
                        description: t(result.errorMessage),
                      });
                    }

                    notification.success({ message: "刪除成功" });
                    getAllData();
                  },
                  onCancel: () => {
                    // console.log('Cancel');
                  },
                });
              }}
            />
          </Space>
        );
      },
    },
    {
      title: `${t("area_name")}`,
      dataIndex: "area_name",
    },
    {
      title: `${t("district_name")}`,
      dataIndex: "district_name",
    },
    {
      title: `${t("pick_up_type")}`,
      dataIndex: "type",
      render: (text, record, index) => {
        let { type } = record;
        return (
          <>
            {type === "" ? (
              <Tag color="grey">{t("na")}</Tag>
            ) : (
              t(type)
            )}
          </>
        );
      },
    },
    {
      title: `${t("related_value")}`,
      dataIndex: "address_name",
      render: (text, record, index) => {
        let { address_name } = record;
        return (
          <>
            {address_name === "" ? (
              <Tag color="grey">{t("na")}</Tag>
            ) : (
              address_name
            )}
          </>
        );
      },
    },
    {
      title: `${t("active")}`,
      dataIndex: "is_active",
      render: (text, record, index) => {
        let { is_active } = record;
        return (
          <>
            {is_active === 1 ? (
              <Tag color="green">{t("active")}</Tag>
            ) : (
              <Tag color="red">{t("inactive")}</Tag>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      let result = await Service.call(
        "get",
        `/api/area_district_option_lookup/all?area_id=${AreaObject.form_option_id}&lang=${i18n.language}`
      );
      // console.log('result', result);

      if (result.status !== 1) {
        return notification.warning({
          message: t("failed_to_obtain_information"),
        });
      }

      setDataList(result.data);
    } catch (error) {
      console.error("error >>> ", error);
    }
  };

  return (
    <>
      <Button
        type="primary"
        className="p_form_option_edit"
        onClick={() => {
          setRecordId(0);
          setSelectedFormData({});
          setModalVisible(true);
        }}
      >
        {t("create")}
      </Button>

      <Modal
        title={recordId > 0 ? t("edit") : t("create")}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <AreaDistrictPickUpForm
          recordId={recordId}
          selectedFormData={selectedFormData}
          AreaObject={AreaObject}
          district={district}
          pickUpType={pickUpType}
          areaList={areaList}
          closeMethod={async () => {
            getAllData();
            setModalVisible(false);
          }}
        />
      </Modal>

      <Table
        columns={columns}
        dataSource={dataList}
        rowKey={() => "area_district_option_lookup"}
      />
    </>
  );
};

const AreaDistrictPickUpForm = (props) => {
  const { t, i18n } = useTranslation();
  const [chosenDistrict, setChosenDistrict] = useState(0);
  const [chosenPickUpType, setChosenPickUpType] = useState("");
  const [addressPickList, setAddressPickList] = useState([]);
  const [hiddenBoolean, setHiddenBoolean] = useState(true);
  const [pickUpHidden, setPickUpHidden] = useState(false);
  const {
    recordId,
    selectedFormData,
    AreaObject,
    district,
    closeMethod,
    pickUpType,
    areaList
  } = props;
  const [districtList, setDistrictList] = useState(district);
  let initialValues = { is_active: 1 };
  let combinedInitialValues = { ...initialValues, ...selectedFormData };
  combinedInitialValues.area_id = AreaObject.form_option_id === 0 ? combinedInitialValues.area_id : AreaObject.form_option_id;

  const [form] = Form.useForm();

  useEffect(() => {
    if (AreaObject.form_option_id === 0) {
      setPickUpHidden(true);
      let newDistrict = district.map((item) => { return {value: item.form_option_id, label: item.name} })
      setDistrictList(newDistrict)
    } else {
      fetchFilterDistrictList();
    }
  }, [])

  useEffect(() => {
    if (chosenDistrict !== 0 && !_.isUndefined(chosenDistrict) && chosenPickUpType !== "" && !_.isUndefined(chosenPickUpType)) {
      checkingRelation();
    }
  }, [chosenDistrict, chosenPickUpType])

  const checkingRelation = async () => {
    let addressResult = await Service.call("get", `/api/form_option/all/${chosenPickUpType}`);
    let option = addressResult.data.map((item) => {
      return ({label: item.name, value: item.form_option_id});
    })
    setAddressPickList(option)
  }
  const fetchFilterDistrictList = async () => {
    let rawDistrictList = await Main.fetchFormOption("district", true)
    let lookup = await Service.call("get", `/api/area_district_option_lookup/search?area_id=${AreaObject.form_option_id}&lang=${i18n.language}`);
    let relatedDistrict = lookup.data.map((item) => {
      return ({value: item.district_id, label: item.district_name, is_default: 0})
    })
    let options = _.intersectionWith(rawDistrictList, relatedDistrict, _.isEqual);
    setDistrictList(options)
  }

  return (
    <Form
      form={form}
      initialValues={combinedInitialValues}
      onFinish={async (values) => {
        if (AreaObject.form_option_id !== 0) values.area_id = AreaObject.form_option_id;
        let method = "post";
        if (recordId !== 0) {
          //  Edit
          method = "patch";
          values.area_district_option_lookup_id = recordId;
        }

        let result = await Service.call(
          method,
          "/api/area_district_option_lookup",
          values
        );
        // console.log('result', result)

        if (result.status !== 1) {
          return notification.error({
            message: t("submission_failed"),
            description: result.errorMessage,
          });
        }

        notification.success({ message: t("submitted_successfully") });
        closeMethod();
      }}
    >
      <Form.Item
        label={t("area")}
        name="area_id"
        rules={[{ required: true }]}
        hidden={!pickUpHidden}
      >
        <Select
          showSearch
          placeholder="搜尋"
          options={areaList.map((item) => {
            return { value: item.form_option_id, label: item.name };
          })}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          onChange={(value, option) => {
            setChosenDistrict(option.value);
          }}
        />
      </Form.Item>
      <Form.Item
        label={t("district")}
        name="district_id"
        rules={[{ required: true }]}
      >
        <Select
          showSearch
          placeholder="搜尋"
          options={districtList}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          onChange={(value, option) => {
            setChosenDistrict(option.value);
          }}
        />
      </Form.Item>

      <Form.Item
        label={t("pick_up_location")}
        name="type"
        hidden={pickUpHidden}
      >
        <Select allowClear onChange={(value) => { setChosenPickUpType(value); if (!_.isUndefined(value) && value !== "") setHiddenBoolean(false); else setHiddenBoolean(true) }}>
          {_.map(pickUpType, (val, key) => {
            return (
              <Option value={key}>{t(`${val}`)}</Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label={t(`${chosenPickUpType}`)}// "地址" / "診所"
        name="form_option_id"
        hidden={hiddenBoolean}
      >
        <Select
          showSearch
          options={addressPickList}
          placeholder={t("click_to_view_options")}
          filterOption={(inputValue, option) => {
            return (
              option.label
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            );
          }}
        />
      </Form.Item>

      <Form.Item
        label={t("active")}
        name="is_active"
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Radio value={1}>{t("active")}</Radio>
          <Radio value={0}>{t("inactive")}</Radio>
        </Radio.Group>
      </Form.Item>

      <Button type="primary" htmlType="submit">
        {t("submit")}
      </Button>
    </Form>
  );
};

export default AreaDistrictPickUp;
