import React, { useState, useEffect } from 'react';
import {Card, Row, Col} from 'antd'
import _ from "lodash";
import queryString from "query-string";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import * as Main from "../../core/Main";
import LanguageList from '../../data/LanguageList.json'

const OrderPublicResult = (props) => {
  const { t, i18n } = useTranslation();
  let location = useLocation();
  const { order_id } = queryString.parse(location.search);
  const [lang, setLang] = useState(_.keys(LanguageList)[0])
  const [loading, setLoading] = useState(true)
  const [orderInfo, setOrderInfo] = useState({})
  useEffect(() => {
    if (!_.isUndefined(order_id)) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      let orderInfoResult = await Service.call(
        "get",
        `/api/order_info_public/${order_id}`
      );
      if (orderInfoResult.status !== 1) {
        return;
      }

      let orderInfoObject = orderInfoResult.results;
      let userResult = await Service.call("get", `/api/public/user/${orderInfoObject.user_id}`)
      if (userResult.status !== 1) {
        return;
      }
      let userObj = userResult.data;
      // console.log(userObj)
      if (!_.isNull(userObj.preferred_language)) setLang(userObj.preferred_language)
      setOrderInfo(orderInfoObject)
      setLoading(false)
    } catch (error) {
      console.error("error >>> ", error);
    }
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: '80vh' }}>
      <Col xs={22} md={10}>
        <Card
          style={{
            width: '100%', marginTop: 16, minHeight: 200, borderWidth: 2, borderRadius: 15
          }}
          bodyStyle={{
            minHeight: 200,
          }}
          loading={loading}
        >
          {lang === _.keys(LanguageList)[1] && (

          <Row justify="center" style={{ textAlign: 'center', minHeight: 'inherit' }} align="middle">
            <Col span={20}>
              <h3 style={{ marginBottom: 20 }}>Thank You !Your Order has been placed!</h3>
              <h2 style={{ textDecoration: 'underline' }}>
                Order No. -
                {' '}
                {orderInfo.order_no_prefix}
                {orderInfo.order_no}
              </h2>
              <p>
                The customer's information has been submitted, and the driver will arrive at the receiving location to receive the pet within two hours. Please write down the order number for future enquiries.
              </p>
            </Col>
          </Row>
          )}
          {lang === _.keys(LanguageList)[0] && (
          <Row justify="center" style={{ textAlign: 'center', minHeight: 'inherit' }} align="middle">
            <Col span={20}>
              <h3 style={{ marginBottom: 20 }}>謝謝，你的訂單已收到！</h3>

              <h2 style={{ textDecoration: 'underline' }}>
                訂單編號 -
                {' '}
                {orderInfo.order_no_prefix}
                {orderInfo.order_no}
              </h2>
              <p>客戶的資料已提交 , 司機會在兩小時內到達接收地點接收寵物，請記下訂單編號以用作日後查詢。</p>
            </Col>
          </Row>
          )}
        </Card>

      </Col>
    </Row>
  )
}

export default OrderPublicResult;
