import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  notification, Button, Space, Input, Divider
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Fuse from 'fuse.js';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import FormUploadExcel from '../../components/form/FormUploadExcel';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import UserAccountTable from '../../components/UserAccountTable';

const debug = require('debug')('app:admin:client:src:userAccount');

let fuse = [];

const DEFAULT_PAGINATION = {pageSize: 10, current: 1, total: 0}

const UserAccount = (props) => {
  const history = useHistory();
  const { t } = useTranslation()
  const inputRef = useRef();

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)

  const [searchInput, setSearchInput] = useState('')

  const [loading, setLoading] = useState(false);

  const [dataList, setDataList] = useState([]);

  const getAllData = async (tablePagination = pagination, searchWord = searchInput) => {
    try {
      setLoading(true);
      let result = await Service.call('get', `/api/user/list`, {...tablePagination, searchWord});
      if (result.status !== 1) {
        notification.warning({ message: '獲取資料失敗' });
        return;
      }


      let data = _.orderBy(result.data.dataList, ['ctime'], ['desc']);
      setDataList(data);
      setPagination({...tablePagination, total: result.data.total})
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllData()
  }, [])

  const debouncedSearch = useRef(
    _.debounce(async (searchWord) => {
      getAllData(DEFAULT_PAGINATION, searchWord)
    }, 300)
  ).current;

  const filterData = async (filterObj = {}) => {
    setSearchInput(filterObj.searchWord)
    debouncedSearch(filterObj.searchWord)
  }

  const importUserCSV = async (filepath) => {
    let resp = await Service.call('post', '/api/user/import', {
      filepath,
    });

    let description = " "
    if (resp.arr.length > 0) {
      description = `Row ${resp.arr} import failed`
    }

    notification.success({
      message: `User import done. ${resp.succeed} succeed, ${resp.failed} failed.`,
      description,
      duration: 0
    });

    getAllData()
  }

  const paginationOnChange = useCallback((tablePagination) => {
    getAllData(tablePagination)
  }, [getAllData, pagination])

  return (
    <NavAndSideFrame {...props} title={t("user_account_list")} selectedKey="user_account">
      <Space direction="horizontal" size="large">
        <Button
          type="primary"
          className="p_user_edit"
          onClick={() => {
            history.push('/company/user_account_form')
          }}
        >
          {t('add')}
        </Button>
        <Button
          type="primary"
          onClick={() => {
            Main.exportToCSV(dataList, 'excel')
          }}
        >
          {t('export')}
        </Button>

        <FormUploadExcel
          onSuccess={(resp) => {
            const {
              originalname,
              filename,
              filepath,
              url,
            } = resp;
            importUserCSV(filepath)
          }}
        />
        <>
          {t('search')}
        </>
        <Input
          ref={inputRef}
          placeholder={t('please_input_keyword')}
          onChange={(event) => {
            filterData({ searchWord: event.target.value })
          }}
        />
      </Space>
      <Divider />
      <UserAccountTable
        dataObj={dataList}
        companyChangeData={getAllData}
        pagination={pagination}
        paginationOnChange={paginationOnChange}
        loading={loading}
      />
    </NavAndSideFrame>
  )
}

export default UserAccount;
