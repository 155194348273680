import React, {useEffect, useState} from "react";
import {
  Button, Tooltip, Modal, Select, notification
} from "antd";
import "antd/dist/antd.css";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import * as Service from "../core/Service";

const InvoiceSelection = ({
  modalVisible = false,
  setModalVisible = () => null,
  order_id = 0,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [invoiceNoOptions, setInvoiceNoOptions] = useState([]);
  const [invoiceNo, setInvoiceNo] = useState('');

  useEffect(() => {
    fetchInvoiceNo();
  }, [])

  const fetchInvoiceNo = async () => {
    try {
      let result = await Service.call('get', `/api/invoice_info/order/${order_id}`);
      const {
        status,
        data,
        errorMessage,
      } = result;
      if (status !== 1) {
        notification.warning({ message: errorMessage });
        return;
      }

      let invoiceNoList = data.map((item) => {
        const {invoice_no, invoice_no_prefix} = item;
        return {
          value: invoice_no,
          label: `${invoice_no_prefix}${invoice_no}`
        }
      })
      setInvoiceNoOptions(invoiceNoList)
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  return (
    <>
      <Modal
        title={t("please_select_invoice")}
        visible={modalVisible}
        onOk={(e) => {
          if (!_.isInteger(invoiceNo)) {
            console.log(`invoiceNo is not integer`)
            return;
          }
          history.push(`/company/receipt_info_form?invoice_no=${invoiceNo}`)
        }}
        onCancel={(e) => {
          setModalVisible(false)
        }}
      >
        <Select
          showSearch
          style={{ width: 400 }}
          placeholder={t("search_invoice_number")}
          options={invoiceNoOptions}
          optionFilterProp="children"
          onChange={(value) => {
            // console.log('value', value)
            setInvoiceNo(value)
          }}
          filterOption={(input, option) => {
            const { label, value } = option;
            return _.toString(label).toLowerCase().indexOf(input.toLowerCase()) >= 0
          }}
        />
      </Modal>
    </>
  );
};

export default InvoiceSelection;
