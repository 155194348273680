import React, { useState, useEffect } from 'react';
import {
  Button, Layout, Table, Tooltip, Modal, notification, Badge, Space, Tag
} from 'antd';
import {
  DeleteOutlined, EditOutlined, CloseOutlined, CheckOutlined, FilePdfOutlined, FileAddOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import CreatePdfButton from '../../components/CreatePdfButton';
import DownloadPdfButton from '../../components/DownloadPdfButton';

const { confirm } = Modal;

const InvoiceInfoTable = (props) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const tableIDName = "invoice_info_id";
  const history = useHistory();
  const { dataObj, fetchData, isReadOnly, loading, pagination, paginationOnChange } = props
  const meta = useSelector((state) => state.app.meta);

  useEffect(() => {
    setDataList(dataObj)
    // console.log("dataObj>>", dataObj)
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record) => {
          let { invoice_pdf, receipt_pdf } = record
          return (
            <Space>
              <Tooltip title={t("modify")}>
                <Button
                  disabled={isReadOnly}
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    history.push(`/company/invoice_info_form?invoice_info_id=${value}`)
                  }}
                />
              </Tooltip>
              <Tooltip title={t('void')}>
                {record.invoice_type === 'general' && (
                <Button
                  disabled={isReadOnly}
                  className="p_invoice_edit"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    confirm({
                      title: `${t('confirm_delete')} ${record.invoice_no}？`,
                      okText: t('yes'),
                      okType: 'danger',
                      cancelText: t('no'),
                      onOk: async () => {
                        let result = await Service.call('delete', `/api/invoice_info/${record.invoice_info_id}`);
                        if (result.status !== 1) {
                          return notification.error({ message: t('fail'), description: t(result.errorMessage) });
                        }
                        notification.success({ message: t('success') });
                        fetchData()
                      },
                      onCancel: () => {
                        // console.log('Cancel');
                      },
                    });
                  }}
                />
                )}
                {record.invoice_type !== 'general' && (
                <Button
                  disabled={isReadOnly}
                  className="p_invoice_monthly_edit"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    confirm({
                      title: `${t('confirm_delete')} ${record.invoice_no}？`,
                      okText: t('yes'),
                      okType: 'danger',
                      cancelText: t('no'),
                      onOk: async () => {
                        let result = await Service.call('delete', `/api/invoice_info/${record.invoice_info_id}`);
                        if (result.status !== 1) {
                          return notification.error({ message: t('fail'), description: t(result.errorMessage) });
                        }
                        notification.success({ message: t('success') });
                        fetchData()
                      },
                      onCancel: () => {
                        // console.log('Cancel');
                      },
                    });
                  }}
                />
                )}
              </Tooltip>
              <CreatePdfButton
                title={`${t('create_invoice')} PDF`}
                params={{invoice_info_id: value}}
                // className={record.invoice_type === 'general' ? "p_invoice_edit" : 'p_invoice_monthly_edit'}
                apiPath="/api/invoice_info/generate_pdf"
                type="invoice"
                fetchData={fetchData}
              />
              {/* <Tooltip title={`${t('create_invoice')} PDF`}>
                {record.invoice_type === 'general' && (
                <Button
                  shape="circle"
                  className="p_invoice_edit"
                  icon={<FileAddOutlined />}
                  onClick={async () => {
                    let result = await Service.call('post', '/api/invoice_info/generate_pdf', { invoice_info_id: value })
                    // console.log('result', result)
                    if (result.status !== 1) return
                    notification.success({ message: t('success') });
                    if (result.data.alert === 1) notification.warn({ message: '提醒！該用戶已超過信貸金額！'})
                    fetchData()
                  }}
                />
                )}
                {record.invoice_type !== 'general' && (
                <Button
                  shape="circle"
                  className="p_invoice_monthly_edit"
                  icon={<FileAddOutlined />}
                  onClick={async () => {
                    let result = await Service.call('post', '/api/invoice_info/generate_pdf', { invoice_info_id: value })
                    // console.log('result', result)
                    if (result.status !== 1) return
                    notification.success({ message: t('success') });
                    if (result.data.alert === 1) notification.warn({ message: '提醒！該用戶已超過信貸金額！'})
                    fetchData()
                  }}
                />
                )}
              </Tooltip> */}
              {invoice_pdf && (
                <DownloadPdfButton
                  title={`${t('download_invoice')} PDF`}
                  // className={record.invoice_type === 'general' ? "p_invoice_edit" : 'p_invoice_monthly_edit'}
                  apiPath={`/api/invoice_info/invoice_pdf/${invoice_pdf}`}
                  preferred_language={record.preferred_language}
                  type="invoice"
                  order_no_with_prefix={_.first(record.order_info).order_no_with_prefix}
                  invoice_or_receipt_no_with_prefix={record.invoice_no_with_prefix}
                />
              )}
              {/* {invoice_pdf && (
                <Tooltip title={`${t('download_invoice')} PDF`}>
                  {record.invoice_type === 'general' && (
                  <Button
                    shape="circle"
                    className="p_invoice_edit"
                    icon={<FilePdfOutlined />}
                    onClick={async () => {
                      let result = await Service.call('get', `/api/invoice_info/invoice_pdf/${invoice_pdf}`, {}, { responseType: 'blob' })
                      // console.log('result', result)
                      if (!result) return
                      const pdfBlob = new Blob([result], { type: 'application/pdf' });

                      let filename = []
                      let language = !_.isEmpty(record.preferred_language) ? record.preferred_language : 'zh_hant'
                      if (language === 'zh_hant') filename.push('發票')
                      if (language === 'en') filename.push('Invoice')
                      filename.push(`${_.first(record.order_info).order_no_with_prefix}-`)
                      filename.push(record.invoice_no_with_prefix)
                      saveAs(pdfBlob, _.join(filename, ''))
                    }}
                  />
                  )}
                  {record.invoice_type !== 'general' && (
                  <Button
                    shape="circle"
                    className="p_invoice_monthly_edit"
                    icon={<FilePdfOutlined />}
                    onClick={async () => {
                      let result = await Service.call('get', `/api/invoice_info/invoice_pdf/${invoice_pdf}`, {}, { responseType: 'blob' })
                      // console.log('result', result)
                      if (!result) return
                      const pdfBlob = new Blob([result], { type: 'application/pdf' });
                      let filename = []
                      let language = !_.isEmpty(record.preferred_language) ? record.preferred_language : 'zh_hant'
                      if (language === 'zh_hant') filename.push('發票')
                      if (language === 'en') filename.push('Invoice')
                      filename.push(`${_.first(record.order_info).order_no_with_prefix}-`)
                      filename.push(record.invoice_no_with_prefix)
                      saveAs(pdfBlob, _.join(filename, ''))
                    }}
                  />
                  )}
                </Tooltip>
              )} */}
              <CreatePdfButton
                title={`${t('create_receipt')} PDF`}
                params={{invoice_info_id: value}}
                // className={record.invoice_type === 'general' ? "p_invoice_edit" : 'p_invoice_monthly_edit'}
                apiPath="/api/invoice_info/generate_receipt_pdf"
                type="receipt"
                fetchData={fetchData}
              />
              {/* <Tooltip title={`${t('create_receipt')} PDF`}>
                {record.invoice_type === 'general' && (
                <Button
                  shape="circle"
                  className="p_invoice_edit"
                  icon={<FileAddOutlined />}
                  onClick={async () => {
                    let result = await Service.call('post', '/api/invoice_info/generate_receipt_pdf', { invoice_info_id: value })
                    // console.log('result', result)
                    if (result.status !== 1) return
                    if (result.data.alert === 1) notification.warn({ message: '提醒！該用戶已超過信貸金額！'})

                    notification.success({ message: '成功' });
                    fetchData()
                  }}
                />
                )}
                {record.invoice_type !== 'general' && (
                <Button
                  shape="circle"
                  className="p_invoice_monthly_edit"
                  icon={<FileAddOutlined />}
                  onClick={async () => {
                    let result = await Service.call('post', '/api/invoice_info/generate_receipt_pdf', { invoice_info_id: value })
                    // console.log('result', result)
                    if (result.status !== 1) return
                    if (result.data.alert === 1) notification.warn({ message: '提醒！該用戶已超過信貸金額！'})

                    notification.success({ message: '成功' });
                    fetchData()
                  }}
                />
                )}
              </Tooltip> */}
              {receipt_pdf && (
                <DownloadPdfButton
                  title={`${t('download_receipt')} PDF`}
                  // className={record.invoice_type === 'general' ? "p_invoice_edit" : 'p_invoice_monthly_edit'}
                  apiPath={`/api/invoice_info/receipt_pdf/${receipt_pdf}`}
                  preferred_language={record.preferred_language}
                  type="receipt"
                  order_no_with_prefix={_.first(record.order_info).order_no_with_prefix}
                  invoice_or_receipt_no_with_prefix={record.receipt_no_with_prefix}
                />
              )}
              {/* {receipt_pdf && (
                <Tooltip title={`${t('download_receipt')} PDF`}>
                  {record.invoice_type === 'general' && (
                  <Button
                    shape="circle"
                    className="p_invoice_edit"
                    icon={<FilePdfOutlined />}
                    onClick={async () => {
                      let result = await Service.call('get', `/api/invoice_info/receipt_pdf/${receipt_pdf}`, {}, { responseType: 'blob' })
                      // console.log('result', result)
                      if (!result) return
                      const pdfBlob = new Blob([result], { type: 'application/pdf' });

                      let filename = []
                      let language = !_.isEmpty(record.preferred_language) ? record.preferred_language : 'zh_hant'
                      if (language === 'zh_hant') filename.push('收據')
                      if (language === 'en') filename.push('Receipt')
                      filename.push(`${_.first(record.order_info).order_no_with_prefix}-`)
                      filename.push(record.receipt_no_with_prefix)
                      saveAs(pdfBlob, _.join(filename, ''))
                    }}
                  />
                  )}
                  {record.invoice_type !== 'general' && (
                  <Button
                    shape="circle"
                    className="p_invoice_monthly_edit"
                    icon={<FilePdfOutlined />}
                    onClick={async () => {
                      let result = await Service.call('get', `/api/invoice_info/receipt_pdf/${receipt_pdf}`, {}, { responseType: 'blob' })
                      // console.log('result', result)
                      if (!result) return
                      const pdfBlob = new Blob([result], { type: 'application/pdf' });
                      saveAs(pdfBlob, receipt_pdf)
                    }}
                  />
                  )}
                </Tooltip>
              )} */}
            </Space>
          )
        }
      },
      {
        title: t('invoice_status'),
        dataIndex: `invoice_status`,
        render: (value, record) => {
          let invoiceStatusDisplay = ''
          switch (value) {
            case 'v':
              invoiceStatusDisplay = (<Tag color="red">{t('void')}</Tag>)
              break;

            case '':
            default:
              invoiceStatusDisplay = (<Tag color="green">{t('normal')}</Tag>)
              break;
          }
          return invoiceStatusDisplay
        }
      },
      {
        title: t('type'),
        dataIndex: `invoice_type`,
        render: (value) => {
          if (value === 'general') return t('general')
          if (value === 'monthly') return t('monthly')
          if (value === 'wholesale') return t('wholesale')
        }
      },
      {
        title: t('invoice_no'),
        dataIndex: `invoice_no`,
        render: (value, record) => {
          return (`${record.invoice_no_prefix}${value}`)
        }
      },
      {
        title: t('order_no'),
        dataIndex: `invoice_order_lookup`,
        render: (value, record) => {
          let orderNoList = value.map((item, index) => (index > 0 ? ', ' : '') + 'SC' + item.order_no)
          return orderNoList
        }
      },
      // {
      //   title: t('pet_no'),
      //   dataIndex: `order_info`,
      //   render: (value, record) => {
      //     let petNoList = value.map((item, index) => (index > 0 ? ', ' : '') + item.pet_no)
      //     return petNoList
      //   }
      // },
      {
        title: t('owner_mobile'),
        dataIndex: `order_info`,
        render: (value, record) => {
          let ownerMobileList = value.map((item, index) => (index > 0 ? ', ' : '') + item.owner_mobile)
          return ownerMobileList
        }
      },
      {
        title: t('invoice_date'),
        dataIndex: `invoice_date`,
        render: (value) => {
          return Main.displayMoment(value);
        }
      },
      {
        title: t('invoice_total'),
        dataIndex: `invoice_total`,
      },
      {
        title: t('payment_total'),
        dataIndex: `payment_total`,
      },
      {
        title: t('remain_amount'),
        dataIndex: `invoice_total`,
        render: (value, record) => {
          return value - record.payment_total
        }
      },
      {
        title: t('payment_status'),
        dataIndex: `payment_status`,
        render: (value, record) => {
          let paymentStatus = ''
          switch (value) {
            case 'f':
              paymentStatus = '完全付款'
              break;
            default:
              paymentStatus = '未付款'
              break;
          }
          return (paymentStatus)
        }
      },
    ];

    return columns;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        loading={loading}
        pagination={pagination}
        onChange={paginationOnChange}
      />
    </Layout>
  )
}

export default InvoiceInfoTable;
