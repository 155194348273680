import React, { Component, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Divider,
  Form,
  Icon,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  message,
  notification,
  Space,
  Input,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  CheckOutlined,
  SearchOutlined,
  FileAddOutlined,
  FilePdfOutlined
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { saveAs } from 'file-saver';
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from 'query-string';
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import FormUploadFile from '../../components/form/FormUploadFile';
import PPSignature from '../../components/PPSignature';
import CreatePdfButton from "../../components/CreatePdfButton";
import DownloadPdfButton from "../../components/DownloadPdfButton";

const debug = require('debug')('app:admin:client:src:pages:OrderInfoServiceSignatureForm');

const OrderInfoServiceSignatureForm = (props) => {
  const history = useHistory();
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)
  const { t } = useTranslation()
  let location = useLocation();

  let { order_id } = queryString.parse(location.search);
  let { initialValues, order_id: orderIdProp = 0, isReadOnly } = props
  if (orderIdProp > 0) {
    order_id = orderIdProp // Override order_id if prop existing
  }

  const { service_signature } = initialValues

  const [form] = Form.useForm();
  const [signatureFormInitialValues, setSignatureFormInitialValues] = useState(initialValues);
  const [postImageURL, setFileURL] = useState(null);
  const [filePath, setFilePath] = useState(null);
  const [viewCeremonyInfo, setViewCeremonyInfo] = useState(false);

  useEffect(() => {
    checkPermission();
  }, []);

  useEffect(() => {
    setFileURL(`${STATIC_SERVER_URL}/${service_signature}`);
    setFilePath(service_signature)
  }, [service_signature])

  useEffect(() => {
    let newDefaultValues = { ...signatureFormInitialValues, ...initialValues };
    setSignatureFormInitialValues(newDefaultValues);
  }, [initialValues])

  useEffect(() => {
    form.resetFields();
  }, [signatureFormInitialValues]);

  const checkPermission = async () => {
    // call new api check p_order_ceremony_view
    let result = await Service.call("get", `/api/check_permission/p_order_ceremony_view?checkOnly=true`);
    // console.log(`result cp777 >> `, result)
    const {
      status = -1,
    } = result;

    if (status !== -1) {
      setViewCeremonyInfo(true);
    }
  }

  const uploadOnChange = (info) => {
    console.log('info', info)
    const { status, response } = info.file;
    if (status === 'done') {
      message.destroy()
      if (response.status > 0) {
        message.success('Uploaded file');
        let data = {
          filename: response.filename,
          filepath: response.filepath,
          url: response.url,
        };
        setFilePath(data.filepath);
        setFileURL(data.url);
      } else {
        message.error(t('common:Failed'))
      }
    }
  };

  const onFinished = async (obj) => {
    if (_.isEmpty(filePath)) {
      return message.error(t('missing_filePath'))
    }

    const postData = {
      order_id,
      service_signature: filePath,
    };

    // return debug('postData', postData)

    const data = await Service.call('patch', '/api/order_info', postData)
    if (data.status !== 1) {
      notification.error({ message: '提交失敗', description: data.errorMessage });
      return;
    }

    notification.success({ message: '提交成功' });
    props.onChange()
  }

  if (!viewCeremonyInfo) {
    return (
      <>
        <div style={{marginTop: 75, textAlign: 'center', fontWeight: 'bold'}}>
          Unauthorized Access
        </div>
      </>
    )
  }

  return (
    <>
      <CreatePdfButton
        title={t("create_service_detail_form_pdf")}
        type="service_form"
        params={{ order_info_id: order_id }}
        // className="p_order_edit"
        className="p_order_ceremony_edit"
        apiPath="/api/order_info/pdf/service_form"
        fetchData={props.onChange}
        buttonStyle={{ marginRight: 8, color: '#000000' }}
      />
      {initialValues.service_form_pdf && (
        <DownloadPdfButton
          title={t("view_service_detail_form_pdf")}
          apiPath={`/api/order_info/pdf/service_form/${initialValues.service_form_pdf}`}
          preferred_language={initialValues.preferred_language}
          type="service_form"
          order_no_with_prefix={initialValues.order_no_prefix + initialValues.order_no}
          buttonStyle={{ marginRight: 8, color: '#000000' }}
        />
      )}
      <PPSignature isReadOnly={isReadOnly} />

      <Form
        layout="vertical"
        form={form}
        initialValues={signatureFormInitialValues}
        onFinish={onFinished}
      >
        <Form.Item
          label={t('upload_signature')}
        >
          <FormUploadFile
            isReadOnly={isReadOnly}
            data={{ scope: "public" }}
            onChange={uploadOnChange}
            imageURL={postImageURL}
          />
        </Form.Item>

        <Form.Item
          label={t("signature_date")}
          name="service_signature_date"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item>
          <Button
            disabled={isReadOnly}
            type="primary"
            className="p_order_ceremony_edit"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default OrderInfoServiceSignatureForm;
