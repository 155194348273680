import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Tooltip, Modal, Row, Col
} from 'antd';
import {
  EditOutlined, DeleteOutlined, PlusOutlined, FilePdfOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from 'react-pdf';
import * as Service from '../../core/Service';
import finishList from '../../data/finishList.json'
import * as Main from "../../core/Main";

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const OrderInfoPlantSpaceForm = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { t } = useTranslation();
  const companyUser = useSelector((state) => state.app.company_user);

  let { order_id } = queryString.parse(location.search);
  let { initialValues, order_id: orderIdProp = 0, isReadOnly } = props
  if (orderIdProp > 0) {
    order_id = orderIdProp // Override order_id if prop existing
  }

  const [plantSpaceForm] = Form.useForm();
  const [viewCeremonyInfo, setViewCeremonyInfo] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    checkPermission();
  }, []);

  useEffect(() => {
    plantSpaceForm.resetFields();
  }, [initialValues]);

  useEffect(() => {
    fetchStaff();
    fetchSpaceRental();
  }, [])

  const checkPermission = async () => {
    // call new api check p_order_ceremony_view
    let result = await Service.call("get", `/api/check_permission/p_order_ceremony_view?checkOnly=true`);
    // console.log(`result cp777 >> `, result)
    const {
      status = -1,
    } = result;

    if (status !== -1) {
      setViewCeremonyInfo(true);
    }
  }

  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)
  const [staffOptions, setStaffOption] = useState([]);
  const [spaceRentalOptions, setSpaceRentalOption] = useState([]);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [pdfPath, setPdfPath] = useState('')
  const [numPages, setNumPages] = useState(null);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }
  const getMetaKey = async () => {
    let meta = "plant_space_floor_plan"
    let metaKey = await Service.call('get', `/api/admin/meta_by_key?meta_key=${meta}`)
    let [floorPlan] = metaKey.data
    return floorPlan.meta_value;
  }

  useEffect(() => {
    if (!companyUser.company_user_id || !staffOptions) { return }
    plantSpaceForm.setFieldsValue({plant_space_consultant_staff_id: companyUser.company_user_id})
  }, [companyUser, plantSpaceForm, staffOptions])

  const fetchStaff = async () => {
    let result = await Service.call("get", `/api/company/user`);
    if (result.status !== 1) return;
    let userInfoList = result.data;
    let staffOption = userInfoList.map((entity) => {
      return {label: entity.company_user_id + '-' + entity.first_name + " " + entity.last_name, value: entity.company_user_id}
    })
    setStaffOption(staffOption);
  };
  const fetchSpaceRental = async () => {
    let result = await Service.call("get", `/api/space_rental?type=plant`);
    if (result.status !== 1) return;
    let spaceRentalList = result.data;
    spaceRentalList.sort((a, b) => {
      return a.order_id - b.order_id
    })
    let spaceRentalOption = spaceRentalList.map((entity) => {
      if (entity.status !== 'a') return {label: entity.space_no, value: entity.space_rental_id, disabled: true}
      return {label: entity.space_no, value: entity.space_rental_id}
    })
    setSpaceRentalOption(spaceRentalOption);
  };
  const updatePlantSpaceEndTime = () => {
    let plant_space_start_date = plantSpaceForm.getFieldValue().plant_space_start_date;
    let plant_space_duration = plantSpaceForm.getFieldValue().plant_space_duration;

    if (!plant_space_start_date || !plant_space_duration) {
      return;
    }

    let plant_space_end_date = moment(plant_space_start_date).add(
      plant_space_duration,
      "months"
    ).subtract(
      1,
      "days"
    );

    plantSpaceForm.setFieldsValue({ plant_space_end_date });
  };

  // ger redux site meta value
  const meta = useSelector((state) => state.app.meta);

  const dateFormat = `${
    !_.isEmpty(meta.system_date_format.meta_value) ? meta.system_date_format.meta_value : 'YYYY/MM/DD'
  } ${
    !_.isEmpty(meta.system_time_format.meta_value) ? meta.system_time_format.meta_value : 'HH:mm'
  }`
  const StartEndDateFormat = `${
    !_.isEmpty(meta.system_date_format.meta_value) ? meta.system_date_format.meta_value : 'YYYY/MM/DD'
  }`

  console.log('init', initialValues)

  if (!viewCeremonyInfo) {
    return (
      <>
        <div style={{marginTop: 75, textAlign: 'center', fontWeight: 'bold'}}>
          Unauthorized Access
        </div>
      </>
    )
  }

  return (
    <div>
      <Form
        visible={pdfModalVisible}
        form={plantSpaceForm}
        name="plantSpaceForm"
        layout="vertical"
        initialValues={{
          ...initialValues,
          plant_space_rental_id: initialValues.plant_space_rental_id ? initialValues.plant_space_rental_id : undefined
        }}
        onFinish={async (values) => {
          if (_.isUndefined(order_id)) {
            notification.error({ message: '提交失敗', description: '請先提交客戶及寵物資料' });
            return;
          }
          if (values.plant_space_rental_id !== 0 && (_.isNull(values.plant_space_start_date) || _.isNull(values.plant_space_end_date))) {
            notification.error({message: "提交失敗", description: "The Rental period should not be empty."});
            return;
          }

          if (values.plant_space_no === "") {
            values.plant_space_no = null
          }

          values.order_id = order_id;
          setSubmitLoading(true);
          let data = await Service.call('patch', '/api/order_info', values);
          // console.log('data', data);

          if (data.status !== 1) {
            notification.error({ message: '提交失敗', description: data.errorMessage });
            setSubmitLoading(false);
            return;
          }
          let rentalObj = {
            space_rental_id: values.plant_space_rental_id,
            order_id,
            start_date: values.plant_space_start_date,
            end_date: values.plant_space_end_date,
            status: "o",
          }
          let rentalResult = await Service.call('patch', '/api/space_rental', rentalObj)
          console.log("Result", rentalResult)

          notification.success({ message: '提交成功' });
          setSubmitLoading(false);
          props.onChange()
        }}
      >

        <Divider>
          ***
          {' '}
          {t("plant_space_rental_info")}
          {' '}
          ***
        </Divider>

        <Row>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              name="plant_space_meet_up_time_status"
              label={t("plant_space_meet_up_time_status")}
            >
              <Select disabled={isReadOnly}>
                {_.map(finishList, (val, key) => {
                  return (
                    <Option value={val}>{t(`${key}`)}</Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label={t("plant_space_meet_up_time")}
              name="plant_space_meet_up_time"
              // rules={[{ required: true }]}
            >
              <DatePicker
                disabled={isReadOnly}
                showTime
                showNow={false}
                format={dateFormat}
                style={{ width: "100%" }}
                placeholder={t('please_select')}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label={t("plant_space_renewal_type")}
              name="plant_space_renewal_type"
              defaultValue=""
            >
              <Select
                options={[
                  {label: t('please_select'), value: ''},
                  {label: t('inform_customer_renewal'), value: 'inform_customer_renewal'},
                  {label: t('customer_coming_in'), value: 'customer_coming_in'}
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={t("plant_space_floor_plan")}
          name="plant_space_floor_plan"
        >
          <Tooltip title="查看PDF">
            <Button
              disabled={isReadOnly}
              shape="circle"
              icon={<FilePdfOutlined />}
              onClick={async () => {
                setPdfModalVisible(true)
                let metaPath = await getMetaKey()
                setPdfPath(`${STATIC_SERVER_URL}/${metaPath}`)
              }}
            />
          </Tooltip>
        </Form.Item>

        <Form.Item
          label={t("plant_space_no")}
          name="plant_space_no"
          hidden
        >
          <Input disabled={isReadOnly} />
        </Form.Item>
        <Form.Item
          label={t("plant_space_no")}
          name="plant_space_rental_id"
        >
          <Select
            disabled={isReadOnly}
            showSearch
            options={_.sortBy(spaceRentalOptions, ['label'])}
            placeholder={t("click_to_view_options")}
            filterOption={(inputValue, option) => {
              return (
                option.label
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              );
            }}
            onChange={(value, option) => {
              plantSpaceForm.setFieldsValue({ plant_space_no: option.label });
            }}
          />
        </Form.Item>

        <Form.Item
          label={t("plant_space_no_remark")}
          name="plant_space_no_remark"
        >
          <Input disabled={isReadOnly} />
        </Form.Item>

        <Form.Item
          label={t("plant_space_duration")}
          name="plant_space_duration"
        >
          <InputNumber
            disabled={isReadOnly}
            min={0}
            max={999}
            onChange={(value) => {
              updatePlantSpaceEndTime();
            }}
          />
        </Form.Item>

        <Form.Item
          label={t("plant_space_start_date")}
          name="plant_space_start_date"
        >
          <DatePicker
            disabled={isReadOnly}
            format={StartEndDateFormat}
            onChange={(value) => {
              updatePlantSpaceEndTime();
            }}
            placeholder={t('please_select')}
          />
        </Form.Item>

        <Form.Item
          label={t("plant_space_end_date")}
          name="plant_space_end_date"
        >
          <DatePicker
            disabled
            format={StartEndDateFormat}
          />
        </Form.Item>

        <Form.Item
          label={t("plant_space_remark")}
          name="plant_space_remark"
        >
          <Input disabled={isReadOnly} />
        </Form.Item>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label={t('consultant_staff')}// "地址" / "診所"
              name="plant_space_consultant_staff_id"
            >
              <Select
                disabled={isReadOnly}
                showSearch
                options={staffOptions}
                placeholder={t("click_to_view_options")}
                filterOption={(inputValue, option) => {
                  return (
                    option.label
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            disabled={isReadOnly || submitLoading}
            loading={submitLoading}
            className="p_order_ceremony_edit"
            type="primary"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
      <Modal
        visible={pdfModalVisible}
        footer={null}
        width="99%"
        destroyOnClose
        onCancel={() => {
          setPdfModalVisible(false);
        }}
      >
        <div>
          <Document
            file={pdfPath}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {_.times(numPages, (index) => { return index + 1 }).map((page) => (
              <Page pageNumber={page} width={1200}>
                Page
                {' '}
                {page}
                {' '}
                of
                {' '}
                {numPages}
              </Page>
            ))}
          </Document>
        </div>
      </Modal>
    </div>
  )
}

export default OrderInfoPlantSpaceForm;
